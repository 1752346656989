import { Button, Snackbar, Typography } from "@material-ui/core";
import axios from "axios";
import React, { Component } from "react";
import { API } from "../Constants/APIs";

export default class ForgotPassword extends Component {
  state = {
    email: "",
    password: "",
    loading: false,
    successLoading: false,
    successMsgL: "",
  };
  handlechange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  login = (e) => {
    e.preventDefault();
    this.setState({ loading: true });
    let payload = {
      email: this.state.email,
      password: this.state.password,
    };
    axios.post(API.postUrls.login, payload).then((resp) => {
      if (resp.data.status === "success") {
        this.setState({
          loading: false,
          successLoading: true,
          successMsg: resp.data.message,
        });
        localStorage.setItem("token", resp.data.user.accessToken);
        setTimeout(() => {
          this.setState({
            successLoading: false,
          });
          window.location.href = "/";
        }, 2000);
      } else {
        this.setState({
          loading: false,

          errorLoading: true,
          errorMsg: resp.data.message,
        });
        setTimeout(() => {
          this.setState({
            errorLoading: false,
          });
          document.getElementById("myForm").reset();
        }, 2000);
      }
    });
  };
  render() {
    return (
      <div className="SignInWrapper">
        <div className="container signIn">
          {this.state.successLoading ? (
            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              open={true}
              style={{ top: "102px" }}
              color={"#bdbdbd"}
              autoHideDuration={6000}
              message={this.state.successMsg}
            ></Snackbar>
          ) : null}
          <h4 className="mainHeading"> Reset Password</h4>

          <form onSubmit={this.login} id="myForm">
            <div className="inputWrapper">
              <span className="spanTag emailTag">Email</span>

              <input
                className="Input"
                name="email"
                required
                onChange={this.handlechange}
              />
            </div>

            <br />

            <Button
              variant="contained"
              type="submit"
              className="submitBtn"
              style={{ marginLeft: "51px", marginBottom: "45px" }}
            >
              {this.state.loading ? "Sending..." : "Submit"}
            </Button>
            <Typography>
              {" "}
              We'll send a password reset link to this email id if{" "}
            </Typography>
            <Typography> a registered Actuality account is found. </Typography>
          </form>
        </div>
      </div>
    );
  }
}
