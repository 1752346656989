import {
  Button,
  CircularProgress,
  Container,
  Snackbar,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import axios from "axios";
import React, { Component } from "react";
import { API } from "../Constants/APIs";

export default class Contact extends Component {
  state = {
    name: "",
    nameError: "",
    email: "",
    emailError: "",
    message: "",
    messageError: "",
    loading: false,
    setLessonImage: [],
    files: [],
  };
  isErrorInForm = () => {
    return (
      this.state.emailError || this.state.nameError || this.state.messageError
    );
  };

  canSubmit = () => {
    return (
      this.state.name &&
      this.state.email &&
      this.state.message &&
      !this.isErrorInForm()
    );
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  contactUs = (e) => {
    e.preventDefault();
    if (this.isErrorInForm()) return;
    // console.log(this.state.files);
    let data = new FormData();
    for (let i = 0; i < this.state.files.length; i++) {
      data.append(`files`, this.state.files[i]);
    }
    this.setState({ loading: true });
    data.append("name", this.state.name);
    data.append("email", this.state.email);
    data.append("message", this.state.message);
    if (this.state.files) {
      data.append("files", this.state.files);
    }

    // console.log(data);
    axios.post(API.postUrls.contact, data).then((resp) => {
      if (resp.data.status === "success") {
        this.setState({
          loading: false,
          successLoading: true,
          successMsg: resp.data.message,
        });
        setTimeout(() => {
          this.setState({
            successLoading: false,
            name: "",
            email: "",
            message: "",
            loading: false,
            setLessonImage: [],
            files: [],
          });
          document.getElementById("myForm").reset();
        }, 2000);
      } else {
        this.setState({
          loading: false,

          errorLoading: true,
          errorMsg: resp.data.message,
        });
        setTimeout(() => {
          this.setState({
            errorLoading: false,
          });
          document.getElementById("myForm").reset();
        }, 2000);
      }
    });
  };
  validateFields = {
    message: (val) => ({
      messageError: val?.trim().length > 0 ? "" : "Message is required",
    }),
    name: (val) => ({
      nameError:
        val?.trim().length > 0
          ? val.match(/^[A-Za-z\s]*$/)
            ? ""
            : "Only alphabets are allowed"
          : "Name is required",
    }),
    email: (val) => ({
      emailError:
        val?.trim().length > 0
          ? /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
              val
            )
            ? ""
            : "Enter a valid email"
          : "Email is required",
    }),
  };
  handlechange = (e) => {
    this.setState(this.validateFields[e.target.name](e.target.value));

    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleFile = (e) => {
    let size = [...e.target.files].reduce(
      (total, file) => total + file.size,
      0
    );
    size = size / 1000000;
    if (e.target.files.length > 5 || size > 201) {
      alert("Only 5 files accepted with total size 200MB accepted.");
      return;
    }
    this.setState(
      { [e.target.name]: [...this.state.files, ...e.target.files] },
      () => {
        // console.log(this.state.files);
        var input = document.getElementById("files");
        const data = this.state.setLessonImage;
        for (var i = 0; i < input.files.length; ++i) {
          data.push(input.files.item(i).name);
        }
        this.setState({
          setLessonImage: data,
        });
      }
    );
  };
  delFile = (index) => {
    this.state.setLessonImage.splice(index, 1);
    this.state.files.splice(index, 1);
    this.setState({
      setLessonImage: this.state.setLessonImage,
      files: this.state.files,
    });
  };
  render() {
    return (
      <div
        className="getInTouch"
        style={{
          border: "0px solid green",
          cursor: "auto",
        }}
      >
        <div
          className="container "
          style={{
            border: "0px solid red",
            cursor: "auto",
          }}
        >
          {this.state.successLoading ? (
            <Snackbar
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              open={true}
              style={{ bottom: "40px", textTransform: "capitalize" }}
              color={"#bdbdbd"}
              autoHideDuration={6000}
              message={this.state.successMsg}
            ></Snackbar>
          ) : null}
          {this.state.errorLoading ? (
            <Snackbar
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
                textTransform: "capitalize",
              }}
              open={true}
              style={{ top: "102px" }}
              color={"#bdbdbd"}
              autoHideDuration={6000}
              message={this.state.errorMsg}
            ></Snackbar>
          ) : null}
          <h3 className="mainHeading">Get in Touch</h3>
          <form action="" id="myForm" onSubmit={this.contactUs}>
            <p
              className="spanTag emailTag"
              style={{
                cursor: "auto",
              }}
            >
              Name
              <Astrisk red={this.state.nameError} />
            </p>

            <input
              className="Input"
              name="name"
              value={this.state.name}
              style={{
                cursor: "auto",
              }}
              required
              onChange={(e) => {
                this.handlechange(e);
              }}
              onFocus={(e) =>
                this.setState(this.validateFields.name(e.target.value))
              }
            />

            <br />
            <Error errorStr={this.state.nameError} />
            <p
              className="spanTag passTag"
              style={{
                cursor: "auto",
              }}
            >
              {" "}
              Email
              <Astrisk red={this.state.emailError} />
            </p>
            <input
              className="Input"
              name="email"
              value={this.state.email}
              required
              type="email"
              onChange={(e) => {
                this.handlechange(e);
              }}
              style={{
                maxWidth: "100%",
                cursor: "auto",
              }}
              onFocus={(e) =>
                this.setState(this.validateFields.email(e.target.value))
              }
            />
            <br />

            <Error errorStr={this.state.emailError} />
            <p
              className="spanTag passTag"
              style={{
                cursor: "auto",
              }}
            >
              Tell us about your project
              <Astrisk red={this.state.messageError} />
            </p>

            <Error errorStr={this.state.messageError} />
            <textarea
              name="message"
              type="text"
              className="Input"
              onChange={this.handlechange}
              value={this.state.message}
              style={{
                height: "150px",
                maxWidth: "100%",
                overflowX: "hidden",
                cursor: "auto",
              }}
              onFocus={(e) =>
                this.setState(this.validateFields.message(e.target.value))
              }
            />
            <br />
            <div
              className="contactRow"
              style={{
                cursor: "auto",
              }}
            >
              <p> Upload relevant files if any</p>
              <Button
                variant="outlined"
                size="medium"
                className="upload"
                component="label"
              >
                Upload
                <input
                  type="file"
                  onChange={this.handleFile}
                  name="files"
                  hidden
                  multiple="5"
                  id="files"
                  style={{
                    maxWidth: "100%",
                  }}
                />
              </Button>
            </div>
            <Container
              maxWidth="sm"
              style={{
                border: "0px solid blue",
              }}
            >
              {this.state.setLessonImage.map((item, index) => (
                <div
                  className="fileList"
                  style={{
                    border: "0px solid red",
                    marginTop: 6,
                  }}
                >
                  <p>{item}</p>
                  <Close
                    className="Close"
                    onClick={() => {
                      this.delFile(index);
                    }}
                  />
                </div>
              ))}
            </Container>
            <br />
            <Button
              variant="contained"
              type="submit"
              className="submitBtn regBtn"
              disabled={!this.canSubmit()}
            >
              {this.state.loading ? (
                <CircularProgress size={35} color={"white"} />
              ) : (
                "Submit"
              )}
            </Button>
          </form>
        </div>
      </div>
    );
  }
}
const Error = ({ errorStr }) => (
  <p
    style={{
      color: "rgba(255,0,0,0.7)",
      border: "0px solid red",
      marginTop: 5,
      textAlign: "left",
      fontSize: 16,
      fontStyle: "italic",
    }}
  >
    {errorStr ?? ""}
  </p>
);
const Astrisk = ({ red }) => (
  <span
    style={{
      color: red ? "red" : "inherit",
    }}
  >
    &nbsp;*
  </span>
);
