import React, { useEffect, useState } from "react";
import "./style.css";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import { API } from "../../Constants/APIs";
import ProductCard from "./ProductCard";
import { useMediaHook } from "../useMediaHook";
import { addViewpostMetaTag } from "../../Helpers/addViewport";
import Footer from "./Footer";
import renderHTML from "react-render-html";
import { Container } from "@material-ui/core";

const ShareProductPage = () => {
  const [shareProduct, setShareProduct] = useState({
    _id: "",
    title: "",
    sub_title: "",
    products: [],
  });

  const location = useLocation();
  const getShareProduct = async (id) => {
    const share_id = location.pathname.split("/").pop();
    id = id ? id : share_id;
    try {
      const { data } = await axios.get(`${API.getUrls.share_products}/${id}`);

      setShareProduct(data.shareProduct);
    } catch (err) {
    } finally {
    }
  };

  useEffect(() => {
    (async () => {
      await getShareProduct();
    })();
    addViewpostMetaTag();
  }, []);
  const isMdOrDown = useMediaHook();
  return (
    <Container maxWidth={isMdOrDown ? "xs" : "lg"} style={{ padding: 0 }}>
      <div
        className="CREATE_SHARE NO_SCROLLBAR"
        style={{
          minHeight: "100vh",
          maxWidth: "100vw",
          overflow: "hidden",
          // padding: isMdOrDown ? '1rem' : '6rem',
          paddingTop: "3rem",
          paddingBottom: "0px",
        }}
      >
        <div
          style={{
            flex: 1,
            justifySelf: "stretech",
            minHeight: "90vh",
          }}
        >
          <div
            className="flex_center"
            style={{ marginBottom: shareProduct?.title ? 0 : 32 }}
          >
            {!shareProduct?.logo ? null : (
              <img
                src={API.imgUrl + shareProduct.logo}
                width={isMdOrDown ? "71" : "150"}
                height={isMdOrDown ? "71" : "150"}
                style={{
                  borderRadius: 15,
                  overflow: "hidden",
                  marginBottom: isMdOrDown ? 15 : 20,
                }}
              />
            )}
          </div>
          {shareProduct?.title && (
            <div
              className="flex_center"
              style={{
                padding: isMdOrDown ? "6px" : "10px 0",
              }}
            >
              <div
                style={{
                  fontSize: isMdOrDown ? 28 : 64,
                  fontWeight: 700,
                  outline: 0,
                  textAlign: "center",
                  width: isMdOrDown ? 280 : "100%",
                  maxWidth: "980%",
                  textAlign: "center",
                  textAlignLast: "center",
                  wordBreak: "break-word",
                  marginBottom: 0,
                  color: "#2e2e2e",
                  minHeight: isMdOrDown ? 0 : 45,
                }}
              >
                {shareProduct.title}
              </div>
            </div>
          )}
          {shareProduct?.sub_title && (
            <div
              className="flex_center"
              style={{
                padding: isMdOrDown ? "6px" : "10px 0",
                border: "0px solid red",
                width: isMdOrDown ? 280 : "100%",
                margin: "0 auto",
              }}
            >
              <div
                style={{
                  // fontSize: isMdOrDown ? 16 : 25,
                  // fontWeight: 500,
                  // border: '0px solid green',
                  // outline: 0,
                  // textAlign: 'center',
                  // width: '100%',
                  // maxWidth: isMdOrDown ? '90%' : '70%',
                  // color: '#919191',
                  // border: '0px solid red',
                  // textAlign: 'justify',
                  // textAlignLast: 'center',
                  // wordBreak: 'break-word',
                  // lineHeight: isMdOrDown ? '20px' : '40px',
                  marginBottom: isMdOrDown ? "24px" : "50px",
                }}
              >
                {shareProduct.sub_title && renderHTML(shareProduct.sub_title)}
              </div>
            </div>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            {shareProduct?.products?.map((product, index) => (
              <ProductCard
                key={product._id}
                product={product}
                index={index}
                length={shareProduct.products.length}
              />
            ))}
          </div>
        </div>

        <Footer shareProduct={shareProduct} />
      </div>
    </Container>
  );
};

export default ShareProductPage;

const lorem = (n = 10) => {
  return Array(n)
    .fill(0)
    .map((v) => (
      <h3 key={v}>
        Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quae culpa
        dolore nam voluptates numquam veritatis voluptatum ratione. Asperiores
        inventore suscipit architecto expedita quos porro sapiente
        reprehenderit, nesciunt tenetur temporibus corporis!
      </h3>
    ));
};
