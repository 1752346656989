import "@google/model-viewer";
import { Button, Icon, Slider, Typography } from "@material-ui/core";
import { CloudUpload, Fullscreen } from "@material-ui/icons";
import React, { useEffect, useMemo, useRef, useState } from "react";
import Geocode from "react-geocode";
import SunCalc from "suncalc";
import * as THREE from "three";
import { OrbitControls } from "three/addons/controls/OrbitControls";
import { GLTFLoader } from "three/addons/loaders/GLTFLoader";
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader.js"; // Import RGBELoader
import pedHDR from "./../../Assets/Images/Tropical_Ruins.hdr";

const months = [
  { label: "Jan", value: 0 },
  { label: "Feb", value: 1 },
  { label: "Mar", value: 2 },
  { label: "Apr", value: 3 },
  { label: "May", value: 4 },
  { label: "Jun", value: 5 },
  { label: "Jul", value: 6 },
  { label: "Aug", value: 7 },
  { label: "Sep", value: 8 },
  { label: "Oct", value: 9 },
  { label: "Nov", value: 10 },
  { label: "Dec", value: 11 },
];

function Shadow() {
  const [showUpload, setShowUpload] = useState(true);
  const [time, setTime] = useState(new Date().getTime());
  const [value, setValue] = useState();
  const [month, setMonth] = useState(new Date().getMonth());
  const [location, setLocation] = useState(null);
  const [city, setCity] = useState(null);
  const [loading, setLoading] = useState(true);
  const [country, setCountry] = useState(null);
  const [sunData, setSunData] = useState(null);
  const [modelUrl, setModelUrl] = useState(null);
  const [isFullscreen, setIsFullscreen] = useState(false);

  const modelViewerRef = useRef(null);

  const fileInputRef = useRef(null);
  const canvasRef = useRef(null);
  const controls = useRef(null);
  const scene = useRef(new THREE.Scene());
  const camera = useRef(
    new THREE.PerspectiveCamera(
      45,
      window.innerWidth / window.innerHeight,
      1,
      1000
    )
  );
  const renderer = useRef(null);
  const light = useRef(new THREE.DirectionalLight(0xffffff, 1));
  // Set up plane to receive shadows
  const planeGeometry = useRef(new THREE.PlaneGeometry(100, 100, 10, 10));
  const planeMaterial = useRef(
    new THREE.MeshStandardMaterial({
      color: 0xdddddd,
      transparent: true,
      // side: THREE.DoubleSide, // Ensure both sides of the plane are rendered
      // opacity: 0, // Adjust opacity as needed
    })
  );
  const plane = useRef(
    new THREE.Mesh(planeGeometry.current, planeMaterial.current)
  );

  useEffect(() => {
    scene.current.background = new THREE.Color(0xdddddd);
    renderer.current = new THREE.WebGLRenderer({ antialias: true });
    const canvasWrapper = document.getElementById("canvasWrapper");
    const aspectRatio = canvasWrapper.offsetWidth / canvasWrapper.offsetHeight;
    console.log(aspectRatio);
    camera.current.aspect = aspectRatio;
    camera.current.updateProjectionMatrix();
    renderer.current.setSize(
      canvasWrapper.offsetWidth,
      canvasWrapper.offsetHeight
    );
    renderer.current.setClearColor(0xdddddd);
    renderer.current.shadowMap.enabled = true;
    renderer.current.shadowMap.type = THREE.PCFSoftShadowMap; // Use soft shadows for smoother transitions
    renderer.current.toneMapping = THREE.ACESFilmicToneMapping;
    renderer.current.outputEncoding = THREE.sRGBEncoding; // plane.current.rotation.x = -Math.PI / 2;

    light.current.shadow.mapSize.width = 4096; // to make shadow sharp
    light.current.shadow.mapSize.height = 4096; // to make shadow sharp
    light.current.shadow.camera.near = 0.5;
    light.current.shadow.camera.far = 500;
    light.current.shadow.bias = -0.00001;
    light.current.shadow.camera.visible = true;
    light.current.castShadow = true;

    plane.current.rotation.x = -Math.PI / 2;
    plane.current.position.y = -0.00001;
    plane.current.receiveShadow = true;
    scene.current.add(plane.current);

    // Set up camera
    camera.current.position.set(0, 2, 3);

    camera.current.aspect =
      canvasWrapper.offsetWidth / canvasWrapper.offsetHeight;

    controls.current = new OrbitControls(
      camera.current,
      renderer.current.domElement
    );
    controls.current.enableDamping = true;
    // Ambient light
    const ambientLight = new THREE.AmbientLight(0x404040, 1);
    scene.current.add(ambientLight);
    scene.current.add(light.current);

    const pmremGenerator = new THREE.PMREMGenerator(renderer.current);
    pmremGenerator.compileEquirectangularShader();

    // Add objects to the scene
    new RGBELoader().load(pedHDR, function (texture) {
      const envMap = pmremGenerator.fromEquirectangular(texture).texture;
      scene.current.environment = envMap;
      texture.dispose();
      pmremGenerator.dispose();
    });
    canvasRef.current.appendChild(renderer.current.domElement);

    const handleResize = () => {
      const canvasWrapper = document.getElementById("canvasWrapper");
      const aspectRatio =
        canvasWrapper.offsetWidth / canvasWrapper.offsetHeight;
      console.log(aspectRatio);
      camera.current.aspect = aspectRatio;
      camera.current.updateProjectionMatrix();
      renderer.current.setSize(
        canvasWrapper.offsetWidth,
        canvasWrapper.offsetHeight
      );
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      renderer.current.dispose();
    };
  }, []);

  useEffect(() => {
    // Handle file change
    const handleFileChange = (event) => {
      const file = event.target.files[0];
      if (file) {
        setShowUpload(false);
        const loader = new GLTFLoader();
        loader.load(
          URL.createObjectURL(file),
          (gltf) => {
            gltf.scene.traverse((child) => {
              if (child.isMesh) {
                // console.log(child.material.map);
                // const resizedTexture = resizeTexture(
                //   child.material.map,
                //   512,
                //   512
                // );

                child.castShadow = true;
                child.receiveShadow = true;
              }
            });
            scene.current.add(gltf.scene);

            const targetObject = new THREE.Object3D();
            // Set up controls
            var boundingBox = new THREE.Box3().setFromObject(gltf.scene);

            // Calculate the center of the bounding box
            var center = new THREE.Vector3();
            boundingBox.getCenter(center);

            // Set the target point for the camera
            controls.current.target.copy(center);

            light.current.target = targetObject;
          },
          undefined,
          (error) => {
            console.error("Error loading 3D model", error);
          }
        );
      }
    };

    fileInputRef.current.addEventListener("change", handleFileChange);

    return () => {
      fileInputRef.current.removeEventListener("change", handleFileChange);
    };
  }, []);

  useEffect(() => {
    // Animation loop
    const animate = () => {
      requestAnimationFrame(animate);
      controls.current.update();
      if (renderer.current && scene.current && camera.current) {
        renderer.current.render(scene.current, camera.current);
      }
    };

    animate();

    return () => {
      // Clean up controls
      controls.current.dispose();
      cancelAnimationFrame(animate);
    };
  }, []);

  useEffect(() => {
    const daystart = new Date().setHours(0, 0, 0);
    const startTime = new Date(daystart).getTime();
    const diffms = time - startTime;
    const min = diffms / 60000;
    setValue(min);
  }, []);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((pos) => {
      const { latitude, longitude } = pos.coords;
      setLocation({ latitude, longitude });
      Geocode.setApiKey("AIzaSyDA30l6vrlZw3uCP9nQnzntysJU1U092F0");

      Geocode.fromLatLng(latitude, longitude).then((response) => {
        const addressComponents = response.results[0].address_components;

        for (const component of addressComponents) {
          if (component.types.includes("locality")) {
            setCity(component.long_name);
          }
          if (component.types.includes("country")) {
            setCountry(component.long_name);
          }
        }
      });
    });
  }, []);

  useEffect(() => {
    if (location) {
      const dateTime = new Date(time);
      const sun_position = SunCalc.getPosition(
        dateTime,
        location.latitude,
        location.longitude
      );
      const getSunData = SunCalc.getTimes(
        dateTime,
        location.latitude,
        location.longitude
      );
      setSunData(getSunData);
      setLoading(false);
      if (
        new Date(dateTime) > new Date(getSunData.sunrise) &&
        new Date(dateTime) < new Date(getSunData.sunset)
      ) {
        const { altitude, azimuth } = sun_position;
        const vector = altAzToCartesian(altitude, azimuth, 1);
        light.current.position.set(vector.x, vector.y, vector.z);
        light.current.intensity = 1;
      } else {
        light.current.intensity = 0;
      }
    }
    const handleResize = () => {
      const canvasWrapper = document.getElementById("canvasWrapper");
      const aspectRatio =
        canvasWrapper.offsetWidth / canvasWrapper.offsetHeight;
      console.log(aspectRatio);
      camera.current.aspect = aspectRatio;
      camera.current.updateProjectionMatrix();
      renderer.current.setSize(
        canvasWrapper.offsetWidth,
        canvasWrapper.offsetHeight
      );
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [time, location]);

  const altAzToCartesian = (altitude, azimuth) => {
    const radius = 10; // You can adjust the distance of the light from the origin
    const x = radius * Math.cos(azimuth) * Math.cos(altitude);
    const y = radius * Math.sin(altitude);
    const z = radius * Math.sin(azimuth) * Math.cos(altitude);
    return new THREE.Vector3(x, y, z);
  };

  const handleChange = (e, value) => {
    let daystart = new Date().setHours(0, value);
    daystart = new Date(daystart).setMonth(month);
    const selectedTime = new Date(daystart);
    setTime(selectedTime);
    setValue(value);
  };

  const handleMonthChange = (e, mon) => {
    let daystart = new Date().setMonth(mon);
    daystart = new Date(daystart).setHours(0, value);
    const selectedTime = new Date(daystart);
    // console.log(selectedTime);
    setTime(selectedTime);
    setMonth(mon);
  };

  // const openAR = () => {
  //   if (modelViewerRef.current) {
  //     // Export the scene as GLB
  //     const exporter = new GLTFExporter();
  //     exporter.parse(
  //       scene.current,
  //       (result) => {
  //         console.log(result);
  //         const resultString = JSON.stringify(result);

  //         const blob = new Blob([resultString], {
  //           type: "model/gltf-binary",
  //         });
  //         const glbUrl = URL.createObjectURL(blob);
  //         setModelUrl(glbUrl);
  //         setTimeout(() => {
  //           modelViewerRef.current.activateAR();
  //         }, 100);
  //       },
  //       {
  //         trs: true,
  //         onlyVisible: true,
  //       }
  //     );
  //   }
  // };

  return (
    <div
      style={{
        display: "flex",
        height: "100vh",
        alignItems: "center",
      }}
    >
      {/* <meta name="viewport" content="width=device-width, initial-scale=1.0" /> */}

      <div
        id="canvasWrapper"
        style={{
          position: "relative",
          width: "75%",
          // padding: "32px",
          borderRadius: 10,
          overflow: "hidden",
          height: "100vh",
        }}
      >
        <div
          style={{
            position: "absolute",
            transition: "all .3s ease-in",
            bottom: showUpload ? "50%" : -32,
            left: "50%",
            transform: "translate(-50%, 50%)",
          }}
        >
          <input
            accept=".gltf, .glb"
            id="contained-button-file"
            ref={fileInputRef}
            multiple
            type="file"
            style={{ display: "none" }}
          />
          <label htmlFor="contained-button-file">
            <Button
              variant="contained"
              color="primary"
              component="span"
              startIcon={<CloudUpload />}
              size="large"
              style={{ width: 250 }}
            >
              Select .glb file
            </Button>
          </label>
        </div>

        <div
          ref={canvasRef}
          style={
            {
              // top: "50%",
              // left: "50%",
              // transform: "translate(-50%,-50%)",
            }
          }
        ></div>
      </div>

      <div
        style={{
          width: "25%",
          padding: "32px",
          height: "100%",
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        {!loading && (
          <div
            style={{
              // position: "fixed",
              // right: 16,
              // top: 16,
              backgroundColor: "#fff",
              borderRadius: 5,
            }}
          >
            <>
              <div style={{ display: "flex" }}>
                <Typography>Time: </Typography>
                <Typography style={{ fontWeight: "bolder" }}>
                  {new Date(time).getHours() +
                    ":" +
                    new Date(time).getMinutes()}
                </Typography>
              </div>
              <div style={{ display: "flex" }}>
                <Typography>Location: </Typography>
                <Typography style={{ fontWeight: "bolder" }}>
                  {city}, {country}
                </Typography>
              </div>
              <div style={{ display: "flex" }}>
                <Typography>Sunrise Time: </Typography>
                <Typography style={{ fontWeight: "bolder" }}>
                  {new Date(sunData?.sunrise).getDate() +
                    " " +
                    months[new Date(sunData?.sunset).getMonth()]?.label +
                    ", " +
                    new Date(sunData?.sunrise).getHours() +
                    ":" +
                    new Date(sunData?.sunrise).getMinutes()}
                </Typography>
              </div>
              <div style={{ display: "flex" }}>
                <Typography>Sunset time: </Typography>
                <Typography style={{ fontWeight: "bolder" }}>
                  {new Date(sunData?.sunset).getDate() +
                    " " +
                    months[new Date(sunData?.sunset).getMonth()]?.label +
                    ", " +
                    new Date(sunData?.sunset).getHours() +
                    ":" +
                    new Date(sunData?.sunset).getMinutes()}
                </Typography>
              </div>
            </>
          </div>
        )}

        {!showUpload && (
          <div
            style={{ display: "flex", justifyContent: "center", marginTop: 32 }}
          >
            <div
              style={{
                // position: "absolute",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                // bottom: "50%",
                // left: "50%",
                // transform: "translate(-50%, -50%)",
                background: "#fff",
                borderTopRightRadius: 10,
                borderTopLeftRadius: 10,
                width: "100%",
                zIndex: 9999,
                // boxShadow:
                //   "0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)",
                alignSelf: "center",
              }}
            >
              <Typography>Select Month</Typography>
              <Slider
                value={month}
                onChange={handleMonthChange}
                aria-labelledby="continuous-slider"
                min={0}
                max={11}
                style={{ width: "90%" }}
                marks={months}
              />
              <Typography style={{ marginTop: 12 }}>Select Time</Typography>
              <Slider
                value={value}
                onChange={handleChange}
                aria-labelledby="continuous-slider"
                min={0}
                max={1440}
                style={{ width: "90%" }}
                marks={[
                  {
                    value: 0,
                    label: "12 am",
                  },
                  {
                    value: 1440,
                    label: "11:59 pm",
                  },
                ]}
                step={15}
              />
              {/* <div
              onClick={openAR}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: 12,
                borderRadius: 4,
                boxShadow:
                  "0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)",
              }}
            >
              <img src={arimage} alt="" width={16} style={{ marginRight: 8 }} />
              <Typography>View in your space</Typography>
            </div> */}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Shadow;
