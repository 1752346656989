import "@google/model-viewer";
import { Button, Grid, Typography } from "@material-ui/core";
import { Check, ThreeSixtySharp } from "@material-ui/icons";
import axios from "axios";
import QRCode from "qrcode.react";
import qs from "query-string";
import React, { Component, createRef } from "react";
import Modal from "react-awesome-modal";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Lottie from "react-lottie";
import "../../App.scss";
import "../../global.css";
import arimage from "../../Assets/Images/ar_icon.png";
// import rotate from "../../Assets/Images/rotate.png";
import rotate from "../../Assets/newIcons/rotate.png";
// import share from "../../Assets/Images/share.png";
import share from "../../Assets/Images/sharebtn.png";
// import zoom from "../../Assets/Images/zoom-in.png";
import zoom from "../../Assets/newIcons/zoom.png";
import loader from "../../Assets/lottie/3dloader.json";
import MobileModelDynamicViewer from "./MobileModelDynamicViewer";
import { API } from "../../Constants/APIs";
import logo from "../../Assets/Images/actualityLogo.png";
import init from "../../Helpers/Windotoken";
import NotFoundPage from "../../Screens/NotFoundPage";
import { Navigate } from "react-router-dom";
import Geocode from "react-geocode";
import { Beforeunload } from "react-beforeunload";
import remove from "../../Assets/Images/delete.png";
import arrowLeft from "../../Assets/Images/arrowLeft.png";
import arrowRight from "../../Assets/Images/arrowRight.png";
import closeModal from "../../Assets/Images/closeModal.png";
import ifraameBgImage from "../../Assets/newIcons/ifraameBgImage.jpg";
import ShareMobileModal from "../ShareMobileModal";
import { LiveProvider, LiveEditor, LiveError, LivePreview } from "react-live";
import modal_Viewer, { deviceSupported, open_in_ar } from "../Constant";
import AnnotationBtns from "../AnnotationBtns";
import HotspotContentDrawer from "../HotspotContentDrawer";
// import Cookies from "universal-cookie";
// import CookieConsent from "react-cookie-consent";
import { fullHeight, fullHeightSupport } from "../../Helpers/fullHeight";
import checkBeforeUnload from "../../Helpers/checkBeforeUnload";
import RecommendedProducts from "../RecommendedProducts";
import {
  loremIpsumDummy,
  offset,
  saveAndGetLocation,
} from "../../Helpers/utils";
import MVDimensions from "../MVDimensions";
import TSEDialog from "../TSEDialog";
import showDims from "../../Assets/newIcons/showDims.png";
import ProductSharePopUp from "../ProductSharePopUp";
const screen = window.screen.width <= 1024 ? "mobile" : "desktop";
const queryParams = new URLSearchParams(window.location.search);

const __ON_SEND_ANALYTICS = false;

export default class ModalDynamic extends Component {
  constructor(props) {
    super(props);
    this.modelRef = createRef();
    this.annotBtnCloseCB = createRef();
    this.state = {
      model: {},
      productDel: {},
      variantIndex: 0,
      revealModel: false,
      mesh: [],
      Components: [],
      frames: [],
      selected_material_id: 0,
      selected_component_id: "",
      showModalBtn: screen === "desktop",
      showVariant: false,
      selectedVariant: {},
      meshes: [],
      Variants: [],
      model_file_url: "",
      ModalImage: "",
      usdzImage: "",
      productId: window.location.pathname.split("/")[2],
      variantsCombo: [],
      Component1: [],
      Component2: [],
      visible: false,
      value: "",
      embedCode: "",
      QRCode: "",
      copied: false,
      qrCopy: false,
      embedCopy: false,
      selectedMaterials: [],
      screen: qs.parse(window.location.search).screen
        ? qs.parse(window.location.search).screen
        : screen,
      visiblity: true,
      productStatus: "",
      redirect: false,
      cancelled: false,
      leftModels: false,
      leftDays: false,
      leftViews: false,
      productName: "",
      language: "",
      showARError: false,

      // for geolocation
      lat: 0,
      long: 0,
      mobile: false,
      platform: "",
      city: "",
      country: "",
      ip: "",
      clicks: 0,
      session: 0,
      count: 0,
      count1: 0,
      seconds: 0,
      referrer: "",
      checkCall: false,
      modalVisible: false,
      shareUrl: "",
      heightModal: "150px",
      widthModal: "50%",
      showHotspot: {
        isTrue: false,
        data: {},
      },
      analytics_id: "",
      arplacement: "",
      showMVDimension: false,
    };
  }

  isMdOrDown = () => this.props.isMdOrDown;
  incrementCount = () => {
    this.setState({ count1: this.state.count1 + 1 }, () => {});
  };

  componentDidMount() {
    this.sendAnalytics();
    let isDimensionVisible = localStorage.getItem("isDimensionVisible");
    isDimensionVisible = isDimensionVisible
      ? JSON.parse(isDimensionVisible)
      : false;

    this.setState({ showMVDimension: isDimensionVisible });
    const referrer = document.referrer;
    if (referrer.length > 0) {
      let domain = referrer.split("/");
      domain = domain[0] + "//" + domain[2];
      this.setState({ referrer: domain });
    } else {
      this.setState({ referrer: "N/A" });
    }

    this.geolocationData();
    this.getIPadd();

    this.interval = setInterval(() => {
      this.setState({
        seconds: this.state.seconds + 1,
      });
    }, 1000);
    var payId = window.location.pathname.split("/")[2];
    axios.get(API.getUrls.getSingleProduct + payId).then((resp) => {
      const firstModelSrc = resp.data.product?.variants?.[0]?.model_file_url;
      this.setState({ language: resp.data?.product?.language, firstModelSrc });
      if (
        resp.data.product.user.is_delete === "yes" ||
        resp.data.product.user.status === "draft"
      ) {
        this.setState({
          redirect: true,
        });
      } else {
        if (
          resp.data.product.status === "published" ||
          resp.data.product.is_public_model === "yes"
        ) {
          axios
            .get(API.getUrls.getSubscriptionDetail + payId)
            .then((respPlan) => {
              if (respPlan.data.status === "success") {
                if (resp.data.product.is_public_model === "no") {
                  let modelsLeft = respPlan.data.data.no_of_models_left;
                  let daysLeft = respPlan.data.data.no_of_days_left;
                  let viewsLeft = respPlan.data.data.no_of_views_left;

                  if (modelsLeft === 0) {
                    this.setState({
                      redirect: true,
                      leftModels: true,
                    });
                  } else if (daysLeft === 0) {
                    this.setState({
                      redirect: true,
                      leftDays: true,
                    });
                  } else if (viewsLeft === 0) {
                    this.setState({
                      redirect: true,
                      leftViews: true,
                    });
                  }
                }

                let payLoad = {
                  prodId: payId,
                };

                axios
                  .put(API.getUrls.websiteViews, payLoad)
                  .then((resViews) => {});
              }
            });
          if (resp.data.status === "success") {
            this.setState({
              visiblity: resp.data.product.visibility,
              productStatus: resp.data.product.status,
              arplacement: resp.data.product.placement,
              embedded_product_url: resp.data.product.embedded_product_url,
            });

            if (this.state.productStatus === "published") {
              this.setState({
                embedCode:
                  "<iframe src='" +
                  "https://actuality.live/modelDynamicviewer/" +
                  this.props.matchparams.id +
                  "' title='some' scrolling='No' height='750px' width='100%' frameborder='0'></iframe>",
                value:
                  "https://actuality.live/modelDynamicviewer/" +
                  this.props.matchparams.id,
                QRCode:
                  "https://actuality.live/modelDynamicviewer/" +
                  this.props.matchparams.id,
                shareUrl: modal_Viewer(this.props.matchparams.id),
                open_in_ar: open_in_ar(this.props.matchparams.id),
              });

              this.setModel();
              this.getComponents();
              this.getVariants();
            } else {
              this.setState({
                embedCode:
                  "<iframe src='" +
                  "https://portal.actuality.live/modelDynamicviewer/" +
                  this.props.matchparams.id +
                  "' title='some' scrolling='No' height='750px' width='100%' frameborder='0'></iframe>",
                value:
                  "https://portal.actuality.live/modelDynamicviewer/" +
                  this.props.matchparams.id,
                QRCode:
                  "https://portal.actuality.live/modelDynamicviewer/" +
                  this.props.matchparams.id,

                shareUrl: modal_Viewer(this.props.matchparams.id),
              });
              this.setModel();
              this.getVariants();
            }
          }
        } else {
          this.setState({
            redirect: true,
          });
        }
      }
    });
  }
  sendAnalytics = () => {
    let payLoadData = {
      product: this.state.productId,
      mobile: this.state.mobile,
      platform: this.state.platform,
      ip: this.state.ip,
      actionClicks: this.state.count,
      spaceClicks: this.state.count1,
      session: this.state.seconds,
      referrerBySession: this.state.referrer,
      ...saveAndGetLocation(),
    };
    axios.post(API.postUrls.addAnalytics, payLoadData).then((res) => {
      if (res?.data?.data?._id) {
        this.setState({ analytics_id: res.data.data._id });
      }
    });
  };

  button = () => {
    return (
      <button
        style={{ height: "100px", width: "100px", backgroundColor: "red" }}
      >
        {" "}
        View 3D Modal
      </button>
    );
  };

  componentWillUnmount() {
    if (this.state.mobile === false) {
    }
  }

  getIPadd = () => {
    axios
      .get("https://api.ipregistry.co/?key=36oivejw4lcirye7")
      .then((resp) => {
        this.setState(
          {
            ip: resp.data.ip,
            platform: resp.data.user_agent.os.name,
            mobile: resp.data.user_agent.os.type === "mobile" ? true : false,
          },
          () => {}
        );
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  geolocationData = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        this.getlocation,
        this.showError
      );
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  };

  getlocation = (data) => {
    let platformData = navigator;
    saveAndGetLocation({
      lat: data.coords.latitude,
      long: data.coords.longitude,
    });
    this.setState(
      {
        lat: data.coords.latitude,
        long: data.coords.longitude,
      },
      () => {
        this.getCountryCityName(data.coords.latitude, data.coords.longitude);
      }
    );
  };

  showError = (error) => {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        break;
      case error.POSITION_UNAVAILABLE:
        break;
      case error.TIMEOUT:
        break;
      case error.UNKNOWN_ERROR:
        break;
    }
  };

  getCountryCityName = (lat, long) => {
    Geocode.setApiKey("AIzaSyDA30l6vrlZw3uCP9nQnzntysJU1U092F0");
    Geocode.setLanguage("en");
    Geocode.setLocationType("ROOFTOP");
    Geocode.enableDebug();
    Geocode.fromLatLng(lat, long).then(
      (response) => {
        //    console.log("response ", response);
        const address = response.results[0].formatted_address;
        let city, state, country;
        for (
          let i = 0;
          i < response.results[0].address_components.length;
          i++
        ) {
          for (
            let j = 0;
            j < response.results[0].address_components[i].types.length;
            j++
          ) {
            switch (response.results[0].address_components[i].types[j]) {
              case "locality":
                city = response.results[0].address_components[i].long_name;
                break;
              case "administrative_area_level_1":
                state = response.results[0].address_components[i].long_name;
                break;
              case "country":
                country = response.results[0].address_components[i].long_name;
                break;
            }
          }
        }
        //    console.log("country city", city, state, country);
        // console.log(address);
        saveAndGetLocation({ city, country });
        this.setState(
          {
            city,
            country,
          },
          () => {
            // console.log(this.state.city, this.state.country);
          }
        );
      },
      (error) => {
        // console.error('error from geocode ', error);
      }
    );
  };

  postAnalytics = (ignore = false, alertData = false) => {
    // if(this.props.isMdOrDown) return
    let combinationViews = !this.state?.combinationViewCount
      ? []
      : Object.keys(this.state?.combinationViewCount).map((combination) => ({
          combination,
          views: this.state?.combinationViewCount[combination],
        }));
    let payLoadData = {
      // lat: this.state.lat,
      // long: this.state.long,
      // country: this.state.country,
      // city: this.state.city,
      analytics_id: this.state.analytics_id,
      product: this.state.productId,
      mobile: this.state.mobile,
      platform: this.state.platform,
      ip: this.state.ip,
      actionClicks: this.state.count,
      spaceClicks: this.state.count1,
      session: this.state.seconds,
      referrerBySession: this.state.referrer,
      combinationViews,
      ...saveAndGetLocation({}),
    };
    // alert(this.state.count)
    // console.log(this.state.__ANALYTICS_SENT, 'payLoadData ', payLoadData);
    // if (ignore || this.state.__ANALYTICS_SENT) return;
    checkBeforeUnload(payLoadData);
    this.setState({ __ANALYTICS_SENT: true });
    // axios
    //   .post(API.postUrls.addAnalytics, payLoadData)
    //   .then((resp) => {
    //   //    console.log("response from postanalytics ", resp.data);
    //     this.setState({ __ANALYTICS_SENT: true });
    //   })
    //   .catch((error) => {
    //     if (error) {
    //     }
    //   });
  };
  incrementSpaceClick = () => {
    this.setState({ count1: this.state.count1 + 1 });
  };
  incrementActionClick = () => {
    this.setState({ count: this.state.count + 1 });
  };

  postViews = () => {
    // if (init() === "success") {
    axios
      .post(API.postUrls.postViews + "/" + this.props.matchparams.id)
      .then((res) => {});
    // }
  };

  openModal = () => {
    this.setState({
      visible: true,
    });
  };

  closeModal = () => {
    this.setState({
      visible: false,
    });
  };

  buttonStyle = () => {
    return (
      <button
        style={{
          height: "100px",
          width: "100px",
          backgroundColor: "red",
        }}
      >
        View 3D Modal
      </button>
    );
  };

  setUpModal = () => {
    var modal = document.getElementById("myModal");
    var modalContent = document.querySelector(".modal-content");

    var btn = document.getElementById("qrtext");
    var ModalView = document.querySelector("#model-viewer");
    var span = document.getElementsByClassName("closeBtn")[0];
    ModalView.addEventListener("ar-status", (event) => {
      // console.log("checkAR_status", event);
      if (event.detail.status === "failed") {
        const error = document.querySelector("#error");
        this.setState({ showARError: true });
        error.classList.remove("hide");
        error.addEventListener("transitionend", (event) => {
          error.classList.add("hide");
        });
      }
    });
    if (btn) {
      btn.onclick = function () {
        modal.style.display = "block";
        modalContent.classList.add("slideIn");
      };
    }

    span.onclick = function () {
      modalContent.classList.remove("slideIn");
      modal.style.display = "none";
    };

    window.onclick = function (event) {
      if (event.target === modal) {
        modalContent.classList.remove("slideIn");
        modal.style.display = "none";
      }
    };
  };

  setModel = () => {
    axios
      .get(API.getUrls.getProducts + "/" + this.props.matchparams.id)
      .then((res) => {
        // console.log(res);
        this.setState(
          {
            productDel: res.data.product,
            productName: res.data.product.title,
            revealModel: true,
          },
          () => {
            this.setUpModal();
          }
        );
      });
  };

  getComponents = () => {
    if (this.props?.combination?.product) return;
    axios
      .get(
        API.getUrls.getComponents +
          this.props.matchparams.id +
          "?page=1&limit=10"
      )
      .then((res) => {
        // console.log("component", res);
        this.setState(
          {
            Components: res.data.components.docs,
          },
          () => {
            let selectedMaterials = [];
            if (
              this.state.selectedVariant.materials?.length > 0 &&
              this.state.Components.length > 0
            ) {
              this.state.Components.forEach((component) => {
                console.log(component);
                component.materials.forEach((item, i) => {
                  if (
                    item._id === this.state.selectedVariant.materials[0]._id
                  ) {
                    component.materials.splice(i, 1);
                    component.materials.unshift(item);
                  }
                });
                // console.log(component.materials);
                // console.log(this.state.selectedVariant.materials[0]._id);
                const _material = component.materials.find(
                  (mat) =>
                    mat._id === this.state.selectedVariant.materials[0]._id
                );
                // console.log(_material);
                selectedMaterials.push({
                  material: _material
                    ? _material._id
                    : component.materials[0]._id,
                  component: component._id,
                });
              });
              this.setState({ selectedMaterials });
            }
          }
        );
      });
  };

  getVariants = () => {
    axios
      .get(
        API.getUrls.variants +
          "?page=1&limit=10&product=" +
          this.props.matchparams.id
      )
      .then((res) => {
        // console.log("variant", res);
        if (res.data.docs.length > 0) {
          this.setState(
            {
              Variants: res.data.docs,
              selectedVariant: res.data.docs[0],
              ModalImage: res.data.docs[0].model_file_url,
              usdzImage: res.data.docs[0].usdz_file_url,
            },
            () => {
              this.getComponents();
            }
            // ,
            // () => {
            // //    console.log('selectedVariant',this.state.selectedVariant);
            // //    console.log("ModalImage",this.state.ModalImage);
            // }
          );
        }
      });
  };

  selectMaterial = (matId, compId) => {
    // console.log("zzzz",matId, compId);

    const selectedMaterials = [];
    //    console.log(`this.state.selectedMaterials `, this.state.selectedMaterials);
    this.state.selectedMaterials.forEach((item) => {
      selectedMaterials.push({
        material: compId === item.component ? matId : item.material,
        component: item.component,
      });
    });
    this.setState(
      {
        selectedMaterials,
      },
      () => {
        this.applyChanges();
      }
    );
  };

  checkSelected = (matId, comId) => {
    const material = this.state.selectedMaterials.find(
      (item) => item.component === comId && item.material === matId
    );
    return material ? true : false;
  };

  selectVariant = (variant) => {
    this.setState({ selectedVariant: variant });
  };

  applyChanges = () => {
    let filteredVariant = {};
    let totalSelected = this.state.selectedMaterials.length;
    this.state.Variants.forEach((variant) => {
      const materials = variant.materials.filter((material) =>
        this.state.selectedMaterials.some(
          (item) =>
            item.material === material._id &&
            material.component === item.component
        )
      );
      if (materials.length === totalSelected) {
        filteredVariant = variant;
      }
    });
    this.setState({
      selectedVariant: filteredVariant,
      showVariant: true,
    });
  };

  downloadQR = () => {
    const canvas = document.getElementById("QRCode");
    const pngUrl = canvas
      .toDataURL(window.location.href)
      .replace(window.location.href, window.location.href);
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = "QRCode";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  redirectToUrl = () => {
    if (this.state.productDel.link_url) {
      window.open(this.state.productDel.link_url);
    }
  };

  copyUrl = (e) => {
    this.setState({
      copied: true,
    });
    setTimeout(() => {
      this.setState({
        copied: false,
      });
    }, 2000);
  };
  copyOpenInAR = () => {
    this.setState({
      open_in_ar_copied: true,
    });
    setTimeout(() => {
      this.setState({
        open_in_ar_copied: false,
      });
    }, 2000);
  };
  copyShare = () => {
    this.setState({
      copyShareUrl: true,
    });
    setTimeout(() => {
      this.setState({
        copyShareUrl: false,
      });
    }, 2000);
  };

  // gotoShare = () => {
  //   this.setState({
  //     copied: true,
  //   });
  //   setTimeout(() => {
  //     this.setState({
  //       copied: false,
  //     });
  //   }, 2000);

  //   // setTimeout(() => {
  // window.open("/share/" + this.props.matchparams.id, "_blank");
  //   // }, 2000);
  // };
  gotoShare() {
    window.open("/share/" + this.props.matchparams.id, "_blank");

    // var iframe1 = document.getElementById("myiFrame");
    // iframe1.style.display = "block";
  }

  copyEmbed = (e) => {
    this.setState({
      embedCopy: true,
    });
    setTimeout(() => {
      this.setState({
        embedCopy: false,
      });
    }, 2000);
  };

  updateMobilePayload = (data) => {
    return;
    this.setState({
      product: data.productId,
      lat: data.lat,
      long: data.long,
      country: data.country,
      city: data.city,
      mobile: data.mobile,
      platform: data.platform,
      ip: data.ip,
      actionClicks: data.count,
      spaceClicks: data.count1,
      session: data.seconds,
      referrerBySession: data.referrer,
      checkCall: data.checkCall,
    });
  };
  // cookies =() => {
  //   const cookies = new Cookies();
  //   cookies.set("myCat", "Pacman", { path: "/" });
  // }
  incrementCombinationViewCount = (combinationId) => {
    const combinationViewCount = this.state.combinationViewCount || {};

    combinationViewCount[combinationId] =
      1 + (combinationViewCount[combinationId] || 0);
    // console.clear();
    // console.log("combinationViewCount ", combinationViewCount);
    this.setState({ combinationViewCount });
  };
  closeError = (status) => {
    this.setState({ showARError: status });
  };
  toggleShareDialog = () => {
    this.setState({ openShareDialog: !!!this.state.openShareDialog });
  };
  render() {
    const FROM_SHARE_PAGE = this.props.FROM_SHARE_PAGE;
    // if (this.state.productDel.is_protected && !!!this.state.isProductVerified) {
    //   return <h1>Verify</h1>;
    // }
    const combination = this.props.combination;

    if (this.state.redirect === true) {
      return (
        <Navigate
          to="../pageNotFound"
          state={{
            cancelled: this.state.cancelled,
            leftModels: this.state.leftModels,
            leftDays: this.state.leftDays,
            leftViews: this.state.leftViews,
          }}
        />
      );
    } else {
      const model = this.state.model;
      const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: loader,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice",
        },
      };

      return (
        <Beforeunload
          onBeforeunload={(e) => {
            this.postAnalytics();
          }}
        >
          {true ? null : (
            <button
              style={{
                padding: "1rem 2rem",
                border: 0,
                position: "fixed",
                top: 0,
                right: 0,
                zIndex: 27362735237,
              }}
              onClick={() => {
                this.setState({ openShareDialog: true });
                // this.postAnalytics(!true);
              }}
            >
              Send Analytics
            </button>
          )}
          <div
            className="modelViewer"
            style={{
              padding: this.state.screen === "desktop" ? "0 24px" : 0,

              ...fullHeightSupport(this.props.isMdOrDown),
            }}
          >
            <ProductSharePopUp
              openShareDialog={true && this.state.openShareDialog}
              handleClose={this.toggleShareDialog}
              productId={this.state.productId}
              language={this.state.language}
              modelSrc={this.state.firstModelSrc}
              embedded_product_url={this.state.embedded_product_url}
            />
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1"
            />
            <div style={{ margin: "auto" }}></div>

            {this.state.screen === "desktop" ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100vh",
                }}
              >
                {this.state.revealModel ? (
                  <Grid
                    container
                    spacing={4}
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                      height: "95vh",
                      border: "0px solid blue",
                      position: "relative",
                      marginTop: 0,
                      marginBottom: 0,
                      // overflow: "hidden",
                    }}
                  >
                    <HotspotContentDrawer
                      open={this.state.showHotspot.isTrue}
                      onClose={() => {
                        this.setState({
                          showHotspot: { isTrue: false, data: {} },
                        });
                        this.annotBtnCloseCB.current();
                        this.annotBtnCloseCB.current = null;
                      }}
                      title={this.state.showHotspot.data.title}
                      description={this.state.showHotspot.data.description}
                    />

                    <Grid
                      item
                      sm={9}
                      style={{
                        height: "95vh",
                        position: "relative",
                        paddingTop: 0,
                        paddingBottom: 0,
                        border: "0px solid blue",
                      }}
                    >
                      <model-viewer
                        environment-image="neutral"
                        disable-tap
                        ref={(elem) => {
                          this.modelRef.current = elem;
                          // console.log("queryParams ", queryParams);
                          if (this.state.query_checked) return;
                          else this.setState({ query_checked: true });
                          // console.clear();
                          const urlParams = new URLSearchParams(
                            window.location.search
                          );
                          let setter = elem.setAttribute;
                          let obj = {};
                          for (let [key, value] of urlParams) {
                            if (value && key == "bg")
                              elem.setAttribute(
                                "skybox-image",
                                API.imgUrl + "/uploads/sharePopUp/" + value
                              );

                            if (value && key == "camera-orbit")
                              elem.setAttribute(
                                key,
                                value?.split?.("_")?.join?.(" ")
                              );

                            if (value == "false" && key == "zoom")
                              elem.setAttribute("disable-zoom", true);
                            if (value && key == "scale") {
                              let n = Number(value);
                              elem.setAttribute("scale", `${n} ${n} ${n}`);
                            }
                          }
                        }}
                        // field-of-view="15deg"
                        id="model-viewer"
                        exposure={model.exposure}
                        src={
                          combination?.variant?.model_file_url
                            ? API.imgUrl + combination?.variant?.model_file_url
                            : this.state.showVariant
                            ? API.imgUrl +
                              this.state.selectedVariant.model_file_url
                            : API.imgUrl + this.state.ModalImage
                        }
                        quick-look-browsers="safari chrome"
                        // min-field-of-view={
                        //   window.innerWidth < 600 ? "90deg" : "55deg"
                        // }
                        // max-field-of-view={
                        //   window.innerWidth < 600 ? "90deg" : "55deg"
                        // }
                        // bounds="tight"
                        data-js-focus-visible
                        camera-controls
                        ar-scale={"auto"}
                        ar
                        ar-modes="scene-viewer webxr quick-look"
                        ar-placement={this.state.arplacement}
                        autoplay
                        loading="eager"
                        style={{
                          background: "#fff",
                          boxShadow: "1px 2px 5px rgba(0, 0, 0, 0.1)",
                          borderRadius: "15px",
                          height: "95vh",
                          width: "100%",
                          position: "relative",
                          top: "50%",
                          transform: "translateY(-50%)",
                          border: "0px solid red",
                        }}
                      >
                        <MVDimensions
                          ref={this.modelRef}
                          open={this.state.showMVDimension}
                        />
                        <AnnotationBtns
                          annots={
                            combination?.variant
                              ? combination?.variant?.hotspots?.length
                                ? combination.variant.hotspots
                                : []
                              : this.state?.selectedVariant?.hotspots
                          }
                          openHotspotDrawer={(annot, cb) => {
                            let interval = 0;
                            if (this.annotBtnCloseCB?.current) interval = 300;
                            this.annotBtnCloseCB?.current?.();
                            // this.annotBtnCloseCB?.current=null;
                            // this.annotBtnCloseCB?.current?.();
                            setTimeout(() => {
                              this.annotBtnCloseCB.current = cb;
                              this.modelRef.current.setAttribute(
                                "camera-target",
                                annot.cameraTarget
                              );
                              this.modelRef.current.setAttribute(
                                "camera-orbit",
                                annot.cameraOrbit
                              );
                              this.modelRef.current.setAttribute(
                                "zoom",
                                annot?.zoom || 12
                              );
                              this.setState({
                                showHotspot: {
                                  isTrue: true,
                                  data: {
                                    title: annot.title,
                                    description: annot.description,
                                  },
                                },
                              });
                            }, interval);
                          }}
                        />
                        {/* Not in main_fx */}
                        {this.state.showARError && (
                          <div id="error" className="hide">
                            <p
                              style={{
                                backgroundColor: "#ffffffdd",
                                borderRadius: "16px",
                                padding: "16px",
                                position: "absolute",
                                left: "60px",
                                top: "200px",
                                zIndex: 9999,

                                boxShadow: "0 4px 4px rgba(0, 0, 0, 0.25)",
                                color: "#000",
                                fontFamily: "Inter",
                              }}
                            >
                              AR is not supported on this device.
                            </p>
                          </div>
                        )}
                        <Typography
                          // className="brand"
                          style={{
                            // zIndex: 2147483647,
                            // border: '2px solid red',
                            maxWidth: "min-content",
                            maxHeight: "min-content",
                            // maxWidth: 'max-content',
                            // maxHeight: 'max-content',
                          }}
                        >
                          {this.state.productDel.product_logo ? (
                            <div
                              className="modelCompanyLogo"
                              // style={{ border: '2px solid aqua' }}
                            >
                              <img
                                src={
                                  API.imgUrl +
                                  this.state.productDel.product_logo
                                }
                                alt=""
                                style={{ width: "100%", borderRadius: 15 }}
                                width="112"
                                height="112"
                              />
                            </div>
                          ) : (
                            ""
                          )}
                        </Typography>
                        <div
                          style={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                          }}
                          slot="poster"
                        >
                          <Lottie
                            options={defaultOptions}
                            height={100}
                            width={100}
                            autoplay
                            loop
                          />
                          <h2 style={{ textAlign: "center" }}>Loading...</h2>
                        </div>
                        <button
                          slot="ar-button"
                          style={{
                            backgroundColor: "transparent",
                            borderRadius: "4px",
                            border: "none",
                            boxShadow:
                              "0 10px 15px -3px rgba(0, 0, 0, 0.1),0 4px 6px -2px rgba(0, 0, 0, 0.05)",
                            position: "absolute",
                            top: 12,
                            right: "16px",
                            background: "#fff",
                          }}
                          onClick={() => {
                            this.setState(
                              { count1: this.state.count1 + 1 },
                              () => {
                                // console.log(this.state.count1);
                              }
                            );
                          }}
                        >
                          <div>
                            <img
                              src={arimage}
                              width="20px"
                              alt=""
                              style={{
                                marginRight: "4px",
                                position: "relative",
                                top: 5,
                              }}
                            />
                            <p
                              style={{
                                fontWeight: "bold",
                                fontSize: 12,
                                display: "inline-block",
                              }}
                            >
                              {this.state.language === "French"
                                ? "Le rover devant vous"
                                : "View in your space"}
                            </p>
                          </div>
                        </button>
                        <div className="iconWrapper">
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <div>
                              <a
                                href="https://actuality.live/"
                                target="_blank"
                                style={{
                                  textDecoration: "none",
                                  color: "#000000",
                                }}
                              >
                                <h5
                                  style={{
                                    marginLeft: "15px",
                                    color: "#565656",
                                    fontWeight: "400",
                                    cursor: "pointer",
                                    fontSize: "15px",
                                    marginBottom: 21,
                                  }}
                                >
                                  {this.state.language === "French"
                                    ? "Alimenté par"
                                    : "powered by "}
                                  <b>actuality.live</b>
                                </h5>
                              </a>
                            </div>
                          </div>
                          <span
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <div
                              style={{
                                // padding: 10,
                                width: 45,
                                height: 45,
                                display: "grid",
                                placeItems: "center",
                                borderRadius: 6,
                                position: "relative",
                                overflow: "hidden",
                                boxShadow: `1px 4px 5px 2px rgba(0, 0, 0, 0.1)`,
                              }}
                              onClick={() => {
                                this.setState({
                                  showMVDimension:
                                    !!!this.state.showMVDimension,
                                });
                                localStorage.setItem(
                                  "isDimensionVisible",
                                  JSON.stringify(!!!this.state.showMVDimension)
                                );
                              }}
                            >
                              {!this.state.showMVDimension ? null : (
                                <div
                                  style={{
                                    position: "absolute",
                                    top: 0,
                                    right: 0,
                                    left: 0,
                                    bottom: 0,
                                    background: "rgb(167, 212, 254)",
                                    opacity: 0.5,
                                  }}
                                />
                              )}
                              <img
                                src={showDims}
                                alt=""
                                style={{
                                  padding: 0,
                                  margin: 0,
                                  display: "block",
                                  width: "28px",
                                  height: "28px",
                                  // border: '1px solid red',
                                  // marginBottom: 4,
                                }}
                              />
                            </div>
                            <img
                              src={rotate}
                              onClick={() => {
                                this.postAnalytics(true);
                              }}
                              alt=""
                              style={{ width: "40px", height: "40px" }}
                            />
                            <img src={zoom} width={"32px"} alt="" />

                            {!FROM_SHARE_PAGE && (
                              <img
                                src={share}
                                style={{
                                  cursor: "pointer",
                                  // border: '2px solid red',
                                }}
                                height={"24px"}
                                alt=""
                                onClick={() => {
                                  this.toggleShareDialog();
                                  // this.openModal();
                                }}
                              />
                            )}
                          </span>
                        </div>
                      </model-viewer>
                    </Grid>
                    <Grid
                      sm={3}
                      item
                      style={{
                        position: "relative",
                        maxHeight: "95vh",
                        minHeight: "95vh",
                        boxSizing: "border-box",
                        paddingLeft: 10,
                        border: "0px solid red",
                        paddingTop: 0,
                        display: "flex",
                        flexDirection: "column",
                        overflow: "hidden",
                        paddingBottom: 0,
                        paddingRight: 10,
                      }}
                    >
                      {/* top detail box */}
                      <div>
                        <div
                          className="cardFirstPreview"
                          style={{
                            border: "0px solid red",
                            marginTop: 0,
                            top: 0,
                            padding: "25px 15px",
                            boxSizing: "border-box",
                            width: "100%",
                          }}
                        >
                          <Typography
                            // className="capitalise"
                            title={this.state?.productDel?.title ?? " "}
                            // className="modelTitlePreview"
                            style={{
                              fontWeight: 600,
                              fontSize: 16 || 24,
                              color: "#2E2E2E",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",

                              // border: "1px solid blue",
                            }}
                            variant="h5"
                          >
                            {this.state?.productDel?.title ?? ` `}
                          </Typography>
                          <p
                            title={
                              this?.state?.productDel?.sub_title === "undefined"
                                ? ""
                                : this.state?.productDel?.sub_title
                            }
                            className="subtitlePreview"
                            style={{
                              // border: "1px solid red",
                              lineHeight: "normal",
                              fontSize: 14,
                              fontWeight: 400,
                              color: "#565656",

                              margin: 0,
                              padding: 0,
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              // marginTop: 5,
                            }}
                          >
                            {" "}
                            {this?.state?.productDel?.sub_title === "undefined"
                              ? ""
                              : this.state?.productDel?.sub_title}
                          </p>
                        </div>

                        {this.state.visiblity ? (
                          <div
                            className="cardPreview"
                            onClick={() => {
                              this.redirectToUrl();
                              this.setState(
                                { count: this.state.count + 1 },
                                () => {
                                  // console.log(this.state.count);
                                }
                              );
                            }}
                            style={{
                              cursor: "pointer",
                              border: "0px solid red",
                              marginTop: 0,
                              top: 0,
                              padding: "25px 15px",
                              boxSizing: "border-box",
                              width: "100%",
                              textTransform: "none",
                            }}
                          >
                            <Typography
                              title={
                                this.state.productDel.link_title
                                  ? this.state.productDel.link_title
                                  : "Buy Now"
                              }
                              gutterBottom
                              className="buyNowPreview"
                              onClick={() => {
                                this.redirectToUrl();
                              }}
                              style={{
                                fontWeight: 600,
                                fontSize: 16 || 24,
                                color: "#2E2E2E",
                                cursor: "pointer",
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                lineHeight: "normal",
                                margin: 0,
                                padding: 0,
                              }}
                            >
                              {this.state.productDel.link_title
                                ? this.state.productDel.link_title
                                : "Buy Now"}
                            </Typography>
                            <p
                              className="buy_subPreview"
                              style={{
                                cursor: "pointer",
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                fontSize: 14,
                                fontWeight: 400,
                                color: "#565656",
                                margin: 0,
                                padding: 0,
                                lineHeight: "normal",
                              }}
                            >
                              {this.state.productDel.link_description
                                ? this.state.productDel.link_description
                                : "From Someweb.com"}
                            </p>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      {/* top detail box end */}

                      <div
                        style={{
                          flex: 1,
                          // borderBottom: "1px solid #ddd",
                          overflow: "auto",
                          // border: "1px solid red",
                          marginTop: 0,
                          top: 0,
                          // padding: "25px 15px",
                          boxSizing: "border-box",
                          width: "100%",
                        }}
                        className="__NO__SCROLLBAR__"
                      >
                        {/* <div> */}
                        <div
                          style={{
                            maxHeight: "100%",
                            overflow: "auto",
                            padding: "4px 1px",
                          }}
                        >
                          {combination?.variant?._id
                            ? null
                            : this.state.Components.map((single, index1) => (
                                <div
                                  className="card"
                                  key={single._id || index1}
                                  style={{
                                    border: "0px solid red",
                                    marginTop: 0,
                                    top: 0,
                                    // padding: "25px 15px",
                                    boxSizing: "border-box",
                                    width: "100%",
                                  }}
                                >
                                  <div className="variant_head_wrapper">
                                    <Typography
                                      gutterBottom
                                      className="buyNowPreview"
                                      style={{
                                        lineHeight: "40px",
                                        fontWeight: 600,
                                        fontSize: 16 || 22,
                                        color: "#2E2E2E",
                                        // border: "1px solid red",
                                        lineHeight: "normal",
                                      }}
                                    >
                                      {single.component_name}
                                    </Typography>
                                  </div>
                                  <div
                                    className="fixBox"
                                    style={{
                                      // border: "1px solid red",
                                      padding: 0,
                                    }}
                                  >
                                    {single.materials[0]._id
                                      ? single.materials.map(
                                          (material, index) => (
                                            <div
                                              key={material._id || index}
                                              style={{
                                                // border: "1px solid blue",
                                                padding: 0,
                                              }}
                                            >
                                              {material.material_type ===
                                              "color" ? (
                                                <>
                                                  <div
                                                    onClick={() => {
                                                      this.selectMaterial(
                                                        material._id,
                                                        single._id
                                                      );
                                                    }}
                                                    className="paletteBox"
                                                    style={{
                                                      backgroundColor:
                                                        material.material_value,
                                                      margin:
                                                        "16px 20px 16px 0px",
                                                    }}
                                                    key={index}
                                                  >
                                                    {this.checkSelected(
                                                      material._id,
                                                      single._id
                                                    ) ? (
                                                      <div className="selected">
                                                        <Check
                                                          style={{
                                                            color: "#000",
                                                          }}
                                                        />
                                                      </div>
                                                    ) : null}
                                                  </div>
                                                  <div
                                                    style={{
                                                      inlineSize: "58px",
                                                      overflowWrap:
                                                        "break-word",
                                                    }}
                                                  >
                                                    <p
                                                      // className="capitalise"
                                                      style={{
                                                        textAlign: "center",
                                                        fontFamily: "Inter",
                                                        width: "78px",
                                                        marginTop: "-6px",
                                                        marginLeft: "-4px",
                                                        fontSize: "14px",
                                                        color: "#565656",
                                                        fontWeight: 400,
                                                        border:
                                                          "0px solid blue",
                                                        paddingLeft: 3,
                                                        width: "50px",
                                                        height: "50px",
                                                      }}
                                                    >
                                                      {material.material_name}
                                                    </p>
                                                  </div>
                                                </>
                                              ) : (
                                                <>
                                                  <div
                                                    className="paletteBox"
                                                    style={{
                                                      margin:
                                                        "16px 20px 16px 0px",
                                                    }}
                                                  >
                                                    <img
                                                      onClick={() => {
                                                        this.selectMaterial(
                                                          material._id,
                                                          single._id
                                                        );
                                                      }}
                                                      key={index}
                                                      src={
                                                        API.imgUrl +
                                                        material.material_value
                                                      }
                                                      className="paletteBox"
                                                      style={{
                                                        marginTop: "0px",
                                                        marginLeft: "0px",
                                                      }}
                                                      alt=""
                                                    />
                                                    {this.checkSelected(
                                                      material._id,
                                                      single._id
                                                    ) ? (
                                                      <div className="selected">
                                                        <Check
                                                          style={{
                                                            color: "#000",
                                                          }}
                                                        />
                                                      </div>
                                                    ) : null}
                                                  </div>

                                                  <div
                                                    style={{
                                                      inlineSize: "58px",
                                                      overflowWrap:
                                                        "break-word",
                                                    }}
                                                  >
                                                    <p
                                                      // className="capitalise"
                                                      style={{
                                                        textAlign: "center",
                                                        fontFamily: "Inter",
                                                        width: "78px",
                                                        // marginTop:
                                                        //   material.material_type ===
                                                        //   "color"
                                                        //     ? "-6px"
                                                        //     : "6px",
                                                        marginTop: "-6px",
                                                        marginLeft: "-4px",
                                                        fontSize: "14px",
                                                        color: "#565656",

                                                        fontWeight: 400,
                                                        // border: "1px solid red",
                                                        paddingLeft: 3,
                                                        width: "50px",
                                                        height: "50px",
                                                      }}
                                                    >
                                                      {material.material_name}
                                                    </p>
                                                  </div>
                                                </>
                                              )}
                                            </div>
                                          )
                                        )
                                      : null}
                                  </div>
                                </div>
                              ))}
                          <RecommendedProducts
                            recommends={this.props.recommends}
                            incrementCombinationViewCount={
                              this.incrementCombinationViewCount
                            }
                          />
                        </div>
                        {/* </div> */}
                      </div>
                      {/* <div
                        style={{
                          height: 120,
                          borderBottom: "1px solid #ddd",
                          width: "100%",
                        }}
                      /> */}
                      <div
                        className="cardPreview"
                        style={{
                          boxShadow: "none",
                          borderRadius: 0,
                          // border: "1px solid red",
                          paddingLeft: 0,
                          paddingBottom: 0,
                          paddingTop: 0,
                          width: "100%",
                          boxSizing: "border-box",
                        }}
                      />
                      {/* <div
                        style={{
                          display: 'flex',
                        }}
                      > */}
                      <div
                        style={{
                          // marginBottom: 4,
                          // border: "1px solid blue",
                          marginTop: 10,
                          width: "100%",
                          boxSizing: "border-box",
                          margin: "auto",
                          paddingBottom: 5,
                        }}
                      >
                        {this.state.showModalBtn ? (
                          <div
                            id="qrtext"
                            style={{
                              bottom: 4,
                              margin: 0,
                              position: "static",
                              top: "auto",
                              bottom: "auto",
                              left: "auto",
                              right: "auto",
                              transform: "none",
                              width: "100%",
                              boxSizing: "border-box",
                              // border: '1px solid red',
                            }}
                            onClick={() => {
                              this.setState(
                                { count1: this.state.count1 + 1 },
                                () => {}
                              );
                            }}
                          >
                            <img
                              src={arimage}
                              width="24px"
                              alt=""
                              style={{ marginRight: "4px" }}
                            />
                            <p
                              className="view_space_btn"
                              style={{
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                              }}
                            >
                              {" "}
                              {this.state.language === "French"
                                ? "Le rover devant vous"
                                : "View in your space"}{" "}
                            </p>
                          </div>
                        ) : null}
                      </div>
                      {/* <div
                          style={{
                            alignSelf: 'stretch',
                            // border: '1px solid red',
                            // width: '100%',
                            width: 65,
                            height: 65,
                            marginLeft: 10,
                            display: 'grid',
                            placeItems: 'center',
                            position: 'relative',
                            borderRadius: 16,
                            overflow: 'hidden',
                          }}
                        >
                          <div
                            style={{
                              position: 'absolute',
                              top: 0,
                              left: 0,
                              right: 0,
                              bottom: 0,
                              background: 'rgba(0,0,255,0.4)',
                            }}
                          />
                          <img src={showDims} width={40} height={40} />
                        </div> */}
                      {/* </div> */}
                    </Grid>
                  </Grid>
                ) : null}
              </div>
            ) : (
              <MobileModelDynamicViewer
                recommends={this.props.recommends}
                postAnalytics={this.postAnalytics}
                incrementSpaceClick={this.incrementSpaceClick}
                incrementActionClick={this.incrementActionClick}
                combination={combination}
                isMdOrDown={this.props.isMdOrDown}
                model={this.state.ModalImage}
                visibility={this.state.visiblity}
                product={this.state.productName}
                productSubTitle={
                  this.state.productDel
                    ? this.state.productDel.sub_title === "undefined"
                      ? null
                      : this.state.productDel.sub_title
                    : ""
                }
                Component={this.state.Components}
                companyLogo={
                  this.state.productDel.user
                    ? this.state.productDel.user.company_logo
                    : ""
                }
                productLogo={
                  this.state.productDel
                    ? this.state.productDel.product_logo
                    : ""
                }
                showARError={this.state.showARError}
                selectMaterial={this.selectMaterial}
                checkSelected={this.checkSelected}
                showVariant={this.state.showVariant}
                selectedVariant={this.state.selectedVariant}
                selectVariant={this.selectVariant}
                visibleModal={this.openModal}
                ViewModal={this.setUpModal}
                openLink={this.redirectToUrl}
                UrlTitle={this.state.productDel.link_title}
                UrlDescription={this.state.productDel.link_description}
                leftModels={this.state.leftModels}
                leftDays={this.state.leftDays}
                leftViews={this.state.leftViews}
                updateMobilePayload={this.updateMobilePayload}
                language={this.state.language}
                onCloseARError={this.closeError}
                analytics_id={this.state.analytics_id}
                arplacement={this.state.arplacement}
              />
            )}
            <div id="myModal" className="modal"></div>
            <div className="modal-content">
              <div className="contentArea">
                <h1>
                  {" "}
                  {this.state.language === "French"
                    ? "Voir le rover en réalité augmentée"
                    : "How to View in Augmented Reality"}
                </h1>
                <p>
                  {this.state.language === "French"
                    ? `Balayez ce code QR avec votre téléphone pour voir le rover devant vous. 
                  L’expérience se lancera automatiquement, aucune application à télécharger!`
                    : `Scan this QR code with your phone to view the object in your
              space. The experience launches directly from your browser - no app
              required !`}
                </p>
                <p>
                  {deviceSupported(this.state.language)}
                  {/* {this.state.language === "French"
                  ? `*Pour de meilleurs résultats, utilisez un iPhone 12 ou plus récent.`
                  : `*works best with iPhone 12 & above`}{" "} */}
                </p>
              </div>
              <div id="qrCodeWrapper">
                {this.state.productStatus === "draft" ? (
                  <QRCode
                    value={
                      "https://actuality.live/modelDynamicviewer/" +
                      this.props.matchparams.id
                    }
                    style={{ height: 200, width: 200, marginTop: 12 }}
                  />
                ) : (
                  <QRCode
                    value={
                      "https://actuality.live/modelDynamicviewer/" +
                      this.props.matchparams.id
                    }
                    style={{ height: 200, width: 200, marginTop: 12 }}
                  />
                )}
              </div>
              <span className="closeBtn">
                {this.state.language === "French" ? "Proche" : "Close"}
              </span>
            </div>
            {/* <CookieConsent
              // location="bottom"
              buttonText="Accept"
              declineButtonClasses="Decline"
              onAccept={()=>this.cookies}
              cookieName="fx_co."
              cookieValue={this.state.ip}
              enableDeclineButton={true}
              style={{ background: "#2B373B" }}
              buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
              expires={150}
            >
              This website uses cookies to enhance the user experience.{" "}
            </CookieConsent> */}
            <Modal
              visible={this.state.visible}
              width="550"
              height="450"
              effect="fadeInUp"
              onClickAway={() => this.closeModal()}
            >
              <div className="shareModal web">
                <span
                  className="textMain"
                  style={{
                    display: "block",
                    textAlign: "center",
                  }}
                >
                  Share
                </span>
                <img
                  src={remove}
                  width="15px"
                  height="15px"
                  alt=""
                  className="colseModelbtn"
                  onClick={() => this.closeModal()}
                />
                {/* <span
                  className="textMain"
                  style={{ marginLeft: "84px", fontWeight: 400 }}
                >
                  For internal testing only
                </span> */}
                <Grid container spacing={2} style={{ position: "relative" }}>
                  <Grid item sm={3}>
                    {" "}
                    <Typography>URL</Typography>{" "}
                  </Grid>
                  <Grid item sm={7}>
                    <input
                      className="input"
                      value={this.state.value}
                      onChange={({ target: { value } }) =>
                        this.setState({ value, copied: false })
                      }
                      style={{
                        width: window.innerWidth < 1100 ? "81%" : "",
                        marginLeft: window.innerWidth < 1100 ? "10px" : "",
                      }}
                    />
                  </Grid>
                  <Grid item sm={2}>
                    {this.state.productStatus === "draft" ? (
                      <CopyToClipboard
                        text={this.state.value}
                        onCopy={this.copyUrl}
                      >
                        <Button
                          className="CopyBtn"
                          style={{
                            marginLeft: window.innerWidth < 1100 ? "-45px" : "",
                          }}
                        >
                          Copy
                        </Button>
                      </CopyToClipboard>
                    ) : (
                      <CopyToClipboard
                        text={this.state.value}
                        onCopy={this.copyUrl}
                      >
                        <Button
                          className="CopyBtn"
                          style={{
                            marginLeft: window.innerWidth < 1100 ? "-45px" : "",
                          }}
                        >
                          Copy
                        </Button>
                      </CopyToClipboard>
                    )}
                    {this.state.copied ? (
                      <Typography
                        style={{
                          color: "red",
                          marginTop: window.innerWidth < 1100 ? "" : "40px",
                          marginLeft: window.innerWidth < 1100 ? "" : "22px",
                        }}
                      >
                        Copied.
                      </Typography>
                    ) : null}
                  </Grid>
                </Grid>
                <Grid container spacing={2} style={{ position: "relative" }}>
                  <Grid item sm={3}>
                    {window.innerWidth < 1100 ? (
                      <Typography>Code</Typography>
                    ) : (
                      <Typography>Embed Code</Typography>
                    )}
                  </Grid>
                  <Grid item sm={7}>
                    <input
                      type="text"
                      className="input"
                      value={this.state.embedCode}
                      style={{ width: window.innerWidth < 1100 ? "84%" : "" }}
                      onChange={({ target: { embedCode } }) =>
                        this.setState({ embedCopy: false })
                      }
                    />
                  </Grid>
                  <Grid item sm={2}>
                    <CopyToClipboard
                      text={this.state.embedCode}
                      onCopy={this.copyEmbed}
                    >
                      <Button
                        className="CopyBtn"
                        style={{
                          marginLeft: window.innerWidth < 1100 ? "-45px" : "",
                        }}
                      >
                        Copy
                      </Button>
                    </CopyToClipboard>
                    {this.state.embedCopy ? (
                      <Typography
                        style={{
                          color: "red",
                          marginTop: window.innerWidth < 1100 ? "" : "40px",
                          marginLeft: window.innerWidth < 1100 ? "" : "22px",
                        }}
                      >
                        Copied.
                      </Typography>
                    ) : null}
                  </Grid>
                </Grid>
                {/* shareurl */}
                <Grid container spacing={2} style={{ position: "relative" }}>
                  <Grid item sm={3}>
                    {window.innerWidth < 1100 ? (
                      <Typography>View 3D</Typography>
                    ) : (
                      <Typography>View 3D </Typography>
                    )}
                  </Grid>
                  <Grid item sm={7}>
                    <input
                      type="text"
                      className="input"
                      value={this.state.shareUrl}
                      style={{ width: window.innerWidth < 1100 ? "84%" : "" }}
                      onChange={({ target: { shareUrl } }) =>
                        this.setState({ copyShareUrl: false })
                      }
                    />
                  </Grid>
                  <Grid item sm={2}>
                    <CopyToClipboard
                      text={this.state.shareUrl}
                      onCopy={this.copyShare}
                    >
                      <Button
                        className="CopyBtn"
                        style={{
                          marginLeft: window.innerWidth < 1100 ? "-45px" : "",
                        }}
                      >
                        Copy
                      </Button>
                    </CopyToClipboard>
                    {this.state.copyShareUrl ? (
                      <Typography
                        style={{
                          color: "red",
                          marginTop: window.innerWidth < 1100 ? "" : "40px",
                          marginLeft: window.innerWidth < 1100 ? "" : "22px",
                        }}
                      >
                        Copied.
                      </Typography>
                    ) : null}
                  </Grid>
                </Grid>

                <Grid container spacing={2} style={{ position: "relative" }}>
                  <Grid item sm={3}>
                    {window.innerWidth < 1100 ? (
                      <Typography>View in AR</Typography>
                    ) : (
                      <Typography>View in AR </Typography>
                    )}
                  </Grid>
                  <Grid item sm={7}>
                    <input
                      type="text"
                      className="input"
                      value={this.state.open_in_ar}
                      style={{ width: window.innerWidth < 1100 ? "84%" : "" }}
                      // onChange={({ target: { shareUrl } }) =>
                      //   this.setState({ open_in_ar_copied: false })
                      // }
                    />
                  </Grid>
                  <Grid item sm={2}>
                    <CopyToClipboard
                      text={this.state.open_in_ar}
                      onCopy={this.copyOpenInAR}
                    >
                      <Button
                        className="CopyBtn"
                        style={{
                          marginLeft: window.innerWidth < 1100 ? "-45px" : "",
                        }}
                      >
                        Copy
                      </Button>
                    </CopyToClipboard>
                    {this.state.open_in_ar_copied ? (
                      <Typography
                        style={{
                          color: "red",
                          marginTop: window.innerWidth < 1100 ? "" : "40px",
                          marginLeft: window.innerWidth < 1100 ? "" : "22px",
                        }}
                      >
                        Copied.
                      </Typography>
                    ) : null}
                  </Grid>
                </Grid>

                <Grid container spacing={2} style={{ position: "relative" }}>
                  <Grid item sm={3}>
                    {" "}
                    <Typography>QR code</Typography>{" "}
                  </Grid>
                  <Grid item sm={4}>
                    {this.state.productStatus === "draft" ? (
                      <QRCode
                        id="QRCode"
                        value={
                          "https://portal.actuality.live/modelDynamicviewer/" +
                          this.state.productId
                        }
                        style={{ height: 150, width: 150, marginTop: 12 }}
                      />
                    ) : (
                      <QRCode
                        id="QRCode"
                        value={
                          "https://actuality.live/modelDynamicviewer/" +
                          this.state.productId
                        }
                        style={{ height: 150, width: 150, marginTop: 12 }}
                      />
                    )}
                  </Grid>
                  <Grid item sm={5}>
                    <Button
                      className="CopyBtn"
                      onClick={this.downloadQR}
                      style={{
                        marginTop: window.innerWidth < 1100 ? "122px" : "120px",
                        marginLeft: window.innerWidth < 1100 ? "78px" : "",
                        position:
                          window.innerWidth < 1100 ? "absolute" : "relative",
                      }}
                    >
                      Download
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </Modal>
          </div>
        </Beforeunload>
      );
    }
  }
}

const ModelDescription = ({ title, sub_title }) => (
  <div
    className="cardFirstPreview"
    style={{
      border: "1px solid red",
      marginTop: 0,
      top: 0,
    }}
  >
    <Typography
      // className="modelTitlePreview"
      style={{ fontWeight: 500, fontSize: 18 }}
      variant="h5"
    >
      {title ?? ` `}
      {/* {this.state.productDel.title ? this.state.productDel.title : ""} */}
    </Typography>
    {/* <p className="subtitlePreview">Scroll to zoom</p> */}
    <p className="subtitlePreview" style={{ border: "0px solid red" }}>
      {/* {this.state.productDel
          ? this.state.productDel.sub_title === "undefined"
            ? null
            : this.state.productDel.sub_title
          : ""} */}
      {sub_title ?? ` `}
    </p>
  </div>
);
const Lorem = () => (
  <>
    <h1>
      Lorem ipsum dolor sit amet consectetur adipisicing elit. At sed libero
      officia excepturi optio porro nobis animi vero quae minus, deserunt
      perspiciatis autem obcaecati placeat et magni possimus, ex ad!
    </h1>
    <br />
  </>
);
