import axios from 'axios';
import { API } from '../Constants/APIs';
const checkBeforeUnload = (payLoadData) => {
  const urls = [API.postUrls.addAnalytics];

  const data = JSON.stringify(payLoadData);
  urls.forEach((url) => {
    if (navigator.sendBeacon) navigator.sendBeacon(url, data);
    else {
      axios
        .post(url, payLoadData)
        .then((res) => {
          // console.log("res from check ", res.data);
        })
        .catch((e) => {
          // console.log("eror from check ", e.response.data);
        });
    }
  });
};

export default checkBeforeUnload;
