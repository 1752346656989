import "@google/model-viewer";
import { Typography } from "@material-ui/core";
import { Check } from "@material-ui/icons";
import React, { Component } from "react";
import arimage from "../Assets/Images/ar_icon.png";
import closeIcon from "../Assets/Images/cancelToggle.png";
import closeModal from "../Assets/Images/closeModal.png";
import hamburger from "../Assets/Images/menu.png";

import axios from "axios";
import { Beforeunload } from "react-beforeunload";
import Geocode from "react-geocode";
import { Navigate } from "react-router-dom";
import { API } from "../Constants/APIs";
var pageVisibility = document.visibilityState;
const variantControllerHeight = 100;
export default class ShareMobileModal extends Component {
  state = {
    toggle: false,
    navClose: { right: "0px" },
    redirect: false,
    leftModels: false,
    leftDays: false,
    leftViews: false,
    productId: window.location.pathname.split("/")[2],

    // for geolocation
    lat: 0,
    long: 0,
    mobile: false,
    platform: "",
    city: "",
    country: "",
    ip: "",
    session: 0,
    count: 0,
    count1: 0,
    seconds: 0,
    checkCall: false,
    referrer: "",
    modalVisible: true,
  };
  toggleMenu = () => {
    this.setState({
      toggle: true,
    });
  };

  componentDidMount() {
    window.addEventListener("blur", () => {
      this.postMobileAnalytics();
    });

    const referrer = document.referrer;
    if (referrer.length > 0) {
      let domain = referrer.split("/");
      domain = domain[0] + "//" + domain[2];
      // console.log(domain);
      this.setState({ referrer: domain }, () => {
        this.props.updateMobilePayload(this.state);
      });
      // console.log( referrer);
    } else {
      this.setState({ referrer: "N/A" }, () => {
        this.props.updateMobilePayload(this.state);
      });
    }

    // console.log(this.props);
    let modelsLeft = this.props.leftModels;
    let daysLeft = this.props.leftDays;
    let viewsLeft = this.props.leftViews;
    if (modelsLeft === 0) {
      this.setState({
        redirect: true,
        leftModels: true,
      });
    } else if (daysLeft === 0) {
      this.setState({
        redirect: true,
        leftDays: true,
      });
    } else if (viewsLeft === 0) {
      this.setState({
        redirect: true,
        leftViews: true,
      });
    }

    this.geolocationData();
    this.getIPadd();

    this.interval = setInterval(() => {
      this.setState(
        {
          seconds: this.state.seconds + 1,
        },
        () => {
          this.props.updateMobilePayload(this.state);
        }
      );
    }, 1000);
  }

  componentWillUnmount() {
    // console.log("unmount");
    // window.removeEventListener("focus", this.onFocus);
  }

  // onFocus = () => {
  //   this.geolocationData();
  //   this.getIPadd();
  //   this.setState({checkCall: true}, ()=>{
  // console.log("called");
  //   });
  //   // setTimeout(() => {
  //   // this.setState({checkCall: false});
  //   // }, 100);
  // };

  componentWillMount() {
    if (window.innerWidth < 750) {
      this.setState({ navClose: { right: "-370px" } });
    }
    if (window.innerWidth < 1199) {
      this.setState({ navClose: { right: "-770px" } });
    }
  }

  getIPadd = () => {
    axios
      .get("https://api.ipregistry.co/?key=36oivejw4lcirye7")
      .then((resp) => {
        // console.log(resp);
        this.setState(
          {
            ip: resp.data.ip,
            platform: resp.data.user_agent.os.name,
            mobile: resp.data.user_agent.os.type === "mobile" ? true : false,
          },
          () => {
            this.props.updateMobilePayload(this.state);

            // console.log(this.state.ip, this.state.platform, this.state.mobile);
          }
        );
      })
      .catch((err) => {
        if (err) {
          // console.log(err.response);
        }
      });
  };

  geolocationData = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        this.getlocation,
        this.showError
      );
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  };

  getlocation = (data) => {
    // console.log(data);
    let platformData = navigator;
    // console.log(platformData);
    this.setState(
      {
        lat: data.coords.latitude,
        long: data.coords.longitude,
        // mobile: platformData.userAgentData.mobile,
        // platform: platformData.userAgentData.platform,
      },
      () => {
        this.props.updateMobilePayload(this.state);
        // console.log(
        //   this.state.lat,
        //   this.state.long
        //   // this.state.mobile,
        //   // this.state.platform
        // );
        this.getCountryCityName(this.state.lat, this.state.long);
      }
    );
  };

  showError = (error) => {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        // console.log("User denied the request for Geolocation.");
        break;
      case error.POSITION_UNAVAILABLE:
        // console.log("Location information is unavailable.");
        break;
      case error.TIMEOUT:
        // console.log("The request to get user location timed out.");
        break;
      case error.UNKNOWN_ERROR:
        // console.log("An unknown error occurred.");
        break;
    }
  };

  getCountryCityName = (lat, long) => {
    Geocode.setApiKey("AIzaSyDA30l6vrlZw3uCP9nQnzntysJU1U092F0");
    Geocode.setLanguage("en");
    Geocode.setRegion("es");
    Geocode.setLocationType("ROOFTOP");
    Geocode.enableDebug();

    Geocode.fromLatLng(lat, long).then(
      (response) => {
        // console.log(response);
        const address = response.results[0].formatted_address;
        let city, state, country;
        for (
          let i = 0;
          i < response.results[0].address_components.length;
          i++
        ) {
          for (
            let j = 0;
            j < response.results[0].address_components[i].types.length;
            j++
          ) {
            switch (response.results[0].address_components[i].types[j]) {
              case "locality":
                city = response.results[0].address_components[i].long_name;
                break;
              case "administrative_area_level_1":
                state = response.results[0].address_components[i].long_name;
                break;
              case "country":
                country = response.results[0].address_components[i].long_name;
                break;
            }
          }
        }
        // console.log(city, state, country);
        // console.log(address);
        this.setState(
          {
            city,
            country,
          },
          () => {
            this.props.updateMobilePayload(this.state);

            // console.log(this.state.city, this.state.country);
          }
        );
      },
      (error) => {
        console.error(error);
      }
    );
  };

  postMobileAnalytics = () => {
    let payLoadData = {
      product: this.state.productId,
      lat: this.state.lat,
      long: this.state.long,
      country: this.state.country,
      city: this.state.city,
      mobile: this.state.mobile,
      platform: this.state.platform,
      ip: this.state.ip,
      actionClicks: this.state.count,
      spaceClicks: this.state.count1,
      session: this.state.seconds,
      referrerBySession: this.state.referrer,
    };
    axios
      .post(API.postUrls.addAnalytics, payLoadData)
      .then((resp) => {
        // console.log(resp);
        // alert("post");
      })
      .catch((error) => {
        if (error) {
          // console.log(error.response);
        }
      });
  };

  openNav() {
    this.setState({ navClose: { right: "0px" }, openMainScreen: true });
  }
  closeNav() {
    this.setState({ navClose: { right: "-770px" } });
  }

  redirectToUrl = () => {
    if (this.props.UrlDescription) {
      window.open(this.props.UrlDescription);
    }
  };

  // componentWillUnmount(){

  // }
  // componentDidUpdate(prevProps, prevState) {
  // console.log(prevState.checkCall, this.state.checkCall);
  //   if(prevState.checkCall !==this.state.checkCall){
  //     // this.setState({checkCall: !this.state.checkCall});
  //   }
  // }
  render() {
    if (this.state.redirect === true) {
      return (
        <Navigate
          to="../pageNotFound"
          state={{
            cancelled: this.state.cancelled,
            leftModels: this.state.leftModels,
            leftDays: this.state.leftDays,
            leftViews: this.state.leftViews,
          }}
        />
      );
    } else {
      const props = this.props;
      // console.log(props);
      return (
        // <Beforeunload
        // onBeforeunload={() => {
        //   setTimeout(() => {
        // console.log("before");
        //     if (!this.state.checkCall) {
        //       this.postMobileAnalytics();
        //     } else {
        //       this.setState({ checkCall: false });
        //     }
        //   }, 1);
        // }}
        // >
        <div
          className="mobileResponsiveViewer"
          style={{
            height: window.innerHeight,
            position: "relative",
            overflowX: "hidden",
          }}
        >
          {this.state.modalVisible && (
            <>
              {props.selectedVariant.usdz_file_url ? (
                <model-viewer
                  id="reveal"
                  exposure="0.8"
                  src={
                    props.showVariant
                      ? API.imgUrl + props.selectedVariant.model_file_url
                      : API.imgUrl + props.model
                  }
                  ios-src={API.imgUrl + props.selectedVariant.usdz_file_url}
                  camera-orbit="45deg 55deg 2.5m"
                  shadow-intensity="2"
                  field-of-view="80deg"
                  min-field-of-view={"80deg"}
                  max-field-of-view={"80deg"}
                  bounds="tight"
                  camera-controls
                  ar
                  ar-modes="webxr scene-viewer quick-look"
                  ar-scale={"auto"}
                  ar-placement={"floor"}
                  autoplay
                  loading="lazy"
                  style={{
                    background: "#FFFFFF",
                    // height: window.innerHeight - variantControllerHeight,
                    height: "360px",
                    width: "-webkit-fill-available",
                    position: "absolute",
                    left: "50%",
                    top: props.showVariant ? "0%" : "30%",
                    transform: props.showVariant
                      ? "translateX(-50%)"
                      : "translate(-50%, -50%)",
                  }}
                >
                  <button
                    slot="ar-button"
                    className="viewInYourSpace"
                    style={{ marginBottom: "-14px" }}
                    onClick={() => {
                      // console.log("click called");

                      this.setState(
                        {
                          checkCall: true,
                          count1: this.state.count1 + 1,
                        },
                        () => {
                          this.props.updateMobilePayload(this.state);
                        }
                      );
                    }}
                  >
                    <img src={arimage} width="20px" alt="" />
                    <p className="view_space_btn">
                      {" "}
                      {this.props.language === "French"
                        ? "Voir dans votre espace"
                        : "View in your space"}
                    </p>
                  </button>
                </model-viewer>
              ) : (
                <model-viewer
                  id="reveal"
                  exposure="0.8"
                  src={
                    props.showVariant
                      ? API.imgUrl + props.selectedVariant.model_file_url
                      : API.imgUrl + props.model
                  }
                  camera-orbit="45deg 55deg 2.5m"
                  shadow-intensity="2"
                  field-of-view="80deg"
                  min-field-of-view={"80deg"}
                  max-field-of-view={"80deg"}
                  bounds="tight"
                  camera-controls
                  ar
                  ar-modes="webxr scene-viewer quick-look"
                  ar-scale={"auto"}
                  ar-placement={"floor"}
                  environment-image="neutral"
                  autoplay
                  loading="lazy"
                  style={{
                    background: "#FFFFFF",
                    // height: window.innerHeight - variantControllerHeight,
                    height: "360px",
                    width: "-webkit-fill-available",
                    position: "absolute",
                    left: "50%",
                    top: props.showVariant ? "0%" : "30%",
                    transform: props.showVariant
                      ? "translateX(-50%)"
                      : "translate(-50%, -50%)",
                  }}
                >
                  <button
                    slot="ar-button"
                    className="viewInYourSpace"
                    style={{ marginBottom: "-14px" }}
                    onClick={() => {
                      // console.log("click called");
                      this.setState(
                        {
                          checkCall: true,
                          count1: this.state.count1 + 1,
                        },
                        () => {
                          this.props.updateMobilePayload(this.state);
                        }
                      );
                    }}
                  >
                    <img src={arimage} width="20px" alt="" />
                    <p className="view_space_btn">
                      {" "}
                      {this.props.language === "French"
                        ? "Voir dans votre espace"
                        : "View in your space"}
                    </p>
                  </button>
                </model-viewer>
              )}
              <div
                className="menuHamburger"
                style={{
                  width: "42px",
                  height: "45px",
                  position: "absolute",
                  top: "0px",
                  right: "21px",
                  zIndex: 2147483648,
                  display: "inline-flex",
                }}
              >
                <img
                  src={hamburger}
                  onClick={this.toggleMenu}
                  alt=""
                  style={{ width: "78%", height: "30px" }}
                />
                <img
                  src={closeModal}
                  onClick={() => {
                    this.setState({ modalVisible: false });
                  }}
                  alt=""
                  style={{ width: "78%", height: "30px" }}
                />
              </div>
              <Typography
                className="brand"
                style={{
                  fontStyle: "normal",
                  fontWeight: "bold",
                  fontSize: "18px",
                  color: "#2d3436",
                  alignSelf: "start",
                  position: "relative",
                  zIndex: 9999,
                  marginBottom: 0,
                  position: "relative",
                  top: 0,
                  left: 0,
                }}
              >
                {this.props.productLogo ? (
                  <div
                    className="modelCompanyLogo"
                    style={{
                      width: "92px",
                      height: "92px",
                      overflow: "hidden",
                      borderRadius: "15px",
                      // background-color: #ddd;
                      padding: "3px",
                      position: "absolute",
                      top: "26px",
                      left: "24px",
                    }}
                  >
                    <img
                      src={API.imgUrl + this.props.productLogo}
                      alt=""
                      style={{ width: 92, height: 92, borderRadius: 15 }}
                    />
                  </div>
                ) : (
                  ""
                )}
              </Typography>
              <div
                className="variantControls"
                style={{
                  height: variantControllerHeight,
                  height: "auto",
                  width: "270px",
                  display: "inline-flex",
                  position: "absolute",
                  bottom: "40px",
                  left: 0,
                  overflowX: "scroll !important",
                  padding: "0 16px",
                  overflow: "hidden",
                }}
              >
                {props.Component.map((single, index1) => (
                  <div>
                    <Typography style={{ marginLeft: 6 }}>
                      {single.component_name}
                    </Typography>
                    <div
                      className="framesWrapper"
                      style={{
                        alignItems: "center",
                        maxHeight: "90px",
                        overflowY: "scroll",
                      }}
                    >
                      <div
                        className="variantListing"
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          minWidth: "120px",
                        }}
                      >
                        {single.materials[0]._id
                          ? single.materials.map((material, index) => (
                              <div>
                                {material.material_type === "color" ? (
                                  <>
                                    <div
                                      onClick={() => {
                                        this.props.selectMaterial(
                                          material._id,
                                          single._id
                                        );
                                      }}
                                      className="paletteBox"
                                      style={{
                                        backgroundColor:
                                          material.material_value,
                                        position: "relative",
                                        width: "50px",
                                        height: "50px",
                                        boxShadow:
                                          "0px 4px 4px rgba(0, 0, 0, 0.25)",
                                        borderRadius: "5px",
                                        margin: "6px",
                                        transition: "0.3s",
                                        cursor: "pointer",
                                      }}
                                      key={index}
                                    >
                                      {this.props.checkSelected(
                                        material._id,
                                        single._id
                                      ) ? (
                                        <div
                                          className="selected"
                                          style={{
                                            position: "absolute",
                                            top: 0,
                                            left: 0,
                                            right: 0,
                                            bottom: 0,
                                            opacity: 0.3,
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            color: "#fff",
                                          }}
                                        >
                                          <Check style={{ color: "#000" }} />
                                        </div>
                                      ) : null}
                                    </div>

                                    <div
                                      style={{
                                        inlineSize: "58px",
                                        overflowWrap: "break-word",
                                      }}
                                    >
                                      <p
                                        style={{
                                          textAlign: "center",
                                          fontSize: "14px",
                                          fontFamily: "Inter",
                                        }}
                                      >
                                        {material.material_name}
                                      </p>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div
                                      className="paletteBox"
                                      style={{
                                        backgroundColor:
                                          material.material_value,
                                        position: "relative",
                                        width: "50px",
                                        height: "50px",
                                        boxShadow:
                                          "0px 4px 4px rgba(0, 0, 0, 0.25)",
                                        borderRadius: "5px",
                                        margin: "6px",
                                        transition: "0.3s",
                                        cursor: "pointer",
                                      }}
                                    >
                                      <img
                                        onClick={() => {
                                          this.props.selectMaterial(
                                            material._id,
                                            single._id
                                          );
                                        }}
                                        key={index}
                                        src={
                                          API.imgUrl + material.material_value
                                        }
                                        className="paletteBox"
                                        style={{
                                          backgroundColor:
                                            material.material_value,
                                          position: "relative",
                                          width: "50px",
                                          height: "50px",
                                          boxShadow:
                                            "0px 4px 4px rgba(0, 0, 0, 0.25)",
                                          borderRadius: "5px",
                                          margin: "6px",
                                          transition: "0.3s",
                                          cursor: "pointer",
                                          marginTop: "0px",
                                          marginLeft: "0px",
                                        }}
                                        alt=""
                                      />
                                      {this.props.checkSelected(
                                        material._id,
                                        single._id
                                      ) ? (
                                        <div
                                          className="selected"
                                          style={{
                                            position: "absolute",
                                            top: 0,
                                            left: 0,
                                            right: 0,
                                            bottom: 0,
                                            opacity: 0.3,
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            color: "#fff",
                                          }}
                                        >
                                          <Check style={{ color: "#000" }} />
                                        </div>
                                      ) : null}
                                    </div>
                                    <div
                                      style={{
                                        inlineSize: "58px",
                                        overflowWrap: "break-word",
                                      }}
                                    >
                                      <p
                                        style={{
                                          textAlign: "center",
                                          fontSize: "14px",
                                          fontFamily: "Inter",
                                        }}
                                      >
                                        {material.material_name}
                                      </p>
                                    </div>
                                  </>
                                )}
                              </div>
                            ))
                          : null}
                        {!props.Component.lenth - 1 === index1 ? (
                          <div
                            className="separator"
                            style={{
                              height: "75px",
                              position: "relative",
                              top: 0,
                              margin: "0 32px",
                              border: "0.5px solid #727272",
                            }}
                          ></div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              <div
                className="poweredBy"
                style={{
                  backgroundColor: "#fff",
                  width: "100%",
                  position: "absolute",
                  bottom: "6px",
                  left: 0,
                  // overflow-x: scroll;
                  boxSizing: "border-box",
                  padding: " 6px 0px 6px 0px",
                  // padding-bottom: 0;
                  textAlign: "center",
                  fontFamily: "Inter",
                }}
              >
                <a
                  href="https://actuality.live/"
                  target="_blank"
                  style={{
                    textDecoration: "none",
                    color: "#000000",
                  }}
                >
                  powered by <b style={{ color: "#000" }}>actuality.live</b>
                </a>
              </div>

              {this.state.toggle ? (
                <>
                  {/* <ClickAwayListener onClickAway={this.handleClickAway}> */}
                  <div className="sideBar" style={{ overflowX: "hidden" }}>
                    {/* <div className="productName">
                  <Typography
                      className="buyNow"
                    >
                      {props.product}
                      <br /> 
                    </Typography>
                    
                    <Typography
                      className="buyNow"
                    >
                      {props.productSubTitle}
                      <br /> 
                    </Typography>
                  </div> */}

                    <div
                      className="productName"
                      style={{
                        backgroundColor: "#fff",
                        width: "100%",
                        // height: 80px;
                        borderRadius: "15px",
                        color: "#000",
                        boxShadow: "1px 2px 5px rgba(0, 0, 0, 0.1)",
                        position: "relative",
                        top: 0,
                      }}
                    >
                      <div
                        className="closeToggle"
                        style={{
                          overflowX: "hidden",
                          cursor: "pointer",
                          zIndex: "99999",
                          position: "absolute",
                          right: "10px",
                          top: "12px",
                        }}
                        onClick={() =>
                          this.setState(
                            {
                              toggle: false,
                            },
                            () => {
                              // console.log(this.state.toggle);
                            }
                          )
                        }
                      >
                        <img src={closeIcon} style={{ width: "16px" }} />
                      </div>
                      <h5
                        className="prd1"
                        style={{
                          fontWeight: 500,
                          fontSize: "17px",
                          color: "#000000",
                          letterSpacing: "-1px",
                          padding: "12px",
                          margin: 0,
                        }}
                      >
                        <Typography
                          className="buyNow"
                          style={{ paddingTop: 0 }}
                        >
                          {props.product ? props.product : ""}
                          <br />
                        </Typography>
                        <Typography>
                          {props.productSubTitle ? props.productSubTitle : ""}
                          <br />
                        </Typography>
                      </h5>
                    </div>

                    <div
                      className="callToAction"
                      style={{
                        backgroundColor: "#fff",
                        width: "230px",
                        height: "80px",
                        borderRadius: "15px",
                        color: "#000",
                        boxShadow: "1px 2px 5px rgba(0, 0, 0, 0.1)",
                        position: "relative",
                        top: "-13px",
                      }}
                    >
                      <h5
                        className="prd"
                        style={{
                          fontWeight: "500",
                          fontSize: "17px",
                          lineHeight: "56px",
                          color: "#000000",
                          paddingLeft: "13px",
                          letterSpacing: "-1px",
                        }}
                      >
                        {this.props.visibility ? (
                          <>
                            <Typography
                              className="buyNow"
                              style={{ paddingTop: "0px" }}
                              onClick={() => {
                                this.props.openLink();
                                // this.redirectToUrl()
                                this.setState(
                                  { count: this.state.count + 1 },
                                  () => {
                                    this.props.updateMobilePayload(this.state);

                                    // console.log(this.state.count);
                                  }
                                );
                              }}
                            >
                              {props.UrlTitle ? props.UrlTitle : "Buy Now"}
                              <br />
                            </Typography>
                            <Typography
                              // className="buyNow"
                              onClick={() => {
                                this.props.updateMobilePayload(this.state);

                                this.props.openLink();
                                // this.redirectToUrl();
                              }}
                            >
                              {props.UrlDescription ? props.UrlDescription : ""}
                              <br />
                            </Typography>
                          </>
                        ) : (
                          ""
                        )}
                      </h5>
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
            </>
          )}
        </div>
        // </Beforeunload>
      );
    }
  }
}
