export const addViewpostMetaTag = (id) => {
  const isExist = document.querySelector(id ?? "#viewportInShare");
  if (isExist) return;

  var viewPortTag = document.createElement("meta");
  viewPortTag.id = "viewportInShare";
  viewPortTag.name = "viewport";
  viewPortTag.content = "width=device-width, initial-scale=1.0";
  document.getElementsByTagName("head")[0].appendChild(viewPortTag);
  return () =>
    document.getElementsByTagName("head")[0].removeChild(viewPortTag);
  // alert("added");
};

export const removeViewportMetaTag = (id) => {
  let selector = `head meta[name=viewport] `;
  selector += id ? `#${id}` : "";
  //    console.log(`selector `, selector);
  const tag = document.querySelector(selector);
  //    console.log(`tag `, tag);
  alert(tag);
};
