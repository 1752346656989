import React, { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { API } from "../../Constants/APIs";
import PasscodeVerfiy from "../PasscodeVerify";
import ModelDynamicRecommend from "./ModelDynamicRecommend";
import { useParams } from "react-router-dom";
import RecommendedWrapper from "../RecommendedWrapper";

export default function ModalDynamicFunctionalTemp() {
  const [isProtected, setisProtected] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const getProduct = () => {
      axios
        .get(API.getUrls.getSingleProduct + id.id)
        .then(({ data }) => {
          //    console.log("singleProduct ", data);
          setisProtected(!!data.product.is_protected);
          setIsLoading(false);
        })
        .catch((err) => {
          //    console.log("err ", err);
          setIsLoading(false);
        });
    };
    getProduct();
  }, []);

  const [isVerify, setIsVerify] = useState(false);

  let id = useParams();
  return (
    <div>
      {isLoading ? (
        <></>
      ) : false && isProtected && !isVerify ? (
        <PasscodeVerfiy
          productId={id.id}
          onSubmit={() => {
            setIsVerify(true);
          }}
        />
      ) : (
        <RecommendedWrapper />
      )}
    </div>
  );
}
