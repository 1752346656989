import {
  useRef,
  useEffect,
  useLayoutEffect,
  forwardRef,
  useState,
} from "react";
import "./index.scss";

import { API } from "../../Constants/APIs";
import Header from "./Header";
import { useMediaHook } from "./useMediaHook";

import Components from "./Components";
import RightBox from "./RightBox";
import ActualityLogo from "./ActualityLogo";
import ArBtn from "./ArBtn";
import BottomIcons from "./BottomIcons";
import MobileBottom from "./MobileBottom";
import InstructionModal from "./InstructionModal2";
import ModelPopUp from "./ModelPopUp";

import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import closeModal from "../../Assets/newIcons/closeModal.png";
import { iframeLoadedListener, isRenderInIframe } from "./lib";
import { addViewpostMetaTag } from "../../Helpers/addViewport";
import { isIOS } from "../../Helpers/isIOS";
import {
  fullHeightSupport,
  fullHeightSupportForIframe,
} from "../../Helpers/fullHeight";
import {
  variantControllerHeight,
  actualityLogoHeight,
  loremIpsumDummy,
} from "../../Helpers/utils";
import CombinedVariantAndRecommended from "../CombinedVariantAndRecommended";
import RightSideBar from "./RightSideBar";
import ShareDesktopUI from "./ShareDesktopUI";
import AnnotationBtns from "../AnnotationBtns";
import { IconButton } from "@material-ui/core";
import ContentStyleWrapper from "../ContentStyleWrapper";
import MVDimensions from "../MVDimensions";
const __SHOW__NEW__UI = true;
const __ON_SEND_ANALYTICS = false;

const outerWidth = "90vw" || "80vw";
const outerHeight = "85vh" || "85vh";

const ShareDesktopModal = ({
  propState,
  model,
  defaultOptions,
  toggleMenu,
  setState,
  redirectToUrl,
  updateMobilePayload,
  checkSelected,
  hideBuyNow,
  selectMaterial,
  hideMaterials,
  matchparams,
  visibility,
  postAnalytics,
  incrementActionClicks,
  incrementSpaceClicks,
  combination,
  recommends,
}) => {
  useEffect(() => {
    addViewpostMetaTag();
  });

  const isMdOrDown = useMediaHook();

  const modelRef = useRef();
  const arNotSupportedRef = useRef();

  const closeArSupport = () =>
    arNotSupportedRef.current.classList.toggle("show");

  const arModalRef = useRef();
  const isMobile = () => /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const openQR = () => arModalRef.current.openARPopUp();
  const activateAR = () => {
    // alert("hello" + JSON.stringify(arModalRef.current, null, 4));
    // if (!arModalRef.current) {
    //   return;
    // }
    if (!isMobile()) {
      arModalRef?.current?.openARPopUp?.();
    }

    if (!modelRef?.current?.canActivateAR && isMobile()) {
      arNotSupportedRef.current.classList.toggle("show");
      return;
    }
    // alert("modelRef.current.canActivateAR " + modelRef.current.canActivateAR);
    modelRef.current.canActivateAR && modelRef.current.activateAR();
  };

  useEffect(() => {
    //    console.log("propState ", propState);
    iframeLoadedListener();
  }, []);

  useLayoutEffect(() => {
    if (!modelRef.current) return;
    const width =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;
    if (width < 800) {
      modelRef.current.setAttribute("camera-target", "0m 0.2m 0m");
    }
    if (isRenderInIframe()) {
      modelRef.current.setAttribute("camera-target", "0m 0 0m");
    }
  }, []);
  const [isInstructionOpen, setIsInstructionOpen] = useState(true);
  const [showMVDimension, setShowMVDimension] = useState(true);
  return (
    <>
      {!isMobile() && (
        <ModelPopUp
          matchparams={matchparams}
          propState={propState}
          ref={arModalRef}
        />
      )}
      <div
        // just for unique reference
        className="SDM"
        style={{
          border: "0px solid aqua",
          overflow: "hidden",
        }}
      >
        <div
          className="wrapper"
          style={{
            border: "0px solid blue",
            padding: 0,
            overflow: "hidden",
            ...fullHeightSupportForIframe(isMdOrDown),
          }}
        >
          <div
            className="modelContainer"
            style={{
              border: "0px solid blue",
              width: isMdOrDown ? "100vw" : outerWidth,
              ...fullHeightSupportForIframe(isMdOrDown, outerHeight),
              padding: isMdOrDown ? 0 : 0,
              boxSizing: "border-box",
              margin: 0,
              display: "flex",
              flexDirection: "column",
              position: "relative",
              overflow: "hidden",
            }}
          >
            {isMdOrDown ? (
              <>
                <div
                  style={{
                    flex: 1,
                    border: "0px solid red",
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      height: "100%",
                      // border: "1px solid red",
                    }}
                  >
                    <Header
                      incrementActionClicks={incrementActionClicks}
                      propState={propState}
                      redirectToUrl={redirectToUrl}
                      setState={setState}
                      visibility={visibility}
                    />
                    <ArBtn
                      showMVDimension={showMVDimension}
                      toggleShowMVDimension={() => {
                        setShowMVDimension((p) => !p);
                      }}
                      bottom={
                        combination?.product
                          ? actualityLogoHeight + 10
                          : variantControllerHeight + actualityLogoHeight + 10
                      }
                      activateAR={activateAR}
                      incrementSpaceClicks={incrementSpaceClicks}
                    />
                    <MV
                      showMVDimension={showMVDimension}
                      propState={propState}
                      ref={modelRef}
                      combination={combination}
                      recommends={recommends}
                      Component={propState.Components}
                      selectMaterial={selectMaterial}
                      checkSelected={checkSelected}
                    />
                    <div
                      style={{
                        width: "100%",
                        // height: 10,
                        // border: "1px solid red",
                        zIndex: 9000000,
                        position: "absolute",
                        bottom: 0,
                        left: 0,
                        right: 0,
                      }}
                    >
                      <ActualityLogo propState={propState} />
                    </div>
                  </div>
                </div>

                {/* <MobileBottom
                  activateAR={activateAR}
                  hideMaterials={hideMaterials}
                  propState={propState}
                  selectMaterial={selectMaterial}
                  checkSelected={checkSelected}
                  isInstructionOpen={!true}
                /> */}
              </>
            ) : __SHOW__NEW__UI ? (
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  right: 0,

                  left: 0,
                  right: 0,
                  maxWidth: "100%",
                  maxHeight: "100%",
                  minWidth: "100%",
                  minHeight: "100%",
                  overflow: "hidden",
                  // width: "200px",
                  // height: "100vh",
                }}
              >
                <ShareDesktopUI
                  incrementActionClicks={incrementActionClicks}
                  openQR={openQR}
                  combination={combination}
                  recommends={recommends}
                  incrementSpaceClicks={incrementSpaceClicks}
                />
              </div>
            ) : (
              <>
                {/* <RightSideBar isInstructionOpen={isInstructionOpen} /> */}
                <MV propState={propState} ref={modelRef} />
                <Header
                  propState={propState}
                  redirectToUrl={redirectToUrl}
                  setState={setState}
                  incrementActionClicks={incrementActionClicks}
                />
                <Components
                  hideMaterials={hideMaterials}
                  propState={propState}
                  selectMaterial={selectMaterial}
                  checkSelected={checkSelected}
                />
                <RightBox
                  incrementActionClicks={incrementActionClicks}
                  propState={propState}
                  redirectToUrl={redirectToUrl}
                  setState={setState}
                />
                <ActualityLogo propState={propState} />
                <ArBtn
                  activateAR={activateAR}
                  incrementSpaceClicks={incrementSpaceClicks}
                />
                <BottomIcons postAnalytics={postAnalytics} />
              </>
            )}

            {__SHOW__NEW__UI && false ? null : (
              <InstructionModal
                propState={propState}
                matchparams={matchparams}
                onCloseCB={() => {
                  setIsInstructionOpen(false);
                }}
                outerHeight={outerHeight}
              />
            )}
          </div>
        </div>
      </div>
      <ModelPopUp propState={propState} matchparams={matchparams} />
      <div className="arNotSupportedRef" ref={arNotSupportedRef}>
        <div
          className="arNotSupportedRefContent"
          style={{
            borderTop: "1px solid #ddd",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <div
              style={{
                display: "flex",
              }}
            >
              <div
                style={{
                  fontFamily: "Inter",

                  fontSize: 16,
                  fontWeight: 500,
                  paddingRight: "2rem",
                  flexGrow: 1,
                  textAlign: "center",
                }}
              >
                Hey! It looks like your device doesn't support AR. Please
                <a
                  href="https://arcade.ltd/is-my-device-ar-compatible/"
                  target={"_blank"}
                  style={{ color: "#000" }}
                >
                  CLICK HERE
                </a>
                to check the supported device list.
              </div>
              <img
                onClick={closeArSupport}
                src={closeModal}
                width={24}
                height={24}
                style={{
                  cursor: "pointer",
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShareDesktopModal;

const boxShadow = "1px 2px 5px rgba(0, 0, 0, 0.1)";
const MV = forwardRef(
  (
    {
      propState,
      recommends,
      Component,
      selectMaterial,
      checkSelected,
      combination,
      showMVDimension,
    },
    modelRef
  ) => {
    const isMdOrDown = useMediaHook();
    const thisModelRef = useRef();
    const [MODAL_STATUS, SET_MODAL_STATUS] = useState("");
    useEffect(() => {
      thisModelRef?.current?.addEventListener?.("ar-status", (e) => {
        SET_MODAL_STATUS(e.detail.status);
      });

      // modelRef.current.addEventListener("load", (event) => {
      //   if (event) {
      //     const dimensions = modelRef.current.getDimensions();
      //     const x = parseFloat(dimensions.toString().split(" ")[0]);
      //     const y = parseFloat(dimensions.toString().split(" ")[1]);
      //     //  const z = parseFloat(dimensions.toString().split(" ")[2]);
      //     //  const XYratioPer = x/y*100;
      //     //  const reduced_no = (10 * Math.log(y) / Math.LN10)
      //     //  alert(window.innerHeight)
      //     //  alert(y)
      //     //  if(XYratioPer < 100){
      //     // alert(x+' '+y)
      //     // alert(x/y)

      //     //  const zoomForModel =  (-reduced_no);
      //     if (x / y < 1) {
      //       // const zoomForModel =  -((y)*15);
      //       const zoomForModel = -(y * 17.5);
      //       modelRef.current.zoom(zoomForModel);
      //     }
      //     //  }
      //   }
      // });
    }, []);
    const closeAnnotBtnRef = useRef();
    const [annot, setAnnot] = useState(null);
    const closeAnnotBtnAndEmpty = () => {
      closeAnnotBtnRef?.current?.();
      closeAnnotBtnRef.current = null;
    };
    return (
      <>
        {!isMdOrDown && true ? null : (
          <div
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              left: "15%",
              bottom: 0,
              // border: "1px solid blue",
              zIndex: 2147483647,
              background: "white",
              maxHeight: "100%",
              borderTopLeftRadius: 20,
              borderBottomLeftRadius: 20,
              transform: annot ? "translate(0)" : "translate(200vw)",
              transition: "transform 300ms",
              // boxShadow,

              boxShadow: `-3px 0px 5px rgba(0, 0, 0, 0.08)`,
              // padding: 10,
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "50%",
                transform: "translate(-50%,-50%)",
                border: "1px solid #ddd",
                borderRadius: "50%",
                background: "white",
                boxShadow,
                zIndex: 2147483647,
              }}
            >
              <IconButton
                onClick={() => {
                  closeAnnotBtnAndEmpty();
                  setAnnot(null);
                }}
                size="small"
                style={{
                  color: "gray",
                }}
              >
                <KeyboardArrowRightIcon fontSize="large" />
              </IconButton>
            </div>
            <div
              style={{
                minHeight: "100%",
                maxHeight: "100%",
                // border: "1px solid aqua",
                overflow: "hidden",
                // padding: 10,
                position: "relative",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  bottom: 0,
                  left: 0,
                  minHeight: "100%",
                  maxHeight: "100%",
                  // border: "1px solid red",
                  overflow: "hidden",
                  overflowY: "auto",
                  padding: 10,
                  paddingLeft: 25,
                }}
              >
                <div
                  style={{
                    fontSize: "1.5rem",
                    fontWeight: 600,
                    color: "#2E2E2E",
                    margin: "0.5rem 0",
                  }}
                >
                  {annot?.title}
                </div>
                <ContentStyleWrapper content={annot?.description} />
              </div>
            </div>
          </div>
        )}
        <div
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            left: 0,
            bottom: isMdOrDown ? actualityLogoHeight : 0,
            // bottom: 0,
            // border: "1px solid blue",
          }}
        >
          <model-viewer
            disable-tap
            ref={(elem) => {
              modelRef.current = elem;
              thisModelRef.current = elem;
            }}
            id="mediaViewer model-viewer"
            src={
              propState.showVariant
                ? API.imgUrl + propState.selectedVariant.model_file_url
                : API.imgUrl + propState.ModalImage
            }
            ios-src={API.imgUrl + propState.selectedVariant.usdz_file_url}
            bounds="tight"
            camera-controls
            ar
            ar-modes="scene-viewer webxr quick-look"
            ar-scale="auto"
            ar-placement="floor"
            autoplay
            touch-action="none"
            loading="eager"
            style={{
              // background: "#FFFFFF",
              position: "absolute",
              top: 0,
              right: 0,
              left: 0,
              bottom: 0,
            }}
            max-field-of-view={"180deg"}
          >
            <MVDimensions ref={thisModelRef} open={showMVDimension} />
            <AnnotationBtns
              annots={propState?.selectedVariant?.hotspots || []}
              openHotspotDrawer={(annot, cb) => {
                closeAnnotBtnAndEmpty();
                if (cb) closeAnnotBtnRef.current = cb;
                setAnnot(annot);
              }}
            />
            {!isMdOrDown || combination?.product ? null : (
              <CombinedVariantAndRecommended
                variantControllerHeight={variantControllerHeight}
                Component={Component}
                selectMaterial={selectMaterial}
                recommends={recommends}
                checkSelected={checkSelected}
                MODAL_STATUS={MODAL_STATUS}
                isShare={true}
              />
            )}
          </model-viewer>
        </div>
      </>
    );
  }
);
