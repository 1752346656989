import { Button, Typography } from "@material-ui/core";
import { RemoveRedEyeSharp } from "@material-ui/icons";
import React, { Component } from "react";
import { opentLink } from "../Helpers/modalLink";

export default class ModelCard extends Component {
  render() {
    const props = this.props;
    return (
      <div className="modelCard"  onClick={() => {
        // opentLink(props.id);
        window.location.href = "/modelDynamicviewer/" + props.id

      }}
    >
        <div className="imgWrapper">
          <img src={props.imgSrc} alt={props.title} />
        </div>
        <div style={{ padding: "16px" }}>
          <Typography className="bold modelTitle" gutterBottom>
            {props.title}
          </Typography>
          <Button
            variant="contained"
            size="large"
            startIcon={<RemoveRedEyeSharp />}
            elevation={0}
            className="themeGrayBtn"
            onClick={() => {
              // opentLink(props.id);
              // alert();
              window.location.href = "/modelDynamicviewer/" + props.id
            }}
          >
            View in your space
          </Button>
        </div>
      </div>
    );
  }
}
