import React from 'react';
import Links from './Links';
import { useMediaHook } from '../useMediaHook';
import { Container } from '@material-ui/core';

const Footer = ({ shareProduct }) => {
  const isMdOrDown = useMediaHook();
  return (
    <Container
      style={{
        position: 'static',
        bottom: 0,
        // left: 0,
        // right: 0,
        // padding: 20,
        borderRadius: 10,
        // border: '1px solid red',
        // paddingLeft: isMdOrDown ? '1rem' : '4rem',
        // paddingRight: isMdOrDown ? '1rem' : '4rem',
        background: '#fff',
        paddingBottom: isMdOrDown ? 8 : 0,
        paddingRight: isMdOrDown ? 16 : 0,
        paddingLeft: isMdOrDown ? 16 : 0,
      }}
    >
      <Links shareProduct={shareProduct} />
      <Logo />
    </Container>
  );
};

const Logo = ({ }) => {
  const isMdOrDown = useMediaHook();
  return (
    <div
      style={{
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        // border: '1px solid #ddd',
      }}
    >
      <a
        style={{
          textAlign: 'center',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          textDecoration: 'none',
          color: '#646464',
          fontSize: isMdOrDown ? 15 : 20,
          fontWeight: 400,
        }}
        href='https://actuality.live'
        target='_blank'
      >
        <span>powered by </span>
        <span
          style={{
            fontWeight: 500,
            color: '#000',
            marginLeft: 5,
          }}
        >
          actuality.live{' '}
        </span>
      </a>
    </div>
  );
};
export default Footer;
