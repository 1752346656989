import React, { useState } from "react";
import { API } from "../../Constants/APIs";
import { isValidUrl } from "../../Helpers/utils";
import { useMediaHook } from "../useMediaHook";
import { IoEyeOutline, IoEyeSharp } from "react-icons/io5";

const ProductCard = ({ product, index, length }) => {
  const isMdOrDown = useMediaHook();
  const isUrlValid = isValidUrl(product?.product?.link_url);
  const [imgHeight, setImgHeight] = useState();
  setTimeout(() => {
    imgSize();
  }, 2000);
  function imgSize() {
    var myImg = document.querySelector("#sky");
    var currWidth = myImg?.clientWidth;
    var currHeight = myImg?.clientHeight;
    console.log(
      "Current width=" + currWidth + ", " + "Original height=" + currHeight
    );
    setImgHeight(currHeight);
  }

  return (
    <>
      <div
        style={{
          padding:
            isMdOrDown && (index + 1) % 2 === 1
              ? "8px 10px 8px 5px"
              : isMdOrDown
              ? "8px 5px"
              : 0,
          margin: isMdOrDown ? 0 : (index + 1) % 3 === 2 ? "0 52px" : "0",
          width: isMdOrDown ? "47.5%" : 390,
          paddingTop: 0,
          paddingBottom: 32,
          alignSelf: "stretch",
          height: "100%",
        }}
      >
        <div
          style={{
            boxShadow: "0px 3px 7px rgba(0, 0, 0, 0.12)",
            borderRadius: 15,
            position: "relative",
            overflow: "hidden",
            padding: "0",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <img
              id="sky"
              style={{
                width: "100%",
                height: isMdOrDown ? imgHeight : "390px",
                // marginTop: 10,
                // padding: isMdOrDown ? 40 : 0,
              }}
              alt={product.title}
              src={API.imgUrl + product.product.poster_image_url}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: isMdOrDown ? 8 : 16,
              paddingBottom: isMdOrDown ? 4 : 16,
              // borderTop: '1px solid #ddd',
              marginTop: isMdOrDown ? 0 : 12,
            }}
          >
            <span
              style={{
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
                fontSize: isMdOrDown ? 12 : 18,
                fontWeight: 500,
                color: "#2e2e2e",
              }}
              title={product.title}
            >
              {product.title}
            </span>
          </div>
          <div
            style={{
              // textOverflow: 'ellipsis',
              // whiteSpace: 'nowrap',
              overflow: "hidden",
              fontSize: isMdOrDown ? 8 : 16,
              fontWeight: 400,
              padding: isMdOrDown ? "0 8px" : 16,
              paddingTop: 0,
              paddingBottom: isMdOrDown ? 5 : 20,
              minHeight: isMdOrDown ? "30px" : 62,
              color: "#828282",
              lineHeight: isMdOrDown ? "12px" : "24px",
            }}
          >
            {isMdOrDown
              ? product.sub_title?.length >= 65
                ? product.sub_title.substring(0, 65) + "..."
                : product.sub_title
              : product.sub_title?.length >= 80
              ? product.sub_title.substring(0, 80) + "..."
              : product.sub_title}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: isUrlValid ? "space-between" : "flex-start",
              alignItems: "center",
              padding: isMdOrDown ? 9 : 16,
              paddingTop: isMdOrDown ? 0 : 16,
              paddingTop: 0,
              border: "0px solid red",
              paddingBottom: isMdOrDown ? "18px" : "35px",
            }}
          >
            <button
              style={{
                border: 0,
                outline: 0,
                background: "#000",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: isMdOrDown ? 4 : 10,
                width: isUrlValid ? "55%" : "55%",
                color: "#fff",
                cursor: "pointer",
                padding: isMdOrDown ? "5px 2px" : "10px 10px",
                height: isMdOrDown ? 25 : 40,
              }}
              onClick={() => {
                window.open(
                  `/modelDynamicViewer/${product.product._id}`,
                  "_blank"
                );
              }}
            >
              {/* <img src={whiteEyeIcon} width={20} height={15} /> */}
              <span
                style={{
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  fontSize: isMdOrDown ? 6 : 14,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {!isMdOrDown && (
                  <IoEyeSharp
                    size="20"
                    color="#fff"
                    style={{ marginRight: 4 }}
                  />
                )}{" "}
                View in your space
              </span>
            </button>
            {isUrlValid ? (
              <a
                href={product.product.link_url}
                target="_blank"
                style={{
                  border: 0,
                  outline: 0,
                  background: "#2e2e2e",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: isMdOrDown ? "5px" : "10px 10px",
                  borderRadius: isMdOrDown ? 4 : 10,
                  width: "40%",
                  color: "#2e2e2e",
                  backgroundColor: "#D8D8D8",
                  textDecoration: "none",
                  whiteSpace: "nowrap",
                  // textOverflow: 'ellipsis',
                  overflow: "hidden",
                  display: "flex",
                  fontSize: isMdOrDown ? 6 : 14,
                  height: isMdOrDown ? 25 : 40,
                }}
              >
                {product.product?.link_title
                  ? product.product?.link_title
                  : "Call to action"}
              </a>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductCard;
