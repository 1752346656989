import { Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import { API } from '../../Constants/APIs';
import { generateRecommendedProductLink } from '../../Helpers/utils';

const RecommendedProducts = ({ recommends, incrementCombinationViewCount }) => {
  return (
    <div className="">
      {recommends.map((recommend) => {
        if (!recommend?.combinations?.length) return null;
        return (
          <div
            className="card"
            key={recommend._id}
            style={{
              boxSizing: 'border-box',
              width: '100%',
              marginTop: 10,
            }}
          >
            <div className="variant_head_wrapper">
              <Typography
                gutterBottom
                className="buyNowPreview capitalise"
                style={{
                  lineHeight: '40px',
                  fontWeight: 600,
                  fontSize: 16 || 22,
                  color: '#2E2E2E',
                }}
              >
                {recommend.title}
              </Typography>
            </div>
            <div className="fixBox">
              {recommend.combinations
                .filter(({ prodduct }) => prodduct)
                .map((combination) => (
                  <div key={combination._id}>
                    <div
                      className="paletteBox"
                      style={{
                        margin: '16px 20px 16px 0px',
                      }}
                    >
                      <a
                        onClick={() => {
                          incrementCombinationViewCount(combination._id);
                        }}
                        style={{
                          textDecoration: 'none',
                        }}
                        href={generateRecommendedProductLink(
                          combination.product._id,
                          combination._id
                        )}
                        target="_blank"
                      >
                        <img
                          src={
                            API.imgUrl + combination.product.poster_image_url
                          }
                          className="paletteBox"
                          style={{
                            marginTop: '0px',
                            marginLeft: '0px',
                          }}
                          alt=""
                        />
                      </a>
                    </div>

                    <div
                      style={{
                        overflowWrap: 'break-word',
                      }}
                    >
                      <p
                        className="capitalise"
                        style={{
                          textAlign: 'center',
                          fontSize: '14px',
                          fontFamily: 'Inter',
                          width: '50px',

                          marginTop: '-6px',
                          marginLeft: '-4px',
                          color: '#565656',
                          padding: 0,
                          margin: 0,
                        }}
                      >
                        <span>
                          <a
                            onClick={() =>
                              incrementCombinationViewCount(combination._id)
                            }
                            style={{
                              textDecoration: 'none',
                              color: 'inherit',
                            }}
                            href={`/modelDynamicviewer/${combination.product._id}?rpId=${combination._id}`}
                            target="_blank"
                          >
                            {combination.title}
                          </a>
                        </span>
                      </p>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        );
      })}
    </div>
  );
};

const RecommendedProducts2 = ({ recommends }) => {
  // console.log("recommends ", recommends);
  return (
    <div className="">
      {recommends.map((recommend) => {
        if (!recommend?.combinations?.length) return null;
        return (
          <div className="card" key={recommend._id}>
            <div className="variant_head_wrapper">
              <Typography
                gutterBottom
                className="buyNowPreview"
                style={{ lineHeight: '40px', fontWeight: 500 }}
              >
                {recommend.title}
              </Typography>
            </div>
            <div className="fixBox">
              {[
                ...recommend.combinations,
                ...recommend.combinations,
                ...recommend.combinations,
              ].map((combination) => (
                <a
                  key={combination._id}
                  style={{
                    textDecoration: 'none',
                    border: '1px solid red',
                    margin: '8px 20px 16px 9px',
                    // padding: 1,
                  }}
                  href={`/modelDynamicviewer/${combination.product._id}`}
                  target="_blank"
                >
                  <div>
                    <div
                      className="paletteBox"
                      style={{
                        // border: "1px solid red",
                        marginBottom: 15,
                      }}
                    >
                      <img
                        src={API.imgUrl + combination.product.poster_image_url}
                        className="paletteBox"
                        style={{
                          marginTop: '0px',
                          marginLeft: '0px',
                        }}
                        alt=""
                      />
                    </div>

                    <div
                      style={{
                        inlineSize: '58px',
                        overflowWrap: 'break-word',
                        // border: "1px solid blue",
                      }}
                    >
                      <p
                        style={{
                          textAlign: 'center',
                          fontSize: '14px',
                          fontFamily: 'Inter',
                          width: '78px',

                          marginTop: '-6px',
                          marginLeft: '-4px',
                        }}
                      >
                        {combination.title}
                      </p>
                    </div>
                  </div>
                </a>
              ))}
            </div>
          </div>
        );
      })}
    </div>
  );
};

const App = ({ recommends }) => (
  <>
    <RecommendedProducts recommends={recommends} />
    <RecommendedProducts2 recommends={recommends} />
  </>
);

// export default App;
export default RecommendedProducts;
