import React from 'react';
import Drawer from '../Drawer';
import ContentStyleWrapper from '../ContentStyleWrapper';
import { useMediaHook } from '../useMediaHook';

const HotspotContentDrawer = ({ open, onClose, title, description }) => {
  const isMdOrDown = useMediaHook();
  return (
    <Drawer
      open={open}
      onClose={onClose}
      style={{
        height: '100%',
        paddingRight: isMdOrDown ? '1rem' : '0.5rem',

        background: 'white',
        border: '0px solid red',
      }}
    >
      <div
        style={{
          fontSize: '1.5rem',
          fontWeight: 600,
          color: '#2E2E2E',
          margin: '0.5rem 0',
        }}
      >
        {title}
      </div>

      <ContentStyleWrapper content={description} />
    </Drawer>
  );
};

export default HotspotContentDrawer;
