import {
  Button, CircularProgress, Snackbar, Typography
} from "@material-ui/core";
import RemoveRedEyeOutlinedIcon from "@material-ui/icons/RemoveRedEyeOutlined";
import VisibilityOffOutlinedIcon from "@material-ui/icons/VisibilityOffOutlined";
import axios from "axios";
import React, { Component } from "react";
import GoogleLogin from "react-google-login";
import MicrosoftLogin from "react-microsoft-login";
import { Link } from "react-router-dom";
import google from "../Assets/Images/search.png";
import { API } from "../Constants/APIs";
export default class SignIn extends Component {
  state = {
    email: "",
    password: "",
    loading: false,
    successLoading: false,
    successMsgL: "",
    hidePass: true,
  };
  handlechange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  login = (e) => {
    e.preventDefault();
    this.setState({ loading: true });
    let payload = {
      email: this.state.email,
      password: this.state.password,
    };
    axios.post(API.postUrls.login, payload).then((resp) => {
      if (resp.data.status === "success") {
        localStorage.setItem("token", resp.data.user.accessToken);
        this.setState({
          // loading: false,
          successLoading: true,
          successMsg: resp.data.message,
        });
        setTimeout(() => {
          this.setState({
            successLoading: false,
            redirect: true,
          });
          window.location.href = "/productlist/allProducts";
        }, 1000);
      } else {
        this.setState({
          loading: false,
          successLoading: true,
          successMsg: resp.data.message,
        });
        setTimeout(() => {
          this.setState({
            successLoading: false,
          });
          document.getElementById("myForm").reset();
        }, 3000);
      }
    });
  };
  responseGoogle = (response) => {
    window.email = response.profileObj ? response.profileObj.email : null;
    window.name = response.profileObj ? response.profileObj.name : null;
    window.social_token = response.profileObj
      ? response.profileObj.googleId
      : null;
    let LoginLoad = {
      email: response.profileObj ? response.profileObj.email : null,
      name: response.profileObj ? response.profileObj.name : null,
    };
    axios.post(API.postUrls.googleLogin, LoginLoad).then((res) => {
      if (res.data.status === "success") {
        this.setState({
          successMsg: "Log In Successfully.",
          successLoading: true,
        });
        localStorage.setItem("token", res.data.token);

        setTimeout(() => {
          this.setState({
            successLoading: false,
          });
          window.location.href = "/productlist/allProducts";
        }, 2000);
      } else {
        this.setState({
          loading: false,

          errorLoading: true,
          errorMsg: "Login Failed",
        });
        setTimeout(() => {
          this.setState({
            errorLoading: false,
          });
          document.getElementById("myForm").reset();
        }, 2000);
      }
    });
  };
  authHandler = (data, err) => {
    if (data) {
      window.email = data.account ? data.account.userName : null;
      window.name = data.account ? data.account.name : null;
      let LoginLoad = {
        email: window.email,
        name: window.name,
      };
      axios
        .post(API.postUrls.googleLogin, LoginLoad)
        .then((res) => {
          if (res.data.status === "success") {
            this.setState({
              successMsg: "Log In Successfully.",
              successLoading: true,
            });
            localStorage.setItem("token", res.data.token);
          } else {
            this.setState({
              loading: false,

              errorLoading: true,
              errorMsg: "Login Failed",
            });
          }
        })
       
    }
  };
  render() {
   
      return (
        <div className="SignInWrapper">
          <div className="container signIn">
            {this.state.successLoading ? (
              <Snackbar
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                open={true}
                style={{ bottom: "40px", textTransform: "capitalize" }}
                color={"#bdbdbd"}
                autoHideDuration={6000}
                message={this.state.successMsg}
              ></Snackbar>
            ) : null}
            <h3 className="mainHeading"> Sign in</h3>
            <form onSubmit={this.login} id="myForm">
              <div className="inputWrapper">
                <span className="spanTag emailTag">Email</span>

                <input
                  className="Input"
                  name="email"
                  required
                  onChange={this.handlechange}
                />
              </div>
              <div className="inputWrapper">
                <span className="spanTag passTag">Password</span>
                <input
                  className="Input"
                  name="password"
                  required
                  type={this.state.hidePass ? "password" : "text"}
                  onChange={this.handlechange}
                />
                <span
                  className="hidePass"
                  onClick={() => {
                    this.setState({
                      hidePass: !this.state.hidePass,
                    });
                  }}
                >
                  {this.state.hidePass ? (
                    <RemoveRedEyeOutlinedIcon />
                  ) : (
                    <VisibilityOffOutlinedIcon />
                  )}
                </span>
              </div>
              <Link to="/forgot-password">
                <p className="forgotPass">Forgot Password ?</p>
              </Link>
              <Button variant="contained" type="submit" className="submitBtn">
                {this.state.loading ? (
                  <CircularProgress size={30} color={"white"} />
                ) : (
                  "Submit"
                )}
              </Button>
              <Typography className="signInor">Or </Typography>
              <GoogleLogin
                render={(renderProps) => (
                  <button
                    onClick={renderProps.onClick}
                    className="signwithgoogle"
                  >
                    <img
                      src={google}
                      alt=""
                      onClick={renderProps.onClick}
                      disabled={renderProps.disabled}
                      width="10%"
                      style={{
                        position: "absolute",
                        left: "11px",
                        top: "12px",
                      }}
                    />
                    <span style={{ marginLeft: "30px" }}>
                      Sign in with Google
                    </span>
                  </button>
                )}
                clientSecret="VnOX2Dit1xi-UWDvdoMh9GyI"
                clientId="325586537681-tpbbmikbr3ut3p0v9buk40h5ujg9ttfj.apps.googleusercontent.com"
                onSuccess={this.responseGoogle}
                onFailure={this.responseGoogle}
              />
              <br />
              <MicrosoftLogin
                clientId="1a75bfc2-fa8f-4c18-bac2-cd5fba23602a"
                authCallback={this.authHandler}
                redirectUri="https://portal.actuality.live/"
                debug={true}
                className="microsoftLogin"
              />
              <Typography className="newUser">New Here ?</Typography>
              <Link to="/sign-up" className="linkStyle">
                <Button variant="contained" type="submit" className="submitBtn">
                  Sign Up
                </Button>
              </Link>
            </form>
          </div>
        </div>
      );
  }
}
