import React, { forwardRef, useEffect, useState } from 'react';
import useBodyScrollLock from '../../Helpers/useBodyScrollLock';

const TSEDialog = forwardRef(
  (
    {
      open,
      children,
      maxWidth = '85vw',
      minWidth = 'auto',
      maxHeight = '85vh',
      minHeight = 'auto',
      onClickOverlay,
    },
    shareButtonRef
  ) => {
    const { lockScroll, unLockScroll } = useBodyScrollLock();

    useEffect(() => {
      if (open) {
        lockScroll();
      } else {
        unLockScroll();
      }
    }, [open]);
    return (
      <div
        className="__TSE_DIALOG"
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: 'rgba(0,0,0,0.7)',
          display: 'grid',
          placeItems: 'center',
          zIndex: 99999999,
          transform: `scale(${open ? 1 : 0})`,
          transition: 'all 300ms',
          boxSizing: 'border-box',
          cursor: 'pointer',
          transition: 'all 300ms',
        }}
        onClick={() => onClickOverlay && onClickOverlay?.()}
      >
        <div
          onClick={(e) => {
            // console.clear();
            e.stopPropagation();
          }}
          style={{
            cursor: 'auto',
            maxWidth: maxWidth,
            minWidth: minWidth,
            maxHeight: maxHeight,
            minHeight: minHeight,
            background: 'white',
            zIndex: 99999999,
            overflowX: 'hidden',
            overflowY: 'auto',
            borderRadius: 10,
            boxShadow: '0 4px 4px rgba(0, 0, 0, 0.25)',
            transition: 'all 300ms',
          }}
        >
          {children}
        </div>
      </div>
    );
  }
);

export default TSEDialog;
