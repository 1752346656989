import React from 'react';
import SearchIcon from '@material-ui/icons/Search';

const Search = ({ handleChangeQuery, query }) => {
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        // margin: '10px 0',
        padding: 20,
        marginBottom: 15,
        borderBottom: '1px solid #ddd',
        // paddingBottom: 20,
      }}
    >
      <div
        style={{
          maxWidth: '700px',
          width: '700px',
          boxShadow:
            'inset 0px -2px 6px rgba(0, 0, 0, 0.1), inset 0px 4px 5px -1px rgba(0, 0, 0, 0.2)',
          borderRadius: 30,
          display: 'flex',
          height: 50,
          overflow: 'hidden',
          alignItems: 'center',
          // marginBottom: 10,
        }}
      >
        <input
          type='search'
          style={{
            background: 'transparent',
            margin: 0,
            padding: 0,
            height: 53,
            display: 'block',
            boxSizing: 'border-box',
            flex: 1,
            border: 0,
            outline: 'none',
            paddingLeft: 10 * 2,
            fontSize: 16,
            fontWeight: 400,
          }}
          placeholder='Search Products'
          value={query}
          onChange={(e) => handleChangeQuery(e.target.value)}
        />
        <div
          style={{
            width: 80,
            height: 55,
            // border: '1px solid red',
            display: 'grid',
            placeItems: 'center',
          }}
        >
          <SearchIcon fontSize='medium' />
        </div>
      </div>
    </div>
  );
};

export default Search;
