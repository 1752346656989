import axios from "axios";
import { API } from "../../../Constants/APIs";

export const getProduct = async (productID) => {
  try {
    const {
      data: { product },
    } = await axios.get(`${API.getUrls.getProducts}/${productID}`);
    const { data } = await axios.get(
      `${API.getUrls.getComponents}/${productID}?limit=100000`
    );
    // console.log("compoennets", data.components.docs);
    if (product.variants.length) {
      product.variants = product.variants.map((t) => {
        const MATS_COMBO = t.materials
          .map((_id) => _id._id ?? _id)
          .sort()
          .join("-");

        return { ...t, MATS_COMBO };
      });
    }
    if (data?.components?.docs) product.components = data.components.docs;
    if (product) return product;
    return {};
  } catch (err) {
    return {};
  }
};
