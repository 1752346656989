import React, { forwardRef, useEffect, useRef, useState } from "react";
import { useMediaHook } from "../useMediaHook";
import "./style.css";
const styles = (isMdOoDown, hideDim) => ({
  dot: {
    display: "none",
    background: "white",
    boxSizing: "border-box",
  },

  dim: {
    background: "#fff",
    borderRadius: "5px",
    border: "none",
    boxSizing: "border-box",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.25)",
    color: "rgba(0, 0, 0, 0.8)",
    display: "block",
    fontSize: isMdOoDown ? "13px" : "14px",
    fontWeight: isMdOoDown ? 400 : "500",
    maxWidth: "128px",
    overflowWrap: "break-word",
    padding: isMdOoDown ? "5px 7px" : "8px 12px",
    position: "absolute",
    lineHeight: 1,
    width: "max-content",
    height: "max-content",
    transform: "translate3d(-50%, -50%, 0)",
    pointerEvents: "none",
    "--min-hotspot-opacity": 0,
  },
  dimensionLineContainer: {
    boxSizing: "border-box",
    pointerEvents: "none",
    display: "block",
  },
  dimensionLine: {
    boxSizing: "border-box",
    stroke: "transparent" || "gray" || "#16a5e6",
    "stroke-width": isMdOoDown ? 2 : 2,
    "stroke-dasharray": isMdOoDown ? 2 : 2,
  },
});

const MVDimensionsWrapper = forwardRef(({ open }, modelRef) => {
  const [modelLoaded, setModalLoaded] = useState(false);

  useEffect(() => {
    modelRef?.current?.addEventListener("model-visibility", () => {
      if (!modelLoaded) setModalLoaded(true);
    });
  }, [modelRef]);
  return modelLoaded ? <MVDimensions open={open} ref={modelRef} /> : null;
});

const MVDimensions = forwardRef(({ open }, modelRef) => {
  useEffect(() => {
    handleModelLoad("load");
    const modelEvents = ["camera-change", "load"];
    modelEvents.forEach((e) => {
      modelRef?.current?.addEventListener(e, () => {
        handleModelLoad(e);
      });
    });
  }, [modelRef]);
  const isMdOoDown = useMediaHook();
  const dim1 = useRef(),
    dim2 = useRef(),
    dim3 = useRef(),
    dim4 = useRef(),
    dim5 = useRef();

  function drawLine(svgLine, dotHotspot1, dotHotspot2, dimensionHotspot) {
    if (dotHotspot1 && dotHotspot1) {
      if (dotHotspot1.canvasPosition.x)
        svgLine?.setAttribute?.("x1", dotHotspot1.canvasPosition.x);
      if (dotHotspot1.canvasPosition.y)
        svgLine?.setAttribute?.("y1", dotHotspot1.canvasPosition.y);
      if (dotHotspot2.canvasPosition.x)
        svgLine?.setAttribute?.("x2", dotHotspot2.canvasPosition.x);
      if (dotHotspot2.canvasPosition.y)
        svgLine?.setAttribute?.("y2", dotHotspot2.canvasPosition.y);

      if (dimensionHotspot && !dimensionHotspot.facingCamera) {
        svgLine.classList.add("hide");
      } else {
        svgLine.classList.remove("hide");
      }
    }
  }

  function renderSVG() {
    drawLine(
      dim1.current,
      modelRef.current.queryHotspot("hotspot-dot+X-Y+Z"),
      modelRef.current.queryHotspot("hotspot-dot+X-Y-Z"),
      modelRef.current.queryHotspot("hotspot-dim+X-Y")
    );
    drawLine(
      dim2.current,
      modelRef.current.queryHotspot("hotspot-dot+X-Y-Z"),
      modelRef.current.queryHotspot("hotspot-dot+X+Y-Z"),
      modelRef.current.queryHotspot("hotspot-dim+X-Z")
    );
    drawLine(
      dim3.current,
      modelRef.current.queryHotspot("hotspot-dot+X+Y-Z"),
      modelRef.current.queryHotspot("hotspot-dot-X+Y-Z")
    );
    drawLine(
      dim4.current,
      modelRef.current.queryHotspot("hotspot-dot-X+Y-Z"),
      modelRef.current.queryHotspot("hotspot-dot-X-Y-Z"),
      modelRef.current.queryHotspot("hotspot-dim-X-Z")
    );
    drawLine(
      dim5.current,
      modelRef.current.queryHotspot("hotspot-dot-X-Y-Z"),
      modelRef.current.queryHotspot("hotspot-dot-X-Y+Z"),
      modelRef.current.queryHotspot("hotspot-dim-X-Y")
    );
  }

  function handleModelLoad(e) {
    if (e != "load") {
      renderSVG();
      return;
    }
    const modelViewer = modelRef.current;
    const center = modelViewer.getBoundingBoxCenter();
    const size = modelViewer.getDimensions();
    const x2 = size.x / 2;
    const y2 = size.y / 2;
    const z2 = size.z / 2;
    const plusXSize = center.x + x2;
    const minusXSize = center.x - x2;
    const minusYSize = center.y - y2;
    const plusYSize = center.y + y2;
    const plusZSize = center.z + z2;

    modelViewer.updateHotspot({
      name: "hotspot-dot+X-Y+Z",
      position: `${plusXSize} ${minusYSize} ${plusZSize}`,
    });

    modelViewer.updateHotspot({
      name: "hotspot-dim+X-Y",
      position: `${plusXSize * 1.2} ${minusYSize * 1.1} ${center.z}`,
    });

    modelViewer.updateHotspot({
      name: "hotspot-dot+X-Y-Z",
      position: `${plusXSize} ${minusYSize} ${center.z - z2}`,
    });

    modelViewer.updateHotspot({
      name: "hotspot-dim+X-Z",
      position: `${plusXSize * 1.2} ${center.y} ${center.z - z2 * 1.2}`,
    });

    modelViewer.updateHotspot({
      name: "hotspot-dot+X+Y-Z",
      position: `${plusXSize} ${center.y + y2} ${center.z - z2}`,
    });

    modelViewer.updateHotspot({
      name: "hotspot-dim+Y-Z",
      position: `${center.x} ${center.y + y2 * 1.1} ${center.z - z2 * 1.1}`,
    });

    modelViewer.updateHotspot({
      name: "hotspot-dot-X+Y-Z",
      position: `${center.x - x2} ${center.y + y2} ${center.z - z2}`,
    });

    modelViewer.updateHotspot({
      name: "hotspot-dim-X-Z",
      position: `${center.x - x2 * 1.2} ${center.y} ${center.z - z2 * 1.2}`,
    });

    modelViewer.updateHotspot({
      name: "hotspot-dot-X-Y-Z",
      position: `${center.x - x2} ${minusYSize} ${center.z - z2}`,
    });

    modelViewer.updateHotspot({
      name: "hotspot-dim-X-Y",
      position: `${center.x - x2 * 1.2} ${minusYSize * 1.1} ${center.z}`,
    });

    modelViewer.updateHotspot({
      name: "hotspot-dot-X-Y+Z",
      position: `${center.x - x2} ${minusYSize} ${plusZSize}`,
    });

    modelViewer.updateHotspot({
      name: "hotspot-dim-indicator1",
      position: `${center.x - x2} ${minusYSize} ${plusZSize}`,
    });
    modelViewer.updateHotspot({
      name: "hotspot-dim-indicator2",
      position: `${plusXSize} ${center.y - y2} ${plusZSize}`,
    });
    modelViewer.updateHotspot({
      name: "hotspot-dim-indicator3",
      position: `${plusXSize} ${minusYSize} ${center.z - z2}`,
    });
    modelViewer.updateHotspot({
      name: "hotspot-dim-indicator4",
      position: `${minusXSize} ${minusYSize} ${center.z - z2}`,
    });

    modelViewer.updateHotspot({
      name: "hotspot-dim-indicator5",
      position: `${center.x - x2} ${plusYSize} ${plusZSize}`,
    });
    modelViewer.updateHotspot({
      name: "hotspot-dim-indicator6",
      position: `${plusXSize} ${plusYSize} ${plusZSize}`,
    });
    modelViewer.updateHotspot({
      name: "hotspot-dim-indicator7",
      position: `${plusXSize} ${plusYSize} ${center.z - z2}`,
    });
    modelViewer.updateHotspot({
      name: "hotspot-dim-indicator8",
      position: `${minusXSize} ${plusYSize} ${center.z - z2}`,
    });

    let target = modelViewer.querySelector('button[slot="hotspot-dim+X-Y"]');

    if (!target) {
    } else if (size.z * 100 < 3) target.style.display = "none";
    else if (target) target.textContent = `${(size.z * 100).toFixed(0)} cm`;

    target = modelViewer.querySelector('button[slot="hotspot-dim+X-Z"]');

    if (!target) {
    } else if (size.y * 100 < 3) target.style.display = "none";
    else target.textContent = `${(size.y * 100).toFixed(0)} cm`;

    target = modelViewer.querySelector('button[slot="hotspot-dim+Y-Z"]');
    if (!target) {
    } else if (size.y * 100 < 3) target.style.display = "none";
    else target.textContent = `${(size.x * 100).toFixed(0)} cm`;

    target = modelViewer.querySelector('button[slot="hotspot-dim-X-Z"]');
    if (!target) {
    } else if (size.y * 100 < 3) target.style.display = "none";
    else target.textContent = `${(size.y * 100).toFixed(0)} cm`;

    target = modelViewer.querySelector('button[slot="hotspot-dim-X-Y"]');
    if (!target) {
    } else if (size.z * 100 < 3) target.style.display = "none";
    else target.textContent = `${(size.z * 100).toFixed(0)} cm`;

    renderSVG();
  }

  const closeCompo = open ? "mvDimension" : "mvDimension hide";
  const hideDim = open ? "" : "hide";
  // if (!modelLoaded) return null;
  // else
  return (
    <>
      <button
        className={closeCompo}
        slot="hotspot-dot+X-Y+Z"
        style={styles(isMdOoDown).dot}
        data-position="1 -1 1"
        data-normal="1 0 0"
      />
      <button
        className={hideDim}
        slot="hotspot-dim+X-Y"
        style={styles(isMdOoDown).dim}
        data-position="1 -1 0"
        data-normal="1 0 0"
      />
      <button
        className={closeCompo}
        slot="hotspot-dot+X-Y-Z"
        style={styles(isMdOoDown).dot}
        data-position="1 -1 -1"
        data-normal="1 0 0"
      />
      <button
        className={hideDim}
        slot="hotspot-dim+X-Z"
        style={styles(isMdOoDown).dim}
        data-position="1 0 -1"
        data-normal="1 0 0"
      />
      <button
        className={closeCompo}
        slot="hotspot-dot+X+Y-Z"
        style={styles(isMdOoDown).dot}
        data-position="1 1 -1"
        data-normal="0 1 0"
      />
      <button
        className={hideDim}
        slot="hotspot-dim+Y-Z"
        style={styles(isMdOoDown).dim}
        data-position="0 -1 -1"
        data-normal="0 0 0"
      />
      <button
        className={closeCompo}
        slot="hotspot-dot-X+Y-Z"
        style={styles(isMdOoDown).dot}
        data-position="-1 1 -1"
        data-normal="0 0 0"
      />
      <button
        className={hideDim}
        slot="hotspot-dim-X-Z"
        style={styles(isMdOoDown).dim}
        data-position="-1 0 -1"
        data-normal="-1 0 0"
      />
      <button
        className={closeCompo}
        slot="hotspot-dot-X-Y-Z"
        style={styles(isMdOoDown).dot}
        data-position="-1 -1 -1"
        data-normal="-1 0 0"
      />
      <button
        className={hideDim}
        slot="hotspot-dim-X-Y"
        style={{ ...styles(isMdOoDown).dim, border: "0px solid red" }}
        data-position="-1 -1 0"
        data-normal="-1 0 0"
      />
      <button
        className={closeCompo}
        slot="hotspot-dot-X-Y+Z"
        style={{ ...styles(isMdOoDown).dot }}
        data-position="-1 -1 1"
        data-normal="-1 0 0"
      />{" "}
      <button className={hideDim} style={{ ...styles(isMdOoDown).dim, height: 10, width: 10, borderRadius: "50%", padding: 0, backgroundColor: "#a7d4fe" }} slot="hotspot-dim-indicator1" data-position="-1 -1 0"
        data-normal="-1 0 0" data-visibility-attribute="visible"></button>
      <button className={hideDim} style={{ ...styles(isMdOoDown).dim, height: 10, width: 10, borderRadius: "50%", padding: 0, backgroundColor: "#a7d4fe" }} slot="hotspot-dim-indicator2" data-position="-1 -1 0"
        data-normal="1 0 0" data-visibility-attribute="visible"></button>
      <button className={hideDim} style={{ ...styles(isMdOoDown).dim, height: 10, width: 10, borderRadius: "50%", padding: 0, backgroundColor: "#a7d4fe" }} slot="hotspot-dim-indicator3" data-position="-1 -1 0"
        data-normal="1 0 0" data-visibility-attribute="visible"></button>
      <button className={hideDim} style={{ ...styles(isMdOoDown).dim, height: 10, width: 10, borderRadius: "50%", padding: 0, backgroundColor: "#a7d4fe" }} slot="hotspot-dim-indicator4" data-position="-1 -1 0"
        data-normal="-1 0 0" data-visibility-attribute="visible"></button>

      {/* <button className={hideDim} style={{ ...styles(isMdOoDown).dim, height: 10, width: 10, borderRadius: "50%", padding: 0, backgroundColor: "#a7d4fe" }} slot="hotspot-dim-indicator5" data-position="-1 -1 0"
        data-normal="-1 0 0" data-visibility-attribute="visible">5</button> */}
      {/* <button className={hideDim} style={{ ...styles(isMdOoDown).dim, height: 10, width: 10, borderRadius: "50%", padding: 0, backgroundColor: "#a7d4fe" }} slot="hotspot-dim-indicator6" data-position="-1 -1 0"
        data-normal="1 0 0" data-visibility-attribute="visible">6</button> */}
      <button className={hideDim} style={{ ...styles(isMdOoDown).dim, height: 10, width: 10, borderRadius: "50%", padding: 0, backgroundColor: "#a7d4fe" }} slot="hotspot-dim-indicator7" data-position="-1 -1 0"
        data-normal="0 0 0" data-visibility-attribute="visible"></button>
      <button className={hideDim} style={{ ...styles(isMdOoDown).dim, height: 10, width: 10, borderRadius: "50%", padding: 0, backgroundColor: "#a7d4fe" }} slot="hotspot-dim-indicator8" data-position="-1 -1 0"
        data-normal="0 0 0" data-visibility-attribute="visible"></button>
      <svg
        id="dimLines"
        width="100%"
        height="100%"
        xmlns="http://www.w3.org/2000/svg"
        style={styles(isMdOoDown).dimensionLineContainer}
        className={closeCompo}
      >
        <line style={styles(isMdOoDown).dimensionLine} ref={dim1}></line>
        <line style={styles(isMdOoDown).dimensionLine} ref={dim2}></line>
        <line style={styles(isMdOoDown).dimensionLine} ref={dim3}></line>
        <line style={styles(isMdOoDown).dimensionLine} ref={dim4}></line>
        <line style={styles(isMdOoDown).dimensionLine} ref={dim5}></line>
      </svg>
    </>
  );
});
// export default MVDimensions;
export default MVDimensionsWrapper;
