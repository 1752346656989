import { Check as CheckIcon } from "@material-ui/icons";
import React, { useEffect, useRef, useState } from "react";
import "@google/model-viewer";
import { generateRecommendedProductLink } from "../../../Helpers/utils";
import "./style.css";

import MenuIcon from "@material-ui/icons/Menu";
import ClearIcon from "@material-ui/icons/Clear";
import { IconButton } from "@material-ui/core";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import { useMatch, useParams } from "react-router-dom";
import { getProduct } from "./libShare";
import { API } from "../../../Constants/APIs";
import actualityLogoMobile from "../../../Assets/newIcons/actualityLogoMobile.png";
import arIcon from "../../../Assets/newIcons/arIcon.png";
import showDims from "../../../Assets/newIcons/showDims.png";

// import rotate from "../../../Assets/Images/rotate.png";
import rotate from "../../../Assets/newIcons/rotate.png";
// import zoom from "../../../Assets/Images/zoom-in.png";
import zoom from "../../../Assets/newIcons/zoom.png";
import share from "../../../Assets/Images/sharebtn.png";
import AnnotationBtns from "../../AnnotationBtns";
import ContentStyleWrapper from "../../ContentStyleWrapper";
import MVDimensions from "../../MVDimensions";
import {
  isRenderInIframe as isRenderInIframeUtil,
  sendOriginBack,
} from "../lib";
const BoxMarginTop = 10;
const boxPadding = 20;
const boxShadow = "1px 2px 5px rgba(0, 0, 0, 0.1)";
const borderRadius = 15;
const leftGap = 30;
const zIndexOrder = 10;
const topZindex = 80;
const transition = "all 300ms";
const rightBoxWidth = 25;
const ShareDesktopUI = ({
  openQR,
  combination,
  recommends,
  incrementActionClicks,
  incrementSpaceClicks,
}) => {
  let { id: productId } = useParams();
  const [product, setProduct] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [selectedVariant, setSelectedVariant] = useState(null);

  const [selectedMaterials, setSelectedMaterials] = useState([
    { material: "", component: "" },
  ]);

  const handleMatClick = (component, material) => {
    const newSelectedMaterials = selectedMaterials.map((c) =>
      c.component == component ? { ...c, material } : c
    );
    const newMatsCombo = newSelectedMaterials
      .map(({ material }) => material)
      .sort()
      .join("-");
    const findNewVarinat = product.variants.find(
      ({ MATS_COMBO }) => MATS_COMBO == newMatsCombo
    );

    if (!findNewVarinat) return;

    setSelectedMaterials(newSelectedMaterials);
    setSelectedVariant(findNewVarinat);
  };

  useEffect(() => {
    if (!productId) return;

    (async () => {
      try {
        setIsLoading(true);
        let tempProduct = await getProduct(productId);
        if (combination.variant && combination.product._id) {
          setSelectedVariant(combination.variant);
          tempProduct.components = [];
          setProduct(tempProduct);
          return;
        }
        setProduct(tempProduct);
        const initSelectedMaterials = tempProduct.components.map(
          ({ _id: component, materials }) => ({
            component,
            material: materials[0]._id,
          })
        );
        const initSelectedMaterialsMatsComo = initSelectedMaterials
          .map(({ material }) => material)
          .sort()
          .join("-");

        const initVariant = tempProduct.variants.find(
          ({ MATS_COMBO }) => MATS_COMBO == initSelectedMaterialsMatsComo
        );
        setSelectedVariant(initVariant);
        setSelectedMaterials(initSelectedMaterials);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [productId]);

  const [open, setOpen] = useState(true);
  const [annot, setAnnot] = useState(null);
  const closeAnnotBtnRef = useRef();
  const modelRef = useRef();
  const [showMVDimension, setShowMVDimension] = useState(true);
  if (isLoading) return null;
  const closeAnnotBtnAndEmpty = () => {
    closeAnnotBtnRef?.current?.();
    closeAnnotBtnRef.current = null;
  };
  const isRenderInIframe = isRenderInIframeUtil();
  return (
    <div
      className="__SHARE_DESKTOP_UI FULL_STRETCH __NO__SCROLLBAR__"
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "flex-end",
      }}
    >
      <div
        style={{
          transition,
          maxWidth: open ? `${100 - rightBoxWidth}%` : "100%",
          minWidth: open ? `${100 - rightBoxWidth}%` : "100%",
          justifySelf: "stretch",

          maxHeight: "100%",
          minHeight: "100%",
          background: "#ddd",
          zIndex: 60,
          position: "relative",
          backgroundColor: "transparent",
          borderRadius,
          boxShadow,
        }}
      >
        <div
          style={{
            borderRadius,
            overflow: "hidden",
          }}
          className="FULL_STRETCH"
        >
          <model-viewer
            ref={(elem) => {
              modelRef.current = elem;
            }}
            disable-tap
            src={API.imgUrl + selectedVariant.model_file_url}
            ios-src={API.imgUrl + selectedVariant.usdz_file_url}
            bounds="tight"
            camera-controls
            ar
            ar-modes="webxr scene-viewer quick-look"
            ar-scale="auto"
            ar-placement="floor"
            autoplay
            touch-action="none"
            loading="eager"
            style={{
              background: "#FFFFFF",
              position: "absolute",
              top: 0,
              right: 0,
              left: 0,
              bottom: 0,
              zIndex: zIndexOrder,
            }}
            max-field-of-view={"180deg"}
          >
            <AnnotationBtns
              annots={selectedVariant.hotspots}
              openHotspotDrawer={(annot, cb) => {
                closeAnnotBtnAndEmpty();
                if (cb) closeAnnotBtnRef.current = cb;
                if (open) setOpen(false);
                setAnnot(annot);
              }}
            />
            <MVDimensions ref={modelRef} open={showMVDimension} />
          </model-viewer>
          {!product?.product_logo ? null : (
            <ProductLogo src={product?.product_logo} />
          )}
          <BottomStrip
            showMVDimension={showMVDimension}
            toggleShowMVDimension={() => {
              setShowMVDimension((p) => !p);
            }}
            language={product?.language}
            openQR={openQR}
            incrementSpaceClicks={incrementSpaceClicks}
          />
        </div>
        {!open || !!annot || true ? (
          <MenuIconWrapper
            rightGap={isRenderInIframe ? leftGap * 2.5 : leftGap}
            onClick={() => {
              setAnnot(null);
              if (!!!annot) setOpen((p) => !p);
              closeAnnotBtnAndEmpty();
            }}
            open={open || !!annot}
          />
        ) : (
          <RightArrowIcon
            onClick={() => {
              setOpen((p) => !p);
            }}
          />
        )}
        {isRenderInIframe ? <CrossIconWrapper /> : null}
      </div>

      <RightSideDrawer
        open={open}
        content={
          <RightMainContent
            incrementActionClicks={incrementActionClicks}
            product={product}
            recommends={recommends}
            handleMatClick={handleMatClick}
            selectedMaterials={selectedMaterials}
          />
        }
      />
      <RightSideDrawer
        open={!!annot}
        content={
          <div
            style={{
              paddingLeft: leftGap,
              paddingRight: boxPadding,
              paddingTop: boxPadding,
              border: "0px solid red",
              maxHeight: "100%",
              minHeight: "100%",
              overflowY: "auto",
              overflowX: "auto",
              borderTopRightRadius: borderRadius,
              borderBottomRightRadius: borderRadius,
            }}
          >
            <div
              style={{
                fontSize: "1.5rem",
                fontWeight: 600,
                color: "#2E2E2E",
                margin: "0.5rem 0",
                border: "0px solid red",
              }}
            >
              {annot?.title}
            </div>
            <ContentStyleWrapper content={annot?.description} />
            {/* {JSON.stringify(annot, null, 4)} */}
          </div>
        }
      />
    </div>
  );
};

const RightSideDrawer = ({ open, content }) => {
  return (
    <div
      style={{
        zIndex: topZindex,
        maxWidth: open ? `${rightBoxWidth}%` : 0,
        minWidth: open ? `${rightBoxWidth}%` : 0,
        maxHeight: "100%",
        minHeight: "100%",
        overflow: "hidden",
        transition,
        position: "relative",
      }}
    >
      <div
        className="FULL_STRETCH"
        style={{
          overflow: "hidden",
          maxWidth: "100%",
          minWidth: "100%",
          maxHeight: "100%",
          minHeight: "100%",
          display: "flex",
          flexDirection: "column",

          // paddingLeft: leftGap,
          // paddingRight: boxPadding,
        }}
      >
        {content}
      </div>
    </div>
  );
};
const RightMainContent = ({
  product,
  recommends,
  handleMatClick,
  selectedMaterials,
  incrementActionClicks,
}) => {
  return (
    <>
      <div
        style={{
          paddingLeft: leftGap,
          paddingRight: boxPadding,
          paddingTop: boxPadding,
        }}
      >
        <DetailBox
          title={product?.title}
          subtitle={product?.sub_title}
          onClick={() => {}}
        />
        {!product?.visibility ||
        !product.link_url ||
        !product.link_title ? null : (
          <DetailBox
            title={product.link_title}
            subtitle={product.link_description}
            onClick={() => {
              incrementActionClicks();
              window.open(product.link_url, "_blank");
            }}
          />
        )}
      </div>
      <div
        style={{
          flex: 1,
          overflow: "hidden",
          // border: "1px solid red",
        }}
      >
        <div
          style={{
            maxHeight: "100%",
            // minHeight: "100%",
            overflow: "hidden",
            overflowY: "auto",
            paddingLeft: leftGap,
            paddingRight: boxPadding,
          }}
        >
          <ComponentsBox
            components={product.components.filter(
              ({ materials }) => materials.length
            )}
            handleMatClick={handleMatClick}
            selectedMaterials={selectedMaterials}
          />

          <RecommmendBoxes
            recommends={recommends?.filter(
              ({ combinations }) => combinations.length
            )}
          />
        </div>
      </div>
    </>
  );
};

const BottomStrip = ({
  openQR,
  incrementSpaceClicks,
  language,
  showMVDimension,
  toggleShowMVDimension,
}) => {
  const [open, setOpen] = useState(false);
  return (
    <div
      onDoubleClick={() => {
        // setOpen((p) => !p);
      }}
      style={{
        position: "absolute",
        bottom: leftGap,
        right: leftGap,
        left: leftGap,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        zIndex: topZindex,
        border: !open ? 0 : "1px solid red",
      }}
    >
      {/* <div
        style={
          {
            // position: "absolute",
            // bottom: boxPadding * 1.5,
            // left: boxPadding,
            // zIndex: topZindex,
            // height: 1s0,
            // border: "1px solid red",
          }
        }
      >
        <img height={18} src={actualityLogoMobile} alt="" />
      </div>{" "} */}
      <OneThird open={open}>
        <BrandLogo language={language} open={false} />
      </OneThird>
      <OneThird open={open} justifyContent="center" showBorder={true}>
        <ViewInSpaceBtn
          open={false}
          openQR={openQR}
          incrementSpaceClicks={incrementSpaceClicks}
        />{" "}
      </OneThird>

      <OneThird open={open} justifyContent="flex-end">
        <RightBottomIcons
          open={false}
          showMVDimension={showMVDimension}
          toggleShowMVDimension={toggleShowMVDimension}
        />{" "}
      </OneThird>
    </div>
  );
};
const OneThird = ({ children, justifyContent, open, showBorder = false }) => (
  <div
    style={{
      maxWidth: "33%",
      minWidth: "33%",
      overflow: "hidden",
      padding: "2px 0",
      display: "flex",
      alignItems: "center",
      justifyContent,
      borderRadius: showBorder ? borderRadius : 0,
      background: "transparent",
      border: open ? "1px solid aqua" : 0,
    }}
  >
    {children}
  </div>
);
const imageGroups = [rotate, zoom, share];

const RightBottomIcons = ({ open, showMVDimension, toggleShowMVDimension }) => {
  return (
    <div
      style={{
        // position: "absolute",
        // right: leftGap,
        // bottom: leftGap,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        // zIndex: topZindex,
        border: !open ? 0 : "1px solid green",
        paddingBottom: 2,
      }}
    >
      <div
        onClick={() => {
          toggleShowMVDimension();
        }}
        style={{
          // order: 2,
          width: 58,
          height: 58,
          overflow: "hidden",
          display: "grid",
          placeItems: "center",
          overflow: "hidden",

          position: "relative",
          borderRadius: 10,
          boxShadow: `1px 4px 5px 2px rgba(0, 0, 0, 0.1)`,
          marginLeft: 10,
        }}
      >
        {!showMVDimension ? null : (
          <div
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              left: 0,
              bottom: 0,
              background: "rgb(167, 212, 254)",
              opacity: 0.5,
            }}
          />
        )}
        <img src={showDims} width="30px" height="30px" />
      </div>
      <img
        src={imageGroups[0]}
        width={40}
        height={40}
        style={{
          marginLeft: 16,
        }}
      />
      <img
        src={imageGroups[1]}
        width={35}
        height={35}
        style={{
          marginLeft: 16,
        }}
      />
      {/* <img
        src={imageGroups[2]}
        width={30}
        height={30}
        style={{
          marginLeft: 16,
        }}
      /> */}
    </div>
  );
};

const ViewInSpaceBtn = ({ openQR, incrementSpaceClicks, open }) => {
  return (
    <div
      onClick={() => {
        incrementSpaceClicks();
        openQR();
      }}
      style={{
        // position: "absolute",
        // bottom: leftGap,
        // left: "50%",
        // transform: "translateX(-50%)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: borderRadius / 1,
        boxShadow,
        fontSize: 20,
        fontWeight: 500,
        color: "#000000",
        // zIndex: topZindex,
        padding: "15px 45px",
        cursor: "pointer",
        background: "white",
        whiteSpace: "nowrap",
        wordBreak: "keep-all",
        border: !open ? 0 : "1px solid green",

        // width: 428,
        // height: 84,
      }}
    >
      <img
        width="35"
        height="35"
        src={arIcon}
        style={{
          marginRight: 5,
        }}
      />
      <p>View in your space</p>
    </div>
  );
};
const BrandLogo = ({ language, open }) => {
  return (
    <a
      href="https://actuality.live/"
      target="_blank"
      style={{
        textDecoration: "none",
        color: "#000",
        fontWeight: 400,
        fontSize: 18,
        border: !open ? 0 : "1px solid green",
        display: "inline-flex",
        justifyContent: "center",
        wrap: "no-wrap",
        cursor: "pointer",
        whiteSpace: "nowrap",
        wordBreak: "normal",
      }}
    >
      <span style={{ wordBreak: "unset" }}>
        {language.toLowerCase() == "french" ? "Alimenté par " : "powered by "}
      </span>
      &nbsp;
      <b
        style={{
          wordBreak: "unset",
          wordWrap: "unset",
        }}
      >
        actuality.live
      </b>
    </a>
  );
};
const ProductLogo = ({ src }) => (
  <div
    style={{
      width: 120,
      height: 120,
      borderRadius,
      position: "absolute",
      top: leftGap,
      left: leftGap,
      // background: "rgba(0,0,255,0.5)",
      zIndex: topZindex,
      overflow: "hidden",
    }}
  >
    {src ? <img width={120} height={120} src={API.imgUrl + src} /> : null}
  </div>
);

const RightArrowIcon = ({ onClick }) => (
  <div
    style={{
      position: "absolute",
      top: "50%",
      right: 0,
      outline: "1px solid gray",
      borderRadius: "50%",
      transform: "translate(50%,-50%)",
      zIndex: 80,
      boxShadow,
    }}
    onClick={onClick}
  >
    <IconButton
      size="small"
      style={{
        color: "gray",
      }}
    >
      <KeyboardArrowRightIcon fontSize="large" />
    </IconButton>
  </div>
);
const CrossIconWrapper = () => (
  <div
    style={{
      position: "absolute",
      top: leftGap,
      right: leftGap,
      zIndex: topZindex,
      // transform: `translate(50%,-50%)`,
      transition: "all 100ms",
      border: `0px solid gray`,
      borderRadius: "50%",
      boxShadow: "none",
    }}
  >
    <IconButton
      onClick={() => {
        sendOriginBack();
      }}
      size="small"
    >
      <ClearIcon
        fontSize="small"
        style={{
          fontSize: 35,
        }}
      />
    </IconButton>
  </div>
);

const MenuIconWrapper = ({ onClick, open, rightGap }) => (
  <div
    style={{
      position: "absolute",
      top: open ? "50%" : leftGap * 1,
      right: open ? 0 : rightGap,
      zIndex: topZindex,
      transform: !open ? "none" : `translate(50%,-50%)`,
      transition: "all 100ms",
      border: `${open ? 0 : 0}px solid gray`,
      borderRadius: "50%",
      boxShadow: !open ? "none" : `0px 1px 4px rgba(0, 0, 0, 0.25)`,
    }}
  >
    <IconButton onClick={onClick} size="small">
      {open ? (
        <KeyboardArrowRightIcon
          fontSize="large"
          style={{
            fontSize: 40,
          }}
        />
      ) : (
        <MenuIcon fontSize="large" />
      )}
    </IconButton>
  </div>
);

const ComponentsBox = ({ components, handleMatClick, selectedMaterials }) => {
  if (!components || !components?.length) return null;
  return components.map((component) => (
    <CompoAndRecommendBoxWrapper
      title={component.component_name}
      boxes={component.materials.map((material) => {
        let color = "",
          image = "";
        if (material.material_type == "color") color = material.material_value;
        else image = API.imgUrl + material.material_value;
        const showRightIcon = selectedMaterials.find(
          (c) => c.material == material._id && c.component == component._id
        );
        return (
          <MatBox
            title={material.material_name}
            image={image}
            color={color}
            onClick={() => {
              handleMatClick(component._id, material._id);
            }}
            ignoreIfShowIconTrue={true}
            showRightIcon={showRightIcon}
          />
        );
      })}
    />
  ));
};

const RecommmendBoxes = ({ recommends }) => {
  if (!recommends || !recommends?.length) return null;

  return recommends.map((recommend) => (
    <CompoAndRecommendBoxWrapper
      title={recommend.title}
      boxes={recommend.combinations.map((combination) => {
        let color = "",
          image =
            API.imgUrl +
            (combination.product.poster_image_url ||
              combination.product.product_logo);
        return (
          <MatBox
            title={combination.title}
            image={image}
            color={color}
            linkUrl={generateRecommendedProductLink(
              combination.product._id,
              combination._id,
              { isShare: true }
            )}
            showIcon={false}
          />
        );
      })}
    />
  ));
};

const CompoAndRecommendBoxWrapper = ({ title, boxes }) => {
  return (
    <div
      style={{
        maxHeight: 240,
        minHeight: 240 || 210 || 170 + boxPadding,

        marginBottom: BoxMarginTop,
        boxShadow,
        borderRadius,
        padding: boxPadding,
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        // border: "1px solid blue",
      }}
    >
      <div
        style={{
          marginBottom: boxPadding,
        }}
      >
        <p
          className="truncateEllipse capitalise"
          style={{
            fontSize: 16 || 22,
            fontWeight: 500,
            color: "#2E2E2E",
          }}
        >
          {title}
        </p>
      </div>

      <div
        style={{
          flex: 1,
          overflow: "hidden",
          position: "relative",
          // border: "1px solid blue",
        }}
      >
        <div
          className="FULL_STRETCH"
          style={{
            overflowY: "auto",
            display: "flex",
            flexWrap: "wrap",
            // border: "1px solid red",
            maxHeight: "100%",
            // minHeight: "min-content",
            // maxHeight: "max-content",
          }}
        >
          {boxes}
          {/* {boxes} */}
        </div>
      </div>
    </div>
  );
};
const boxSize = 55;
const MatBox = ({
  title,
  image,
  showRightIcon,
  color,
  onClick,
  linkUrl,
  ignoreIfShowIconTrue,
}) => {
  const Inner = () => (
    <div
      style={{
        width: boxSize,
        maxWidth: boxSize,
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginBottom: boxPadding / 1,
        marginRight: boxPadding,
        cursor: "pointer",
      }}
      onClick={() => {
        if (!onClick || (ignoreIfShowIconTrue && showRightIcon)) return;
        onClick();
      }}
    >
      <div
        style={{
          boxShadow,
          borderRadius: borderRadius / 3,
          maxWidth: boxSize,
          maxHeight: boxSize,
          minWidth: boxSize,
          minHeight: boxSize,
          overflow: "hidden",
          position: "relative",
          background: !color ? "transparent" : color,
        }}
      >
        {showRightIcon && (
          <div
            className="FULL_STRETCH"
            style={{
              zIndex: 20,
              display: "grid",
              placeItems: "center",
            }}
          >
            <CheckIcon />
          </div>
        )}
        {image && (
          <img
            src={image}
            style={{
              zIndex: 10,
            }}
            width={boxSize}
            height={boxSize}
          />
        )}
      </div>
      <p
        style={{
          wordBreak: "break-all",
          textAlign: "center",
          padding: "0 2px",
          paddingTop: boxPadding / 2,
          // border: "1px solid red",
          fontSize: 14 || 18,
          fontWeight: 400,
          color: "#2E2E2E",
        }}
        className="capitalise"
      >
        {title}
      </p>
    </div>
  );
  return linkUrl ? (
    <a
      href={linkUrl}
      target="_blank"
      style={{
        textDecoration: "none",
      }}
    >
      <Inner />
    </a>
  ) : (
    <Inner />
  );
};

const DetailBox = ({ title, subtitle, onClick }) => {
  return (
    <div
      onClick={() => onClick()}
      style={{
        cursor: "pointer",
        padding: boxPadding,
        boxShadow,
        borderRadius,
        marginBottom: BoxMarginTop,
      }}
    >
      <p
        className="truncateEllipse capitalise"
        style={{
          padding: 0,
          margin: 0,
          fontSize: 16 || 24,
          color: "#2E2E2E",
          fontWeight: 500,
        }}
      >
        {title}
      </p>
      {!subtitle ? null : (
        <p
          className="truncateEllipse  capitalise"
          style={{
            margin: 0,
            padding: 0,
            fontSize: 14 || 16,
            color: "#565656",
            marginTop: 4,
            fontWeight: 400,
          }}
        >
          {subtitle}
        </p>
      )}
    </div>
  );
};
export default ShareDesktopUI;
