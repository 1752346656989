import "@google/model-viewer";

import React, { forwardRef, useEffect, useState } from "react";

import { API } from "../../Constants/APIs";
import { spacing, boxShadow, borderRadius } from "./const";
const MVLeft = forwardRef(({ modelSrc, iframeData }, modelRef) => {
  const [bgImg, setBgImg] = useState("");
  useEffect(() => {
    if (iframeData.bgImg != bgImg) {
      // console.log("one ", modelRef.current);
      if (!iframeData.bgImg) {
        // console.log("two");
        modelRef.current.removeAttribute("skybox-image");
      } else {
        // console.log("three");
        modelRef.current.removeAttribute("skybox-image");
        modelRef.current.setAttribute(
          "skybox-image",
          API.imgUrl + iframeData.bgImg
        );
      }
      setBgImg(iframeData.bgImg);
      // if (!iframeData.enableZooming) {
      //   modelRef.current.setAttribute("disable-zoom", true);
      // } else modelRef.current.removeAttribute("disable-zoom");
    }
  }, [iframeData]);
  return (
    <div
      style={{
        flex: 0.57,
        overflow: "hidden",
        display: "flex",
        justifyContent: "center",
        boxShadow,
        borderRadius,
        padding: spacing,
      }}
    >
      <model-viewer
        ref={(elem) => {
          modelRef.current = elem;
        }}
        camera-orbit={`${iframeData.xRotate}deg ${iframeData.yRotate}deg ${iframeData.zRotate}% `}
        src={API.imgUrl + modelSrc}
        ar
        alt="A 3D model of a damaged helmet"
        autoplay
        camera-controls
        ar-model="webxr scene-viewer quick-look"
        style={{
          width: "100%",
          height: "100%",
        }}
      ></model-viewer>
    </div>
  );
});

export default MVLeft;
