import "@google/model-viewer";
import { Button, Grid, Typography } from "@material-ui/core";
import { Check, KeyboardArrowUp } from "@material-ui/icons";
import axios from "axios";
import QRCode from "qrcode.react";
import qs from "query-string";
import React, { Component } from "react";
import Lottie from "react-lottie";
import "../App.scss";
import arimage from "../Assets/Images/ar_icon.png";
import rotate from "../Assets/Images/rotate.png";
import share from "../Assets/Images/share.png";
import zoom from "../Assets/Images/zoom-in.png";
import loader from "../Assets/lottie/3dloader.json";
import { deviceSupported } from "../Components/Constant";
import MobileModelViewer from "../Components/MobileModelViewer";

const screenWidth =
  window.innerWidth ||
  document.documentElement.clientWidth ||
  document.body.clientWidth;

const screen = screenWidth <= 902 ? "mobile" : "desktop";

export default class Modal extends Component {
  constructor() {
    super();

    this.state = {
      model: {},
      variantIndex: 0,
      revealModel: false,
      mesh: [],
      frames: [],
      selected_mesh_id: "",
      selected_frame_id: "",
      showModalBtn: screen === "desktop",
      showVariant: false,
      selectedVariant: {},
      meshes: [],
      screen: qs.parse(window.location.search).screen
        ? qs.parse(window.location.search).screen
        : screen,

      actualScreen: "",
    };
  }

  componentDidMount() {
    const screenWidth =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;
    //    console.log("from cdm ", screenWidth);
    const screen = screenWidth <= 920 ? "mobile" : "desktop";
    this.setState({ actualScreen: screen });
    // console.log("screen ", screen);
    this.setModel();
  }

  setUpModal = () => {
    var modal = document.getElementById("myModal");
    var modalContent = document.querySelector(".modal-content");

    var btn = document.getElementById("qrtext");

    var span = document.getElementsByClassName("closeBtn")[0];

    if (btn) {
      btn.onclick = function () {
        modal.style.display = "block";
        modalContent.classList.add("slideIn");
      };
    }

    span.onclick = function () {
      modalContent.classList.remove("slideIn");
      modal.style.display = "none";
    };

    window.onclick = function (event) {
      if (event.target === modal) {
        modalContent.classList.remove("slideIn");
        modal.style.display = "none";
      }
    };
  };

  setModel = () => {
    axios.get("/SingleModel.json").then((res) => {
      const models = res.data.models;
      const model = models.find(
        (model) => model.id === Number(atob(this.props.matchparams.id))
      );
      this.setState(
        {
          model,
          revealModel: true,
          variants: model.variants,
          mesh: res.data.mesh,
          frames: res.data.frames,
          showVariant: model.is_variant_available,
        },
        () => {
          this.setUpModal();
        }
      );
      if (model.is_variant_available) {
        let meshes = [];
        let frames = [];
        model.variants.forEach((variant) => {
          const mVari = meshes.find((mesh) => mesh.mesh_id === variant.mesh_id);
          if (!mVari) {
            meshes.push(variant);
          }
          const fVari = frames.find(
            (frame) => frame.frame_id === variant.frame_id
          );
          if (!fVari) {
            frames.push(variant);
          }
        });

        this.setState({
          meshes,
          frames,
          selectedVariant: model.variants[0],
          selected_frame_id: model.variants[0].frame_id,
          selected_mesh_id: model.variants[0].mesh_id,
        });
      }
    });
  };

  selectMesh = (id) => {
    this.setState({ selected_mesh_id: id }, () => {
      this.applyChanges();
    });
  };
  selectFrame = (id) => {
    this.setState({ selected_frame_id: id }, () => {
      this.applyChanges();
    });
  };
  selectVariant = (variant) => {
    this.setState({ selectedVariant: variant });
  };
  applyChanges = () => {
    const variant = this.state.variants.find(
      (variant) =>
        variant.mesh_id === this.state.selected_mesh_id &&
        variant.frame_id === this.state.selected_frame_id
    );
    this.setState({ selectedVariant: variant }, () => {});
  };

  render() {
    const model = this.state.model;
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: loader,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };
    return (
      <div
        className="modelViewer"
        // style={{ padding: this.state.screen === "desktop" ? "0 24px" : 0 }}
        style={{
          border: "0px solid blue",
        }}
      >
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        {/* {this.state.screen === "desktop" ? ( */}
        {this.state.actualScreen === "desktop" ? (
          <>
            {this.state.revealModel ? (
              <Grid container spacing={4}>
                <Grid
                  item
                  sm={9}
                  style={{
                    height: "100vh",
                    position: "relative",
                    border: "0px solid red",
                  }}
                >
                  <model-viewer
                    id="model-viewer"
                    src={
                      this.state.showVariant
                        ? this.state.selectedVariant.glb_file_path
                        : model.glb_file_path
                    }
                    ios-src={
                      this.state.showVariant
                        ? this.state.selectedVariant.usdz_file_path
                        : model.usdz_file_path
                    }
                    quick-look-browsers="safari chrome"
                    camera-controls
                    ar
                    ar-modes="webxr scene-viewer quick-look"
                    ar-scale={"auto"}
                    ar-placement={"floor"}
                    autoplay
                    loading="eager"
                    style={{
                      // background: "radial-gradient(#fff, grey)",
                      background: "#fff",
                      boxShadow: "1px 2px 5px rgba(0, 0, 0, 0.1)",
                      borderRadius: "15px",
                      height: "90vh",
                      width: "100%",
                      // width: "-webkit-fill-available",
                      position: "relative",
                      top: "50%",
                      transform: "translateY(-50%)",
                      // position: "absolute",
                      // top: "50%",
                      // left: "50%",
                    }}
                  >
                    {/* <Typography className="brand">
                      <a href="/"> Actuality</a>
                    </Typography> */}

                    <div
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                      }}
                      slot="poster"
                    >
                      <Lottie
                        options={defaultOptions}
                        height={100}
                        width={100}
                        autoplay
                        loop
                      />
                      <h2 style={{ textAlign: "center" }}>Loading...</h2>
                    </div>
                    <button
                      slot="ar-button"
                      style={{
                        backgroundColor: "transparent",
                        borderRadius: "4px",
                        border: "none",
                        boxShadow:
                          "0 10px 15px -3px rgba(0, 0, 0, 0.1),0 4px 6px -2px rgba(0, 0, 0, 0.05)",
                        position: "absolute",
                        top: 12,
                        right: "16px",
                        // border: "1px solid #222",
                        background: "#fff",
                      }}
                    >
                      <div style={{}}>
                        <img
                          src={arimage}
                          width="20px"
                          alt=""
                          style={{
                            marginRight: "4px",
                            position: "relative",
                            top: 5,
                          }}
                        />
                        <p
                          style={{
                            fontWeight: "bold",
                            fontSize: 12,
                            display: "inline-block",
                          }}
                        >
                          View in your space
                        </p>
                      </div>
                    </button>
                    <div className="iconWrapper">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          className="text-center"
                          style={{ marginRight: 12, height: "100%" }}
                        >
                          <img src={rotate} alt="" />
                          {/* <Typography className="iconTitle">
                            Drag to rotate
                          </Typography> */}
                        </div>
                        <div
                          className="text-center"
                          style={{ marginRight: 4, height: "100%" }}
                        >
                          <img src={zoom} width={"32px"} alt="" />
                          {/* <Typography className="iconTitle">
                            Scroll to zoom
                          </Typography> */}
                        </div>
                      </div>
                      <div>
                        <div className="shareBtn">
                          <img src={share} height={"24px"} alt="" />
                        </div>
                      </div>
                    </div>
                  </model-viewer>
                </Grid>
                <Grid
                  sm={3}
                  style={{ paddingTop: "32px", position: "relative" }}
                >
                  {model.is_name_buy_now_visible ? (
                    <>
                      <div className="card">
                        <Typography className="buyNow" gutterBottom>
                          {model.title}
                        </Typography>
                        <p className="buy_sub">Scroll to zoom</p>
                      </div>
                      <div className="card">
                        <Typography gutterBottom className="buyNow">
                          Buy Now
                        </Typography>
                        <p className="buy_sub">From someweb.com</p>
                      </div>
                    </>
                  ) : null}
                  {this.state.showVariant ? (
                    <>
                      <div
                        className="card"
                        style={{
                          border: "0px solid blue",
                        }}
                      >
                        <div className="variant_head_wrapper">
                          <Typography gutterBottom className="variant_head">
                            {model.variants[0].frame_id
                              ? "Frame Finishes"
                              : "Street Type"}
                          </Typography>
                          <KeyboardArrowUp />
                        </div>
                        <div className="fixBox">
                          {model.variants[0].frame_id
                            ? this.state.frames.map((variant, index) => (
                                <div
                                  onClick={() => {
                                    this.selectFrame(variant.frame_id);
                                  }}
                                  className="paletteBox"
                                  style={{
                                    backgroundColor: variant.frame_color,
                                    border: "0px solid red",
                                  }}
                                >
                                  {this.state.selected_frame_id ===
                                  variant.frame_id ? (
                                    <div className="selected">
                                      <Check />
                                    </div>
                                  ) : null}
                                </div>
                              ))
                            : model.variants.map((variant, index) => (
                                <Button
                                  key={index}
                                  size={"small"}
                                  variant="contained"
                                  className="themeBtn"
                                  style={{
                                    margin: 4,
                                    backgroundColor:
                                      variant.text_id ===
                                      this.state.selectedVariant.text_id
                                        ? "rgba(146, 180, 211, 0.8)"
                                        : "#bdbdbd",
                                  }}
                                  elevation={0}
                                  onClick={() => {
                                    this.selectVariant(variant);
                                  }}
                                >
                                  {variant.text}
                                </Button>
                              ))}
                        </div>
                      </div>
                      {model.is_name_buy_now_visible ? (
                        <div className="card">
                          <div className="variant_head_wrapper">
                            <Typography gutterBottom className="variant_head">
                              Fabric Finishes
                            </Typography>
                            <KeyboardArrowUp />
                          </div>
                          <div className="fixBox">
                            {this.state.meshes.map((mesh, index) => (
                              <div
                                onClick={() => {
                                  this.selectMesh(mesh.mesh_id);
                                }}
                                className="paletteBox"
                                style={{ backgroundColor: mesh.mesh_color }}
                              >
                                {this.state.selected_mesh_id ===
                                mesh.mesh_id ? (
                                  <div className="selected">
                                    <Check />
                                  </div>
                                ) : null}
                              </div>
                            ))}
                          </div>
                        </div>
                      ) : null}
                    </>
                  ) : null}
                  {this.state.showModalBtn ? (
                    <div id="qrtext">
                      <img
                        src={arimage}
                        width="24px"
                        alt=""
                        style={{ marginRight: "4px" }}
                      />
                      <p className="view_space_btn">View in your space</p>
                    </div>
                  ) : null}
                </Grid>
                {/* {this.state.showVariant ? (
              <div
                style={{
                  position: "fixed",
                  bottom: screen === "desktop" ? 16 : 0,
                  left: screen === "desktop" ? 16 : 0,
                  minWidth: 228,
                  width: screen === "desktop" ? "auto" : "100%",
                  boxShadow:
                    "0 10px 15px -3px rgba(0,0,0,0.1),0 4px 6px -2px rgba(0,0,0,0.05)",
                  border: screen === "desktop" ? "none" : "1px solid #222",
                  borderRadius: 6,
                  borderTopLeftRadius: screen === "desktop" ? 6 : 32,
                  borderTopRightRadius: screen === "desktop" ? 6 : 32,
                  padding: 16,
                  background: "#fff",
                  display: "flex",
                  flexDirection: screen === "desktop" ? "column" : "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    padding: screen === "desktop" ? 0 : "0",
                    display: "flex",
                    alignItems: screen === "desktop" ? "start" : "center",
                    flexDirection: "column",
                    justifyContent: "center",
                    width: screen === "desktop" ? "100%" : "50%",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: screen === "desktop" ? "start" : "center",
                    }}
                  >
                    <h6
                      style={{
                        margin: "0 0 12px",
                        textAlign: screen === "desktop" ? "left" : "center",
                        width: "100%",
                        fontSize: screen === "desktop" ? "inherit" : "14px",
                        fontWeight: "normal",
                      }}
                    >
                      Frame Finishes
                    </h6>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent:
                          screen === "mobile" ? "center" : "flex-start",
                        width: "100%",
                      }}
                    >
                      {this.state.frames.map((frame, i) => (
                        <div
                          onClick={() => {
                            this.selectFrame(frame.id);
                          }}
                          key={i}
                          style={{
                            cursor: "pointer",
                            height: screen === "desktop" ? 48 : 36,
                            width: screen === "desktop" ? 48 : 36,
                            borderRadius: "50%",
                            backgroundColor: frame.color,
                            border:
                              this.state.selected_frame_id === frame.id
                                ? "5px solid #ccc"
                                : "5px solid transparent",
                            marginRight:
                              this.state.frames.length === i + 1 ? 0 : 16,
                          }}
                        ></div>
                      ))}
                    </div>
                  </div>
                </div>
                <div style={{ margin: "16px 0" }}></div>
                <div
                  style={{
                    display: "flex",
                    alignItems: screen === "desktop" ? "start" : "center",
                    flexDirection: "column",
                    justifyContent: "center",
                    padding: screen === "desktop" ? 0 : "0 ",
                    width: screen === "desktop" ? "100%" : "50%",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <h6
                      style={{
                        margin: "0 0 12px",
                        textAlign: screen === "desktop" ? "left" : "center",
                        width: "100%",
                        fontSize: screen === "desktop" ? "inherit" : "14px",
                        fontWeight: "normal",
                      }}
                    >
                      Mesh Finishes
                    </h6>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent:
                          screen === "mobile" ? "space-evenly" : "flex-start",
                        width: "100%",
                      }}
                    >
                      {this.state.mesh.map((mesh, i) => (
                        <div
                          key={i}
                          onClick={() => {
                            this.selectMesh(mesh.id);
                          }}
                          style={{
                            cursor: "pointer",
                            height: screen === "desktop" ? 48 : 36,
                            width: screen === "desktop" ? 48 : 36,
                            borderRadius: "50%",
                            backgroundColor: mesh.color,
                            marginRight:
                              screen === "desktop"
                                ? this.state.mesh.length === i + 1
                                  ? 0
                                  : 16
                                : 0,
                            border:
                              this.state.selected_mesh_id === mesh.id
                                ? "5px solid #ccc"
                                : "5px solid transparent",
                          }}
                        ></div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            ) : null} */}
              </Grid>
            ) : null}
          </>
        ) : (
          <MobileModelViewer
            meshes={this.state.meshes}
            frames={this.state.frames}
            model={this.state.model}
            selectFrame={this.selectFrame}
            selectMesh={this.selectMesh}
            selected_frame_id={this.state.selected_frame_id}
            selected_mesh_id={this.state.selected_mesh_id}
            showVariant={this.state.showVariant}
            selectedVariant={this.state.selectedVariant}
            selectVariant={this.selectVariant}
          />
        )}
        <div id="myModal" className="modal"></div>
        <div className="modal-content">
          <div className="contentArea">
            <h1>How to View in Augmented Reality</h1>
            <p>
              Scan this QR code with your phone to view the object in your
              space. The experience launches directly from your browser - no app
              required!
            </p>
            <p>{deviceSupported("english")}</p>
          </div>
          <div id="qrCodeWrapper">
            <QRCode
              value={window.location.href}
              style={{ height: 200, width: 200, marginTop: 12 }}
            />
          </div>
          <span className="closeBtn">Close</span>
        </div>
      </div>
    );
  }
}
