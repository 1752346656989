import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import React, { Component } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import './global.css';
import Footer from './Components/Footer';
import ForgotPassword from './Components/ForgotPassword';
import Header from './Components/Header';
import init from './Helpers/Windotoken';
import About from './Screens/About';
import BlogDetail from './Screens/BlogDetail';
import BlogSection from './Screens/BlogSection';
import Contact from './Screens/Contact';
import ContactFromProduct from './Screens/ContactFromProduct';
import Landing from './Screens/Landing';
import Modal from './Screens/Modal';
import ModalDynamic from './Screens/ModalDynamic';
import Payments from './Screens/Payments';
import PaymentSettings from './Screens/PaymentSettings';
import PaymentSucces from './Screens/PaymentSucces';
import ProductCreate from './Screens/ProductCreate';
import Pricing from './Screens/Pricing';
import PrivacyPolicy from './Screens/PrivacyPolicy';
import ProductEdit from './Screens/ProductEdit';
import ProductList from './Screens/ProductList';
import ResetPassword from './Screens/ResetPassword';
import Setting from './Screens/Setting';
import SignIn from './Screens/SignIn';
import SignUp from './Screens/SignUp';
import TermsOfService from './Screens/TermsOfService';
import UnPublishModal from './Screens/UnPublishModal';
import UserProfile from './Screens/UserProfile';
import NotFoundPage from './Screens/NotFoundPage';
import PrivateRoute from './Helpers/PrivateRoute';
import Plans_and_pricing from './Screens/Plans_and_pricing';
import Static from './Screens/Static';
import StaticFunctional from './Screens/StaticFunctional';
import TeknionFunctional from './Screens/TeknionFunctional';
import ModalDynamicFunctional from './Screens/ModalDynamicFunctional';
import ModalFunctional from './Screens/ModalFunctional';
import ShareModalFunctional from './Screens/ShareModalFunctional';
import ModalDynamicTemp from './Components/ModelDynamic';
import ModalDynamicFunctionalTemp from './Components/ModalDynamicFunctionalTemp2';
// import ModalDynamicFunctionalTempFull from "./Components/ModalDynamicFunctionalTempFull";
// import ModalDynamicFunctionalTempCopy from "./Components/ModalDynamicFunctionalTempCopy";
import ShareAr from "./Components/ShareAr";
import Lab from "./Screens/Lab";
import Shadow from "./Screens/LabWork/Shadow";
// import ShareAr from "./Components/ShareNew";
import PricingSection from './Components/PricingSection';
import UserProducts from './Components/UserProducts';
import ShareProductPage from './Components/ShareProductPage';
const theme = createTheme({
  typography: {
    fontFamily: 'Inter, sans-serif',
  },
  // palette:{
  //   dark:
  // }
});
export default class App extends Component {
  state = {
    formFocus: false,
  };
  formFocus = (status) => {
    // console.log(status);
    this.setState({ formFocus: status });
  };

  render() {
    return (
      <MuiThemeProvider theme={theme}>
        <BrowserRouter>
          <Routes>
            <Route
              path={'/'}
              exact
              element={
                <>
                  <Header checkAuth={this.state.checkAuth} />
                  <Landing />
                  <Footer />
                </>
              }
            />
            <Route
              path={'https://portal.actuality.live/'}
              exact
              element={
                <>
                  <Header checkAuth={this.state.checkAuth} />
                  <SignIn />
                  <Footer />
                </>
              }
            />
            <Route
              path={'/sign-up'}
              exact
              element={
                <>
                  <Header checkAuth={this.state.checkAuth} />
                  <SignUp />
                  <Footer />
                </>
              }
            />
            <Route
              path={'/contact-us'}
              exact
              element={
                <>
                  <Header checkAuth={this.state.checkAuth} />
                  <Contact />
                  <Footer />
                </>
              }
            />
            <Route
              path={'/Contact'}
              exact
              element={
                <>
                  <Header checkAuth={this.state.checkAuth} />
                  <ContactFromProduct />
                  <Footer />
                </>
              }
            />
            <Route
              path={'/forgot-password'}
              exact
              element={
                <>
                  <Header checkAuth={this.state.checkAuth} />
                  <ForgotPassword />
                  <Footer />
                </>
              }
            />
            <Route
              path={'/reset-password'}
              exact
              element={
                <>
                  <Header checkAuth={this.state.checkAuth} />
                  <ResetPassword />
                  <Footer />
                </>
              }
            />
            <Route
              path={'/about-us'}
              exact
              element={
                <>
                  <Header checkAuth={this.state.checkAuth} />
                  <About />
                  <Footer />
                </>
              }
            />
            <Route
              path={'/plans_and_pricing'}
              exact
              element={
                <>
                  <Header checkAuth={this.state.checkAuth} />
                  <PricingSection />
                  <Footer />
                </>
              }
            />
            <Route
              path={'/privacy'}
              exact
              element={
                <>
                  <Header checkAuth={this.state.checkAuth} />
                  <PrivacyPolicy />
                  <Footer />
                </>
              }
            />
            <Route
              path={'/terms'}
              exact
              element={
                <>
                  <Header checkAuth={this.state.checkAuth} />
                  <TermsOfService />
                  <Footer />
                </>
              }
            />
            <Route
              path={'/blogs'}
              exact
              element={
                <>
                  <Header checkAuth={this.state.checkAuth} />
                  <BlogSection />
                  <Footer />
                </>
              }
            />
            <Route
              path={'/blog-detail'}
              exact
              element={
                <>
                  <Header checkAuth={this.state.checkAuth} />
                  <BlogDetail />
                  <Footer />
                </>
              }
            />
            <Route
              path={'/payment-methods'}
              exact
              element={
                <>
                  <Header checkAuth={this.state.checkAuth} />
                  <PaymentSettings />
                  <Footer />
                </>
              }
            />
            <Route
              path={'/modelviewer/:id'}
              exact
              element={
                <>
                  <ModalFunctional />
                </>
              }
            />
            <Route path='/teknion/EA/' element={<StaticFunctional />}>
              <Route
                path=':id'
                element={(props) => <StaticFunctional {...props} />}
              />
            </Route>
            <Route path='/teknion_upstage/EA/' element={<TeknionFunctional />}>
              <Route
                path=':id'
                element={(props) => <TeknionFunctional {...props} />}
              />
            </Route>
            <Route
              path={'/modelDynamicviewer/:id'}
              exact
              element={
                <>
                  {/* <ModalDynamicFunctionalTempCopy /> */}
                  {/* <ModalDynamicTemp /> */}
                  <ModalDynamicFunctionalTemp />
                  {/* <ModalDynamicFunctionalTempFull /> */}
                </>
              }
            />
            <Route
              path={'/share/:id'}
              exact
              element={<ShareModalFunctional />}
            />{" "}
            <Route path={"/sharear/:id"} exact element={<ShareAr />} />
            <Route path={"/lab"} exact element={<Lab />} />
            <Route path="/lab/shadow" element={<Shadow />} />

            <Route
              path={'/pageNotFound'}
              exact
              element={
                <>
                  <NotFoundPage />
                </>
              }
            />{' '}
            <Route
              path={'/myproducts/:shareId'}
              exact
              element={<ShareProductPage />}
            />
            <Route
              path={'/products/:companyName/:userId'}
              exact
              element={
                <>
                  <Header checkAuth={this.state.checkAuth} />
                  <UserProducts userProducts={true} />
                  <Footer />
                </>
              }
            />
          </Routes>
        </BrowserRouter>
      </MuiThemeProvider>
    );
  }
}
