/**
 *
 * @URL  http://localhost:3000/modelviewer/:id
 */

import React from "react";
import Modal from "./Modal";
import { useParams } from "react-router-dom";

export default function ModalFunctional() {
  let id = useParams();

  return (
    <div>
      <Modal matchparams={id} />
    </div>
  );
}
