import { Button, Container, Grid, Typography } from "@material-ui/core";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import img2 from "../Assets/Images/Image-2.png";
import img3 from "../Assets/Images/Image-3.png";
import img4 from "../Assets/Images/Image-4.png";
import img5 from "../Assets/Images/Image-5.png";
import img6 from "../Assets/Images/Image-6.png";
import img1 from "../Assets/Images/Image.png";
// import fx_video from "../Assets/Images/fx_video.mp4";
import fx_giff from "../Assets/Images/Giff.gif";
import ReactPlayer from "react-player";
export default class Hero extends Component {
  gotoBottom = () => {
    let body = document.body,
      html = document.documentElement;

    const height = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );
    window.scroll({ top: height, left: 0, behavior: "smooth" });
  };

  render() {
    return (
      <div className="hero">
        <Typography
          variant="h1"
          className="bolder txtWhiteShadow themeFontColor compactTxt topSpacing heroTitle animateFadeUp"
        >
          Bring the power of <span className="AR rounded"> AR </span>
        </Typography>
        <Typography
          variant="h2"
          className="bolder themeFontColor compactTxt bottomMaxSpacing animateFadeUpDelay"
          gutterBottom
        >
          to all your products
        </Typography>
        {/* <div className="rect_ele animateRotate" /> */}
        <Container maxWidth="md" style={{ padding: 0 }}>
          <Grid container spacing={3}>
            <Grid item sm={3}>
              <Typography
                variant="body1"
                className="themeFontColor lineHeight bottomSpacing animateFadeLeftDelay"
                gutterBottom
              >
                With just a line of code, transform your product page with
                augmented reality.
              </Typography>
              <img
                src={img1}
                className={"animateFadeUpDelay"}
                width="100%"
                alt="image1"
              />
            </Grid>
            <Grid item sm={9}>
              <Grid
                container
                spacing={3}
                style={
                  {
                    // alignItems: "flex-end",
                  }
                }
              >
                <Grid item sm={8}>
                  {/* <img
                    className={"animateFadeUpDelay"}
                    src={
                      "https://cdn.pixabay.com/photo/2019/01/09/14/13/leaves-3923413_1280.jpg"
                    }
                    style={{ borderRadius: 20 }}
                    width="100%"
                    alt="image1"
                  /> */}

                  {/* <video
                    style={{ maxWidth: "651px", width: "651px", height: "390px", borderRadius: "11%" }}
                    playsInline
                    autoPlay
                    loop
                    muted
                    alt="Fx Video"
                    src={fx_video}
                  /> */}

                  <img
                    src={fx_giff}
                    className={"animateFadeUpDelay"}
                    style={{ borderRadius: 20 }}
                    width="100%"
                    height="320px"
                    alt="Giff.gif"
                  />
                </Grid>
                <Grid item sm={4}>
                  <Typography
                    variant="body1"
                    className="themeFontColor lineHeight bottomSpacing animateFadeRightDelay"
                    gutterBottom
                  >
                    Designed for your operations, marketing and business teams
                    to be able to create what’s right for your products
                  </Typography>
                  <Grid container spacing={2}>
                    {/* <Grid item sm={6}>
                      <img
                        className={"animateFadeUpDelay"}
                        src={img2}
                        width="100%"
                        alt="image1"
                      />
                    </Grid> */}
                    <Grid
                      item
                      sm={12}
                      style={{
                        display: "flex",
                        // justifyContent: "flex-end",
                      }}
                    >
                      <img
                        className={"animateFadeUpDelay"}
                        src={img3}
                        // width="100%"
                        alt="image1"
                        style={{
                          // width:"170px",
                          height: "144px",
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item sm={4}>
                  <img
                    className={"animateFadeUpDelay"}
                    src={img4}
                    width="100%"
                    alt="image1"
                  />
                </Grid>
                <Grid item sm={5}>
                  <img
                    className={"animateFadeUpDelay"}
                    src={img5}
                    width="100%"
                    alt="image1"
                  />
                </Grid>
                <Grid item sm={3}>
                  <img
                    className={"animateFadeUpDelay"}
                    src={img6}
                    width="100%"
                    alt="image1"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <div className="flexChildCenter">
            {/* <a href="https://calendly.com/rishabh_gupta/30min" target="_blank" style={{ textDecoration: "none", color: "#666666", cursor:"pointer" }}>
              <Button
                variant="contained"
                className="tryButton"
                size="large"
                elevation={0}
              >
                BOOK A DEMO
              </Button>
            </a> */}

            <Link to="/contact-us" style={{ textDecoration: "none" }}>
              <Button
                size={"large"}
                variant="contained"
                className="getInTouchHero txtShadow rounded"
              >
                Get In Touch
              </Button>
            </Link>
          </div>
        </Container>
      </div>
    );
  }
}
