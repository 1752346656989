import { isRenderInIframe } from '../Components/NewShareDesktopModalDynmic/lib';
import { isIOS } from './isIOS';

export const fullHeight = (isMdOrDown, maxHeight = '100vh') => ({
  height: isMdOrDown
    ? '100vh'
    : isIOS()
    ? '-webkit-fill-available'
    : window.innerHeight,
  minHeight: window.innerHeight,
  height: window.innerHeight,
});

export const fullHeightSupport = (isMdOrDown, maxHeight = '100vh') =>
  isMdOrDown
    ? isIOS()
      ? {
          maxHeight: '-webkit-fill-available',
          minHeight: '-webkit-fill-available',
          border: '0px dotted red',
        }
      : {
          maxHeight: window.innerHeight,
          minHeight: window.innerHeight,
        }
    : {
        maxHeight: maxHeight,
        minHeight: maxHeight,
      };

export const fullHeightSupportForIframe = (isMdOrDown, maxHeight = '100vh') =>
  isMdOrDown
    ? isRenderInIframe()
      ? {
          maxHeight: '100%',
          minHeight: '100%',
        }
      : isIOS()
      ? {
          maxHeight: '-webkit-fill-available',
          minHeight: '-webkit-fill-available',
          border: '0px dotted red',
        }
      : {
          maxHeight: window.innerHeight,
          minHeight: window.innerHeight,
        }
    : {
        maxHeight: maxHeight,
        minHeight: maxHeight,
      };

export const fullHeightSupportMoz = (isMdOrDown, maxHeight = '100vh') =>
  isMdOrDown
    ? isIOS()
      ? {
          maxHeight: '-moz-available',
          minHeight: '-moz-available',
          outline: '2px dash red',
        }
      : {
          maxHeight: window.innerHeight,
          minHeight: window.innerHeight,
        }
    : {
        maxHeight: maxHeight,
        minHeight: maxHeight,
      };

export const fullHeightSupportFill = (isMdOrDown, maxHeight = '100vh') =>
  isMdOrDown
    ? isIOS()
      ? {
          maxHeight: 'fill-available',
          minHeight: 'fill-available',
          outline: '2px dash aqua',
        }
      : {
          maxHeight: window.innerHeight,
          minHeight: window.innerHeight,
        }
    : {
        maxHeight: maxHeight,
        minHeight: maxHeight,
      };
export default fullHeight;
