import "@google/model-viewer";
import { Button, Grid, Typography } from "@material-ui/core";
import { Check, KeyboardArrowUp } from "@material-ui/icons";
import axios from "axios";
import QRCode from "qrcode.react";
import qs from "query-string";
import React, { Component } from "react";
import Lottie from "react-lottie";
import "../App.scss";
import arimage from "../Assets/Images/ar_icon.png";
import collLogo from "../Assets/Images/colle_logo.png";
import rotate from "../Assets/Images/rotate.png";
import teknionLogo from "../Assets/Images/teknion-logo.svg";
import metroLogo from "../Assets/Images/metro_logo.png";
import zoom from "../Assets/Images/zoom-in.png";
import loader from "../Assets/lottie/3dloader.json";
import StaticMobileModal from "../Components/StaticMobileModal";
import TeknionMobileModal from "../Components/TeknionMobileModal";
const screen = window.screen.width <= 1024 ? "mobile" : "desktop";

export default class Teknion extends Component {
  constructor() {
    super();
    this.state = {
      model: {},
      variantIndex: 0,
      revealModel: false,
      mesh: [],
      frames: [],
      selected_mesh_id: "",
      selected_frame_id: "",
      showModalBtn: screen === "desktop",
      showVariant: false,
      selectedVariant: {},
      meshes: [],
      screen: qs.parse(window.location.search).screen
        ? qs.parse(window.location.search).screen
        : screen,
    };
  }

  componentDidMount() {
    this.setModel();
  }

  setUpModal = () => {
    var modal = document.getElementById("myModal");
    var modalContent = document.querySelector(".modal-content");

    var btn = document.getElementById("qrtext");

    var span = document.getElementsByClassName("closeBtn")[0];

    if (btn) {
      btn.onclick = function () {
        modal.style.display = "block";
        modalContent.classList.add("slideIn");
      };
    }

    span.onclick = function () {
      modalContent.classList.remove("slideIn");
      modal.style.display = "none";
    };

    window.onclick = function (event) {
      if (event.target === modal) {
        modalContent.classList.remove("slideIn");
        modal.style.display = "none";
      }
    };
  };

  setModel = () => {
    axios.get("/SingleModel.json").then((res) => {
      const models = res.data.models;
      const model = models.find(
        (model) => model.id === Number(atob(this.props.matchparams.id))
      );
      this.setState(
        {
          model,
          revealModel: true,
          variants: model.variants,
          mesh: res.data.mesh,
          frames: res.data.frames,
          showVariant: model.is_variant_available,
        },
        () => {
          this.setUpModal();
        }
      );
      if (model.is_variant_available) {
        let meshes = [];
        let frames = [];
        model.variants.forEach((variant) => {
          const mVari = meshes.find((mesh) => mesh.mesh_id === variant.mesh_id);
          if (!mVari) {
            meshes.push(variant);
          }
          const fVari = frames.find(
            (frame) => frame.frame_id === variant.frame_id
          );
          if (!fVari) {
            frames.push(variant);
          }
        });

        this.setState({
          meshes,
          frames,
          selectedVariant: model.variants[0],
          selected_frame_id: model.variants[0].frame_id,
          selected_mesh_id: model.variants[0].mesh_id,
        });
      }
    });
  };

  selectMesh = (id) => {
    this.setState({ selected_mesh_id: id }, () => {
      this.applyChanges();
    });
  };
  selectFrame = (id) => {
    this.setState({ selected_frame_id: id }, () => {
      this.applyChanges();
    });
  };
  selectVariant = (variant) => {
    this.setState({ selectedVariant: variant });
  };
  applyChanges = () => {
    const variant = this.state.variants.find(
      (variant) =>
        variant.mesh_id === this.state.selected_mesh_id &&
        variant.frame_id === this.state.selected_frame_id
    );
    this.setState({ selectedVariant: variant }, () => {});
  };

  render() {
    const model = this.state.model;
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: loader,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };
    return (
      <div
        className="modelViewer"
        style={{ padding: this.state.screen === "desktop" ? "0 24px" : 0 }}
      >
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        {this.state.screen === "desktop" ? (
          <>
            {this.state.revealModel ? (
              <Grid container spacing={4}>
                <Grid
                  item
                  sm={9}
                  style={{ height: "100vh", position: "relative" }}
                >
                  <model-viewer
                    exposure={model.exposure}
                    scale={1}
                    src={
                      this.state.showVariant
                        ? this.state.selectedVariant.glb_file_path
                        : model.glb_file_path
                    }
                    // quick-look-browsers="safari chrome"
                    shadow-intensity={model.shadow_intensity}
                    camera-orbit="45deg 55deg 2.5m"
                    field-of-view="60deg"
                    min-field-of-view={
                      window.innerWidth < 600 ? "90deg" : "55deg"
                    }
                    max-field-of-view={
                      window.innerWidth < 600 ? "90deg" : "55deg"
                    }
                    camera-controls
                    ar
                    ar-modes="webxr scene-viewer quick-look"
                    ar-placement={"floor"}
                    autoplay
                    style={{
                      background: "#fff",
                      boxShadow: "1px 2px 5px rgba(0, 0, 0, 0.1)",
                      borderRadius: "15px",
                      height: "90vh",
                      width: "-webkit-fill-available",
                      position: "relative",
                      top: "50%",
                      transform: "translateY(-50%)",
                    }}
                  >
                    <div className="brand" style={{ flexDirection: "column" }}>
                      <div style={{ width: "100%" }}>
                        <img src={teknionLogo} width="130px" alt="" />
                      </div>

                      <div style={{ width: "100%" }}>
                        <img src={collLogo} width="130px" alt="" />
                      </div>
                      <div style={{ width: "100%" }}>
                        <img src={metroLogo} width="130px" alt="" />
                      </div>
                    </div>

                    <div
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                      }}
                      slot="poster"
                    >
                      <Lottie
                        options={defaultOptions}
                        height={100}
                        width={100}
                        autoplay
                        loop
                      />
                      <h2 style={{ textAlign: "center" }}>Loading...</h2>
                    </div>
                    <button
                      slot="ar-button"
                      style={{
                        backgroundColor: "transparent",
                        borderRadius: "4px",
                        border: "none",
                        boxShadow:
                          "0 10px 15px -3px rgba(0, 0, 0, 0.1),0 4px 6px -2px rgba(0, 0, 0, 0.05)",
                        position: "absolute",
                        top: 12,
                        right: "16px",
                        background: "#fff",
                      }}
                    >
                      <div style={{}}>
                        <img
                          src={arimage}
                          width="20px"
                          alt=""
                          style={{
                            marginRight: "4px",
                            position: "relative",
                            top: 5,
                          }}
                        />
                        <p
                          style={{
                            fontWeight: "bold",
                            fontSize: 12,
                            display: "inline-block",
                          }}
                        >
                          View in your space
                        </p>
                      </div>
                    </button>
                    <div className="iconWrapper">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          className="text-center"
                          style={{ marginRight: 12, height: "100%" }}
                        >
                          <img src={rotate} alt="" />
                          <Typography className="iconTitle">
                            Drag to rotate
                          </Typography>
                        </div>
                        <div
                          className="text-center"
                          style={{ marginRight: 4, height: "100%" }}
                        >
                          <img src={zoom} width={"32px"} alt="" />
                          <Typography className="iconTitle">
                            Scroll to zoom
                          </Typography>
                        </div>
                      </div>
                    </div>
                  </model-viewer>
                </Grid>
                <Grid
                  sm={3}
                  style={{ paddingTop: "32px", position: "relative" }}
                >
                  {model.is_name_buy_now_visible ? (
                    <>
                      <div className="card">
                        <Typography className="modelTitle">
                          {model.title}
                        </Typography>
                        <p className="subtitle">Scroll to zoom</p>
                      </div>
                      <div className="card">
                        <Typography gutterBottom className="buyNow">
                          Buy Now
                        </Typography>
                        <p className="buy_sub">From someweb.com</p>
                      </div>
                    </>
                  ) : null}
                  <div className="card">
                    <Typography className="modelTitle">
                      Teknion Upstage Scenario 4
                    </Typography>
                    <Typography
                      className="subtitle"
                      style={{ paddingTop: "10px" }}
                    >
                      from Teknion,The Collective & Metro Contract Group
                    </Typography>
                  </div>
                  {/* <div className="card">
                    <a
                      target="_blank"
                      href="https://teknion.box.com/s/lki8mqlk6c2qy1tc3izvdpf4fgiy0gsl"
                      style={{ textDecoration: "none" }}
                    >
                      <Typography
                        className="modelTitle"
                        style={{ fontSize: "19px" }}
                      >
                        Download RFP
                      </Typography>
                      <Typography
                        className="subtitle"
                        style={{ paddingTop: "10px" }}
                      >
                        Click here
                      </Typography>
                    </a>
                  </div> */}
                  <div className="card">
                    <a
                      target="_blank"
                      href=" https://www.teknion.com/ca/products/product-details?productlineid=c9743b6e-8e7a-673b-a6c8-ff00004460c4"
                      style={{ textDecoration: "none" }}
                    >
                      <Typography
                        className="modelTitle"
                        style={{ fontSize: "19px" }}
                      >
                        Upstage Desking System
                      </Typography>
                      <Typography
                        className="subtitle"
                        style={{ paddingTop: "10px" }}
                      >
                        Learn more
                      </Typography>
                    </a>
                  </div>
                  {this.state.showVariant ? (
                    <>
                      <div className="card">
                        <div className="variant_head_wrapper">
                          <Typography gutterBottom className="variant_head">
                            {/* {model.variants[0].frame_id
                              ? "Frame Finishes"
                              : "Street Type"} */}
                            Personas
                          </Typography>
                          <KeyboardArrowUp />
                        </div>
                        <div className="fixBox">
                          {model.variants[0].mesh_id
                            ? this.state.variants.map((variant, index) => (
                                <div style={{ width: "30%" }}>
                                  <div
                                    onClick={() => {
                                      this.selectMesh(variant.mesh_id);
                                    }}
                                    className="paletteBox"
                                    style={{
                                      backgroundColor: variant.mesh_color,
                                    }}
                                  >
                                    {this.state.selected_mesh_id ===
                                    variant.mesh_id ? (
                                      <div className="selected">
                                        <Check />
                                      </div>
                                    ) : null}
                                  </div>
                                  <Typography
                                    style={{
                                      fontSize: "11px",
                                      textAlign: "center",
                                      width: "63px",
                                    }}
                                  >
                                    {" "}
                                    {variant.name}{" "}
                                  </Typography>
                                </div>
                              ))
                            : model.variants.map((variant, index) => (
                                <Button
                                  key={index}
                                  size={"small"}
                                  variant="contained"
                                  className="themeBtn"
                                  style={{
                                    margin: 4,
                                    backgroundColor:
                                      variant.text_id ===
                                      this.state.selectedVariant.text_id
                                        ? "rgba(146, 180, 211, 0.8)"
                                        : "#bdbdbd",
                                  }}
                                  elevation={0}
                                  onClick={() => {
                                    this.selectVariant(variant);
                                  }}
                                >
                                  {variant.mesh_id}
                                </Button>
                              ))}
                        </div>
                      </div>
                      {model.is_name_buy_now_visible ? (
                        <div className="card">
                          <div className="variant_head_wrapper">
                            <Typography gutterBottom className="variant_head">
                              Fabric Finishes
                            </Typography>
                            <KeyboardArrowUp />
                          </div>
                          <div className="fixBox">
                            {this.state.meshes.map((mesh, index) => (
                              <div
                                onClick={() => {
                                  this.selectMesh(mesh.mesh_id);
                                }}
                                className="paletteBox"
                                style={{ backgroundColor: mesh.mesh_color }}
                              >
                                {this.state.selected_mesh_id ===
                                mesh.mesh_id ? (
                                  <div className="selected">
                                    <Check />
                                  </div>
                                ) : null}
                              </div>
                            ))}
                          </div>
                        </div>
                      ) : null}
                    </>
                  ) : null}
                  {this.state.showModalBtn ? (
                    <div id="qrtext">
                      <img
                        src={arimage}
                        width="24px"
                        alt=""
                        style={{ marginRight: "4px" }}
                      />
                      <p className="view_space_btn">View in your space</p>
                    </div>
                  ) : null}
                </Grid>
              </Grid>
            ) : null}
          </>
        ) : (
          <TeknionMobileModal
            meshes={this.state.meshes}
            frames={this.state.frames}
            variants={this.state.variants}
            model={this.state.model}
            selectFrame={this.selectFrame}
            selectMesh={this.selectMesh}
            selected_frame_id={this.state.selected_frame_id}
            selected_mesh_id={this.state.selected_mesh_id}
            showVariant={this.state.showVariant}
            selectedVariant={this.state.selectedVariant}
            selectVariant={this.selectVariant}
          />
        )}
        <div id="myModal" className="modal"></div>
        <div className="modal-content">
          <div className="contentArea">
            <h1>How to View in Augmented Reality</h1>
            <p>
              Scan this QR code with your phone to view the object in your
              space. The experience launches directly from your browser - no app
              required!
            </p>
            <p>* Works best with iPhone 12 & above.</p>
          </div>
          <div id="qrCodeWrapper">
            <QRCode
              value={window.location.href}
              style={{ height: 200, width: 200, marginTop: 12 }}
            />
          </div>
          <span className="closeBtn">Close</span>
        </div>
      </div>
    );
  }
}
