import React from 'react';
import { spacing } from './const';

const Tab = ({ text, children }) => {
  return (
    <div
      style={{
        // maxWidth: 900,
        margin: 'auto',
        paddingLeft: spacing * 2,
        paddingRight: spacing * 2,
      }}
    >
      <div
        style={{
          color: '#4B4B4B',
          fontSize: 20,
          fontWeight: 400,
          padding: spacing * 3,
          paddingLeft: 0,
          paddingBottom: 0,
        }}
      >
        {text}
      </div>
      {children}
    </div>
  );
};

export default Tab;
