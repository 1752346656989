import { Button, Grid, Typography } from "@material-ui/core";
import axios from "axios";
import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import { API } from "../Constants/APIs";
import init from "../Helpers/Windotoken";

export default class Sidebar extends Component {
  state = {
    metaData: {},
    userdetail: {},
    categories: [],
    query: "",
    productStatus: "",
    CategoryLength: false,
    searching: false,
  };
  componentDidMount() {
    this.getMetaData();
    this.getUser();
  }

  getMetaData = () => {
    if (init() === "success") {
      axios.get(API.getUrls.productMeta).then((res) => {
        // console.log(res);
        this.setState({ metaData: res.data });
      });
    }
  };
  getUser = () => {
    if (init() === "success") {
      axios.get(API.getUrls.getUser).then((res) => {
        this.setState({ userdetail: res.data.user });
      });
    }
  };
  fetchCategories = () => {
    this.setState({ loading: true });
    if (init() === "success") {
      axios
        .get(
          API.getUrls.getUserCat +
            "?status=" +
            this.state.productStatus +
            "&q=" +
            this.state.query
        )
        .then((res) => {
          this.setState({
            categories: res.data.categories,
            CategoryLength: res.data.categories.length === 0 ? true : false,
            loading: false,
          });
        });
    }
  };

  logout = () => {
    localStorage.clear();
    window.location.href = "/sign-in";
  };
  render() {
    return (
      <div className="">
        {this.state.userdetail ? (
          this.state.userdetail.company_logo ? (
            <img
              src={API.imgUrl + this.state.userdetail.company_logo}
              style={{
                width: "123px",
                height: "123px",
                borderRadius: "16px",
              }}
              alt=""
            />
          ) : (
            <div className="sidebarItem"> </div>
          )
        ) : (
          <div className="sidebarItem"> </div>
        )}

        {this.state.userdetail ? (
          <Typography className="nameComp" align={"center"}>
            {this.state.userdetail
              ? this.state.userdetail.company_name === "undefined"
                ? "Company Name"
                : this.state.userdetail.company_name
                ? this.state.userdetail.company_name
                : null
              : null}
          </Typography>
        ) : (
          <Typography className="nameComp" align={"center"}>
            {"Company Name"}
          </Typography>
        )}

        <Typography className="performance"> Performance </Typography>
        <Grid container spacing={2} style={{ textAlign: "left" }}>
          <Grid item sm={6}>
            <span className="spanTag">Total Views</span>
          </Grid>
          <Grid item sm={6} style={{ textAlign: "center" }}>
            <span className="spanTag">
              {this.state.metaData.totalViews
                ? this.state.metaData.totalViews
                : 0}
            </span>
          </Grid>
        </Grid>

        <Typography className="performance"> Products </Typography>
        
        <Grid container spacing={2} style={{ textAlign: "left" }}>
          <Grid item sm={6}>
            <NavLink exact to="/productlist/allProducts" className="onHoverLink">
              <span
                className="spanTag"
                onClick={() =>
                  this.setState({ productStatus: "All Products" }, () => {
                    this.fetchCategories();
                  })
                }
              >
                All Products
              </span>
            </NavLink>
          </Grid>
          <Grid item sm={6} style={{ textAlign: "center" }}>
            <span className="spanTag">
              {this.state.metaData.all ? this.state.metaData.all : 0}
            </span>
          </Grid>
        </Grid>

        <Grid container spacing={2} style={{ textAlign: "left" }}>
          <Grid item sm={6}>
            <NavLink
              exact
              to={{
                pathname: "/productlist/live",
              }}
              className="onHoverLink"
            >
              <span
                className="spanTag"
                onClick={() =>
                  this.setState({ productStatus: "published" }, () => {
                    this.fetchCategories();
                  })
                }
              >
                Live Products
              </span>
            </NavLink>
          </Grid>
          <Grid item sm={6} style={{ textAlign: "center" }}>
            <span className="spanTag">
              {" "}
              {this.state.metaData.published
                ? this.state.metaData.published
                : 0}{" "}
            </span>
          </Grid>
        </Grid>
        {/* <Grid container spacing={2} style={{ textAlign: "left" }}>
          <Grid item sm={6}>
            <NavLink exact to="/productlist/wip" className="onHoverLink">
              <span
                className="spanTag"
                onClick={() =>
                  this.setState({ productStatus: "WIP" }, () => {
                    this.fetchCategories();
                  })
                }
              >
                WIP
              </span>
            </NavLink>
          </Grid>
          <Grid item sm={6} style={{ textAlign: "center" }}>
            <span className="spanTag">
              {this.state.metaData.wip ? this.state.metaData.wip : 0}
            </span>
          </Grid>
        </Grid> */}

        {/* <Grid container spacing={2} style={{ textAlign: "left" }}>
          <Grid item sm={8}>
            <NavLink exact to="/productlist/pending" className="onHoverLink">
              <span
                className="spanTag"
                onClick={() =>
                  this.setState({ productStatus: "pending" }, () => {
                    this.fetchCategories();
                  })
                }
              >
                Awainting Approval
              </span>
            </NavLink>
          </Grid>
          <Grid item sm={4}>
            <span className="spanTag" style={{ marginLeft: "5px" }}>
              {" "}
              {this.state.metaData.pending ? this.state.metaData.pending : 0}
            </span>
          </Grid>
        </Grid> */}
        <Grid container spacing={2} style={{ textAlign: "left" }}>
          <Grid item sm={6}>
            <NavLink exact to="/productlist/draft" className="onHoverLink">
              <span
                className="spanTag"
                onClick={() =>
                  this.setState(
                    {
                      productStatus: "draft",
                      categories: this.state.categories,
                    },
                    () => {
                      this.fetchCategories();
                    }
                  )
                }
              >
                Draft Products
              </span>
            </NavLink>
          </Grid>
          <Grid item sm={6} style={{ textAlign: "center" }}>
            <span className="spanTag">
              {" "}
              {this.state.metaData.drafted
                ? this.state.metaData.drafted
                : 0}{" "}
            </span>
          </Grid>
        </Grid>
        <Link
          to="/product-create"
          style={{ textDecoration: "none", color: "#666666" }}
        >
          <Button
            className="productBtn"
            style={{
              width: "100%",
              fontSize: window.innerWidth < 1100 ? "14px" : "18px",
              marginTop: "35px",
            }}
          >
            Add New Product +
          </Button>
        </Link>
        <Link
          to="/settings"
          style={{ textDecoration: "none", color: "#666666" }}
        >
          <Button
            className="productBtn"
            style={{
              width: "100%",
              fontSize: window.innerWidth < 1100 ? "14px" : "18px",

              marginTop: "26px",
            }}
          >
            Settings
          </Button>
        </Link>
        <Button
          className="productBtn"
          onClick={this.logout}
          style={{
            marginTop: "26px",
            width: "100%",
            fontSize: window.innerWidth < 1100 ? "14px" : "18px",
          }}
        >
          Logout
        </Button>
      </div>
    );
  }
}
