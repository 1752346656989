import React, { useState } from "react";
import { useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import { getRecommends, getSingleCombination } from "../../Helpers/lib";
import { useMediaHook } from "../useMediaHook";
import ModalDynamic from "../ModalDynamicFunctionalTemp2/ModalDynamic";
import ShareModal from "../../Screens/ShareModal";

const RecommendedWrapper = ({ isShare }) => {
  let id = useParams();
  const query = useLocation();

  const [recommends, setRecommends] = useState([]);
  const productId = window.location.pathname.split("/")[2];
  const [isLoading, setIsLoading] = useState(true);

  const [combination, setCombination] = useState({});
  useEffect(() => {
    let rpId = query.search.split("?").pop();
    rpId = !rpId?.startsWith("rpId") ? "" : rpId.split("=").pop();
    // console.clear();

    if (rpId) {
      getSingleCombination(rpId)
        .then((data) => {
          setCombination(data.combination);
        })
        .catch((errr) => {})
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      getRecommends(productId)
        .then((data) => {
          setRecommends(data.recommendProducts);
        })
        .catch((err) => {})
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, []);

  const isMdOrDown = useMediaHook();

  if (isLoading) return null;

  return isShare ? (
    <ShareModal
      matchparams={id}
      isMdOrDown={isMdOrDown}
      recommends={recommends
        .map(({ combinations, ...rest }) => ({
          ...rest,
          combinations: combinations.filter((c) => c?.prodduct),
        }))
        .filter(({ combinations }) => combinations?.length)}
      combination={combination}
    />
  ) : (
    <ModalDynamic
      matchparams={id}
      isMdOrDown={isMdOrDown}
      recommends={recommends
        .map(({ combinations, ...rest }) => ({
          ...rest,
          combinations: combinations.filter((c) => c?.prodduct),
        }))
        .filter(({ combinations }) => combinations?.length)}
      combination={combination}
    />
  );
};

export default RecommendedWrapper;
