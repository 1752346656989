import "@google/model-viewer";
import { Grid, Typography } from "@material-ui/core";
import { Check } from "@material-ui/icons";
import axios from "axios";
import QRCode from "qrcode.react";
import qs from "query-string";
import React, { Component } from "react";
import Modal from "react-awesome-modal";
import Lottie from "react-lottie";
import "../App.scss";
import arimage from "../Assets/Images/ar_icon.png";
import rotate from "../Assets/Images/rotate.png";
// import share from "../Assets/Images/share.png";
import Geocode from "react-geocode";
import { Navigate } from "react-router-dom";
import arrowLeft from "../Assets/Images/arrowLeft.png";
import arrowRight from "../Assets/Images/arrowRight.png";
import closeModal from "../Assets/Images/closeModal.png";
import zoom from "../Assets/Images/zoom-in.png";
import loader from "../Assets/lottie/3dloader.json";
import { API } from "../Constants/APIs";
import ModalDynamic from "../Screens/ModalDynamic";
import hamburger from "../Assets/Images/menu.png";
import closeIcon from "../Assets/Images/cancelToggle.png";
import { deviceSupported } from "./Constant";
const screen = window.screen.width <= 1024 ? "mobile" : "desktop";
const variantControllerHeight = 100;
const queryParams = new URLSearchParams(window.location.search);

export class ShareDesktopModalPopulated extends Component {
  render() {
    return (
      <div>
        <div
          className="shareModalWrap"
          style={{
            width: "80vw",
            height:
              window.innerWidth >= 370 && window.innerWidth < 767
                ? "90vh"
                : window.innerWidth === 316
                ? "90vh"
                : "80vh",
            backgroundColor: "#fff",
            boxShadow: "0px 4px 4px rgb(0 0 0 / 25%)",
            borderRadius: "6px",
            marginTop: window.innerWidth === 316 ? 30 : 0,
            border: "2px solid red",
          }}
        >
          <div className="modalWrap">
            <Grid container spacing={2} className="editWrapper">
              <Grid item sm={9}>
                <div className="headerTop" style={{ border: "0px solid red" }}>
                  {this.props.propState.productDel.product_logo ? (
                    <div
                      style={{ width: "65px", height: "65px" }}
                      className="logo"
                    >
                      <img
                        src={
                          API.imgUrl +
                          this.props.propState.productDel.product_logo
                        }
                        alt=""
                        className="img"
                        style={{ width: "100%", borderRadius: 15 }}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="web">
                    <div
                      style={{
                        flexDirection: "column",
                        marginTop: "-7px",
                      }}
                    >
                      <Typography
                        className="Product-title"
                        style={{ fontWeight: 500 }}
                      >
                        {this.props.propState.productDel.title
                          ? this.props.propState.productDel.title
                          : ""}
                      </Typography>
                      <br />
                      <p
                        className="subtitle"
                        style={{ color: "#000", marginLeft: "21px" }}
                      >
                        {this.props.propState.productDel
                          ? this.props.propState.productDel.sub_title ===
                            "undefined"
                            ? null
                            : this.props.propState.productDel.sub_title
                          : ""}
                      </p>
                    </div>
                  </div>
                </div>
              </Grid>

              <Grid
                item
                sm={3}
                sx={{
                  border: "0px solid black",
                }}
              >
                <div className="responsive">
                  <div
                    className="menuHamburger respHamburger"
                    style={{
                      width: "43px",
                      height: "10px",
                      position: "absolute",
                      top: window.innerWidth === 316 ? "66px" : "78px",
                      right: window.innerWidth === 656 ? "27px" : "21px",
                      zIndex: 2147483648,
                      display: "inline-flex",
                    }}
                  >
                    <img
                      src={hamburger}
                      onClick={this.props.toggleMenu}
                      alt=""
                      style={{ width: "63%", height: "30px" }}
                    />
                  </div>
                  {this.props.propState.toggle ? (
                    <>
                      <div className="sideBar" style={{ overflowX: "hidden" }}>
                        <div
                          className="productName"
                          style={{
                            backgroundColor: "#fff",
                            width: "100%",
                            borderRadius: "15px",
                            color: "#000",
                            boxShadow: "1px 2px 5px rgba(0, 0, 0, 0.1)",
                            position: "relative",
                            top: 0,
                          }}
                        >
                          <div
                            className="closeToggle"
                            style={{
                              overflowX: "hidden",
                              cursor: "pointer",
                              zIndex: "99999",
                              position: "absolute",
                              right: "10px",
                              top: "12px",
                            }}
                            onClick={() =>
                              this.props.setState(
                                {
                                  toggle: false,
                                },
                                () => {
                                  // console.log(this.props.propState.toggle);
                                }
                              )
                            }
                          >
                            <img src={closeIcon} style={{ width: "16px" }} />
                          </div>
                          <h5
                            className="prd1"
                            style={{
                              fontWeight: 500,
                              fontSize: "17px",
                              color: "#000000",
                              letterSpacing: "-1px",
                              padding: "12px",
                              margin: 0,
                            }}
                          >
                            <Typography
                              className="buyNow"
                              style={{ paddingTop: 0 }}
                            >
                              {this.props.propState.productName
                                ? this.props.propState.productName
                                : ""}
                              <br />
                            </Typography>
                            <Typography>
                              {this.props.propState.productDel
                                ? this.props.propState.productDel.sub_title ===
                                  "undefined"
                                  ? null
                                  : this.props.propState.productDel.sub_title
                                : ""}
                              <br />
                            </Typography>
                          </h5>
                        </div>

                        <div
                          className="callToAction"
                          style={{
                            backgroundColor: "#fff",
                            width: "230px",
                            height: "80px",
                            borderRadius: "15px",
                            color: "#000",
                            boxShadow: "1px 2px 5px rgba(0, 0, 0, 0.1)",
                            position: "relative",
                            top: "-13px",
                          }}
                        >
                          <h5
                            className="prd"
                            style={{
                              fontWeight: "500",
                              fontSize: "17px",
                              lineHeight: "56px",
                              color: "#000000",
                              paddingLeft: "13px",
                              letterSpacing: "-1px",
                            }}
                          >
                            {this.props.propState.visiblity ? (
                              <>
                                <Typography
                                  className="buyNow"
                                  style={{ paddingTop: "0px" }}
                                  onClick={() => {
                                    this.props.redirectToUrl();
                                    // this.redirectToUrl()
                                    this.props.setState(
                                      { count: this.props.propState.count + 1 },
                                      () => {
                                        this.props.updateMobilePayload(
                                          this.props.propState
                                        );

                                        // console.log(this.props.propState.count);
                                      }
                                    );
                                  }}
                                >
                                  {this.props.propState.productDel.link_title
                                    ? this.props.propState.productDel.link_title
                                    : "Buy Now"}
                                  <br />
                                </Typography>
                                <Typography
                                  // className="buyNow"
                                  onClick={() => {
                                    this.props.updateMobilePayload(
                                      this.props.propState
                                    );

                                    this.props.redirectToUrl();
                                    // this.redirectToUrl();
                                  }}
                                >
                                  {this.props.propState.productDel
                                    .link_description
                                    ? this.props.propState.productDel
                                        .link_description
                                    : ""}
                                  <br />
                                </Typography>
                              </>
                            ) : (
                              ""
                            )}
                          </h5>
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </Grid>
            </Grid>

            <div className="responsive">
              {this.props.propState.selectedVariant.usdz_file_url ? (
                <model-viewer
                  id="reveal"
                  exposure="0.8"
                  src={
                    this.props.propState.showVariant
                      ? API.imgUrl +
                        this.props.propState.selectedVariant.model_file_url
                      : API.imgUrl + this.props.propState.ModalImage
                  }
                  ios-src={
                    API.imgUrl +
                    this.props.propState.selectedVariant.usdz_file_url
                  }
                  camera-orbit="45deg 55deg 2.5m"
                  shadow-intensity="2"
                  field-of-view="80deg"
                  min-field-of-view={"80deg"}
                  max-field-of-view={"80deg"}
                  bounds="tight"
                  camera-controls
                  ar
                  ar-modes="webxr scene-viewer quick-look"
                  ar-scale={"auto"}
                  ar-placement={"floor"}
                  autoplay
                  loading="lazy"
                  style={{
                    background: "transparent",
                    // height: window.innerHeight - variantControllerHeight,
                    height: "300px",
                    width: "-webkit-fill-available",
                    position: "absolute",
                    left: "50%",
                    top: this.props.propState.showVariant ? "0%" : "40%",
                    transform: this.props.propState.showVariant
                      ? "translateX(-50%)"
                      : "translate(-50%, -50%)",
                  }}
                >
                  <button
                    slot="ar-button"
                    className="viewInYourSpace"
                    style={{ marginBottom: "-14px" }}
                    onClick={() => {
                      // console.log("click called");

                      this.props.setState(
                        {
                          checkCall: true,
                          count1: this.props.propState.count1 + 1,
                        },
                        () => {
                          this.props.updateMobilePayload(this.props.propState);
                        }
                      );
                    }}
                  >
                    <img
                      src={arimage}
                      width="20px"
                      alt=""
                      style={{ marginRight: "5px" }}
                    />
                    <p className="view_space_btn">
                      {" "}
                      {this.props.language === "French"
                        ? "Voir dans votre espace"
                        : "View in your space"}
                    </p>
                  </button>
                </model-viewer>
              ) : (
                <model-viewer
                  id="reveal"
                  exposure="0.8"
                  src={
                    this.props.propState.showVariant
                      ? API.imgUrl +
                        this.props.propState.selectedVariant.model_file_url
                      : API.imgUrl + this.props.propState.ModalImage
                  }
                  camera-orbit="45deg 55deg 2.5m"
                  shadow-intensity="2"
                  field-of-view="80deg"
                  min-field-of-view={"80deg"}
                  max-field-of-view={"80deg"}
                  bounds="tight"
                  camera-controls
                  ar
                  ar-modes="webxr scene-viewer quick-look"
                  ar-scale={"auto"}
                  ar-placement={"floor"}
                  environment-image="neutral"
                  autoplay
                  loading="lazy"
                  style={{
                    background: "transparent",
                    // height: window.innerHeight - variantControllerHeight,
                    height: "360px",
                    width: "-webkit-fill-available",
                    position: "absolute",
                    left: "50%",
                    top: this.props.propState.showVariant ? "0%" : "30%",
                    transform: this.props.propState.showVariant
                      ? "translateX(-50%)"
                      : "translate(-50%, -50%)",
                  }}
                >
                  <button
                    slot="ar-button"
                    className="viewInYourSpace"
                    style={{ marginBottom: "-14px" }}
                    onClick={() => {
                      // console.log("click called");
                      this.props.setState(
                        {
                          checkCall: true,
                          count1: this.props.propState.count1 + 1,
                        },
                        () => {
                          this.props.updateMobilePayload(this.props.propState);
                        }
                      );
                    }}
                  >
                    <img
                      src={arimage}
                      width="20px"
                      alt=""
                      style={{ marginRight: "5px" }}
                    />
                    <p className="view_space_btn">
                      {" "}
                      {this.props.propState.language === "French"
                        ? "Voir dans votre espace"
                        : "View in your space"}
                    </p>
                  </button>
                </model-viewer>
              )}
            </div>
            <div className="web">
              {this.props.propState.showMaterials ? (
                <div
                  className={
                    this.props.propState.showMaterials
                      ? "slide-In verticalScrollBar"
                      : "slideOut verticalScrollBar"
                  }
                  style={{ border: "0px solid blue" }}
                >
                  {this.props.propState.Components.map((single, index1) => (
                    <div key={index1}>
                      <div className="variant_head_wrapper">
                        <Typography
                          gutterBottom
                          className="buyNowPreview"
                          style={{
                            lineHeight: "15px",
                            fontWeight: 600,
                            marginTop: "24px",
                            color: "#000",
                          }}
                        >
                          {single.component_name}
                        </Typography>
                      </div>
                      <div className="">
                        {single.materials[0]._id
                          ? single.materials.map((material, index) => (
                              <div key={index}>
                                {material.material_type === "color" ? (
                                  <>
                                    <div
                                      onClick={() => {
                                        this.props.selectMaterial(
                                          material._id,
                                          single._id
                                        );
                                      }}
                                      className="paletteBoxModal"
                                      style={{
                                        backgroundColor:
                                          material.material_value,
                                        margin: "16px 20px 9px 0px",
                                      }}
                                      key={index}
                                    >
                                      {this.props.checkSelected(
                                        material._id,
                                        single._id
                                      ) ? (
                                        <div className="selectedCheck">
                                          <Check
                                            style={{
                                              color: "#000",
                                            }}
                                          />
                                        </div>
                                      ) : null}
                                    </div>
                                    <div
                                      style={{
                                        inlineSize: "58px",
                                        overflowWrap: "break-word",
                                      }}
                                    >
                                      <p
                                        style={{
                                          textAlign: "center",
                                          fontSize: "12px",
                                          fontFamily: "Inter",
                                          width: "54px",
                                          textTransform: "capitalize",
                                          marginTop:
                                            material.material_type === "color"
                                              ? "-6px"
                                              : "6px",
                                          color: "#5B5B5B",
                                        }}
                                      >
                                        {material.material_name}
                                      </p>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div
                                      className="paletteBoxModal"
                                      style={{
                                        margin: "16px 20px 9px 0px",
                                      }}
                                    >
                                      <img
                                        onClick={() => {
                                          this.props.selectMaterial(
                                            material._id,
                                            single._id
                                          );
                                        }}
                                        key={index}
                                        src={
                                          API.imgUrl + material.material_value
                                        }
                                        className="paletteBoxModal"
                                        style={{
                                          marginTop: "0px",
                                          marginLeft: "0px",
                                        }}
                                        alt=""
                                      />
                                      {this.props.checkSelected(
                                        material._id,
                                        single._id
                                      ) ? (
                                        <div className="selectedCheck">
                                          <Check
                                            style={{
                                              color: "#000",
                                            }}
                                          />
                                        </div>
                                      ) : null}
                                    </div>

                                    <div
                                      style={{
                                        inlineSize: "58px",
                                        overflowWrap: "break-word",
                                      }}
                                    >
                                      <p
                                        style={{
                                          textAlign: "center",
                                          fontSize: "12px",
                                          fontFamily: "Inter",
                                          width: "54px",
                                          marginTop:
                                            material.material_type === "color"
                                              ? "-6px"
                                              : "6px",
                                          color: "#5B5B5B",
                                        }}
                                      >
                                        {material.material_name}
                                      </p>
                                    </div>
                                  </>
                                )}
                              </div>
                            ))
                          : null}
                      </div>
                    </div>
                  ))}
                </div>
              ) : null}
            </div>
            <div className="web" style={{ border: "0px solid blue" }}>
              <div
                className="arrowWrap"
                style={{
                  left:
                    this.props.propState.showMaterials === true ? "18%" : "10%",
                }}
                onClick={() => {
                  this.props.hideMaterials();
                }}
              >
                {this.props.propState.showMaterials ? (
                  <img src={arrowLeft} className="arrowLeft" alt="" />
                ) : (
                  <img src={arrowRight} className="arrowLeft" alt="" />
                )}
              </div>
            </div>
            <div className="imgWrap" border="0px solid aqua">
              <div className="web">
                <model-viewer
                  exposure={this.props.model.exposure}
                  scale="1 1 1"
                  src={
                    this.props.propState.showVariant
                      ? API.imgUrl +
                        this.props.propState.selectedVariant.model_file_url
                      : API.imgUrl + this.props.propState.ModalImage
                  }
                  shadow-intensity={this.props.model.shadow_intensity}
                  quick-look-browsers="safari chrome"
                  min-field-of-view={
                    window.innerWidth < 600 ? "90deg" : "55deg"
                  }
                  max-field-of-view={
                    window.innerWidth < 600 ? "90deg" : "55deg"
                  }
                  bounds="tight"
                  data-js-focus-visible
                  camera-controls
                  ar-scale="auto"
                  ar
                  ar-modes="webxr scene-viewer quick-look"
                  ar-placement="floor"
                  autoplay
                  loading="lazy"
                  environment-image="neutral"
                  style={{
                    // boxShadow: "1px 2px 5px rgba(0, 0, 0, 0.1)",
                    borderRadius: "15px",
                    height: "65vh",
                    // width: "-webkit-fill-available",
                    width: "400px",
                    position: "relative",
                    // top: "50%",
                    transform: "translateY(-30%)",
                    transform: "translateX(55%)",
                    marginTop: "-60px",
                  }}
                >
                  <div
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                    slot="poster"
                  >
                    <Lottie
                      options={this.props.defaultOptions}
                      height={100}
                      width={100}
                      autoplay
                      loop
                    />
                    <h2 style={{ textAlign: "center" }}>Loading...</h2>
                  </div>
                </model-viewer>
              </div>
              <div className="web">
                <button
                  id="qrtext"
                  slot="ar-button"
                  style={{
                    backgroundColor: "#fff",
                    borderRadius: "6px",
                    border: "none",
                    boxShadow: "1px 2px 5px rgba(0, 0, 0, 0.1)",
                    // "0 10px 15px -3px rgba(0, 0, 0, 0.1),0 4px 6px -2px rgba(0, 0, 0, 0.05)",
                    position: "absolute",
                    bottom: "13%",
                    // right: "16px",
                    background: "#fff",
                    padding: "5px 24px",
                    width: "245px",
                    height: "54px",
                  }}
                  onClick={() => {
                    this.props.setState(
                      { count1: this.props.propState.count1 + 1 },
                      () => {
                        // console.log(this.props.propState.count1);
                      }
                    );
                  }}
                >
                  <div style={{}}>
                    <img
                      src={arimage}
                      width="20px"
                      alt=""
                      style={{
                        marginRight: "13px",
                        position: "relative",
                        top: 8,
                      }}
                    />
                    <p
                      style={{
                        fontWeight: "500",
                        fontSize: "16px",
                        display: "inline-block",
                        fontFamily: "Inter",
                        color: "#000",
                      }}
                    >
                      {this.props.propState.language === "French"
                        ? "Voir dans votre espace"
                        : "View in your space"}
                    </p>
                  </div>
                </button>
              </div>
            </div>
            <div className="web">
              {this.props.propState.showBuyNow && (
                <>
                  <div
                    className={
                      this.props.propState.showBuyNow
                        ? "buy_now_Wrap slideFromRight"
                        : "buy_now_Wrap slideFromLeft"
                    }
                    style={{ top: "35%" }}
                  >
                    <Typography
                      gutterBottom
                      className="buyNowPreview"
                      onClick={() => {
                        this.props.redirectToUrl();
                      }}
                      style={{
                        fontWeight: 500,
                        cursor: "pointer",
                        fontSize: "14px",
                        color: "#000",
                      }}
                    >
                      Download RPF
                    </Typography>
                    <p
                      className="buy_subPreview"
                      style={{
                        cursor: "pointer",
                        fontSize: "10px",
                        marginTop: "6px",
                        fontWeight: 400,
                        color: "#000",
                      }}
                    >
                      Click here
                    </p>
                  </div>
                  {this.props.propState.visiblity ? (
                    <div
                      className={
                        this.props.propState.showBuyNow
                          ? "buy_now_Wrap slideFromRight"
                          : "buy_now_Wrap slideFromLeft"
                      }
                      onClick={() => {
                        this.props.redirectToUrl();
                        this.props.setState(
                          { count: this.props.propState.count + 1 },
                          () => {
                            // console.log(this.props.propState.count);
                          }
                        );
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <Typography
                        gutterBottom
                        className="buyNowPreview"
                        onClick={() => {
                          this.props.redirectToUrl();
                        }}
                        style={{
                          fontWeight: 500,
                          cursor: "pointer",
                          fontSize: "14px",
                          color: "#000",
                        }}
                      >
                        {this.props.propState.productDel.link_title
                          ? this.props.propState.productDel.link_title
                          : "Buy Now"}
                      </Typography>
                      <p
                        className="buy_subPreview"
                        style={{
                          cursor: "pointer",
                          fontSize: "10px",
                          marginTop: "6px",
                          color: "#000",
                          fontWeight: 400,
                        }}
                      >
                        {this.props.propState.productDel.link_description
                          ? this.props.propState.productDel.link_description
                          : "From Someweb.com"}
                      </p>
                    </div>
                  ) : (
                    ""
                  )}
                </>
              )}
            </div>
            <div className="web">
              <div
                className="arrowWrapRight"
                onClick={() => {
                  this.props.hideBuyNow();
                }}
                style={{
                  right:
                    this.props.propState.showBuyNow === true ? "29%" : "10%",
                }}
              >
                {this.props.propState.showBuyNow ? (
                  <img src={arrowRight} className="arrowRight" alt="" />
                ) : (
                  <img src={arrowLeft} className="arrowRight" alt="" />
                )}
              </div>
            </div>
            <div className="web">
              <div style={{ position: "absolute", bottom: "10%" }}>
                <a
                  href="https://actuality.live/"
                  target="_blank"
                  style={{
                    textDecoration: "none",
                    color: "#000000",
                  }}
                >
                  <h5
                    style={{
                      marginLeft: "9px",
                      color: "#000",
                      fontWeight: "400",
                      cursor: "pointer",
                      fontSize: "14px",
                      marginBottom: 21,
                    }}
                  >
                    {this.props.propState.language === "French"
                      ? "Alimenté par"
                      : "Powered By"}{" "}
                    <b>actuality.live</b>
                  </h5>
                </a>
              </div>
            </div>
            <div className="responsive">
              <div
                className="variantControls"
                style={{
                  height: variantControllerHeight,
                  height: "auto",
                  width:
                    window.innerWidth >= 656 || window.innerWidth === 614
                      ? "520px"
                      : "270px",
                  display: "inline-flex",
                  position: "absolute",
                  bottom: window.innerWidth === 316 ? "45px" : "100px",
                  left: 0,
                  overflowX: "scroll !important",
                  padding: "0 16px",
                  overflow: "hidden",
                }}
              >
                {this.props.propState.Components.map((single, index1) => (
                  <div key={index1}>
                    <Typography style={{ marginLeft: 6 }}>
                      {single.component_name}
                    </Typography>
                    <div
                      className="framesWrapper"
                      style={{
                        alignItems: "center",
                        maxHeight: "90px",
                        overflowY: "scroll",
                      }}
                    >
                      <div
                        className="variantListing"
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          minWidth: "120px",
                        }}
                      >
                        {single.materials[0]._id
                          ? single.materials.map((material, index) => (
                              <div key={index}>
                                {material.material_type === "color" ? (
                                  <>
                                    <div
                                      onClick={() => {
                                        this.props.selectMaterial(
                                          material._id,
                                          single._id
                                        );
                                      }}
                                      className="paletteBox"
                                      style={{
                                        backgroundColor:
                                          material.material_value,
                                        position: "relative",
                                        width: "50px",
                                        height: "50px",
                                        boxShadow:
                                          "0px 4px 4px rgba(0, 0, 0, 0.25)",
                                        borderRadius: "5px",
                                        margin: "6px",
                                        transition: "0.3s",
                                        cursor: "pointer",
                                      }}
                                      key={index}
                                    >
                                      {this.props.checkSelected(
                                        material._id,
                                        single._id
                                      ) ? (
                                        <div
                                          className="selected"
                                          style={{
                                            position: "absolute",
                                            top: 0,
                                            left: 0,
                                            right: 0,
                                            bottom: 0,
                                            opacity: 0.3,
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            color: "#fff",
                                          }}
                                        >
                                          <Check
                                            style={{
                                              color: "#000",
                                            }}
                                          />
                                        </div>
                                      ) : null}
                                    </div>

                                    <div
                                      style={{
                                        inlineSize: "58px",
                                        overflowWrap: "break-word",
                                      }}
                                    >
                                      <p
                                        style={{
                                          textAlign: "center",
                                          fontSize: "14px",
                                          fontFamily: "Inter",
                                        }}
                                      >
                                        {material.material_name}
                                      </p>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div
                                      className="paletteBox"
                                      style={{
                                        backgroundColor:
                                          material.material_value,
                                        position: "relative",
                                        width: "50px",
                                        height: "50px",
                                        boxShadow:
                                          "0px 4px 4px rgba(0, 0, 0, 0.25)",
                                        borderRadius: "5px",
                                        margin: "6px",
                                        transition: "0.3s",
                                        cursor: "pointer",
                                      }}
                                    >
                                      <img
                                        onClick={() => {
                                          this.props.selectMaterial(
                                            material._id,
                                            single._id
                                          );
                                        }}
                                        key={index}
                                        src={
                                          API.imgUrl + material.material_value
                                        }
                                        className="paletteBox"
                                        style={{
                                          backgroundColor:
                                            material.material_value,
                                          position: "relative",
                                          width: "50px",
                                          height: "50px",
                                          boxShadow:
                                            "0px 4px 4px rgba(0, 0, 0, 0.25)",
                                          borderRadius: "5px",
                                          margin: "6px",
                                          transition: "0.3s",
                                          cursor: "pointer",
                                          marginTop: "0px",
                                          marginLeft: "0px",
                                        }}
                                        alt=""
                                      />
                                      {this.props.checkSelected(
                                        material._id,
                                        single._id
                                      ) ? (
                                        <div
                                          className="selected"
                                          style={{
                                            position: "absolute",
                                            top: 0,
                                            left: 0,
                                            right: 0,
                                            bottom: 0,
                                            opacity: 0.3,
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            color: "#fff",
                                          }}
                                        >
                                          <Check
                                            style={{
                                              color: "#000",
                                            }}
                                          />
                                        </div>
                                      ) : null}
                                    </div>
                                    <div
                                      style={{
                                        inlineSize: "58px",
                                        overflowWrap: "break-word",
                                      }}
                                    >
                                      <p
                                        style={{
                                          textAlign: "center",
                                          fontSize: "14px",
                                          fontFamily: "Inter",
                                        }}
                                      >
                                        {material.material_name}
                                      </p>
                                    </div>
                                  </>
                                )}
                              </div>
                            ))
                          : null}
                        {!this.props.propState.Components.lenth - 1 ===
                        index1 ? (
                          <div
                            className="separator"
                            style={{
                              height: "75px",
                              position: "relative",
                              top: 0,
                              margin: "0 32px",
                              border: "0.5px solid #727272",
                            }}
                          ></div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              <div
                className="poweredBy"
                style={{
                  width: "100%",
                  position: "absolute",
                  bottom: window.innerWidth === 316 ? "15px" : "65px",
                  left: 0,
                  // overflow-x: scroll;
                  boxSizing: "border-box",
                  padding: " 6px 0px 6px 0px",
                  // padding-bottom: 0;
                  textAlign: "center",
                  fontFamily: "Inter",
                }}
              >
                <a
                  href="https://actuality.live/"
                  target="_blank"
                  style={{
                    textDecoration: "none",
                    color: "#000000",
                  }}
                >
                  powered by <b style={{ color: "#000" }}>actuality.live</b>
                </a>
              </div>
            </div>
            <div className="iconWrapper web">
              <div
                style={{
                  position: "absolute",
                  right: "12%",
                  bottom: "13%",
                }}
              >
                <span>
                  <img
                    src={rotate}
                    alt=""
                    style={{ width: "30px", marginLeft: "15px" }}
                  />
                  <p
                    style={{
                      fontFamily: "Inter",

                      fontWeight: "400",
                      fontSize: "10px",
                      lineHeight: "0px",

                      textAlign: "center",
                      marginTop: "4px",
                      color: "#5C5C5C",
                    }}
                  >
                    Drag to rotate
                  </p>
                  <img
                    src={zoom}
                    width={"26px"}
                    alt=""
                    style={{
                      marginLeft: "19px",
                      marginTop: "8px",
                    }}
                  />
                  <p
                    style={{
                      fontFamily: "Inter",

                      fontWeight: "400",
                      fontSize: "10px",
                      lineHeight: "0px",
                      marginTop: "4px",
                      textAlign: "center",

                      color: "#5C5C5C",
                    }}
                  >
                    Drag to zoom
                  </p>
                </span>
              </div>
            </div>
          </div>
          <div id="myModal" className="modal"></div>
          <div
            className="modal-content"
            style={{ height: "410px", width: "250px" }}
          >
            <div className="">
              <h1 style={{ fontSize: "19px", textAlign: "center" }}>
                {" "}
                {this.props.propState.language === "French"
                  ? "Comment afficher en réalité augmentée"
                  : "How to View in Augmented Reality"}
              </h1>
              <p style={{ width: "250px" }}>
                {this.props.propState.language === "French"
                  ? `Scannez ce QR code avec votre téléphone pour visualiser l'objet dans votre espace. L'expérience se lance directement depuis votre navigateur - aucune application requise ! `
                  : `Scan this QR code with your phone to view the object in your
space. The experience launches directly from your browser - no app
required !`}
              </p>
              <p style={{ width: "250px" }}>
                {deviceSupported(this.props.propState.language)}
              </p>
            </div>
            <div id="qrCodeWrapper">
              {this.props.propState.productStatus === "draft" ? (
                <QRCode
                  value={
                    "https://actuality.live/modelDynamicviewer/" +
                    this.props.matchparams.id
                  }
                  style={{ height: 200, width: 200, marginTop: 12 }}
                />
              ) : (
                <QRCode
                  value={
                    "https://actuality.live/modelDynamicviewer/" +
                    this.props?.matchparams?.id
                  }
                  style={{ height: 130, width: 130, marginTop: 12 }}
                />
              )}
            </div>
            <span
              className="closeBtn"
              style={{
                position: "absolute",
                bottom: "17px",
                left: "32px",
                minWidth: "168px",
                color: "#000",
              }}
            >
              {this.props.propState.language === "French" ? "Proche" : "Close"}
            </span>
          </div>
        </div>
      </div>
    );
  }
}

export default ShareDesktopModalPopulated;
