import React from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import Shadow from "./LabWork/Shadow";
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Switch,
  Typography,
} from "@material-ui/core";
const { width, height } = window.screen;
function Lab() {
  const navigation = useNavigate();
  return (
    <>
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />

      <Container maxWidth="lg" style={{ padding: "32px 0" }}>
        <Typography
          variant={"h2"}
          className="text-center"
          style={{ margin: "20px 0 40px" }}
        >
          This is Actuality's Lab Work Page
        </Typography>
        <Grid
          container
          style={{ padding: "0 32px" }}
          alignItems="center"
          justifyContent="center"
        >
          <Grid md={8} lg={3} sm={8} xs={8} item>
            <Card>
              <CardActionArea onClick={() => navigation("shadow")}>
                <CardMedia
                  image={require("../Assets/Images/shadow.png")}
                  title="Real Time Shadow in 3D Viewer"
                  component="img"
                  height={width > 600 ? "200" : 300}
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    Real time shadow in 3D Viewer
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    This project is about showing the real time shadow on 3D
                    model.
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default Lab;
