import { Snackbar } from "@material-ui/core";
import React, { Component } from "react";
import Alert from "@material-ui/lab/Alert";

export default class NotificationComponent extends Component {
  state = {
    isVisible: this.props.isVisible,
  };
  handleClose = () => {
    this.setState({ isVisble: false });
  };
 

  render() {
    return (
      <Snackbar
        open={this.props.isVisible}
        autoHideDuration={6000}
        onClose={this.handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <Alert
          onClose={this.handleClose}
          severity={this.props.status}
          className="notiMessage"
        >
          {this.props.message}
        </Alert>
      </Snackbar>
    );
  }
}
