import React from 'react';
import { useMediaHook } from '../useMediaHook';

const Links = ({ shareProduct }) => {
  const isMdOrDown = useMediaHook();
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        // flexWrap: 'wrap',
        width: '100%',
        // background: '#000000',
        borderRadius: isMdOrDown ? 6 : 10,
        color: '#fff',
        marginBottom: 15,
        position: 'relative',
        height: shareProduct?.links?.length ? 'auto' : 35,
        border: '0px solid green',
        marginTop: isMdOrDown ? 10 : 0
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexWrap: 'wrap',
          alignItems: 'center',
          borderRadius: isMdOrDown ? 6 : 10,
          overflow: 'hidden',
          background: '#000',
          width: isMdOrDown ? '100%' : shareProduct?.links?.length > 2 ? '100%' : '50%',
          padding: isMdOrDown ? "0 11px" : "0"
        }}
      >
        {shareProduct?.links?.map((link, index) => (
          <>
            <a
              style={{
                // flex: isMdOrDown ? '2' : 'auto',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                // border: '0.5px solid #ddd',
                padding: isMdOrDown ? "10px 4px" : '10px 20px',
                background: '#000000',
                flex: 1,
                // borderRadius: 8,
                // margin: 2,
                textDecoration: 'none',
                color: 'white',
                fontSize: isMdOrDown ? 10 : 16,
              }}
              key={index}
              target='_blank'
              href={link.href}
            >
              <span>{link.title}</span>
            </a>
          </>
        ))}
      </div>
    </div >
  );
};

export default Links;
