import { Typography } from "@material-ui/core";
import { Check } from "@material-ui/icons";
import React, { useRef, useState } from "react";
import { useEffect } from "react";

import "../../App.scss";
import { API } from "../../Constants/APIs";
import { generateRecommendedProductLink } from "../../Helpers/utils";

const GAP = 20;

const style1 = (variantControllerHeight) => ({
  minWidth: "100%",
  minHeight: variantControllerHeight,
  maxWidth: "100%",
  boxSizing: "border-box",
  maxHeight: variantControllerHeight,
});

const style2 = (variantControllerHeight) => ({
  minHeight: variantControllerHeight,
  maxHeight: variantControllerHeight,
  width: "100%",
  display: "flex",
  overflowX: "auto",
  overflowY: "hidden",
});

const style3 = (isFirst) => ({
  display: "flex",
  flexDirection: "column",
  paddingLeft: isFirst ? GAP / 2 : GAP,
  position: "relative",
});

const style4 = {
  padding: 0,
  margin: 0,
  fontSize: "17px",
  fontWeight: 500,
  color: "#2d3436",
  padding: "8px 0",
  whiteSpace: "nowrap",
  paddingRight: 10,
};
const style5 = {
  flex: 1,
  overflowX: "hidden",
  overflowY: "auto",
  paddingRight: 0 ?? GAP,
};
const style6 = {
  maxHeight: "100%",
  minHeight: "100%",
  overflow: "auto",
  display: "flex",
  scrollbarColor: "#fff",
  scrollbarWidth: 0,
  position: "relative",
};
const style7 = {
  display: "flex",
  flexDirection: "column",
  paddingRight: GAP,
  paddingBottom: GAP,
};
const style8 = {
  minWidth: "50px",
  minHeight: "50px",
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  borderRadius: 5,
  position: "relative",
  overflow: "hidden",
};
const dividerColor = "#DFDFDF";

const CombinedVariantAndRecommended = ({
  variantControllerHeight,
  Component = [],
  selectMaterial,
  recommends = [],
  checkSelected,
  MODAL_STATUS,
  isShare,
}) => {
  const [isInArMode, setIsInArMode] = useState(false);
  useEffect(() => {
    setIsInArMode(
      MODAL_STATUS && MODAL_STATUS.toLowerCase() == "session-started"
    );
  }, [MODAL_STATUS]);
  // const isInArMode = () => MODAL_STATUS.toLowerCase() == "session-started";
  const containerRef = useRef();
  const leftBoxRef = useRef();
  const rightBoxRef = useRef();
  const inc = useRef(0);
  const [containerWidth, setContainerWidth] = useState("100%");
  const [leftBoxWidth, setLeftBoxWidth] = useState("50%");
  const [rightBoxWidth, setRightBoxWidth] = useState("50%");

  const [compoBoxWidth, setCompoBoxWidth] = useState();
  useEffect(() => {
    if (!Component?.length && !recommends?.length) {
      return;
    }
    inc.current++;
    const obj = {
      containerRef: containerRef.current.offsetWidth,
      leftBoxRef: leftBoxRef.current.offsetWidth,
      rightBoxRef: rightBoxRef.current.offsetWidth,
    };
    const obj1 = {
      containerRef: getComputedStyle(containerRef.current),
      leftBoxRef: getComputedStyle(leftBoxRef.current),
      rightBoxRef: getComputedStyle(rightBoxRef.current),
    };
    const strs = [
      "marginLeft",
      "marginRight",
      "paddingRight",
      "paddingLeft",
      "width",
    ];
    Object.keys(obj1).map((k) => {
      strs.map((s) => {
        // console.log(k, s, " => ", obj1?.[k]?.[s]);
      });
    });
    setContainerWidth(obj.containerRef);
    setLeftBoxWidth(obj.leftBoxRef);
    setRightBoxWidth(obj.rightBoxRef);
    // console.log(inc.current, obj, "obj ", obj1);
    // console.log("compoBoxWidth ", compoBoxWidth);
    // alert(JSON.stringify(obj, null, 4));
  }, [Component, recommends]);
  const setCompoWidth = (w) => {
    // console.log("w", w);
    setCompoBoxWidth(w);
  };

  const isSingle =
    Component.length == 1 && Component?.[0].materials.length == 1;

  return (
    <div
      className="__NO__SCROLLBAR__"
      ref={containerRef}
      style={{
        position: "absolute",
        bottom: 0,
        right: 0,
        left: 0,
        // display: "flex",
        // flexDirection: "column",
        alignItems: "center",
        minHeight: variantControllerHeight,
        maxHeight: variantControllerHeight,
        // border: "1px solid black",
        overflow: "hidden",
        boxSizing: "border-box",
        display: "flex",
        // background:
        //   "linear-gradient(180deg, rgba(255, 255, 255, 0.52) 0%, rgba(255, 255, 255, 0.73) 100%)",
        // backdropFilter: "blur(3.5px)",
        background: isInArMode ? dividerColor : "#ffffff",
        borderRadius: "20px 20px 0 0",
        // border: "1px solid red",
        // maxWidth: containerWidth,
      }}
    >
      <div
        ref={leftBoxRef}
        style={{
          // minWidth: isInArMode || !recommends?.length ? "100%" : "50%",
          maxWidth: isInArMode || !recommends?.length ? "100%" : "50%",
          minWidth: isSingle ? "25%" : "50%",
          // minWidth: leftBoxWidth,
          // minWidth: 60,
          overflow: "hidden",
          paddingRight: 8,
          // borderRight: "1px solid #ddd",
          position: "relative",
          // border: "1px solid red",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "50%",
            right: 0,
            height: "70%",
            transform: "translate(50%,-50%)",
            width: "4px",
            borderRadius: 4,
            background: dividerColor,
            display: isInArMode || !recommends?.length ? "none" : "block",
          }}
        />
        <VariantComponentsWithRecommendation
          variantControllerHeight={variantControllerHeight}
          Component={Component}
          selectMaterial={selectMaterial}
          checkSelected={checkSelected}
          setCompoWidth={setCompoWidth}
        />
      </div>
      <div
        ref={rightBoxRef}
        style={{
          display: isInArMode || !recommends?.length ? "none" : "block",
          minWidth: isSingle ? "75%" : "50%",
          maxWidth: "100%",
          // maxWidth: containerWidth - leftBoxWidth - 30,
          overflow: "hidden",
          // border: "1px solid red",
        }}
      >
        <RecommendationBoxes
          isShare={isShare}
          variantControllerHeight={variantControllerHeight}
          recommends={recommends}
        />
      </div>
    </div>
  );
};

const VariantComponentsWithRecommendation = ({
  variantControllerHeight,
  Component,
  selectMaterial,
  checkSelected,
  addClass = false,
  circularBorders,
  setCompoWidth,
}) => {
  const matRef = useRef(0);
  const countRef = useRef(0);
  useEffect(() => {
    if (!matRef?.current) return;
    countRef.current++;
    // console.clear();
    // console.log(
    //   countRef.current,
    //   " matRef.current ",
    //   getComputedStyle(matRef.current).width
    // );
    // console.log("firstfirstfirst ", matRef.current.offsetWidth);
    // console.log("firstfirstfirst ", matRef.current.scrollWidth);
    setCompoWidth(matRef.current.offsetWidth);
    // console.log("matRef ", );
  }, []);
  return (
    <div style={style1(variantControllerHeight)} ref={matRef}>
      <div style={style2(variantControllerHeight)}>
        {Component.map((component, componentIndex) => {
          return (
            <div
              style={{
                ...style3(componentIndex == 0),
                // border: "1px solid red",
              }}
              ref={(e) => {
                if (componentIndex == 0 && !matRef.current) {
                  // alert("ok");
                  // matRef.current = e;
                }
              }}
            >
              <div
                style={{
                  display: componentIndex == 0 ? "none" : "block",
                  position: "absolute",
                  top: "50%",
                  left: 0,
                  height: "60%",
                  width: "2px",
                  background: dividerColor,
                  transform: "translateY(-50%)",
                }}
              />
              <p style={style4}>{component.component_name}</p>
              <div style={style5}>
                <div style={{ ...style6 }}>
                  {component.materials.map((material, materialIndex) => {
                    return (
                      <div style={style7}>
                        <div
                          onClick={() => {
                            // alert("hello");
                            selectMaterial(material._id, component._id);
                          }}
                          style={{ ...style8 }}
                        >
                          {material.material_type == "color" ? (
                            <div
                              style={{
                                zIndex: 10,

                                position: "absolute",
                                top: 0,
                                right: 0,
                                left: 0,
                                bottom: 0,
                                background: material.material_value,
                                // background: "transparent",
                              }}
                            />
                          ) : (
                            <img
                              src={`${API.imgUrl}${material.material_value}`}
                              width="50"
                              height="50"
                            />
                          )}
                          <div
                            style={{
                              position: "absolute",
                              top: 0,
                              right: 0,
                              left: 0,
                              bottom: 0,
                              zIndex: 80,
                              display: checkSelected(
                                material._id,
                                component._id
                              )
                                ? "grid"
                                : "none",
                              placeItems: "center",
                              // border: "1px solid red",
                            }}
                          >
                            <Check style={{ color: "#000" }} />
                          </div>
                        </div>
                        <div
                          style={{
                            overflowWrap: "break-word",
                            textAlign: "center",
                            fontSize: 12,
                            fontWeight: 500,
                            padding: "10px 0",
                            maxWidth: "50px",
                          }}
                        >
                          {material.material_name}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
export const RecommendationBoxes = ({
  variantControllerHeight,
  recommends,
  isShare,
}) => {
  return (
    <div style={style1(variantControllerHeight)}>
      <div style={style2(variantControllerHeight)}>
        {recommends?.map((recommend, recommendIndex) => {
          return (
            <div style={style3(recommendIndex == 0)}>
              <div
                style={{
                  display: recommendIndex == 0 ? "none" : "block",
                  position: "absolute",
                  top: "50%",
                  left: 0,
                  height: "60%",
                  width: "2px",
                  background: dividerColor,
                  transform: "translateY(-50%)",
                }}
              />
              <p style={style4}>{recommend.title}</p>
              <div style={style5}>
                <div style={style6}>
                  {recommend.combinations.map(
                    (combination, combinationIndex) => {
                      return (
                        <div style={style7}>
                          <div style={style8}>
                            <a
                              href={generateRecommendedProductLink(
                                combination.product._id,
                                combination._id,
                                { isShare }
                              )}
                              target="_blank"
                            >
                              <img
                                src={`${API.imgUrl}${combination.product.poster_image_url}`}
                                width="50"
                                height="50"
                              />
                            </a>
                          </div>
                          <div
                            style={{
                              overflowWrap: "break-word",
                              textAlign: "center",
                              fontSize: 12,
                              fontWeight: 500,
                              padding: "10px 0",
                              maxWidth: "50px",
                            }}
                          >
                            {combination.title}
                          </div>
                        </div>
                      );
                    }
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default CombinedVariantAndRecommended;
