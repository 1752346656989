import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { API } from '../../Constants/APIs';
import { useLocation } from 'react-router-dom';
import ProductCards from './ProductCards';
import Search from './Search';

const UserProducts = ({ shareProducts }) => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  useEffect(() => {
    setLoading(true);
    const userId = location.pathname?.split?.('/')?.pop?.();
    if (!userId) {
      setLoading(false);
      return;
    }
    (async () => {
      try {
        const URL =
          API.getUrls[shareProducts ? 'share_products' : 'myProducts'];
        const { data } = await axios.get(`${URL}/${userId}`);
        setProducts(
          shareProducts ? data?.shareProduct?.products || [] : data.products
        );
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  const [query, setQuery] = useState('');

  const [queryProducts, setQueryProducts] = useState([]);

  const handleChangeQuery = (q) => {
    if (!q) {
      setQueryProducts([]);
      setQuery('');
    } else {
      q = q.toLowerCase();
      setQuery(q);
      const q_p = products.filter(({ title, sub_title, status }) => {
        console.log({ title, sub_title, status });
        return (
          title &&
          status == 'published' &&
          (title.toLowerCase().includes(q) ||
            sub_title?.toLowerCase?.()?.includes?.(q))
        );
      });
      setQueryProducts(q_p);
    }
  };

  return (
    <div
      style={{
        minHeight: '400px',
      }}
    >
      <Search handleChangeQuery={handleChangeQuery} query={query} />
      {(query && !queryProducts.length) || (!loading && !products.length) ? (
        <h2
          style={{
            textAlign: 'center',
            fontStyle: 'italic',
          }}
        >
          No products found
        </h2>
      ) : (
        <ProductCards
          products={
            query && queryProducts.length
              ? queryProducts
              : products.filter(({ status }) => status == 'published')
          }
        />
      )}
    </div>
  );
};

export default UserProducts;
