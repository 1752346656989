import "@google/model-viewer";
import { Button, Typography } from "@material-ui/core";
import { Check } from "@material-ui/icons";
import React, { Component } from "react";
import Lottie from "react-lottie";
import arimage from "../Assets/Images/ar_icon.png";
import collLogo from "../Assets/Images/colle_logo.png";
import metroLogo from "../Assets/Images/metro_logo.png";
import teknionLogo from "../Assets/Images/teknion-logo.svg";
import loader from "../Assets/lottie/3dloader.json";

const variantControllerHeight = 100;
export default class TeknionMobileModal extends Component {
  render() {
    const props = this.props;
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: loader,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };
    return (
      <div
        className="mobileResponsiveViewer"
        style={{ height: window.innerHeight }}
      >
        {/* {props.is_name_buy_now_visible ? ( */}
        <div className="header">
          <div>
            {/* <Typography className="proName">{props.model.title}</Typography> */}
          </div>
          <div style={{ marginTop: "35px" }}>
            <div style={{ marginBottom: "10px" }} className="staticModal">
              {" "}
              <Typography className="name">
                {" "}
                Teknion Upstage Scenario 4
              </Typography>
              <Typography className="subTitleInstatic">
                from Teknion,The Collective & Metro Contract Group
              </Typography>
            </div>
            {/* </div> */}
            {/* <div className="staticModal" style={{ marginBottom: "10px" }}>
              <a
                target="_blank"
                href="https://teknion.box.com/s/lki8mqlk6c2qy1tc3izvdpf4fgiy0gsl"
                style={{ textDecoration: "none" }}
              >
                <Typography className="name"> Download RFP</Typography>

                <Typography className="subTitleInstatic">Click here</Typography>
              </a>
            </div> */}
            <div className="staticModal">
              <a
                target="_blank"
                href=" https://www.teknion.com/ca/products/product-details?productlineid=c9743b6e-8e7a-673b-a6c8-ff00004460c4"
                style={{ textDecoration: "none" }}
              >
                <Typography className="name">Upstage Desking System</Typography>

                <Typography className="subTitleInstatic">Learn more</Typography>
              </a>
            </div>
          </div>
        </div>
        {/* // ) : null} */}
        <model-viewer
          id="reveal"
          // src={model?.variants[this.state.variantIndex].glb_file_path}
          exposure="0.8"
          // ios-src={model?.variants[this.state.variantIndex].usdz_file_path}
          src={
            props.showVariant
              ? props.selectedVariant.glb_file_path
              : props.model.glb_file_path
          }
          ios-src={
            props.showVariant
              ? props.selectedVariant.usdz_file_path
              : props.model.usdz_file_path
          }
          camera-orbit="45deg 55deg 2.5m"
          shadow-intensity="2"
          field-of-view="80deg"
          min-field-of-view={"80deg"}
          max-field-of-view={"80deg"}
          bounds="tight"
          data-js-focus-visible
          camera-controls
          ar
          ar-modes="webxr scene-viewer quick-look"
          // quick-look-browsers="safari chrome"
          ar-scale={"auto"}
          ar-placement={"floor"}
          autoplay
          loading="lazy"
          style={{
            background: "#FFFFFF",
            height: window.innerHeight - variantControllerHeight,
            width: "-webkit-fill-available",
            position: "absolute",
            left: "50%",
            top: props.showVariant ? "0%" : "50%",
            transform: props.showVariant
              ? "translateX(-50%)"
              : "translate(-50%, -50%)",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
            slot="poster"
          >
            <Lottie
              options={defaultOptions}
              height={100}
              width={100}
              autoplay
              loop
            />
            <h2 style={{ textAlign: "center" }}>Loading...</h2>
          </div>
          <div className="brand" style={{ flexDirection: "column", top: 40 }}>
            <img
              src={teknionLogo}
              width="80px"
              alt=""
              style={{ marginBottom: 16 }}
            />
            <img
              src={collLogo}
              width="80px"
              alt=""
              style={{ marginBottom: 16 }}
            />
            <img src={metroLogo} width="80px" alt="" />
          </div>
          <button
            slot="ar-button"
            style={{
              backgroundColor: "rgb(255, 255, 255)",
              boxShadow: "1px 2px 5px rgba(0, 0, 0, 0.1)",
              borderRadius: "15px",
              position: "absolute",
              left: "50%",
              transform: "translateX(-50%)",
              bottom: "16px",
              border: "none",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              padding: "0 12px",
            }}
          >
            <img src={arimage} width="20px" alt="" />
            <p className="view_space_btn">View in your space</p>
          </button>
        </model-viewer>

        {props.showVariant ? (
          <div
            className="variantControls"
            style={{
              height: variantControllerHeight,
              width: window.innerWidth,
            }}
          >
            <div className="framesWrapper">
              <div>
                <Typography style={{ marginLeft: 6 }}>
                  {/* {props.model.is_name_buy_now_visible
                    ? "Frame Finishes"
                    : "Street Type"} */}
                  Personas
                </Typography>
                <div className="variantListing">
                  {props.variants[0].mesh_id
                    ? props.variants.map((variant, index) => (
                        <div>
                          <div
                            key={index}
                            onClick={() => {
                              this.props.selectMesh(variant.mesh_id);
                            }}
                            className="paletteBox"
                            style={{ backgroundColor: variant.mesh_color }}
                          >
                            {props.selected_mesh_id === variant.mesh_id ? (
                              <div className="selected">
                                <Check />
                              </div>
                            ) : null}
                          </div>
                          <Typography
                            style={{
                              fontSize: "11px",
                              textAlign: "center",
                              width: "63px",
                            }}
                          >
                            {" "}
                            {variant.name}{" "}
                          </Typography>
                        </div>
                      ))
                    : props.variants.map((variant, index) => (
                        <Button
                          key={index}
                          size={"small"}
                          variant="contained"
                          className="themeBtn"
                          style={{
                            margin: 4,
                            backgroundColor:
                              variant.text_id === props.selectedVariant.text_id
                                ? "rgba(146, 180, 211, 0.8)"
                                : "#bdbdbd",
                            fontSize: 10,
                            width: "80px",
                            paddingTop: 6,
                            paddingBottom: 6,
                          }}
                          elevation={0}
                          onClick={() => {
                            this.props.selectVariant(variant);
                          }}
                        >
                          {variant.text}
                        </Button>
                      ))}
                </div>
              </div>
              {props.model.is_name_buy_now_visible ? (
                <>
                  <div className="separator"></div>
                  <div>
                    <Typography style={{ marginLeft: 6 }}>
                      Mesh Finishes
                    </Typography>
                    <div className="variantListing">
                      {props.meshes.map((variant, index) => (
                        <div
                          key={index}
                          onClick={() => {
                            this.props.selectMesh(variant.mesh_id);
                          }}
                          className="paletteBox"
                          style={{ backgroundColor: variant.mesh_color }}
                        >
                          {props.selected_mesh_id === variant.mesh_id ? (
                            <div className="selected">
                              <Check />
                            </div>
                          ) : null}
                        </div>
                      ))}
                    </div>
                  </div>
                </>
              ) : null}
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}
