import { Button, Snackbar } from "@material-ui/core";
import axios from "axios";
import React, { Component } from "react";
import { API } from "../Constants/APIs";
class ResetPassword extends Component {
  state = {
    email: "",
    password: "",
    loading: false,
    successLoading: false,
    successMsgL: "",
  };
  handlechange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  login = (e) => {
    e.preventDefault();
    this.setState({ loading: true });
    let payload = {
      email: this.state.email,
      password: this.state.password,
    };
    axios.post(API.postUrls.login, payload).then((resp) => {
      if (resp.data.status === "success") {
        this.setState({
          loading: false,
          successLoading: true,
          successMsg: resp.data.message,
        });
        localStorage.setItem("token", resp.data.user.accessToken);
        setTimeout(() => {
          this.setState({
            successLoading: false,
          });
          window.location.href = "/";
          // document.getElementById("myForm").reset();
        }, 2000);
      } else {
        this.setState({
          loading: false,

          errorLoading: true,
          errorMsg: resp.data.message,
        });
        setTimeout(() => {
          this.setState({
            errorLoading: false,
          });
          document.getElementById("myForm").reset();
        }, 2000);
      }
    });
  };
  render() {
    return (
      <div style={{ width: "100%" }}>
        <div style={{ width: "45%", margin: "auto" }}>
          <div className="container signIn">
            {this.state.successLoading ? (
              <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                open={true}
                style={{ top: "102px" }}
                color={"#bdbdbd"}
                autoHideDuration={6000}
                message={this.state.successMsg}
              ></Snackbar>
            ) : null}
            <h4 className="mainHeading"> Reset Password</h4>

            <form onSubmit={this.login} id="myForm">
              <div className="inputWrapper">
                <ul style={{ listStyle: "none", display: "inline-flex" }}>
                  <li  className="spanTag emailTag">Enter new password</li>
                  <li>
                    <input
                      className="Input"
                      name="password"
                      required
                      onChange={this.handlechange}
                    />
                  </li>
                </ul>
              </div>
              <div className="inputWrapper">
                <ul style={{ listStyle: "none", display: "inline-flex" }}>
                  <li  className="spanTag emailTag" style={{marginLeft:"-20px"}}>Confirm new password</li>
                  <li>
                    <input
                      className="Input"
                      name="password"
                      required
                      onChange={this.handlechange}
                    />
                  </li>
                </ul>
              </div>
              {/* <div className="inputWrapper">
                <span className="spanTag emailTag">Confirm new password</span>

                <input
                  className="Input"
                  name="confirm_password"
                  required
                  onChange={this.handlechange}
                />
              </div>{" "} */}
              <br />
              <Button
                variant="contained"
                type="submit"
                className="submitBtn"
                style={{ marginLeft: "150px", marginBottom: "45px" }}
              >
                {this.state.loading ? "Saving..." : "Save"}
              </Button>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default ResetPassword;
