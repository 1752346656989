import { Button, Container, Grid, Typography } from '@material-ui/core';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import axios from 'axios';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Toggle from 'react-toggle';
import Modal from 'react-awesome-modal';
import modelImg1 from '../Assets/Images/modelImg1.png';
import modelImg2 from '../Assets/Images/modelImg2.png';
import modelImg3 from '../Assets/Images/modelImg3.png';
import Blogs from '../Components/Blogs';
import Hero from '../Components/Hero';
import ModelCard from '../Components/ModelCard';
import { API } from '../Constants/APIs';
import right from '../Assets/Images/Right.png';
import wrong from '../Assets/Images/Wrong.png';
import cross from '../Assets/Images/cross.png';
import workflow from '../Assets/Images/workflow.png';
import modeling from '../Assets/Images/modeling.png';
import code from '../Assets/Images/code.png';
import init from '../Helpers/Windotoken';
// import crossIconImg from "../Assets/newIcons/crossIcon.png";
import crossIconImg from '../Assets/newIcons/crossIcon3.png';
import checkIconImg from '../Assets/newIcons/checkIcon.png';
import PricingSection from '../Components/PricingSection';
const CrossIcon = () => (
  <div
    style={{
      height: 12,
      width: 12,
      border: '1px solid rgba(0,0,0,0.5)',
      borderRadius: '50%',
      display: 'inline-grid',
      placeItems: 'center',
    }}
  >
    <img src={crossIconImg} height='6' width='6' />
  </div>
);
const percentage1 = 60;
const percentage2 = 40;
const percentage3 = 94;

const planPricingDetails = (type) => [
  {
    title: `${
      type == 'beginner' ? 20 : type == 'growth' ? 50 : 100
    } Hosted Models`,
    show: true,
  },
  {
    title: `${
      type == 'beginner' ? '20,000' : type == 'growth' ? '80,000' : '150,000'
    } Monthly Website Views`,
    show: true,
  },
  {
    title: 'First Model Optimization Free',
    show: true,
  },
  { title: 'Installation Support', show: true },
  { title: 'Analytics', show: true },
  { title: 'Animated Models', show: type == 'beginner' ? false : true },
  {
    title: 'Dedicated Account Manager',
    show: type == 'beginner' || type == 'growth' ? false : true,
  },
  {
    title: 'White Labelled',
    show: type == 'beginner' || type == 'growth' ? false : true,
  },
];

const customPlanDetails = [
  'Custom Hosted Models',
  'Custom Monthly Website Views',
  'First Model Optimization Free',
  'Installation Support',
  'Custom Analytics',
  'Animated Models',
  'Dedicated Account Manager',
  'Custom UI/UX',
];

export default class Landing extends Component {
  state = {
    // services: [],
    models: [
      // {
      //   title: "Retro Television from Herman Miller",
      //   img_src: modelImg1,
      // },
      // {
      //   title: "Kitchen Furniture by ",
      //   img_src: modelImg2,
      // },
      // {
      //   title: "Gold Lamp from Herman Miller",
      //   img_src: modelImg3,
      // },
      // {
      //   title: "Kitchen Furniture by ",
      //   img_src: modelImg2,
      // },
      // {
      //   title: "Retro Television from Herman Miller",
      //   img_src: modelImg1,
      // },
      // {
      //   title: "Gold Lamp from Herman Miller",
      //   img_src: modelImg3,
      // },
      // {
      //   title: "Gold Lamp from Herman Miller",
      //   img_src: modelImg3,
      // },
      // {
      //   title: "Kitchen Furniture by ",
      //   img_src: modelImg2,
      // },
      // {
      //   title: "Retro Television from Herman Miller",
      //   img_src: modelImg1,
      // },
    ],
    services: [
      {
        image: workflow,
        title: 'Smooth Workflow',
        description:
          'Easy coordination between multiple team members in the marketing and sales team. Dashboard to get an overview of product status and performance',
      },
      {
        image: modeling,
        title: 'Product Modelling',
        description:
          'Don’t have a digital 3D model yet? We can craft your products in 3D form photographs and specs sheet.',
      },
      {
        image: code,
        title: 'AR with a single line of code',
        description:
          'Insert AR functionality into your website, social media with a single line of code, or use a QR code anywhere, transforming your website experience.',
      },
    ],
    VAS: [
      {
        title: 'VR',
      },
      {
        title: 'Virtual Photography',
      },
      {
        title: 'Photorealistic Renders',
      },
    ],
    plans: [
      {
        title: 'Beginner Plan',
        no_of_models: 10,
        is_first_model_free: true,
        ar_360_view_fee: '$300',
        platform_fee: '$1200',
      },
      {
        title: 'Growth Plan',
        no_of_models: 30,
        is_first_model_free: true,
        ar_360_view_fee: '$300',
        platform_fee: '$1200',
      },
      {
        title: 'Enterprise Plan',
        no_of_models: 50,
        is_first_model_free: true,
        ar_360_view_fee: '$300',
        platform_fee: '$1200',
      },
    ],
    isImgLoaded: false,
    itemId: '',
    itemPrice: '',
    loadingBeg: false,
    loadingGrow: false,
    loadingEnte: false,
    alreadypurchased: false,
    toggleStatus: false,
  };

  componentDidMount() {
    this.fetchModels();
    this.setUpImageloader();
  }

  setUpImageloader = () => {
    window.addEventListener('load', (event) => {
      var image = document.querySelector('img');
      this.setState({
        isImgLoaded: image.complete && image.naturalHeight !== 0,
      });
    });
  };
  fetchModels = () => {
    axios.get(API.getUrls.get_public_models).then((res) => {
      this.setState({ models: res.data.products });
    });
  };

  payment = () => {
    if (init() === 'success') {
      let authToken = localStorage.getItem('token');

      axios
        .get(API.getUrls.getPlans, {
          headers: { Authorization: 'Bearer ' + authToken },
        })
        .then((resp) => {
          if (resp.data.orders.docs.length > 0) {
            this.setState({
              alreadypurchased: true,
              loadingBeg: false,
              loadingGrow: false,
              loadingEnte: false,
            });
          } else {
            const payload = {
              item_id: this.state.itemId,
              item_price: this.state.itemPrice,
            };
            axios
              .post(API.postUrls.chargebeePayment, payload, {
                headers: { Authorization: 'Bearer ' + authToken },
              })
              .then((resp) => {
                if (resp.data.status === 'success') {
                  window
                    .open(resp.data.result.hosted_page.url, '_blank')
                    .focus();
                  this.setState({
                    loadingBeg: false,
                    loadingGrow: false,
                    loadingEnte: false,
                  });
                } else {
                  window.location.href =
                    'https://portal.actuality.live/pricing';
                  this.setState({
                    loadingBeg: false,
                    loadingGrow: false,
                    loadingEnte: false,
                  });
                }
              });
          }
        });
    } else {
      window.location.href = 'https://portal.actuality.live/pricing';
    }
  };

  handleToggleChange = () => {
    if (this.state.toggleStatus === true) {
      this.setState({
        toggleStatus: false,
      });
    } else {
      this.setState({
        toggleStatus: true,
      });
    }

    //    console.log(this.state.toggleStatus);
  };

  render() {
    return (
      <div>
        <Hero />

        {/* <Banner /> */}
        <Container>
          <div
            // maxWidth={"lg"}
            style={{
              paddingBottom: '64px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              paddingLeft: '10px',
              paddingRight: '10px',
            }}
          >
            <iframe
              src={
                this.state.models.length > 0 &&
                '/modelDynamicviewer/' + this.state.models[0]._id
              }
              title='some'
              frameborder='0'
              style={{
                minHeight: '728px',
                width: '95vw',
                alignSelf: 'center',
              }}
            ></iframe>
          </div>
        </Container>
        {/* Progress Bar */}
        <section className='sectionInnerSpacingBrowse'>
          <Typography
            align={'center'}
            variant={'h5'}
            className={'bolder verticalSpacing browseHead'}
          >
            Better product visuals mean better results
          </Typography>
          <Container className='containerSpacing' maxWidth={'lg'}>
            <Grid container spacing={3}>
              <Grid item sm={4} style={{ textAlign: 'center' }}>
                <CircularProgressbar
                  value={percentage1}
                  text={`${percentage1}%`}
                  style={{ width: '200px', height: '200px' }}
                  strokeWidth={12}
                  styles={buildStyles({
                    strokeLinecap: 'butt',
                    textColor: '#25262B',
                    pathColor: '#25262B',
                    trailColor: '##BDC4C7',
                  })}
                />
                <p
                  className={
                    window.innerWidth < 1100
                      ? 'progressParaResp'
                      : 'progressPara'
                  }
                >
                  People say they want to be able to visualize where and how a
                  product could fit into their lives
                </p>
              </Grid>
              <Grid item sm={4} style={{ textAlign: 'center' }}>
                <CircularProgressbar
                  value={percentage2}
                  text={`${percentage2}%`}
                  style={{ width: '200px', height: '200px' }}
                  strokeWidth={12}
                  styles={buildStyles({
                    strokeLinecap: 'butt',
                    textColor: '#25262B',
                    pathColor: '#25262B',
                    trailColor: '##BDC4C7',
                  })}
                />
                <p
                  className={
                    window.innerWidth < 1100
                      ? 'progressParaResp'
                      : 'progressPara'
                  }
                >
                  Merchants who have used AR have seen a 40% reduction in
                  returns.
                </p>
              </Grid>
              <Grid item sm={4} style={{ textAlign: 'center' }}>
                <CircularProgressbar
                  value={percentage3}
                  text={`${percentage3}%`}
                  style={{ width: '200px', height: '200px' }}
                  strokeWidth={12}
                  styles={buildStyles({
                    strokeLinecap: 'butt',
                    textColor: '#25262B',
                    pathColor: '#25262B',
                    trailColor: '##BDC4C7',
                  })}
                />
                <p
                  className={
                    window.innerWidth < 1100
                      ? 'progressParaResp'
                      : 'progressPara'
                  }
                >
                  Merchants who have used AR have seen a 94% increase in
                  conversion rate.
                </p>
              </Grid>
            </Grid>
          </Container>
        </section>
        <section className='sectionInnerSpacingBrowse'>
          <Typography
            align={'center'}
            variant={'h5'}
            className={'bolder verticalSpacing browseHead'}
          >
            Browse some of our models in your space
          </Typography>
          <Container className='containerSpacing' maxWidth={'lg'}>
            <Grid container spacing={3}>
              {/* {this.state.models.map((ele, index) =>
                ele.is_visible_in_landing ? (
                  <Grid item sm={4} key={index}>
                    <ModelCard
                      imgSrc={ele.poster_path ? ele.poster_path : modelImg1}
                      id={ele.id}
                      title={ele.title}
                    />
                  </Grid>
                ) : null
              )} */}
              {this.state.models.length > 0 &&
                this.state.models.slice(1, 10).map((ele, index) => (
                  <Grid item sm={4} key={index}>
                    <ModelCard
                      imgSrc={
                        ele.poster_image_url
                          ? API.imgUrl + ele.poster_image_url
                          : modelImg1
                      }
                      id={ele._id}
                      title={ele.title}
                    />
                  </Grid>
                ))}
            </Grid>
          </Container>
          <Container style={{ textAlign: 'center' }}>
            {/* <a href="/" style={{ textDecoration: "none", color: "#666666", cursor: "pointer" }}>
              <Button variant="contained" className="serviceBtnLanding" style={{ backgroundColor: "#bdbdbd", borderRadius: "8px", marginTop: "80px" }}>
                <Typography component="body" className={"bold"} style={{ color: "#fff", fontSize: "28px", fontWeight: "bold", textTransform: "none" }}>
                  See More
                </Typography>
              </Button>
            </a> */}
            <Link to='/' style={{ textDecoration: 'none' }}>
              <Button
                size={'large'}
                variant='contained'
                className='getInTouchLanding txtShadow rounded'
              >
                See More
              </Button>
            </Link>
          </Container>
        </section>
        <section className='sectionInnerSpacingAr'>
          <Container maxWidth={'lg'} className='containerSpacing'>
            <Typography className='serviceHead' gutterBottom>
              AR can make your sales process a breeze
            </Typography>
            <Typography
              className={
                window.innerWidth < 1100
                  ? 'serviceSubHeadResp'
                  : 'serviceSubHead'
              }
              gutterBottom
            >
              We enable your team to express the best features of your products
              through augmented reality, in a way that your customers can
              interact with your product range in more engaging ways.
            </Typography>
            <Grid container spacing={2}>
              {this.state.services.map((service, i) => (
                <Grid
                  className={'serviceWrapper'}
                  item
                  sm={4}
                  spacing={3}
                  key={i}
                >
                  <div>
                    <img
                      src={service.image}
                      alt=''
                      width='20%'
                      style={{ paddingBottom: '20px' }}
                    />
                    <Typography className='serviceTitle'>
                      {service.title}
                    </Typography>
                    <Typography className='serviceDescription'>
                      {service.description}
                    </Typography>
                  </div>
                </Grid>
              ))}
            </Grid>
            {/* <a href="https://calendly.com/rishabh_gupta/30min" target="_blank" style={{ textDecoration: "none", color: "#666666", cursor: "pointer" }}>
              <Button variant="contained" className={window.innerWidth < 1100 ? "serviceBtnLandingResp" : "serviceBtnLanding"}
              >
                <Typography component="body" className={"bold"} style={{ color: "#fff", fontSize: "28px", fontWeight: "bold", textTransform: "none" }}>
                  Book Demo
                </Typography>
              </Button>
            </a> */}
            <a
              href='https://calendly.com/rishabh_gupta/30min'
              target='_blank'
              style={{
                textDecoration: 'none',
                color: '#666666',
                cursor: 'pointer',
              }}
            >
              <Button
                size={'large'}
                variant='contained'
                className='bookDemoLanding txtShadow rounded'
              >
                Book Demo
              </Button>
            </a>
          </Container>
        </section>
        <section
          className='sectionInnerSpacingPricing'
          style={{ marginTop: '-50px' }}
        >
          <PricingSection />
        </section>
        {/* <section className="sectionInnerSpacing">
          <Typography className="pricingTitle" align="center">
            Pricing
          </Typography>
          <Container maxWidth={"lg"} className="containerSpacing">
            <div className="priceTableWrapper">
              <Grid container>
                <Grid item sm={3}>
                  <div style={{ height: 113 }}></div>
                  <Typography className="priceTabHead">
                    Free first model
                  </Typography>
                  <Typography className="priceTabHead">
                    Number of models
                  </Typography>
                  <Typography className="priceTabHead">
                    AR & 360° view
                  </Typography>
                  <Typography className="priceTabHead noBorder">
                    Platform Fee
                  </Typography>
                </Grid>
                {this.state.plans.map((plan, i) => (
                  <Grid
                    item
                    sm={3}
                    key={i}
                    alignItems="center"
                    justify="center"
                  >
                    <Typography className="priceTabHead planTitle alignCenter noBorder">
                      {plan.title}
                    </Typography>
                    <Typography className="priceTabData">
                      <Check fs />
                    </Typography>
                    <Typography className="priceTabData">
                      {plan.no_of_models}
                    </Typography>
                    <Typography className="priceTabData">
                      {plan.ar_360_view_fee}
                      <Typography color="inherit" style={{ fontWeight: 500 }}>
                        per model
                      </Typography>
                    </Typography>
                    <Typography className="priceTabData noBorder">
                      {plan.platform_fee}
                      <Typography color="inherit" style={{ fontWeight: 500 }}>
                        per year
                      </Typography>
                    </Typography>
                  </Grid>
                ))}
              </Grid>
            </div>
          </Container>
        </section> */}
        <section className='sectionInnerSpacing'>
          <Container>
            <div className='text-center VAS'>
              <Typography className='VAStitle'>Value Added Services</Typography>
              <Typography className='VASsubtitle' gutterBottom>
                Priced based on requirement
              </Typography>
              <div className='VASwrapper'>
                <Grid container spacing={3}>
                  {this.state.VAS.map((item, index) => (
                    <Grid item sm={4} key={index} className='text-center'>
                      <Typography className='VASitem'>{item.title}</Typography>
                    </Grid>
                  ))}
                </Grid>
              </div>
              <div className='touchBtnWrapper text-center'>
                <a
                  href='/contact-us'
                  target='_blank'
                  style={{
                    textDecoration: 'none',
                    color: '#666666',
                    cursor: 'pointer',
                  }}
                >
                  <Button
                    size={'large'}
                    variant='contained'
                    className='bookDemoLanding txtShadow rounded'
                  >
                    Get In Touch
                  </Button>
                </a>
              </div>
            </div>
          </Container>
        </section>
        <section className='sectionInnerSpacingBlogs'>
          <Container>
            <Blogs />
          </Container>
        </section>
        {/* <BackdropComponent
          isVisible={!this.state.isImgLoaded}
          bgColor={"#fff"}
        /> */}
        {/* Purchase plan modal */}
        <Modal
          visible={this.state.alreadypurchased}
          width='350'
          height='230'
          effect='fadeInUp'
          onClickAway={() => this.setState({ alreadypurchased: false })}
        >
          <div
            className='confirmationModalPublish'
            style={{ textAlign: 'center' }}
          >
            <Typography>You already have purchased plan.</Typography>
            <Button
              onClick={() => this.setState({ alreadypurchased: false })}
              className='DeleteBtnPublish'
              style={{ background: '#bdbdbd', marginTop: '30px' }}
            >
              Ok
            </Button>
          </div>
        </Modal>
      </div>
    );
  }
}
