import "@google/model-viewer";
import { Button, Typography } from "@material-ui/core";
import { Check, LinearScaleTwoTone } from "@material-ui/icons";
import React, { Component } from "react";
import arimage from "../Assets/Images/ar_icon.png";
import share from "../Assets/Images/share.png";
import hamburger from "../Assets/Images/menu.png";
import closeIcon from "../Assets/Images/cancelToggle.png";

const variantControllerHeight = 100;
export default class MobileModelViewer extends Component {
  state = {
    toggle: false,
  };
  toggleMenu = () => {
    this.setState({
      toggle: true,
    });
  };
  render() {
    const props = this.props;
    // console.log(props);
    return (
      <div
        className="mobileResponsiveViewer"
        style={{ height: window.innerHeight }}
      >
        {props.is_name_buy_now_visible ? (
          <div className="header">
            <div>
              <Typography className="brand">
                <a href="#">Actuality</a>
              </Typography>
              <Typography className="proName">{props.model.title}</Typography>
            </div>
            <div>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div className="shareBtn">
                  <img src={share} height={"20px"} alt="" />
                </div>
                <div className="shareBtn">
                  <LinearScaleTwoTone />
                </div>
              </div>
              <div>
                <Typography className="buyNow">Buy Now</Typography>
              </div>
            </div>
          </div>
        ) : null}

        {props.selectedVariant.usdz_file_path ? (
          <model-viewer
            id="reveal model-viewer"
            src={
              props.showVariant
                ? props.selectedVariant.glb_file_path
                : props.model.glb_file_path
            }
            ios-src={
              props.showVariant
                ? props.selectedVariant.usdz_file_path
                : props.model.usdz_file_path
            }
            camera-controls
            ar
            ar-light-estimation
            ar-modes="webxr scene-viewer quick-look"
            quick-look-browsers="safari chrome"
            ar-scale={"auto"}
            ar-placement={"floor"}
            autoplay
            loading="eager"
            style={{
              background: "#FFFFFF",
              height: window.innerHeight - variantControllerHeight,
              width: "100vw",
              position: "absolute",
              left: "50%",
              top: props.showVariant ? "0%" : "50%",
              transform: props.showVariant
                ? "translateX(-50%)"
                : "translate(-50%, -50%)",
            }}
          >
            <button slot="ar-button" className="viewInYourSpace">
              <img src={arimage} width="20px" alt="" />
              <p className="view_space_btn">View in your space</p>
            </button>
          </model-viewer>
        ) : (
          <model-viewer
            id="reveal model-viewer"
            src={
              props.showVariant
                ? props.selectedVariant.glb_file_path
                : props.model.glb_file_path
            }
            quick-look-browsers="safari chrome"
            camera-controls
            ar
            ar-modes="webxr scene-viewer quick-look"
            ar-scale={"auto"}
            ar-placement={"floor"}
            autoplay
            loading="eager"
            style={{
              background: "#ffff",
              height: window.innerHeight - variantControllerHeight,
              width: "100vw",
              position: "absolute",
              left: "50%",
              top: props.showVariant ? "0%" : "50%",
              transform: props.showVariant
                ? "translateX(-50%)"
                : "translate(-50%, -50%)",
            }}
          >
            <button
              slot="ar-button"
              className="viewInYourSpace"
              // style={{
              //   backgroundColor: "rgb(255, 255, 255)",
              //   boxShadow: "1px 2px 5px rgba(0, 0, 0, 0.1)",
              //   borderRadius: "15px",
              //   position: "absolute",
              //   left: "50%",
              //   transform: "translateX(-50%)",
              //   bottom: "16px",
              //   border: "none",
              //   display: "flex",
              //   flexDirection: "row",
              //   alignItems: "center",
              //   padding: "0 12px",
              // }}
            >
              <img src={arimage} width="20px" alt="" />
              <p className="view_space_btn">View in your space</p>
            </button>
          </model-viewer>
        )}
        <div className="menuHamburger">
          <img
            src={hamburger}
            onClick={this.toggleMenu}
            alt=""
            style={{ width: "100%" }}
          />
        </div>
        {props.showVariant ? (
          <div
            className="variantControls"
            style={{
              height: variantControllerHeight,
              width: window.innerWidth,
            }}
          >
            <div
              className="framesWrapper"
              style={{
                display: "flex",
                border: "0px solid red",
                maxWidth: "100%",
                overflow: "auto",
              }}
            >
              <div style={{ border: "0px solid red" }}>
                <Typography style={{ marginLeft: 6 }}>
                  {props.model.is_name_buy_now_visible
                    ? "Frame Finishes"
                    : "Street Type"}
                </Typography>
                <div className="variantListing">
                  {props.frames[0].frame_id
                    ? props.frames.map((variant, index) => (
                        <div
                          key={index}
                          onClick={() => {
                            this.props.selectFrame(variant.frame_id);
                          }}
                          className="paletteBox"
                          style={{ backgroundColor: variant.frame_color }}
                        >
                          {props.selected_frame_id === variant.frame_id ? (
                            <div className="selected">
                              <Check />
                            </div>
                          ) : null}
                        </div>
                      ))
                    : props.model.variants.map((variant, index) => (
                        <Button
                          key={index}
                          size={"small"}
                          variant="contained"
                          className="themeBtn"
                          style={{
                            margin: 4,
                            backgroundColor:
                              variant.text_id === props.selectedVariant.text_id
                                ? "rgba(146, 180, 211, 0.8)"
                                : "#bdbdbd",
                            fontSize: 10,
                            width: "80px",
                            paddingTop: 6,
                            paddingBottom: 6,
                          }}
                          elevation={0}
                          onClick={() => {
                            this.props.selectVariant(variant);
                          }}
                        >
                          {variant.text}
                        </Button>
                      ))}
                </div>
              </div>
              {props.model.is_name_buy_now_visible ? (
                <>
                  <div
                    className="separator"
                    style={{
                      border: "0px solid green",
                    }}
                  ></div>
                  <div style={{ border: "0px solid red" }}>
                    <Typography style={{ marginLeft: 6 }}>
                      Mesh Finishes
                    </Typography>
                    <div className="variantListing">
                      {props.meshes.map((variant, index) => (
                        <div
                          key={index}
                          onClick={() => {
                            this.props.selectMesh(variant.mesh_id);
                          }}
                          className="paletteBox"
                          style={{ backgroundColor: variant.mesh_color }}
                        >
                          {props.selected_mesh_id === variant.mesh_id ? (
                            <div className="selected">
                              <Check />
                            </div>
                          ) : null}
                        </div>
                      ))}
                    </div>
                  </div>
                </>
              ) : null}
            </div>
          </div>
        ) : null}

        <div
          className="poweredBy"
          style={{
            backgroundColor: "#fff",
            width: "100%",
          }}
        >
          <a
            href="https://actuality.live/"
            target="_blank"
            style={{
              textDecoration: "none",
              color: "#000000",
            }}
          >
            powered by <b style={{ color: "#000" }}>actuality.live</b>
          </a>
        </div>
        {this.state.toggle ? (
          <>
            <div className="sideBar" style={{ overflowX: "hidden" }}>
              <div className="productName">
                <div
                  className="closeToggle"
                  style={{
                    overflowX: "hidden",
                    cursor: "pointer",
                    zIndex: "99999",
                  }}
                  onClick={() =>
                    this.setState(
                      {
                        toggle: false,
                      },
                      () => {
                        // console.log(this.state.toggle);
                      }
                    )
                  }
                >
                  <img src={closeIcon} style={{ width: "16px" }} />
                </div>
                <h5 className="prd1">
                  <Typography className="buyNow" style={{ paddingTop: 0 }}>
                    {props.model.title ? props.model.title : ""}
                    <br />
                  </Typography>
                  <Typography>
                    {props.model.description ? props.model.description : ""}
                    <br />
                  </Typography>
                </h5>
              </div>

              <div className="callToAction">
                <h5 className="prd">
                  <>
                    <Typography
                      className="buyNow"
                      onClick={() => {
                        this.props.openLink();
                      }}
                    >
                      {props.model.is_name_buy_now_visible && "Buy Now"}
                      <br />
                    </Typography>
                    <Typography
                      onClick={() => {
                        this.props.openLink();
                      }}
                    >
                      {props.model.from ? props.model.from : ""}
                      <br />
                    </Typography>
                  </>
                </h5>
              </div>
            </div>
          </>
        ) : (
          ""
        )}
      </div>
    );
  }
}
