const saveAndGetLocation = ({ city, country, lat, long } = {}) => {
  country && localStorage.setItem('country', country);
  city && localStorage.setItem('city', city);
  lat && localStorage.setItem('lat', lat);
  long && localStorage.setItem('long', long);
  return {
    country: localStorage.getItem('country', country) || 'N/A',
    city: localStorage.getItem('city', city) || 'N/A',
    lat: localStorage.getItem('lat', lat) || -0,
    long: localStorage.getItem('long', long) || 0,
    fromUtil: true,
  };
};
export const actualityLogoHeight = 34;
export const variantControllerHeight = 114;
const generateRecommendedProductLink = (
  productId,
  combinationId,
  { isMDV = true, isShare, isShareAr } = {}
) =>
  `/${
    isShare ? 'share' : isMDV ? 'modelDynamicviewer' : 'sharear'
  }/${productId}?rpId=${combinationId}`;

export { saveAndGetLocation, generateRecommendedProductLink };

export const loremIpsumDummy = (n = 50) =>
  [...new Array(n)]
    .map(
      () => `Cras mattis consectetur purus sit amet fermentum.
Cras justo odio, dapibus ac facilisis in, egestas eget quam.
Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
Praesent commodo cursus magna, vel scelerisque nisl consectetur et.`
    )
    .join('\n');
export function offset(el) {
  var rect = el.getBoundingClientRect(),
    scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
    scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
}

function copy(e, contentToCopy) {
  e.preventDefault(); // default behaviour is to copy any selected text
  e.clipboardData.setData('text/plain', contentToCopy);
}

export function copyDataToClipboard(contentToCopy) {
  document.addEventListener('copy', (e) => copy(e, contentToCopy));
  try {
    document.execCommand('copy');
  } catch (exception) {
    console.error('Copy to clipboard failed');
  } finally {
    document.removeEventListener('copy', (e) => copy(e, contentToCopy));
  }
}

export const downloadCanvasAsImage = (canvasImg, imageName) => {
  let xhr = new XMLHttpRequest();
  xhr.responseType = 'blob';
  xhr.onload = function () {
    let a = document.createElement('a');
    a.href = window.URL.createObjectURL(xhr.response);
    a.download = imageName + '.png';
    a.style.display = 'none';
    document.body.appendChild(a);
    a.click();
    a.remove();
  };
  xhr.open('GET', canvasImg); // This is to download the canvas Image
  xhr.send();
};
export const isValidUrl = (urlString) => {
  let url;
  try {
    url = new URL(urlString);
  } catch (e) {
    return false;
  }
  return url.protocol === 'http:' || url.protocol === 'https:';
};
