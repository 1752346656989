import { Container, Typography } from "@material-ui/core";
import axios from "axios";
import React, { Component } from "react";
import renderHTML from "react-render-html";
import { API } from "../Constants/APIs";
class TermsOfService extends Component {
  state = {
    terms: "",
    description: "",
  };
  componentDidMount() {
    this.getTerms();
  }

  getTerms = () => {
    axios.get(API.getUrls.getTerms).then((res) => {
      this.setState({
        terms: res.data.terms.content,
      });
    });
  };
  render() {
    return (
      <Container className="containerSpacingdashBoard" maxWidth={"lg"}>
        <div className="privacyPolicy">
          <Typography align={"center"} variant={"h3"} className="descripttag">
           
            Terms Of Service
          </Typography>
          <Typography> {renderHTML(this.state.terms)} </Typography>
        </div>
      </Container>
    );
  }
}

export default TermsOfService;
