import "@google/model-viewer";
import {
  Button,
  CircularProgress,
  Container,
  Grid,
  Snackbar,
  Typography,
} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { Edit } from "@material-ui/icons";
import AddIcon from "@material-ui/icons/Add";
import AddCircleOutlineSharpIcon from "@material-ui/icons/AddCircleOutlineSharp";
import CloseIcon from "@material-ui/icons/Close";
import CloseSharpIcon from "@material-ui/icons/CloseSharp";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import RemoveRedEyeOutlinedIcon from "@material-ui/icons/RemoveRedEyeOutlined";
import axios from "axios";
import QRCode from "qrcode.react";
import React, { Component } from "react";
import Modal from "react-awesome-modal";
import { SketchPicker } from "react-color";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Lottie from "react-lottie";
import { Link } from "react-router-dom";
import "../App.scss";
import edit from "../Assets/Images/edit.png";
import rectangle from "../Assets/Images/Rectangle 54.png";
import rotate from "../Assets/Images/rotate.png";
import share from "../Assets/Images/share.png";
import verticalLine from "../Assets/Images/Vector 12.png";
import yes from "../Assets/Images/yes.png";
import zoom from "../Assets/Images/zoom-in.png";
import loader from "../Assets/lottie/3dloader.json";
import { API } from "../Constants/APIs";
import init from "../Helpers/Windotoken";
import remove from "../Assets/Images/delete.png";
const pId = document.location.href;
var url = new URL(pId);
var productId = url.searchParams.get("id");
class UnPublishModal extends Component {
  state = {
    visible: false,
    showFirst: false,
    showSecond: false,
    showThird: false,
    showFourth: false,
    compname: "",
    productId: 0,
    addColor: true,
    type: "color",
    material_value: "",
    compId: 0,
    file: "",
    showMaterials: true,
    setValue: 0,
    array1: [],
    array2: [],
    emptyVariants: false,
    emptyComponents: false,
    model_file: "",
    processing: false,
    userdetail: {},
    title: "",
    uploadCount: 0,
    posterImage: "",
    deleteConfirmation: false,
    confirmationComponentDelete: false,
    deleteMatId: 0,
    deleteCompId: 0,
    loading: false,
    confirmationComponentAdd: false,
    selectedMaterials: [],
    colorPicker: false,
    model: {},
    product: {},
    variantIndex: 0,
    revealModel: false,
    mesh: [],
    Components: [],
    frames: [],
    selected_material_id: 0,
    selected_component_id: "",
    // showModalBtn: screen === "desktop",
    showVariant: false,
    selectedVariant: {},
    meshes: [],
    Variants: [],
    model_file_url: "",
    ModalImage: "",
    variantsCombo: [],
    Component1: [],
    Component2: [],
    value: "https://portal.actuality.live/modelDynamicviewer/" + productId,
    QRCode: "https://actuality.live/modelDynamicviewer/" + productId,
    copied: false,
    qrCopy: false,
    embedCopy: false,
    embedCode: "",
    accordionHeight: "43px",
    active: 0,
    activeComponents: [],
    link_title: "",
    link_description: "",
    link_url: "",
    userId: "",
    purchasePlan: false,
    modalId: "",
    productStatus: "",
    language: "English",
  };
  publish = () => {
    // window.location.href = "/pricing";
    axios.get(API.getUrls.getUser).then((resp) => {
      if (resp.data.status === "success") {
        this.setState({
          userId: resp.data.user._id,
        });

        axios.get(API.getUrls.getPlans).then((res) => {
          // console.log(res);
          if (res.data.status === "success") {
            if (res.data.orders.docs.length > 0) {
              if (res.data.orders.docs.pop().user._id === this.state.userId) {
                var url_string = window.location.href; //window.location.href
                var url = new URL(url_string);
                var payId = url.searchParams.get("id");
                const payload = {
                  status: "published",
                };
                axios
                  .post(
                    API.postUrls.changeStatus + payId + "?type=product",
                    payload
                  )
                  .then((statusResp) => {
                    // console.log(statusResp);
                    if (statusResp.data.status === "success") {
                      window.location.href = "/modelDynamicviewer/" + payId;
                    }
                  });
              }
            } else {
              this.setState({
                purchasePlan: true,
              });
            }
          } else {
            this.setState({
              purchasePlan: true,
            });
          }
        });
      }
    });
  };
  componentDidMount() {
    let pId = document.location.href;
    var url = new URL(pId);
    var productId = url.searchParams.get("id");
    this.setState({
      modalId: productId,
      embedCode:
        "<iframe src='" +
        "https://portal.actuality.live/modelDynamicviewer/" +
        productId +
        "' title='iFrame Name' scrolling='No' height='750px' width='100%' frameborder='0'></iframe>",
    });
    this.setModel();
    this.getSingleProduct();
    this.getComponents();
    this.getUser();
    this.getVariants();
  }
  selectedComponents = (CompId) => {
    if (this.state.activeComponents.includes(CompId)) {
      let index = this.state.activeComponents.indexOf(CompId);
      this.state.activeComponents.splice(index, 1);
    } else {
      this.state.activeComponents.push(CompId);
    }
  };
  setModel = () => {
    let pId = document.location.href;
    var url = new URL(pId);
    var productId = url.searchParams.get("id");
    axios.get(API.getUrls.getProducts + "/" + productId).then((res) => {
      this.setState(
        {
          product: res.data.product,
          revealModel: true,
        },
        () => {
          this.setUpModal();
        }
      );
    });
  };
  setUpModal = () => {
    var modal = document.getElementById("myModal");
    var modalContent = document.querySelector(".modal-content");

    var btn = document.getElementById("qrtext");

    var span = document.getElementsByClassName("closeBtn")[0];

    if (btn) {
      btn.onclick = function () {
        modal.style.display = "block";
        modalContent.classList.add("slideIn");
      };
    }

    span.onclick = function () {
      modalContent.classList.remove("slideIn");
      modal.style.display = "none";
    };

    window.onclick = function (event) {
      if (event.target === modal) {
        modalContent.classList.remove("slideIn");
        modal.style.display = "none";
      }
    };
  };
  getVariants = () => {
    let pId = document.location.href;
    var url = new URL(pId);
    var productId = url.searchParams.get("id");
    axios
      .get(API.getUrls.variants + "?page=1&limit=10&product=" + productId)
      .then((res) => {
        this.setState({
          Variants: res.data.docs,
          selectedVariant: res.data.docs[0],
          ModalImage: res.data
            ? res.data.docs
              ? res.data.docs[0]
                ? res.data.docs[0].model_file_url
                : null
              : null
            : null,
        });
      });
  };

  onImageChange = (event) => {
    this.setState({
      file: event.target.files[0],
    });
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.setState({ swatchFile: e.target.result });
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };
  getComponents = () => {
    let pId = document.location.href;
    var url = new URL(pId);
    var productId = url.searchParams.get("id");
    this.setState({ productId });
    axios
      .get(API.getUrls.getComponents + productId + "?page=1&limit=10")
      .then((res) => {
        this.setState(
          {
            Components: res.data.components.docs,
          },
          () => {
            this.state.Components.forEach((item) => {
              this.state.activeComponents.push(item._id);
            });
            this.setState({
              variantsCombo: this.getCombinations(this.state.Components),

              emptyComponents:
                this.state.Components.length === 0 ? true : false,
            });
          }
        );
      });
    // }
  };
  getUser = () => {
    if (init() === "success") {
      axios.get(API.getUrls.getUser).then((res) => {
        this.setState({ userdetail: res.data.user });
      });
    }
  };

  openModal() {
    this.setState({
      showFirst: true,
      showSecond: false,
      showThird: false,
      showFourth: false,
      visible: true,
    });
  }
  secondModal() {
    this.setState({
      showFirst: false,
      showSecond: true,
      showThird: false,
      showFourth: false,
      visible: true,
    });
  }
  thirdModal() {
    this.setState({
      showFirst: false,
      showSecond: false,
      showThird: true,
      showFourth: false,
      visible: true,
    });
  }
  fourthModal() {
    this.setState({
      showFirst: false,
      showSecond: false,
      showThird: false,
      showFourth: true,
      visible: true,
    });
  }
  closeModal() {
    this.setState({
      visible: false,
      openEdit: false,
      compnentEdit: false,
      materialEdit: false,
    });
    if (this.state.ShowComponent) {
      document.getElementById("componentForm").reset();
    } else {
      document.getElementById("materialForm").reset();
    }
  }
  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  addComponent = (e) => {
    e.preventDefault();
    let authToken = localStorage.getItem("token");
    this.setState({ loading: true });
    let rawData = {
      component_name: this.state.compname,
      product: this.state.productId,
    };
    axios
      .post(API.postUrls.addComponents, rawData, {
        headers: { Authorization: "Bearer " + authToken },
      })
      .then((resp) => {
        if (resp.data.status === "success") {
          this.setState({
            notification: true,
            successMsg: resp.data.message,
            loading: false,
            compId: resp.data.component._id,
            showFirst: true,
            visible: true,
          });
          setTimeout(() => {
            this.setState({ notification: false });
            document.getElementById("componentForm").reset();

            this.getComponents();
          }, 2000);
        } else {
          this.setState({
            notification: true,
            successMsg: resp.data.message,
          });
          setTimeout(() => {
            this.setState({ notification: false });
          }, 2000);
        }
      });
  };
  addMaterial = (e) => {
    let pId = document.location.href;
    var url = new URL(pId);
    var productId = url.searchParams.get("id");
    e.preventDefault();

    this.setState({ loading: true });

    let data = new FormData();
    data.append("material_name", this.state.material_name);
    data.append("material_type", this.state.type);
    if (this.state.type === "color") {
      data.append("material_value", this.state.material_value);
    }
    data.append("component", this.state.compId);
    if (this.state.type === "swatch") {
      data.append("swatch_image", this.state.file);
    }
    if (init() === "success") {
      axios.post(API.postUrls.addMaterial, data).then((resp) => {
        if (resp.data.status === "success") {
          this.setState({
            notification: true,
            successMsg: resp.data.message,
            visible: false,
            loading: false,
            swatchFile: "",
            file: "",
          });
          setTimeout(() => {
            this.setState({ notification: false });
            document.getElementById("materialForm").reset();
            this.getComponents();
            window.location.href = "/product-edit/" + "?id=" + productId;
          }, 4000);
        } else {
          this.setState({
            notification: true,
            successMsg: resp.data.message,
          });
          setTimeout(() => {
            this.setState({ notification: false });
          }, 2000);
        }
      });
    }
  };
  handleFile = (e) => {
    this.setState({ [e.target.name]: e.target.files[0] });
  };

  getCombinations(arr) {
    if (arr.length === 0) return [[]];
    let [current, ...rest] = arr;
    let combinations = this.getCombinations(rest);
    return current.materials.reduce(
      (a, string) => [...a, ...combinations.map((c) => [string, ...c])],
      []
    );
  }
  handleChangeComplete = (color) => {
    this.setState({ material_value: color.hex }, () => {});
  };

  getSingleProduct = () => {
    let pId = document.location.href;
    var url = new URL(pId);
    var productId = url.searchParams.get("id");
    if (init() === "success") {
      axios.get(API.getUrls.getProducts + "/" + productId).then((res) => {
        // console.log(res);
        this.setState({
          product: res.data.product,
          productStatus: res.data.product.status,
          language: res.data.product.language,
        });
      });
    }
  };
  updateProduct = (e) => {
    e.preventDefault();
    this.setState({ loading: true });

    let data = new FormData();
    data.append("title", this.state.title);
    if (this.state.filename) {
      data.append("posterImage", this.state.filename);
    }

    this.state.product.categories.forEach((cat) => {
      data.append("categories[]", cat._id);
    });
    data.append("link_url", this.state.link_url);
    data.append("link_title", this.state.link_title);
    data.append("link_description", this.state.link_description);
    data.append("language", this.state.language);
    if (init() === "success") {
      axios
        .put(API.postUrls.addproduct + "/" + this.state.product._id, data)
        .then((resp) => {
          if (resp.data.status === "success") {
            this.setState({
              notification: true,
              loading: false,
              successMsg: resp.data.message,
              productadded: true,
              openEdit: false,
              openBuyNow: false,
            });
            setTimeout(() => {
              this.setState({ notification: false });
              this.getSingleProduct();
            }, 2000);
          } else {
            this.setState({
              notification: true,
              successMsg: resp.data.message,
              loading: false,
            });
            setTimeout(() => {
              this.setState({ notification: false });
            }, 2000);
          }
        });
    }
  };
  updateComponent = (e) => {
    e.preventDefault();
    let authToken = localStorage.getItem("token");
    this.setState({ loading: true });
    let rawData = {
      component_name: this.state.compname,
      product: this.state.productId,
    };
    axios
      .put(API.postUrls.addComponents + "/" + this.state.compId, rawData, {
        headers: { Authorization: "Bearer " + authToken },
      })
      .then((resp) => {
        if (resp.data.status === "success") {
          this.setState({
            notification: true,
            successMsg: resp.data.message,
            loading: false,
            compnentEdit: false,
          });
          setTimeout(() => {
            this.setState({ notification: false });

            this.getComponents();
          }, 2000);
        } else {
          this.setState({
            notification: true,
            successMsg: resp.data.message,
          });
          setTimeout(() => {
            this.setState({ notification: false });
          }, 2000);
        }
      });
  };
  updateMaterial = (e) => {
    e.preventDefault();

    this.setState({ loading: true });

    let data = new FormData();
    data.append("material_name", this.state.material_name);
    data.append("material_type", this.state.type);
    if (this.state.type === "color") {
      data.append("material_value", this.state.material_value);
    }
    data.append("component", this.state.compId);
    if (this.state.type === "swatch") {
      data.append("swatch_image", this.state.file);
    }
    if (init() === "success") {
      axios
        .put(API.postUrls.addMaterial + "/" + this.state.material_id, data)
        .then((resp) => {
          if (resp.data.status === "success") {
            this.setState({
              notification: true,
              successMsg: resp.data.message,
              visible: false,
              loading: false,
              materialEdit: false,
            });
            setTimeout(() => {
              this.setState({ notification: false });
              this.getComponents();
            }, 2000);
          } else {
            this.setState({
              notification: true,
              successMsg: resp.data.message,
            });
            setTimeout(() => {
              this.setState({ notification: false });
            }, 2000);
          }
        });
    }
  };
  openEditModal = () => {
    this.setState({
      openEdit: true,
    });
  };
  getVariants = () => {
    let pId = document.location.href;
    var url = new URL(pId);
    var productId = url.searchParams.get("id");
    axios
      .get(API.getUrls.variants + "?page=1&limit=10&product=" + productId)
      .then((res) => {
        this.setState({
          Variants: res.data.docs,
          ModalImage: res.data
            ? res.data.docs
              ? res.data.docs[0]
                ? res.data.docs[0].model_file_url
                : null
              : null
            : null,
        });
      });
  };

  variantDelete = () => {
    let pId = document.location.href;
    var url = new URL(pId);
    var productId = url.searchParams.get("id");
    if (this.state.Variants.length > 0) {
      if (init() === "success") {
        axios
          .delete(API.postUrls.deleteAllVariants + "/" + productId)
          .then((res) => {
            if (res.data.status === "success") {
              this.setState({
                notification: true,
                successMsg: res.data.message,
                confirmationComponentAdd: false,
              });
              setTimeout(() => {
                this.setState({
                  notification: false,
                });
                window.location.href = "/product-edit" + "?id=" + productId;
              }, 5000);
            }
          });
      }
    }
  };
  deleteMaterial = (id, isValid) => {
    if (init() === "success") {
      axios.delete(API.postUrls.addMaterial + "/" + id).then((res) => {
        if (res.data.status === "failed") {
          this.setState(
            {
              notification: true,
              successMsg: res.data.message,
              deleteConfirmation: false,
            },
            () => {
              if (this.state.Variants.length > 0) {
                this.state.Variants.forEach((item) => {
                  item.materials.forEach((mat) => {
                    if (mat.is_variant_made === isValid && mat._id === id) {
                      if (init() === "success") {
                        axios
                          .delete(API.getUrls.variants + "/" + item._id)
                          .then((res) => {
                            if (res.data.status === "success") {
                              this.setState({
                                notification: true,
                                successMsg: res.data.message,
                                deleteConfirmation: false,
                              });
                              this.getVariants();
                            }
                          });
                      }
                    }
                  });
                });
              }
            }
          );
          setTimeout(() => {
            this.setState({
              notification: false,
            });
            window.location.href =
              "product-edit" + "?id=" + this.state.productId;
          }, 5000);
        }
      });
    }
  };
  deleteComponent = (id) => {
    let authToken = localStorage.getItem("token");
    this.setState({ loading: true });

    axios
      .delete(API.postUrls.addComponents + "/" + id, {
        headers: { Authorization: "Bearer " + authToken },
      })
      .then((resp) => {
        if (resp.data.status === "failed") {
          this.setState({
            notification: true,
            successMsg: resp.data.message,
            confirmationComponentDelete: false,
          });
          setTimeout(() => {
            this.setState({
              notification: false,
            });
          }, 5000);
          this.variantDelete();
          this.getComponents();
        } else {
          this.setState({
            notification: true,
            successMsg: resp.data.message,
            confirmationComponentDelete: false,
          });
          setTimeout(() => {
            this.setState({
              notification: false,
            });
          }, 2000);
          this.variantDelete();
          this.getComponents();
        }
      });
  };
  downloadQR = () => {
    const canvas = document.getElementById("QRCode");
    const pngUrl = canvas
      .toDataURL(window.location.href)
      .replace(window.location.href, window.location.href);
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = "QRCode";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };
  openInnewWindow = () => {
    window.open("/modelDynamicviewer/" + this.state.productId, "_blank");
  };

  render() {
    const model = this.state.model;
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: loader,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };
    return (
      <Container className="containerSpacingdashBoard" maxWidth={"lg"}>
        <div className="modelViewer editWrapper">
          {this.state.notification ? (
            <Snackbar
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              open={true}
              style={{ bottom: "40px" }}
              color={"#bdbdbd"}
              autoHideDuration={6000}
              message={this.state.successMsg}
            ></Snackbar>
          ) : null}
          <Grid container spacing={2} className="editWrapper">
            <Grid
              item
              sm={9}
              style={{
                height: window.innerWidth < 1100 ? "400px" : "100vh",
                position: window.innerWidth < 1100 ? "relative" : "sticky",
                marginTop: window.innerWidth < 1100 ? "" : "-24px",
                top: window.innerWidth < 1100 ? "" : "0",
              }}
            >
              <model-viewer
                exposure={model.exposure}
                scale={1}
                src={API.imgUrl + this.state.ModalImage}
                shadow-intensity={model.shadow_intensity}
                camera-orbit="45deg 55deg 2.5m"
                field-of-view="60deg"
                min-field-of-view={window.innerWidth < 600 ? "90deg" : "55deg"}
                max-field-of-view={window.innerWidth < 600 ? "90deg" : "55deg"}
                camera-controls
                ar
                ar-modes="webxr scene-viewer quick-look"
                ar-placement={"floor"}
                autoplay
                style={{
                  background: "#fff",
                  boxShadow: "1px 2px 5px rgba(0, 0, 0, 0.1)",
                  borderRadius: "15px",
                  height: window.innerWidth < 1100 ? "46vh" : "90vh",
                  width: "-webkit-fill-available",
                  position: "relative",
                  top: window.innerWidth < 1100 ? "117%" : "50%",
                  transform: "translateY(-50%)",
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                  slot="poster"
                >
                  <Lottie
                    options={defaultOptions}
                    height={100}
                    width={100}
                    autoplay
                    loop
                  />
                  <h2 style={{ textAlign: "center" }}>Loading...</h2>
                </div>

                <div className="iconWrapper">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      className="text-center"
                      style={{ marginRight: 12, height: "100%" }}
                    >
                      <img src={rotate} alt="" />
                    </div>
                    <div
                      className="text-center"
                      style={{ marginRight: 4, height: "100%" }}
                    >
                      <img src={zoom} width={"32px"} alt="" />
                    </div>
                  </div>
                  <div>
                    <div
                      className="shareBtn"
                      onClick={() => {
                        this.setState({ openShareModal: true });
                      }}
                    >
                      <img src={share} height={"24px"} alt="" />
                    </div>
                  </div>
                </div>
              </model-viewer>
            </Grid>

            <Grid item sm={3}>
              <div className="editRightWrapper">
                <div
                  className="box1 "
                  style={{ position: "relative", cursor: "pointer" }}
                  onClick={() => {
                    this.openEditModal();
                  }}
                >
                  <div
                    className="inlineWrapper"
                    style={{ marginBottom: "20px" }}
                  >
                    {/* <img
                      src={
                        this.state.product.poster_image_url
                          ? API.imgUrl + this.state.product.poster_image_url
                          : ""
                      }
                      width="50px"
                      height="50px"
                      style={{ borderRadius: "30px" }}
                      alt=""
                    /> */}

                    <Typography
                      className="compName"
                      style={{
                        textTransform: "capitalize",
                        fontWeight: 600,
                        fontSize: "18px",
                        marginTop: "35px",
                      }}
                    >
                      {this.state.product
                        ? this.state.product.title
                        : "  Product name not available"}
                    </Typography>
                  </div>
                  <br />
                  <Typography
                    className="compName"
                    style={{ marginTop: "-15px" }}
                  >
                    {this.state.userdetail.company_name === "undefined"
                      ? "Company name not available"
                      : this.state.userdetail.company_name
                      ? this.state.userdetail.company_name
                      : null}
                  </Typography>
                  <img
                    src={edit}
                    width="20px"
                    height="20px"
                    style={{
                      position: "absolute",
                      right: "6%",
                      top: "33%",
                      cursor: "pointer",
                    }}
                    alt=""
                    onClick={() => {
                      this.openEditModal();
                    }}
                  />
                  <p
                    style={{
                      fontWeight: "bold",
                      position: "absolute",
                      right: "5%",
                      top: "21%",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      this.openEditModal();
                    }}
                  >
                    __
                  </p>
                </div>
                <div
                  className="box1"
                  onClick={() => {
                    this.setState({ openBuyNow: true });
                  }}
                  style={{
                    position: "relative",
                    alignItems: "center",
                    display: "flex",
                    cursor: "pointer",
                  }}
                >
                  <div className="inlineWrapper">
                    <Typography
                      className="buyNow"
                      style={{ fontWeight: "bold", cursor: "pointer" }}
                      onClick={() => {
                        this.setState({ openBuyNow: true });
                      }}
                    >
                      {this.state.product
                        ? this.state.product.link_title
                          ? this.state.product.link_title
                          : "Buy Now"
                        : "Buy Now"}
                    </Typography>
                    <br />

                    <img
                      width="20px"
                      height="20px"
                      style={{
                        position: "absolute",
                        right: "7%",
                        cursor: "pointer",
                      }}
                      src={edit}
                      alt=""
                      onClick={() => {
                        this.setState({ openBuyNow: true });
                      }}
                    />
                    <p
                      style={{
                        fontWeight: "bold",
                        position: "absolute",
                        right: "6%",
                        top: "22%",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        this.setState({ openBuyNow: true });
                      }}
                    >
                      __
                    </p>
                  </div>
                  <br />
                  <Typography
                    className="buyNow"
                    style={{
                      cursor: "pointer",
                      fontSize: "14px",
                      letterSpacing: "0px",
                      position: "absolute",
                      bottom: "10px",
                    }}
                    onClick={() => {
                      this.setState({ openBuyNow: true });
                    }}
                  >
                    {this.state.product
                      ? this.state.product.link_description
                        ? this.state.product.link_description
                        : ""
                      : ""}
                    {/* {this.state.product.link_description
                      ? this.state.product.link_description
                      : ""} */}
                  </Typography>
                </div>

                {/* Components Mapping */}

                {this.state.Components
                  ? this.state.Components.map((comp, index) => (
                      <div
                        className="box1"
                        style={{
                          width: "100%",
                          height: this.state.activeComponents.includes(comp._id)
                            ? "226px"
                            : "38px",
                          overflowY: this.state.activeComponents.includes(
                            comp._id
                          )
                            ? "auto"
                            : "",
                          position: "relative",
                          transitionDuration: "222ms",
                        }}
                      >
                        <ExpandMoreRoundedIcon
                          className="downArrow"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            this.selectedComponents(comp._id);

                            this.setState({
                              showMaterials:
                                this.state.activeComponents.includes(comp._id)
                                  ? true
                                  : false,
                            });
                          }}
                        />
                        <CloseIcon
                          onClick={() => {
                            this.setState({
                              confirmationComponentDelete: true,
                              deleteCompId: comp._id,
                            });
                            // this.deleteComponent(comp._id);
                          }}
                          style={{
                            color: "#bdbdbd",
                            fontSize: "18px",
                            position: "absolute",
                            right: "40px",
                            top: "17px",
                            cursor: "pointer",
                          }}
                        />
                        <div
                          className="inlineWrapper"
                          style={{ marginLeft: "10px", cursor: "pointer" }}
                        >
                          <Typography
                            className="buyNow"
                            style={{ cursor: "pointer" }}
                          >
                            {comp.component_name}
                          </Typography>

                          <EditOutlinedIcon
                            style={{
                              marginLeft: "10px",
                              marginTop: "16px",
                              cursor: "pointer",
                              position: "absolute",
                              right: window.innerWidth < 1000 ? "56px" : "64px",
                              top: "2px",
                              fontSize: "16px",
                              color: "#bdbdbd",
                            }}
                            onClick={() =>
                              this.setState({
                                compId: comp._id,
                                compnentEdit: true,
                                component_name: comp.component_name,
                              })
                            }
                          />
                        </div>
                        <br />
                        {this.state.activeComponents.includes(comp._id) ? (
                          <div
                            className="inlineWrapper"
                            style={{ marginLeft: "3px" }}
                          >
                            {comp
                              ? comp.materials.length > 0
                                ? comp.materials.map((singleMat) => (
                                    <div>
                                      {singleMat ? (
                                        singleMat.material_type ? (
                                          singleMat.material_type ===
                                          "color" ? (
                                            <div
                                              className="smallBox"
                                              onClick={() => {
                                                this.setState({
                                                  material_value:
                                                    singleMat.material_value,
                                                  material_name:
                                                    singleMat.material_name,
                                                  compId: comp._id,
                                                  material_id: singleMat._id,
                                                });
                                              }}
                                              style={{
                                                backgroundColor:
                                                  singleMat.material_value,
                                                position: "relative",
                                                cursor: "pointer",
                                              }}
                                            >
                                              <CloseIcon
                                                onClick={() => {
                                                  this.setState({
                                                    deleteConfirmation: true,
                                                    deleteMatId: singleMat._id,
                                                    isAvailable:
                                                      singleMat.is_variant_made,
                                                  });
                                                }}
                                                style={{
                                                  color: "#fff",
                                                  fontSize: "13px",
                                                  position: "absolute",
                                                  right: "-7px",
                                                  top: "-8px",
                                                  cursor: "pointer",
                                                  background: "gray",
                                                  borderRadius: "10px",
                                                }}
                                              />
                                              <Edit
                                                onClick={() => {
                                                  this.setState({
                                                    materialEdit: true,
                                                  });
                                                }}
                                                style={{
                                                  color: "#fff",
                                                  fontSize: "22px",
                                                  position: "absolute",
                                                  right: "14px",
                                                  top: "14px",
                                                  cursor: "pointer",
                                                }}
                                              />
                                            </div>
                                          ) : (
                                            <div
                                              style={{
                                                position: "relative",
                                                width: "70px",
                                                height: "53px",
                                              }}
                                            >
                                              <img
                                                className="smallBox"
                                                style={{
                                                  background: "transparent",
                                                  position: "relative",
                                                  cursor: "pointer",
                                                }}
                                                src={
                                                  API.imgUrl +
                                                  singleMat.material_value
                                                }
                                                alt=""
                                                onClick={() => {
                                                  this.setState({
                                                    // materialEdit: true,
                                                    material_value:
                                                      singleMat.material_value,
                                                    material_name:
                                                      singleMat.material_name,
                                                    material_id: singleMat._id,
                                                    compId: comp._id,
                                                  });
                                                }}
                                              />
                                              <CloseIcon
                                                onClick={() => {
                                                  this.setState({
                                                    deleteConfirmation: true,
                                                    deleteMatId: singleMat._id,
                                                    isAvailable:
                                                      singleMat.is_variant_made,
                                                  });
                                                }}
                                                style={{
                                                  color: "#fff",
                                                  fontSize: "13px",
                                                  position: "absolute",
                                                  right: "-7px",
                                                  top: "-8px",
                                                  cursor: "pointer",
                                                  background: "gray",
                                                  borderRadius: "10px",
                                                }}
                                              />
                                              <Edit
                                                onClick={() => {
                                                  this.setState({
                                                    materialEdit: true,
                                                  });
                                                }}
                                                style={{
                                                  color: "#fff",
                                                  fontSize: "22px",
                                                  position: "absolute",
                                                  right: "22px",
                                                  top: "14px",
                                                  cursor: "pointer",
                                                }}
                                              />
                                            </div>
                                          )
                                        ) : null
                                      ) : null}

                                      <div
                                        style={{
                                          inlineSize: "60px",
                                          overflowWrap: "break-word",
                                        }}
                                      >
                                        <p
                                          style={{
                                            fontSize:
                                              window.innerWidth < 1100
                                                ? "12px"
                                                : "14px",
                                            fontFamily: "Inter",
                                            textAlign: "center",
                                            cursor: "pointer",
                                            textTransform: "capitalize",
                                            paddingLeft: "0px",
                                            marginTop:
                                              singleMat.material_type ===
                                              "color"
                                                ? "-9px"
                                                : "6px",
                                          }}
                                        >
                                          {" "}
                                          {singleMat
                                            ? singleMat.material_name
                                            : null}{" "}
                                        </p>
                                      </div>
                                    </div>
                                  ))
                                : null
                              : null}

                            <br />

                            <div
                              className="secondBox"
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                this.setState({ compId: comp._id }, () => {
                                  this.openModal();
                                })
                              }
                            >
                              <AddIcon
                                style={{
                                  marginTop: "14px",
                                  color: "gray",
                                  cursor: "pointer",
                                }}
                              />

                              <Typography
                                className="default"
                                style={{
                                  marginLeft: "0px",
                                  width: "55px",
                                  lineHeight: "19px",
                                }}
                              >
                                Add Material
                              </Typography>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    ))
                  : null}

                <div
                  className="addComp"
                  onClick={() =>
                    this.setState({
                      confirmationComponentAdd: true,
                    })
                  }
                  style={{ cursor: "pointer" }}
                >
                  <div className="inlineWrapper">
                    {this.state.Variants.length > 0 ? (
                      <Typography
                        className="text"
                        onClick={() =>
                          this.setState({
                            confirmationComponentAdd: true,
                          })
                        }
                      >
                        {" "}
                        Add Component
                      </Typography>
                    ) : (
                      <Typography
                        className="text"
                        onClick={() =>
                          this.setState({
                            ShowComponent: true,
                            showFirst: false,
                            showSecond: false,
                            showThird: false,
                            showFourth: false,
                            visible: true,
                          })
                        }
                      >
                        {" "}
                        Add Component
                      </Typography>
                    )}

                    <AddCircleOutlineSharpIcon
                      style={{
                        position: "absolute",
                        right: "23px",
                        top: "15px",
                      }}
                    />
                  </div>
                </div>
                <div
                  className="addComp"
                  style={{
                    position: "relative",
                    paddingBottom: "7px",
                    height: "auto",
                    cursor: "pointer",
                    // height: "110px",
                  }}
                  onClick={() => {
                    this.setState({ openShareModal: true });
                  }}
                >
                  <Typography className="text"> Share</Typography>
                  <p style={{ marginTop: "-7px" }}>
                    {" "}
                    (Unpublished,internal Only){" "}
                  </p>
                  <KeyboardArrowLeftIcon
                    style={{
                      position: "absolute",
                      top: "14px",
                      right: "28px",
                      color: "#565656",
                    }}
                  />
                  <KeyboardArrowRightIcon
                    style={{
                      position: "absolute",
                      top: "14px",
                      right: "16px",
                      color: "#565656",
                    }}
                  />
                </div>
                <div
                  className="addComp"
                  // style={{ width: window.innerWidth < 1000 ? "100%" : "108%" }}
                >
                  <Typography className="text">
                    {this.state.productStatus === "published"
                      ? "Status : Live"
                      : "Status : Draft"}
                  </Typography>
                </div>

                <Button
                  className="saveBtn"
                  type="submit"
                  style={{
                    background: "#E1E1E1",
                    color: "#757575",
                    width: "109%",
                    position: "relative",
                    fontSize: "24px",
                  }}
                  onClick={() => this.openInnewWindow()}
                >
                  Preview{" "}
                  <RemoveRedEyeOutlinedIcon
                    style={{
                      color: "black",
                      marginLeft: "20px",
                      position: "absolute",
                      right: "24px",
                    }}
                  />
                </Button>

                <span onClick={this.publish} style={{ textDecoration: "none" }}>
                  <Button
                    className="saveBtn"
                    type="submit"
                    style={{ width: "109%", fontSize: "24px" }}
                  >
                    {this.state.productStatus === "published"
                      ? "Published"
                      : "Publish"}
                  </Button>
                </span>
                <Typography style={{ color: "red" }}>
                  {this.state.warn1 ? "* Modal file is required." : ""}
                </Typography>
                {/* <div className="chatBoxEdit">
                  <p>Edits</p>
                  <span>
                    do you need any changes to be made? Please share notes for
                    our model maker.
                  </span>
                  <textarea className="chatBox" />
                </div> */}
              </div>
            </Grid>
          </Grid>
          <div id="myModal" className="modal"></div>
          <div className="modal-content">
            <div className="contentArea">
              <h1>How to View in Augmented Reality</h1>
              <p>
                Scan this QR code with your phone to view the object in your
                space. The experience launches directly from your browser - no
                app required!
              </p>
            </div>
            <div id="qrCodeWrapper">
              <QRCode
                value={this.state.value}
                style={{ height: 200, width: 200, marginTop: 12 }}
              />
            </div>
            <span className="closeBtn">Close</span>
          </div>

          {/* Purchase plan modal */}
          <Modal
            visible={this.state.purchasePlan}
            width="400"
            height="300"
            effect="fadeInUp"
            onClickAway={() => this.setState({ purchasePlan: false })}
          >
            <div
              className="confirmationModalPublish"
              style={{ textAlign: "center" }}
            >
              <Typography>
                Please Purchase Plan To Publish Your Product.
              </Typography>
              <Button
                onClick={() =>
                  (window.location.href =
                    "/pricing?modalId=" + this.state.modalId)
                }
                className="DeleteBtnPublish"
                style={{ background: "#bdbdbd" }}
              >
                Click To Purchase Plan
              </Button>
              <Button
                onClick={() => this.setState({ purchasePlan: false })}
                className="DeleteBtnPublish"
                style={{ background: "#bdbdbd" }}
              >
                Cancel
              </Button>
            </div>
          </Modal>

          <Modal
            visible={this.state.visible}
            width="300"
            height="470"
            effect="fadeInUp"
            onClickAway={() => this.closeModal()}
          >
            {this.state.showFirst ? (
              <div className="modalWrapper">
                <img
                  src={yes}
                  alt=""
                  style={{ position: "absolute", right: "44px", top: "10px" }}
                />
                <CloseSharpIcon
                  style={{ position: "absolute", right: "10px", top: "5px" }}
                  onClick={() => this.closeModal()}
                />
                <form action="" id="materialForm" onSubmit={this.addMaterial}>
                  <h1 className="modalHead">Preview</h1>
                  {this.state.type === "color" ? (
                    <div
                      className="modalBox"
                      style={{
                        backgroundColor: this.state.material_value
                          ? this.state.material_value
                          : "red",
                      }}
                    ></div>
                  ) : (
                    <div>
                      {this.state.swatchFile ? (
                        <img
                          src={this.state.swatchFile}
                          width="105px"
                          height="105px"
                          style={{ marginBottom: "10px" }}
                          alt=""
                        />
                      ) : (
                        <img
                          src={rectangle}
                          width="105px"
                          height="105px"
                          style={{ marginBottom: "10px" }}
                          alt=""
                        />
                      )}
                    </div>
                  )}
                  <div className="inputTextWrap">
                    <span className="modalText">Name</span>
                    <input
                      type="text"
                      className="modalInput"
                      required
                      name="material_name"
                      onChange={this.handleInput}
                    />
                  </div>

                  <Typography className="modalMaterialHead">
                    {" "}
                    Material
                  </Typography>

                  <div style={{ marginBottom: "20px" }}>
                    <span
                      className="modalMaterialHead"
                      style={{
                        marginRight: "80px",
                        borderBottom: "none",
                        fontWeight: this.state.type === "color" ? "bold" : 400,
                      }}
                      onClick={() =>
                        this.setState({
                          addColor: true,
                          addSwatch: false,
                          type: "color",
                        })
                      }
                    >
                      Solid Color
                    </span>
                    <img src={verticalLine} className="varticalLine" alt="" />

                    <span
                      className="modalText"
                      style={{
                        fontWeight: this.state.type === "swatch" ? "bold" : 400,
                      }}
                      onClick={() =>
                        this.setState({
                          addSwatch: true,
                          addColor: false,
                          type: "swatch",
                        })
                      }
                    >
                      Swatch
                    </span>
                  </div>

                  {this.state.addColor ? (
                    <div>
                      <div
                        className={
                          this.state.colorPicker
                            ? "inputTextWrap2"
                            : "inputTextWrap"
                        }
                      >
                        <span
                          className="modalText"
                          style={{ marginLeft: " -8px" }}
                        >
                          Hexcode
                        </span>
                        <input
                          type="text"
                          className="modalInput"
                          name="material_value"
                          value={
                            this.state.material_value
                              ? this.state.material_value
                              : "#ff0000"
                          }
                          onClick={() =>
                            this.setState({
                              colorPicker: !this.state.colorPicker,
                            })
                          }
                        />
                        {this.state.colorPicker ? (
                          <SketchPicker
                            name="material_value"
                            color={this.state.material_value}
                            onChangeComplete={this.handleChangeComplete}
                            width="350px"
                            style={{
                              position: "relative",
                              bottom: "157px",
                              left: "2px",
                            }}
                          />
                        ) : null}
                      </div>
                    </div>
                  ) : null}

                  {this.state.addSwatch ? (
                    <div>
                      <Button
                        variant="outlined"
                        size="medium"
                        className="uploadbtnswatch"
                        component="label"
                        style={{ margin: "11px auto" }}
                      >
                        Upload Swatch
                        <input
                          type="file"
                          onChange={this.onImageChange}
                          name="file"
                          hidden
                        />
                      </Button>
                      <Typography className="modalHead">
                        {this.state.file
                          ? "Swatch Uploaded"
                          : "accepted file types : .jpeg, .png"}
                      </Typography>
                    </div>
                  ) : null}
                  <Button
                    className={
                      this.state.colorPicker
                        ? "uploadbtnswatchPicker"
                        : "uploadbtnswatch"
                    }
                    type="submit"
                    // style={{ position: "relative", bottom: "243px" }}
                  >
                    Add Material
                  </Button>
                </form>
              </div>
            ) : null}

            {this.state.ShowComponent ? (
              <div className="modalWrapper" style={{ marginTop: "115px" }}>
                <form action="" id="componentForm" onSubmit={this.addComponent}>
                  <h1 className="modalHead" style={{ fontSize: "18px" }}>
                    Add Component
                  </h1>
                  <img
                    src={yes}
                    alt=""
                    style={{ position: "absolute", right: "44px", top: "10px" }}
                  />
                  <CloseSharpIcon
                    style={{ position: "absolute", right: "10px", top: "5px" }}
                    onClick={() => this.closeModal()}
                  />

                  <div className="inputTextWrap">
                    <span className="modalText">Name</span>
                    <input
                      type="text"
                      className="modalInput"
                      name="compname"
                      required
                      onChange={this.handleInput}
                    />
                  </div>
                  <br />
                  <Button className="addBtn" type="submit">
                    {this.state.loading ? (
                      <CircularProgress />
                    ) : (
                      "Add Component"
                    )}
                  </Button>
                </form>
              </div>
            ) : null}
          </Modal>
          <Modal
            visible={this.state.processing}
            width="450"
            height="200"
            effect="fadeInUp"
          >
            <div style={{ textAlign: "center", marginTop: "66px" }}>
              <p>Please wait...</p>
              <Box sx={{ position: "relative", display: "inline-flex" }}>
                <CircularProgress
                  variant="determinate"
                  value={Math.round(
                    (100 / this.state.variantsCombo.length) *
                      this.state.uploadCount
                  )}
                />
                <Box
                  sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: "absolute",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    variant="caption"
                    component="div"
                    color="text.secondary"
                  >
                    {`${Math.round(
                      (100 / this.state.variantsCombo.length) *
                        this.state.uploadCount
                    )}%`}
                  </Typography>
                </Box>
              </Box>

              <p>
                {this.state.uploadCount +
                  "/" +
                  this.state.variantsCombo.length +
                  " models are uploaded"}
              </p>
            </div>
          </Modal>
          <Modal
            visible={this.state.openEdit}
            width="350"
            height="550"
            effect="fadeInUp"
            onClickAway={() => this.closeModal()}
          >
            <div className="modalWrapper">
              <h4>Edit product details</h4>
              <form action="" onSubmit={this.updateProduct}>
                <Typography>
                  {this.state.filename ? "File updated" : null}
                </Typography>
                <br />
                <div className="contentWrap">
                  <p>Product Name</p>
                  <input
                    type="text"
                    name="title"
                    className="modalInput"
                    style={{ marginBottom: "10px" }}
                    defaultValue={
                      this.state.product ? this.state.product.title : ""
                    }
                    onChange={this.handleInput}
                    required
                  />
                  <Typography className="buyNow">Select Language</Typography>
                  <select
                    name="language"
                    id=""
                    className="modalInput"
                    style={{ width: "100%" }}
                    value={this.state.language}
                    onChange={this.handleInput}
                  >
                    <option value="English">English</option>
                    <option value="French">French</option>
                  </select>
                  <p> Upload Document</p>
                  <Button
                    variant="outlined"
                    size="medium"
                    className="uploadbtn"
                    component="label"
                    style={{
                      border: "1px solid #bdbdbd",
                      width: "100%",
                      textTransform: "capitalize",
                      background: "#bdbdbd73",
                      marginBottom: "10px",
                    }}
                  >
                    Upload Image
                    <input
                      type="file"
                      onChange={this.handleFile}
                      name="filename"
                      hidden
                    />
                  </Button>
                  <br />
                  <Button
                    className="addBtn"
                    type="submit"
                    style={{ width: "100%", marginLeft: "0px" }}
                  >
                    {this.state.loading ? (
                      <CircularProgress color={"white"} size={25} />
                    ) : (
                      "Update"
                    )}
                  </Button>
                </div>
              </form>
            </div>
          </Modal>
          <Modal
            visible={this.state.compnentEdit}
            width="300"
            height="350"
            effect="fadeInUp"
            onClickAway={() => this.closeModal()}
          >
            <div className="modalWrapper" style={{ marginTop: "80px" }}>
              <form
                action=""
                id="componentForm"
                onSubmit={this.updateComponent}
              >
                <h1 className="modalHead" style={{ fontSize: "18px" }}>
                  Edit component details
                </h1>

                <CloseSharpIcon
                  style={{ position: "absolute", right: "10px", top: "5px" }}
                  onClick={() => this.closeModal()}
                />

                <div className="inputTextWrap">
                  <span className="modalText">Name</span>
                  <input
                    type="text"
                    className="modalInput"
                    name="compname"
                    defaultValue={this.state.component_name}
                    required
                    onChange={this.handleInput}
                  />
                </div>
                <br />
                <Button className="addBtn" type="submit">
                  {this.state.loading ? (
                    <CircularProgress />
                  ) : (
                    "Update Component"
                  )}
                </Button>
              </form>
            </div>
          </Modal>
          <Modal
            visible={this.state.materialEdit}
            width="300"
            height="470"
            effect="fadeInUp"
            onClickAway={() => this.closeModal()}
          >
            <div className="modalWrapper">
              <img
                src={yes}
                alt=""
                style={{ position: "absolute", right: "44px", top: "10px" }}
              />
              <CloseSharpIcon
                style={{ position: "absolute", right: "10px", top: "5px" }}
                onClick={() => this.closeModal()}
              />
              <form action="" id="materialForm" onSubmit={this.updateMaterial}>
                <h1 className="modalHead">Preview</h1>
                {this.state.type === "color" ? (
                  <div
                    className="modalBox"
                    style={{
                      backgroundColor: this.state.material_value
                        ? this.state.material_value
                        : "red",
                    }}
                  ></div>
                ) : (
                  <div>
                    {this.state.swatchFile ? (
                      <img
                        src={this.state.swatchFile}
                        width="105px"
                        height="105px"
                        style={{ marginBottom: "10px" }}
                        alt=""
                      />
                    ) : (
                      <img
                        src={rectangle}
                        width="105px"
                        height="105px"
                        style={{ marginBottom: "10px" }}
                        alt=""
                      />
                    )}
                  </div>
                )}
                <div className="inputTextWrap">
                  <span className="modalText">Name</span>
                  <input
                    type="text"
                    className="modalInput"
                    required
                    name="material_name"
                    defaultValue={this.state.material_name}
                    onChange={this.handleInput}
                  />
                </div>

                <Typography className="modalMaterialHead"> Material</Typography>

                <div style={{ marginBottom: "20px" }}>
                  <span
                    className="modalMaterialHead"
                    style={{
                      marginRight: "80px",
                      borderBottom: "none",
                      fontWeight: this.state.type === "color" ? "bold" : 400,
                    }}
                    onClick={() =>
                      this.setState({
                        addColor: true,
                        addSwatch: false,
                        type: "color",
                      })
                    }
                  >
                    Solid Color
                  </span>
                  <img src={verticalLine} className="varticalLine" alt="" />
                  <span
                    className="modalText"
                    style={{
                      fontWeight: this.state.type === "swatch" ? "bold" : 400,
                    }}
                    onClick={() =>
                      this.setState({
                        addSwatch: true,
                        addColor: false,
                        type: "swatch",
                      })
                    }
                  >
                    Swatch
                  </span>
                </div>

                {this.state.addColor ? (
                  <div>
                    <div className="inputTextWrap">
                      <span
                        className="modalText"
                        style={{ marginLeft: " -8px" }}
                      >
                        Hexcode
                      </span>
                      <input
                        type="text"
                        className="modalInput"
                        name="material_value"
                        onClick={() =>
                          this.setState({
                            colorPicker: !this.state.colorPicker,
                          })
                        }
                        value={
                          this.state.material_value
                            ? this.state.material_value
                            : "#000"
                        }
                      />
                    </div>
                  </div>
                ) : null}
                {this.state.colorPicker ? (
                  <SketchPicker
                    name="material_value"
                    color={this.state.material_value}
                    onChangeComplete={this.handleChangeComplete}
                  />
                ) : null}
                {this.state.addSwatch ? (
                  <div>
                    <Button
                      variant="outlined"
                      size="medium"
                      className="uploadbtnswatch"
                      component="label"
                      style={{ margin: "11px auto" }}
                    >
                      Upload Swatch
                      <input
                        type="file"
                        onChange={this.onImageChange}
                        name="file"
                        hidden
                      />
                    </Button>
                    <Typography className="modalHead">
                      {this.state.file
                        ? "Swatch Uploaded"
                        : "accepted file types : .jpeg, .png"}
                    </Typography>
                  </div>
                ) : null}
                <Button className="uploadbtnswatch" type="submit">
                  Update Material
                </Button>
              </form>
            </div>
          </Modal>
          <Modal
            visible={this.state.deleteConfirmation}
            width="400"
            height="300"
            effect="fadeInUp"
            onClickAway={() => this.setState({ deleteConfirmation: false })}
          >
            <div className="confirmationModal" style={{ textAlign: "center" }}>
              <Typography>
                Are you sure you want to delete this material ?
              </Typography>
              <Button
                onClick={() => {
                  this.deleteMaterial(
                    this.state.deleteMatId,
                    this.state.isAvailable
                  );
                }}
                className="DeleteBtn"
              >
                Delete{" "}
              </Button>
              <Button
                onClick={() => this.setState({ deleteConfirmation: false })}
                className="DeleteBtn"
                style={{ background: "#bdbdbd" }}
              >
                Cancel
              </Button>
            </div>
          </Modal>
          <Modal
            visible={this.state.confirmationComponentDelete}
            width="400"
            height="300"
            effect="fadeInUp"
            onClickAway={() =>
              this.setState({ confirmationComponentDelete: false })
            }
          >
            <div className="confirmationModal" style={{ textAlign: "center" }}>
              {this.state.Variants.length > 0 ? (
                <Typography>
                  All Variants of this product will be deleted. Are you sure you
                  want to delete this component ?
                </Typography>
              ) : (
                <Typography>
                  Are you sure you want to delete this component ?
                </Typography>
              )}

              <Button
                onClick={() => {
                  this.deleteComponent(this.state.deleteCompId);
                }}
                className="DeleteBtn"
              >
                Delete{" "}
              </Button>
              <Button
                onClick={() =>
                  this.setState({ confirmationComponentDelete: false })
                }
                className="DeleteBtn"
                style={{ background: "#bdbdbd" }}
              >
                Cancel
              </Button>
              <Typography style={{ color: "green" }}>
                {" "}
                {this.state.notification ? this.setState.successMsg : ""}
              </Typography>
            </div>
          </Modal>
          <Modal
            visible={this.state.confirmationComponentAdd}
            width="400"
            height="300"
            effect="fadeInUp"
            onClickAway={() =>
              this.setState({ confirmationComponentAdd: false })
            }
          >
            <div className="confirmationModal" style={{ textAlign: "center" }}>
              <Typography>
                All Variants of this product will be deleted. Are you sure you
                want to delete Variants ?
              </Typography>

              <Button
                onClick={() => {
                  this.variantDelete();
                }}
                className="DeleteBtn"
              >
                Delete{" "}
              </Button>
              <Button
                onClick={() =>
                  this.setState({ confirmationComponentAdd: false })
                }
                className="DeleteBtn"
                style={{ background: "#bdbdbd" }}
              >
                Cancel
              </Button>
              <Typography style={{ color: "green" }}>
                {" "}
                {this.state.notification ? this.setState.successMsg : ""}
              </Typography>
            </div>
          </Modal>

          <Modal
            visible={this.state.openShareModal}
            width="550"
            height="450"
            effect="fadeInUp"
            onClickAway={() => this.setState({ openShareModal: false })}
          >
            <div className="shareModal web">
              <span
                className="textMain"
                style={{ display: "block", textAlign: "center" }}
              >
                Share
              </span>
              <img
                src={remove}
                width="15px"
                height="15px"
                alt=""
                className="colseModelbtn"
                onClick={() => this.setState({ openShareModal: false })}
              />
              {/* <span
                className="textMain"
                style={{ marginLeft: "84px", fontWeight: 400 }}
              >
                For internal testing only
              </span> */}
              <Grid container spacing={2} style={{ position: "relative" }}>
                <Grid item sm={3}>
                  {" "}
                  <Typography>URL</Typography>{" "}
                </Grid>
                <Grid item sm={7}>
                  <input
                    className="input"
                    value={this.state.value}
                    onChange={({ target: { value } }) =>
                      this.setState({ value, copied: false })
                    }
                    style={{
                      width: window.innerWidth < 1100 ? "81%" : "",
                      marginLeft: window.innerWidth < 1100 ? "10px" : "",
                    }}
                  />
                </Grid>
                <Grid item sm={2} style={{ position: "relative" }}>
                  <CopyToClipboard
                    text={this.state.value}
                    onCopy={() => this.setState({ copied: true })}
                  >
                    <Button
                      className="CopyBtn"
                      style={{
                        marginLeft: window.innerWidth < 1100 ? "-45px" : "",
                      }}
                    >
                      Copy
                    </Button>
                  </CopyToClipboard>
                  {this.state.copied ? (
                    <Typography
                      style={{
                        color: "red",
                        marginTop: window.innerWidth < 1100 ? "" : "40px",
                        marginLeft: window.innerWidth < 1100 ? "" : "22px",
                      }}
                    >
                      Copied.
                    </Typography>
                  ) : null}
                </Grid>
              </Grid>
              <Grid container spacing={2} style={{ position: "relative" }}>
                <Grid item sm={3}>
                  {window.innerWidth < 1100 ? (
                    <Typography>Code</Typography>
                  ) : (
                    <Typography>Embed Code</Typography>
                  )}
                </Grid>
                <Grid item sm={7}>
                  <input
                    type="text"
                    className="input"
                    value={this.state.embedCode}
                    style={{ width: window.innerWidth < 1100 ? "84%" : "" }}
                    onChange={({ target: { embedCode } }) =>
                      this.setState({ embedCopy: false })
                    }
                  />
                </Grid>
                <Grid item sm={2}>
                  <CopyToClipboard
                    text={this.state.embedCode}
                    onCopy={() => this.setState({ embedCopy: true })}
                  >
                    <Button
                      className="CopyBtn"
                      style={{
                        marginLeft: window.innerWidth < 1100 ? "-45px" : "",
                      }}
                    >
                      Copy
                    </Button>
                  </CopyToClipboard>
                  {this.state.embedCopy ? (
                    <Typography
                      style={{
                        color: "red",
                        marginTop: window.innerWidth < 1100 ? "" : "40px",
                        marginLeft: window.innerWidth < 1100 ? "" : "22px",
                      }}
                    >
                      Copied.
                    </Typography>
                  ) : null}
                </Grid>
              </Grid>
              <Grid container spacing={2} style={{ position: "relative" }}>
                <Grid item sm={3}>
                  {" "}
                  <Typography>QR code</Typography>{" "}
                </Grid>
                <Grid item sm={4}>
                  <QRCode
                    id="QRCode"
                    value={
                      "https://portal.actuality.live/modelDynamicviewer/" +
                      productId
                    }
                    style={{ height: 150, width: 150, marginTop: 12 }}
                  />
                </Grid>
                <Grid item sm={5}>
                  <Button
                    className="CopyBtn"
                    onClick={this.downloadQR}
                    style={{
                      marginTop: window.innerWidth < 1100 ? "122px" : "120px",
                      marginLeft: window.innerWidth < 1100 ? "78px" : "",
                      position:
                        window.innerWidth < 1100 ? "absolute" : "relative",
                    }}
                  >
                    Download
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Modal>
          <Modal
            visible={this.state.openBuyNow}
            width="400"
            height="450"
            effect="fadeInUp"
            onClickAway={() => this.setState({ openBuyNow: false })}
          >
            <div
              className="inputWrapper"
              style={{
                padding: "20px",
                textAlign: "center",
                marginTop: "30px",
              }}
            >
              <form action="" onSubmit={this.updateProduct}>
                <div className="modalWrapper">
                  <h4 style={{ marginTop: "-10px" }}>Edit product details</h4>

                  <div className="contentWrap">
                    <p>Link URL</p>
                    <input
                      type="text"
                      name="link_url"
                      className="modalInput"
                      style={{ marginBottom: "10px" }}
                      defaultValue={
                        this.state.product ? this.state.product.link_url : ""
                      }
                      onChange={this.handleInput}
                      required
                    />{" "}
                    <p>Link Title</p>
                    <input
                      type="text"
                      name="link_title"
                      className="modalInput"
                      style={{ marginBottom: "10px" }}
                      defaultValue={
                        this.state.product ? this.state.product.link_title : ""
                      }
                      onChange={this.handleInput}
                      required
                    />{" "}
                    <p>Link Description</p>
                    <input
                      type="text"
                      name="link_description"
                      className="modalInput"
                      style={{ marginBottom: "10px" }}
                      defaultValue={
                        this.state.product
                          ? this.state.product.link_description
                          : ""
                      }
                      onChange={this.handleInput}
                      required
                    />
                    <br />
                    <Button
                      className="addBtn"
                      type="submit"
                      style={{ width: "100%", marginLeft: "0px" }}
                    >
                      {this.state.loading ? (
                        <CircularProgress color={"white"} size={25} />
                      ) : (
                        "Update"
                      )}
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </Modal>
        </div>
      </Container>
    );
  }
}

export default UnPublishModal;
