import React from "react";
import { useParams } from "react-router-dom";
import RecommendedWrapper from "../Components/RecommendedWrapper";
import ModalDynamic from "./ModalDynamic";
import ShareModal from "./ShareModal";

export default function ShareModalFunctional() {
  return (
    <div>
      {/* <ModalDynamic matchparams={id} FROM_SHARE_PAGE={true} /> */}
      <RecommendedWrapper isShare={true} />
    </div>
  );
}
