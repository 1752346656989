import { AppBar, Button, Toolbar, Typography } from "@material-ui/core";
import React, { Component } from "react";
import logo from "../Assets/Images/actualityLogo.png";
import { Link } from "react-router-dom";
class Header extends Component {
  state = {
    userToken: "",
  };
  componentDidMount() {
    let Token = localStorage.getItem("token");
    this.setState(
      {
        userToken: Token,
      },
      () => {
        // console.log(this.state.userToken);
      }
    );
  }

  gotoHomeLink = () => {
    window.location.href = "/";
  };
  gotoBottom = () => {
    let body = document.body,
      html = document.documentElement;

    const height = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );
    window.scroll({ top: height, left: 0, behavior: "smooth" });
  };
  render() {
    return (
      <AppBar position="static" className="bgTheme navbar" elevation={0}>
        <Toolbar style={{ justifyContent: "space-between" }}>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Typography
              variant="h3"
              className="bolder"
              style={{ cursor: "pointer" }}
              onClick={() => this.gotoHomeLink()}
            >
              <span>
                <img
                  src={logo}
                  alt=""
                  width={window.innerWidth < 1100 ? "50%" : "33%"}
                />
              </span>
            </Typography>
          </div>
          <ul
            className={window.innerWidth < 1100 ? "listItemsResp" : "listItems"}
          >
            {window.innerWidth < 1100 ? (
              <li style={{ paddingRight: "30px" }}>
                <Link to="/contact-us" style={{ textDecoration: "none" }}>
                  <Typography variant="h6" className="DemoText">
                    Contact
                  </Typography>
                </Link>
              </li>
            ) : (
              <li style={{ paddingRight: "50px" }}>
                <Link to="/contact-us" style={{ textDecoration: "none" }}>
                  <Typography variant="h6" className="DemoText">
                    Contact
                  </Typography>
                </Link>
              </li>
            )}
            <a
              href="https://portal.actuality.live/"
              style={{ textDecoration: "none" }}
            >
              {window.innerWidth < 1100 ? (
                <li style={{ paddingRight: "50px", width: "100%" }}>
                  <Typography variant="h6" className="DemoText">
                    Sign In
                  </Typography>
                </li>
              ) : (
                <li style={{ paddingRight: "30px" }}>
                  <Typography variant="h6" className="DemoText">
                    Sign In
                  </Typography>
                </li>
              )}
            </a>
          </ul>
          <a
            href="https://calendly.com/rishabh_gupta/30min"
            target="_blank"
            style={{ textDecoration: "none" }}
          >
            <Button
              size={"large"}
              variant="contained"
              className="themeBtn txtShadow rounded"
            >
              Book Demo
            </Button>
          </a>
        </Toolbar>
      </AppBar>
    );
  }
}

export default Header;
