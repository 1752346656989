import { Backdrop, CircularProgress } from "@material-ui/core";
import React, { Component } from "react";

export default class BackdropComponent extends Component {
  render() {
    return (
      <Backdrop
        open={this.props.isVisible}
        style={{ background: this.props.bgColor, zIndex: 9999 }}
      >
        <CircularProgress color="primary" />
      </Backdrop>
    );
  }
}
