const useBodyScrollLock = () => {
  const lockScroll = () => document.body.style.overflow == 'hidden';
  const unLockScroll = () => document.body.style.overflow == 'auto';
  return {
    lockScroll,
    unLockScroll,
  };
};

export default useBodyScrollLock;
