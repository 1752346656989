import { Button, Container, Grid, Typography } from "@material-ui/core";
import ArrowBackRoundedIcon from "@material-ui/icons/ArrowBackRounded";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import Sidebar from "../Components/Sidebar";

export default class PaymentSettings extends Component {
  render() {
    return (
      <Container className="containerSpacingdashBoard" maxWidth={"lg"}>
        <Grid container spacing={2} className="paymentsettings">
          <Grid item sm={2} className="sidebar">
            <Sidebar />
            <Button
              className="productBtn"
              style={{ width: "105%", marginLeft: "-10px", marginTop: "30px" }}
            >
              <Link
                to="/settings"
                style={{ textDecoration: "none", color: "#666666" }}
              >
                Setting
              </Link>
            </Button>
          </Grid>
          <Grid item sm={10} style={{ paddingLeft: "105px" }}>
            <Button
              className="SettingBtn"
              style={{ marginLeft: "-10px", marginTop: "30px" }}
            >
              <Link
                to="/settings"
                style={{ textDecoration: "none", color: "#666666" }}
              >
                <ArrowBackRoundedIcon
                  style={{ position: "absolute", left: "50px", top: "16px" }}
                />
                Setting
              </Link>
            </Button>
            <Typography className="mainText">
              <PersonOutlineIcon
                style={{
                  color: "#8a8a8a",
                  fontSize: "30px",
                  marginTop: "20px",
                  marginRight: "20px",
                }}
              />{" "}
              Payment Settings
            </Typography>
            <div>
              <Typography>Default Payment Methods</Typography>
              <div
                style={{
                  display: "inline-block",
                  borderBottom: "1px solid #ddd",
                }}
              >
                <div className="masterCard"></div>
                <Typography> Mastercard ending in **** </Typography>
              </div>
            </div>
          </Grid>
        </Grid>
      </Container>
    );
  }
}
