import axios from "axios";
import { API } from "../Constants/APIs";

export const getSingleCombination = async (combinationId) => {
  let URL = API.getUrls.getSingleCombination + combinationId;
  try {
    const { data } = await axios.get(URL);
    return data;
  } catch (err) {
    throw err?.response?.data?.message || null;
  }
};

export const getRecommends = async (productId) => {
  try {
    const { data } = await axios.get(
      `${API.getUrls.getRecommendProducts}${productId}?populateCombinations=true`
    );
    return data;
  } catch (err) {
    throw err?.response?.data || [];
  }
};
