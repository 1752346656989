import { API } from "../../../Constants/APIs";

import { useMediaHook } from "../useMediaHook";

import closeModal from "../../../Assets/newIcons/closeModal.png";

import Hamburger from "../Hamburger";

import { isRenderInIframe, sendOriginBack } from "../lib";
import { useEffect } from "react";

const Header = ({
  propState,
  redirectToUrl,
  setState,
  incrementActionClicks,
}) => {
  const isMdOrDown = useMediaHook();
  /* useEffect(() => {
    alert(
      JSON.stringify({
        "API.imgUrl + propState?.productDel?.product_logo":
          API.imgUrl + propState?.productDel?.product_logo,
      })
    );
  }, [propState?.productDel?.product_logo]);
  */
  return isMdOrDown ? (
    <>
      {propState?.productDel?.product_logo ? (
        <div
          style={{
            position: "absolute",
            top: 14,
            left: "0.5rem",
            width: `calc(${isMdOrDown ? 65 : 56.28}px + ${
              isMdOrDown ? 1.5 : 3
            }rem)`,
            // border: "1px solid red",
            display: "grid",
            placeItems: "center",
            backgroundColor: "transparent",
          }}
          className="bgWhiteTopMost"
        >
          <img
            style={{
              borderRadius: isMdOrDown ? 7 : 15,
              backgroundColor: "transparent",
            }}
            src={API.imgUrl + propState?.productDel?.product_logo}
            alt="company_logo"
            width={!isMdOrDown ? 65 : 79}
            height={!isMdOrDown ? 65 : 79}
          />
        </div>
      ) : (
        ""
      )}
      <div
        style={{
          position: "absolute",
          top: 12,
          right: "0.5rem",
          display: "flex",
          // alignItems: "center",
          border: "0px solid green",
          zIndex: 2000,
          // position: "relative",
          height: 40,
          // width: 40,
          marginRight: "0.5rem",
        }}
      >
        <div
          style={{
            position: "relative",
            zIndex: 2000,
          }}
        >
          <Hamburger
            propState={propState}
            redirectToUrl={redirectToUrl}
            setState={setState}
            incrementActionClicks={incrementActionClicks}
          />
        </div>
        {isRenderInIframe() && (
          <img
            src={closeModal}
            onClick={sendOriginBack}
            alt="closeIcon"
            width={isMdOrDown ? 26 : 40}
            height={isMdOrDown ? 26 : 40}
            style={{
              borderRadius: "15px",
              cursor: "pointer",
              marginLeft: "0.5rem",
              border: "0px solid red",
              zIndex: 2000,
              position: "relative",
              top: "5px",
              // right: "1.25rem",
            }}
          />
        )}
      </div>
    </>
  ) : (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        display: "flex",
        alignItems: isMdOrDown ? "center" : "	flex-start",
        padding: "1.25rem 1rem 0rem 0.5rem",
        paddingRight: isMdOrDown ? 20 : "1rem",
        paddingTop: isMdOrDown ? "12px" : "1.25rem",
        border: "0px solid aqua",
        zIndex: isMdOrDown ? 2000 : 1000,
      }}
      className="mvLevel"
    >
      <div
        style={{
          width: `calc(${isMdOrDown ? 65 : 56.28}px + ${
            isMdOrDown ? 1.5 : 3
          }rem)`,
          border: "0px solid red",
          display: "grid",
          placeItems: "center",
          backgroundColor: "transparent",
        }}
        className="bgWhiteTopMost"
      >
        {propState.productDel.product_logo ? (
          <img
            style={{
              borderRadius: isMdOrDown ? 7 : 15,
              backgroundColor: "transparent",
            }}
            src={API.imgUrl + propState.productDel.product_logo}
            alt="company_logo"
            width={!isMdOrDown ? 65 : 79}
            height={!isMdOrDown ? 65 : 79}
          />
        ) : (
          ""
        )}
      </div>

      {!isMdOrDown && (
        <div
          className="bgWhiteTopMost"
          style={{
            // position: "absolute",
            // left: "110px",
            // top: "1.5rem",
            border: "0px solid red",
            display: "inline-block",
            backgroundColor: "transparent",
          }}
        >
          <div
            style={{
              fontWeight: 500,
              fontSize: 20,
              fontStyle: "Normal",
              marginBottom: "0.2rem",
            }}
          >
            {propState.productDel.title ?? ""}
          </div>
          <div
            style={{
              fontSize: 12,
              fontWeight: 500,
              fontStyle: "Normal",
            }}
          >
            {propState.productDel.sub_title ?? ""}{" "}
          </div>
        </div>
      )}
      <div style={{ flexGrow: 1, zIndex: 1 }} className="mvLevel" />
      {isMdOrDown && (
        <Hamburger
          propState={propState}
          redirectToUrl={redirectToUrl}
          setState={setState}
        />
      )}

      {/* {isRenderInIframe() && false && (
        <img
          src={closeModal}
          onClick={sendOriginBack}
          alt="closeIcon"
          width={isMdOrDown ? 26 : 40}
          height={isMdOrDown ? 26 : 40}
          style={{
            borderRadius: "15px",
            cursor: "pointer",
            marginLeft: "0.5rem",
            border: "0px solid red",
            // position: "absolute",
            // top: "1.5rem",
            // right: "1.25rem",
          }}
        />
      )} */}
    </div>
  );
};

export default Header;
