import React, { useRef, useState } from "react";
import Tab from "./Tabs";
import zoomOutScale from "../../Assets/newIcons/zoomOutScale.png";
import rotateShareIcon from "../../Assets/newIcons/rotateShareIcon.png";
import copyCode from "../../Assets/newIcons/copyCode.png";
import { boxShadow, borderRadius, spacing } from "./const";
import BackupOutlinedIcon from "@material-ui/icons/BackupOutlined";
import { Slider, Switch } from "@material-ui/core";
import MVLeft from "./MVLeft";
import { API } from "../../Constants/APIs";
import axios from "axios";
import { copyDataToClipboard } from "../../Helpers/utils";

const EmbedCode = ({ productId, modelSrc }) => {
  const [iframeData, setIframeData] = useState({
    height: "100%",
    width: "100%",
    scaling: 100,
    enableZooming: true,
    bgImg: "",
    src: `actuality.live/modeldynamicviewer/${productId}`,
    xRotate: 0,
    yRotate: 75,
    zRotate: 105,
    cameraOrbit: "0deg 75deg 105%",
  });

  const modelRef = useRef();

  let iframeUrl =
    "<iframe height='" +
    iframeData.height +
    "' width='" +
    iframeData.width +
    "' src='" +
    `https://actuality.live/modeldynamicviewer/${productId}?bg=${iframeData.bgImg
      ?.split?.("/")
      ?.pop?.()}&zoom=${iframeData.enableZooming ? "true" : "false"}&scale=${
      iframeData.scaling / 100
    }&camera-orbit=${iframeData.cameraOrbit.split(" ").join("_")}'` +
    " ></iframe>";

  return (
    <Tab text={`Customize the size and appearance of your embedded model`}>
      <div
        style={{
          display: "flex",
          alignItems: "stretch",
          justifyContent: "space-between",
          paddingTop: spacing,
          paddingBottom: spacing,
        }}
      >
        <MVLeft ref={modelRef} modelSrc={modelSrc} iframeData={iframeData} />
        <div
          style={{
            flex: 0.4,
            boxShadow,
            borderRadius,
            padding: spacing * 2,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            paddingBottom: 2 * spacing,
            paddingTop: 2 * spacing,
          }}
        >
          <div
            style={{
              fontSize: 20,
              fontWeight: 400,
            }}
          >
            Customizations
          </div>
          <div
            style={{
              display: "flex",
              padding: 1,
              paddingTop: spacing,
              paddingBottom: 0 * spacing,
              border: "0px solid red",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                flex: 0.49,
                border: "0px solid green",
              }}
            >
              <div
                style={{
                  fontSize: 16,
                  fontWeight: 400,
                }}
              >
                Width
              </div>
              <input
                type="text"
                max="100"
                min="1"
                value={iframeData.width}
                onChange={(e) => {
                  const val = e?.target?.value;
                  if (typeof val != "undefined") {
                    setIframeData((prev) => ({
                      ...prev,
                      width: val,
                    }));
                  }
                }}
                style={{
                  width: "100%",
                  outline: "1px solid #ddd",
                  border: 0,
                  borderRadius: borderRadius / 2,
                  padding: 8,
                  fontSize: 18,
                  margin: 0,
                  display: "block",
                  marginTop: spacing,
                }}
              />
            </div>
            <div style={{ flex: 0.49, border: "0px solid aqua" }}>
              <div
                style={{
                  fontSize: 16,
                  fontWeight: 400,
                }}
              >
                Height
              </div>{" "}
              <input
                type="text"
                style={{
                  width: "100%",
                  outline: "1px solid #ddd",
                  border: 0,
                  borderRadius: borderRadius / 2,
                  padding: 8,
                  fontSize: 18,
                  margin: 0,
                  display: "block",
                  marginTop: spacing,
                }}
                max="100"
                min="1"
                value={iframeData.height}
                onChange={(e) => {
                  const val = e?.target?.value;
                  if (typeof val != "undefined") {
                    setIframeData((prev) => ({
                      ...prev,
                      height: val,
                    }));
                  }
                }}
              />
            </div>
          </div>
          <TSEDivider />

          <label
            style={{
              border: 0,
              outline: 0,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              background: "white",
              borderRadius: borderRadius / 2,
              boxShadow,
              padding: spacing,
              width: "100%",
              fontSize: 19,
              fontWeight: 400,
              color: "#2e2e2e",
              cursor: "pointer",
            }}
          >
            <BackupOutlinedIcon />
            <input
              type="file"
              id="file"
              style={{
                display: "none",
              }}
              onChange={(e) => {
                const file = e.target.files[0];
                if (!file) return;
                const formData = new FormData();
                formData.append("image", file);

                axios
                  .post(API.imgUrl + "/api/sharePopUp", formData)
                  .then((res) => {
                    if (res.data.image)
                      setIframeData((pre) => ({
                        ...pre,
                        bgImg: res.data.image,
                      }));
                  })
                  .catch((err) => {
                    console.log("err ", err);
                  });
              }}
              accept="image/*, .hdr"
            />
            <span
              style={{
                marginLeft: spacing,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              Upload Background Image
            </span>
          </label>
          {/* </button> */}
          <TSEDivider />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <span
              style={{
                fontSize: 18,
                color: "#2e2e2e",
                fontWeight: 500,
              }}
            >
              Enable Zooming
            </span>
            <span>
              <Switch
                checked={iframeData.enableZooming}
                onChange={(e) => {
                  if (!e?.target?.checked) {
                    modelRef.current.setAttribute("disable-zoom", true);
                  } else modelRef.current.removeAttribute("disable-zoom");
                  setIframeData((prev) => ({
                    ...prev,
                    enableZooming: !prev.enableZooming,
                  }));
                }}
                color="primary"
                name="checkedB"
                inputProps={{ "aria-label": "primary checkbox" }}
              />
            </span>
          </div>
        </div>
      </div>
      <div
        style={{
          boxShadow,
          borderRadius: borderRadius / 2,
          padding: spacing,
          display: "flex",
          alignItems: "center",
          marginTop: spacing,
          paddingTop: spacing * 2,
          paddingBottom: spacing * 2,
        }}
      >
        <img
          src={zoomOutScale}
          width="60"
          height="40"
          style={{
            paddingLeft: spacing * 2,
            display: "block",
          }}
          onClick={() => {
            if (modelRef.current)
              setIframeData((pre) => ({
                ...pre,
                cameraOrbit: modelRef.current.getCameraOrbit().toString(),
              }));
          }}
        />
        <img
          src={rotateShareIcon}
          width="60"
          height="40"
          onClick={() => {
            setIframeData((prev) => ({ ...prev, xRotate: prev.xRotate + 10 }));
          }}
          style={{
            paddingLeft: spacing * 2,
            display: "block",
          }}
        />
        <img
          src={rotateShareIcon}
          width="60"
          height="40"
          onClick={() => {
            setIframeData((prev) => ({ ...prev, yRotate: prev.yRotate + 10 }));
          }}
          style={{
            paddingLeft: spacing * 2,
            display: "block",
          }}
        />
        <img
          src={rotateShareIcon}
          width="60"
          height="40"
          onClick={() => {
            setIframeData((prev) => ({ ...prev, zRotate: prev.zRotate + 10 }));
          }}
          style={{
            paddingLeft: spacing * 2,
            display: "block",
          }}
        />
        <div
          style={{
            width: "1px",
            // height: '100%',
            background: "#2e2e2e",
            border: "1px solid #2e2e2e",
            alignSelf: "stretch",
            marginLeft: spacing * 2,
          }}
        />
        <span
          style={{
            fontSize: 18,
            fontWeight: 500,
            color: "#2e2e2e",
            padding: spacing * 2,
          }}
        >
          Scale
        </span>
        <div
          style={{
            display: "block",
            flex: 1,
            paddingLeft: spacing * 1,
            paddingRight: spacing * 2,
          }}
        >
          <Slider
            aria-labelledby="discrete-slider-always"
            min={1}
            getAriaValueText={(v) => `${v} %`}
            valueLabelDisplay="auto"
            value={iframeData.scaling}
            max={100}
            onChange={(e, scaling) => {
              modelRef.current.setAttribute(
                "scale",
                `${scaling / 100} ${scaling / 100} ${scaling / 100} `
              );
              setIframeData((prev) => ({ ...prev, scaling }));
            }}
          />
        </div>
      </div>
      <div
        style={{
          boxShadow,
          borderRadius: borderRadius / 2,
          padding: spacing * 2,
          display: "flex",
          alignItems: "center",
          marginTop: spacing * 2,
          marginBottom: spacing * 2,
          maxWidth: "100%",
          overflow: "hidden",
        }}
      >
        <span
          style={{
            flex: 1,
            fontSize: 20,
            color: "#2e2e2e",
            fontWeight: 500,
            textOverflow: "clip",
            wordBreak: "break-all",
            paddingRight: spacing * 2,
          }}
        >
          {iframeUrl}
        </span>
        <button
          style={{
            border: 0,
            outline: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: spacing,
            paddingLeft: spacing * 3,
            paddingRight: spacing * 3,
            borderRadius: borderRadius / 2,
            background: "white",
            boxShadow,
          }}
        >
          <img src={copyCode} width={30} height={30} />
          <span
            style={{
              marginLeft: spacing / 2,
              color: "#2e2e2e",
              fontSize: 18,
              fontWeight: 500,
            }}
            onClick={() => {
              copyDataToClipboard(iframeUrl);
            }}
          >
            Copy
          </span>
        </button>
      </div>
    </Tab>
  );
};

export default EmbedCode;
const TSEDivider = () => (
  <div
    style={{
      width: "100%",
      border: "1px solid #ddd",
      marginTop: 2 * spacing,
      marginBottom: 2 * spacing,
    }}
  />
);
