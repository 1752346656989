import arimage from "../../../Assets/newIcons/arIcon.png";
import showDims from "../../../Assets/newIcons/showDims.png";

import ModelPopUp from "../ModelPopUp";
import { useMediaHook } from "../useMediaHook";
const ArBtn = ({
  activateAR,
  incrementSpaceClicks,
  bottom,
  showMVDimension,
  toggleShowMVDimension,
}) => {
  const isMdOrDown = useMediaHook();

  return (
    <div
      style={{
        position: "absolute",
        bottom: bottom ? bottom : isMdOrDown ? "5px" : "1.30rem",
        left: isMdOrDown ? 0 : "50%",
        right: 0,
        display: isMdOrDown ? "flex" : "inline-flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      // className="bgWhiteTopMost"
    >
      <div
        onClick={() => {
          toggleShowMVDimension();
        }}
        style={{
          order: 2,
          overflow: "hidden",
          width: 58,
          height: 58,
          overflow: "hidden",
          display: "grid",
          placeItems: "center",
          position: "relative",
          borderRadius: 10,
          boxShadow: `1px 4px 5px 2px rgba(0, 0, 0, 0.1)`,
          marginLeft: 10,
        }}
      >
        {!showMVDimension ? null : (
          <div
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              left: 0,
              bottom: 0,
              background: "rgb(167, 212, 254)",
              opacity: 0.5,
              zIndex: 99999,
            }}
          />
        )}
        <img
          style={{ zIndex: 99999 }}
          src={showDims}
          width="30px"
          height="30px"
        />
      </div>

      <button
        id="SDM_QR_TEXT"
        onClick={() => {
          incrementSpaceClicks();
          toggleShowMVDimension();
          activateAR();
        }}
        style={{
          display: isMdOrDown ? "flex" : "inline-flex",
          alignItems: "center",
          justifyContent: "center",
          boxShadow: `1px 2px 5px rgba(0, 0, 0, 0.1)`,
          borderRadius: isMdOrDown ? "13px" : `10px`,
          width: isMdOrDown ? "230px" : `245px`,
          height: isMdOrDown ? "58px" : "54.09px",
          margin: 0,
          outline: "none",
          border: "none",
          border: "0px solid red",
        }}
        className="bgWhiteTopMost"
      >
        <img
          src={arimage}
          width={isMdOrDown ? 28 : 38}
          height={isMdOrDown ? 28 : 40}
          alt="ar image icon"
        />
        <div
          style={{
            marginLeft: "0.5rem",
            fontSize: isMdOrDown ? "18px" : "16px",
            fontWeight: isMdOrDown ? 500 : 500,
            wordWrap: "keep-all",
            color: "#2E2E2E",
            letterSpacing: -1,
          }}
        >
          {`View in your space`}
        </div>
      </button>
    </div>
  );
};

export default ArBtn;
