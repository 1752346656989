import { Typography } from "@material-ui/core";
import React, { Component } from "react";
import Lottie from "react-lottie";
import notfound from "../Assets/lottie/36395-lonely-404.json";
import { useLocation } from "react-router-dom";
let location;
const UseLocation = () => {
  location = useLocation();
  return null;
};
class NotFoundPage extends Component {
  state = {
    isStopped: true,
    errorMessage: "Page Not Found",
  };
  componentDidMount() {
    // console.log(this.props);
    // console.log(location);
    // if(location.state.cancelled===true)
    // {
    //   this.setState({
    //     errorMessage: "This User Has Cancelled The Plan."
    //   });
    // }
    if (location.state.leftModels === true) {
      this.setState({
        errorMessage: "This User Dont't Have Left Models.",
      });
    } else if (location.state.leftDays === true) {
      this.setState({
        errorMessage: "This User Plan Validity Has Been Expired.",
      });
    } else if (location.state.leftViews === true) {
      this.setState({
        errorMessage: "This User Dont't Have Left Views.",
      });
    }
  }
  render() {
    const defaultOptions = {
      loop: false,
      autoplay: true,
      animationData: notfound,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };
    return (
      <>
        <div
          style={{
            textAlign: "center",
            marginTop: "120px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {window.innerWidth < 1100 ? (
            <>
              <Lottie
                options={defaultOptions}
                height="40%"
                width="35%"
                isStopped={this.state.isStopped}
              />
              <Typography variant={"h6"} style={{ textAlign: "center" }}>
                {this.state.errorMessage}
              </Typography>
            </>
          ) : (
            <>
              <Lottie
                options={defaultOptions}
                height={300}
                width={600}
                isStopped={this.state.isStopped}
              />
              <Typography variant={"h3"} style={{ textAlign: "center" }}>
                {this.state.errorMessage}
              </Typography>
            </>
          )}

          {<UseLocation />}
        </div>
      </>
    );
  }
}

export default NotFoundPage;
