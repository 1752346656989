import React from 'react';
import eyeIcon from '../../Assets/newIcons/eyeIcon.png';
import { API } from '../../Constants/APIs';
import { Link } from 'react-router-dom';

const ProductCards = ({ products }) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        // border: '1px solid red',
      }}
    >
      {products.map((product) => (
        <div
          key={product._id}
          style={{
            width: '250px',
            overflow: 'hidden',
            padding: 15,
          }}
        >
          <div
            style={{
              boxShadow: `0px 4px 4px rgba(0, 0, 0, 0.25)`,
              borderRadius: 15,
              width: '100%',
              height: '100%',
            }}
          >
            <div
              style={{
                padding: 13,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'stretch',
              }}
            >
              <img
                width={220}
                height={220}
                src={API.imgUrl + product.poster_image_url}
                style={{ margin: 'auto auto', border: '0px solid green' }}
              />
              <p
                style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  fontSize: 18,
                  fontWeight: 500,
                  margin: 0,
                  padding: 0,
                  marginTop: 10,
                }}
              >
                {product.title}
              </p>
              <Link
                to={`/modeldynamicviewer/${product._id}`}
                style={{
                  textDecoration: 'none',
                  // alignSelf: "center",
                  marginBottom: 10,
                }}
              >
                <button
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    // alignSelf: "center",
                    color: '#8C8C8C',
                    fontSize: 14,
                    padding: '10px 15px',
                    background: '#E3E3E3',
                    borderRadius: 7,
                    marginTop: 15,
                    border: 0,
                    outline: 0,
                    cursor: 'pointer',
                  }}
                >
                  <img src={eyeIcon} width={30} height={20} />
                  <span
                    style={{
                      marginLeft: 8,
                    }}
                  >
                    View in your space
                  </span>
                </button>
              </Link>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ProductCards;
