import { Button, Container, Grid, Typography } from "@material-ui/core";
import axios from "axios";
import React, { Component, useState } from "react";
import {
  FiFacebook,
  FiInstagram,
  FiLinkedin,
  FiSlack,
  FiTwitter,
} from "react-icons/fi";
import {
  IoCreateOutline,
  IoMailOutline,
  IoPersonOutline,
} from "react-icons/io5";
import { Link } from "react-router-dom";
import { API } from "../Constants/APIs";
import BackdropComponent from "./BackdropComponent";
import NotificationComponent from "./NotificationComponent";

export default class Footer extends Component {
  state = {
    followUs: [
      {
        title: "facebook",
        icon: FiFacebook,
        link: "https://www.facebook.com/actuality.live",
      },
      {
        title: "LinkedIn",
        icon: FiLinkedin,
        link: "https://www.linkedin.com/company/81686009/admin/",
      },
      {
        title: "Instagram",
        icon: FiInstagram,
        link: "https://www.instagram.com/actuality.live/",
      },
      // {
      //   title: "Slack",
      //   icon: FiSlack,
      //   link: "https://slack.com",
      // },
      // {
      //   title: "Twitter",
      //   icon: FiTwitter,
      //   link: "https://twitter.com",
      // },
    ],
    name: "",
    email: "",
    message: "",
    isLoaderVisible: false,
    showNotification: false,
    notiMesage: "",
    notiStatus: "",
  };
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  getSocials = () => {
    axios.get(API.getUrls.getSocials).then((res) => {
      this.setState({});
    });
  };
  sendMessage = (e, data, onSubmitCB) => {
    e.preventDefault();
    this.setState({ isLoaderVisible: true, showNotification: true });
    const payload = {
      ...data,
      // name: this.state.name,
      // email: this.state.email,
      // message: this.state.message,
    };
    axios.post(API.postUrls.contact, payload).then((res) => {
      try {
        if (res.data.status === "success") {
          this.setState({
            isLoaderVisible: false,
            name: "",
            email: "",
            message: "",
            showNotification: true,
            notiStatus: "success",
          });
          onSubmitCB && onSubmitCB();
          setTimeout(() => {
            this.setState({
              showNotification: false,
              notiMesage: res.data.message,
            });
          }, 5000);
        } else {
          this.setState({
            isLoaderVisible: false,
            notiStatus: "error",
            showNotification: true,
          });
          setTimeout(() => {
            this.setState({
              showNotification: false,
              notiMesage: res.data.message,
            });
          }, 5000);
        }
      } catch (error) {}
    });
  };
  render() {
    return (
      <section className="footer sectionInnerSpacing">
        <div className="bgElement" />
        <Container className="containerSpacing">
          <Grid container spacing={2}>
            <Grid item sm={6}>
              <Typography className="followTitle" gutterBottom>
                Contact us
              </Typography>
              <div className={"iconWrapper"}>
                {this.state.followUs.map((item, index) => (
                  <a
                    key={index}
                    href={item.link}
                    target="_blank"
                    // rel="noreferrer"
                    rel="noopener noreferrer"
                  >
                    {<item.icon className="followicon" />}
                  </a>
                ))}
              </div>
              <Typography className="infoTitle" gutterBottom>
                Information
              </Typography>
              <div className="infoWrapper">
                <Grid container spacing={2}>
                  <Grid item sm={4}>
                    <Link to="/about-us" style={{ textDecoration: "none" }}>
                      {" "}
                      <Typography className="infoItem">About</Typography>
                    </Link>
                    <Link to="/contact-us" style={{ textDecoration: "none" }}>
                      {" "}
                      <Typography className="infoItem">Get in Touch</Typography>
                    </Link>
                    <Typography className="infoItem">How it works</Typography>
                  </Grid>
                  <Grid item sm={4}>
                    <Link to="/blogs" style={{ textDecoration: "none" }}>
                      <Typography className="infoItem">Blog</Typography>
                    </Link>
                    <Link to="/privacy" style={{ textDecoration: "none" }}>
                      {" "}
                      <Typography className="infoItem">
                        Privacy Policy
                      </Typography>
                    </Link>
                    <Link to="/terms" style={{ textDecoration: "none" }}>
                      <Typography className="infoItem">
                        Terms of Service
                      </Typography>
                    </Link>
                  </Grid>
                  <Grid item sm={4}>
                    <a
                      href="https://angel.co/company/actuality-live/jobs"
                      target="_blank"
                      style={{ textDecoration: "none", cursor: "pointer" }}
                    >
                      <Typography className="infoItem">
                        We are hiring!
                      </Typography>
                    </a>
                    {/* <Typography className="infoItem">Resources</Typography> */}
                  </Grid>
                </Grid>
              </div>
            </Grid>
            <Grid item sm={6}>
              <Typography className="followTitle" gutterBottom>
                Contact us
              </Typography>
              <Typography className="email">hello@actuality.live</Typography>
              <Typography className="infoTitle" gutterBottom>
                keep in touch
              </Typography>

              <ContactUsFooter
                formFocus={this.props.formFocus}
                sendMessage={this.sendMessage}
              />
            </Grid>
          </Grid>
        </Container>
        <BackdropComponent
          isVisible={this.state.isLoaderVisible}
          bgColor="transparent"
        />
        <NotificationComponent
          isVisible={this.state.showNotification}
          message={this.state.notiMesage}
          status={this.state.notiStatus}
        />
      </section>
    );
  }
}
const ContactUsFooter = ({ formFocus, sendMessage }) => {
  let [{ name = "", email = "", message = "" }, setForm] = useState({});

  let [{ nameError = "", emailError = "", messageError = "" }, setFormError] =
    useState({});

  const isError = () =>
    !!nameError ||
    !!emailError ||
    !!messageError ||
    !!!name ||
    !!!email ||
    !!!message;

  const handleChange = (e) => {
    // console.log("name ", e.target.name);
    // console.log("value ", e.target.value);

    const newErrorObj = {
      nameError,
      emailError,
      messageError,
      ...validateFields[e.target.name](e.target.value),
    };
    const newObj = { name, email, message, [e.target.name]: e.target.value };

    setFormError(newErrorObj);
    setForm(newObj);
  };

  // const [hello,setState]=useState()
  // ref={(el) => (this.contactForm = el)}
  return (
    <form
      onSubmit={(e) => {
        if (isError()) return;
        //    console.log("first");
        sendMessage(e, { name, email, message }, () =>
          setForm({ name: "", email: "", message: "" })
        );
      }}
    >
      <Grid container spacing={2} style={{ border: "0px solid red" }}>
        <Grid
          item
          sm={6}
          style={{ position: "relative", border: "0px solid blue" }}
        >
          <Grid container>
            <Grid
              item
              sx={12}
              style={{
                border: "0px solid black",
                marginBottom: 0,
                paddingBottom: 0,
              }}
            >
              <input
                required
                name="name"
                type="text"
                placeholder="Your Name"
                className="contactInput"
                onChange={handleChange}
                value={name}
                // this.props.formFocus
                autoFocus={formFocus}
                style={{
                  marginBottom: 5,
                  cursor: "auto",
                }}
              />
              <IoPersonOutline
                className="inputIcon"
                style={{
                  top: nameError ? "45%" : "49%",
                }}
              />
            </Grid>
            <Grid
              item
              sx={12}
              style={{
                margin: 0,
                padding: 0,
              }}
            >
              <p
                style={{
                  padding: 0,
                  margin: 0,
                  color: "rgba(255,0,0,0.7)",
                  border: "0px solid red",
                  textAlign: "left",
                  fontSize: 14,
                  fontStyle: "italic",
                }}
              >
                {nameError}
              </p>
            </Grid>
          </Grid>

          {/* <p>HEllo {this.state.invalidName}</p> */}
        </Grid>
        {/* Start */}
        <Grid
          item
          sm={6}
          style={{ position: "relative", border: "0px solid blue" }}
        >
          <Grid container>
            <Grid
              item
              sx={12}
              style={{
                border: "0px solid black",
                marginBottom: 0,
                paddingBottom: 0,
              }}
            >
              <input
                required
                name="email"
                type="email"
                placeholder="E-mail"
                className="contactInput"
                onChange={handleChange}
                value={email}
                style={{ cursor: "auto", marginBottom: 5 }}
              />
              <IoMailOutline
                className="inputIcon"
                style={{
                  top: "48%",
                }}
              />
            </Grid>
            <Grid
              item
              sx={12}
              style={{
                margin: 0,
                padding: 0,
              }}
            >
              <p
                style={{
                  padding: 0,
                  margin: 0,
                  color: "rgba(255,0,0,0.7)",
                  border: "0px solid red",
                  textAlign: "left",
                  fontSize: 14,
                  fontStyle: "italic",
                }}
              >
                {emailError}
              </p>
            </Grid>
          </Grid>

          {/* <p>HEllo {this.state.invalidName}</p> */}
        </Grid>
        {/* End */}
        {/* <Grid item sm={6} style={{ position: "relative" }}>
          <input
            required
            name="email"
            type="email"
            placeholder="E-mail"
            className="contactInput"
            multiple={true}
            onChange={handleChange}
            value={email}
            style={{ cursor: "auto" }}
          />
          <IoMailOutline className="inputIcon" />
        </Grid> */}
        {/* <Grid item sm={12}>
      <p>HEllo {this.state.invalidName}</p>
    </Grid> */}
        <Grid
          item
          sm={8}
          style={{
            position: "relative",
            border: "0px solid red",
            marginBottom: 0,
            paddingBottom: 0,
          }}
        >
          <div>
            <textarea
              name="message"
              type="text"
              required
              placeholder="Leave your message"
              className="contactInput messageInput"
              onChange={handleChange}
              value={message}
              style={{
                resize: "vertical",
                cursor: "auto",
                // padding: "1rem",
                marginBottom: 0,
              }}
            />

            <IoCreateOutline className="inputIcon messageInputIcon" />
          </div>
          <div
            style={{
              border: "0px solid red",
              marginTop: 5,
            }}
          >
            <p
              style={{
                padding: 0,
                margin: 0,
                color: "rgba(255,0,0,0.7)",
                border: "0px solid red",
                textAlign: "left",
                fontSize: 14,
                fontStyle: "italic",
              }}
            >
              {messageError}
            </p>
          </div>
        </Grid>
        <Grid item sm={4} style={{ position: "relative" }}>
          <Button
            type="submit"
            size={"large"}
            variant="contained"
            className="sendBtnLanding txtShadow rounded"
            disabled={isError()}
          >
            Send
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export const validateFields = {
  message: (val) => ({
    messageError: val?.trim().length > 0 ? "" : "Message is required",
  }),
  name: (val) => ({
    nameError:
      val?.trim().length > 0
        ? val.match(/^[A-Za-z\s]*$/)
          ? ""
          : "Only alphabets are allowed"
        : "Name is required",
  }),
  email: (val) => ({
    emailError:
      val?.trim().length > 0
        ? /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
            val
          )
          ? ""
          : "Enter a valid email"
        : "Email is required",
  }),
};
