import "@google/model-viewer";
import { useRef, useEffect, useLayoutEffect } from "react";
import "./index.scss";

import { API } from "../../../Constants/APIs";
import Header from "./Header";
import { useMediaHook } from "./useMediaHook";

import Components from "./Components";
import RightBox from "./RightBox";
import ActualityLogo from "./ActualityLogo";
import ArBtn from "./ArBtn";
import BottomIcons from "./BottomIcons";
import MobileBottom from "./MobileBottom";
import InstructionModal from "./InstructionModal";
import ModelPopUp from "./ModelPopUp";

import { iframeLoadedListener, isRenderInIframe } from "./lib";
import { addViewpostMetaTag } from "../../../Helpers/addViewport";
import MobileNotSupportAr from "./MobileNotSupportAr";
import { isIOS } from "../../../Helpers/isIOS";
import CombinedVariantAndRecommended from "../../CombinedVariantAndRecommended";
import { variantControllerHeight } from "../../../Helpers/utils";

const __ON_SEND_ANALYTICS = false;
const NewShareDesktopModal = ({
  propState,
  setState,
  redirectToUrl,
  checkSelected,
  selectMaterial,
  hideMaterials,
  matchparams,
  incrementActionClicks,
  incrementSpaceClicks,
  postAnalytics,
}) => {
  useEffect(() => {
    addViewpostMetaTag();
  });

  const isMdOrDown = useMediaHook();

  const modelRef = useRef();
  const arNotSupportedRef = useRef();

  const arModalRef = useRef();
  const isMobile = () => /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const canActivateAr = () => modelRef.current.canActivateAR;

  const activateAR = (rightAway = false) => {
    // alert("i was called");
    if (rightAway) {
      modelRef.current.activateAR();
      return;
    }
    if (!isMobile()) {
      arModalRef.current.openARPopUp();
      return;
    }

    if (true && !modelRef?.current?.canActivateAR && isMobile()) {
      arNotSupportedRef.current.close();
      return;
    }

    modelRef.current.canActivateAR && modelRef.current.activateAR();
  };

  useEffect(() => {
    iframeLoadedListener();
  }, []);

  useLayoutEffect(() => {
    const width =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;
    if (width < 800) {
      modelRef.current.setAttribute("camera-target", "0m 0.2m 0m");
    }
    if (isRenderInIframe()) {
      modelRef.current.setAttribute("camera-target", "0m 0 0m");
    }
  }, []);

  return (
    <>
      <div
        className="SDM"
        style={
          {
            // border: "1px solid red",
          }
        }
      >
        {!isMobile() && (
          <ModelPopUp
            matchparams={matchparams}
            propState={propState}
            ref={arModalRef}
          />
        )}
        <div
          className="wrapper"
          style={{
            maxHeight: isIOS() ? "-webkit-fill-available" : window.innerHeight,
            // border: "1px solid red",
          }}
        >
          <div
            className="modelContainer"
            style={{
              // border: "1px solid green",
              width: isMdOrDown ? "100vw" : "80vw",
              maxHeight: isRenderInIframe()
                ? "100%"
                : isMdOrDown
                ? isIOS()
                  ? "-webkit-fill-available"
                  : window.innerHeight
                : "85vh",
              minHeight: isRenderInIframe()
                ? "100%"
                : isMdOrDown
                ? isIOS()
                  ? "-webkit-fill-available"
                  : window.innerHeight
                : "85vh",
              padding: 0,
              margin: 0,
              position: "relative",
              overflow: "hidden",
            }}
          >
            <model-viewer
              disable-tap
              ref={(elem) => {
                modelRef.current = elem;
              }}
              id="mediaViewer model-viewer"
              src={
                propState.showVariant
                  ? API.imgUrl + propState.selectedVariant.model_file_url
                  : API.imgUrl + propState.ModalImage
              }
              ios-src={API.imgUrl + propState.selectedVariant.usdz_file_url}
              quick-look-browsers="safari chrome"
              camera-controls
              ar-scale={"auto"}
              ar
              ar-modes="scene-viewer webxr quick-look"
              ar-placement={"floor"}
              autoplay
              loading="eager"
              touch-action="none"
              style={{
                // width: "50px",
                // height: "50px",
                background: "#FFFFFF",
                position: "absolute",
                top: 0,
                right: 0,
                left: 0,
                bottom: 0,
              }}
            >
              {propState.Components.length ? (
                <CombinedVariantAndRecommended
                  variantControllerHeight={variantControllerHeight}
                  Component={propState.Components}
                  selectMaterial={selectMaterial}
                  recommends={[]}
                  checkSelected={checkSelected}
                  MODAL_STATUS={"session-started"}
                  isShare={true}
                />
              ) : null}
            </model-viewer>

            <InstructionModal
              canActivateAr={canActivateAr}
              propState={propState}
              matchparams={matchparams}
              activateAR={activateAR}
              incrementActionClicks={incrementActionClicks}
              incrementSpaceClicks={incrementSpaceClicks}
            />
          </div>
        </div>
      </div>
      {isMdOrDown && <MobileNotSupportAr ref={arNotSupportedRef} />}
    </>
  );
};

export default NewShareDesktopModal;
