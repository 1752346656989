// export const domain = "http://localhost:3000/";
export const domain = "https://core.actuality.live/";
// export const domain = "https://coretest.feelxr.co/";
export const API = {
  imgUrl: "https://core.actuality.live",
  // imgUrl: "https://coretest.feelxr.co",
  getUrls: {
    share_products: domain + `api/share_products`,
    myProducts: domain + `api/products/myproducts`,
    getPlans: domain + "api/order",
    getPlansPricing: domain + "api/pricing",
    blogs: domain + "api/blogs",
    services: domain + "api/services",
    Categories: domain + "api/categories",
    getComponents: domain + "api/components/",
    getMaterials: domain + "api/materials",
    getUser: domain + "api/auth/user",
    getProducts: domain + "api/products",
    getSingleProduct: domain + "api/products/",
    productMeta: domain + "api/auth/product_meta",
    variants: domain + "api/variants",
    getUserCat: domain + "api/products/user/categories",
    getSocials: domain + "api/socials",
    getAboutUs: domain + "api/aboutus",
    getPrivacy: domain + "api/privacy",
    getTerms: domain + "api/terms",
    get_public_models: domain + "api/products/admin/models_for_actuality",
    getSubscriptionDetail:
      domain + "api/subscribe/subcsriptionDetailsByProdId/",
    websiteViews: domain + "api/subscribe/websiteViews",
    getRecommendProducts: domain + "api/recommendProducts/",
    getSingleCombination: domain + "api/combinations/single/",
  },
  postUrls: {
    changeStatus: domain + "api/status/",
    contact: domain + "api/contacts",
    login: domain + "api/auth/signin",
    signUp: domain + "api/auth/signup",
    addproduct: domain + "api/products",
    addComponents: domain + "api/components",
    addMaterial: domain + "api/materials",
    addVariants: domain + "api/variants",
    googleLogin: domain + "api/auth/social_login",
    updateProfile: domain + "api/auth/update_profile",
    postViews: domain + "api/products/product_views",
    deleteAllVariants: domain + "api/products/delete_all_variant",
    chargebeePayment: domain + "api/payment",
    order: domain + "api/order",
    addAnalytics: domain + "api/analytics",
    verifyPasscode: domain + "api/products/verifyProductPasscode",
  },
};
