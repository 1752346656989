import { Button, Container, Grid } from '@material-ui/core';
import React, { useState } from 'react';
import checkIconImg from '../../Assets/newIcons/checkIcon.png';

import { Link } from 'react-router-dom';
import Toggle from 'react-toggle';

import crossIconImg from '../../Assets/newIcons/crossIcon3.png';
import { useEffect } from 'react';
import axios from 'axios';
import { API } from '../../Constants/APIs';
const customExtraPoints = [
  {
    light: 'AR Model Optimization starts from',
    bold: '$100 + $20/Variant',
    id: 1,
  },
  { light: 'VR starts from', bold: '$200', id: 2 },
  { light: 'Gamified Walkthrough starts from', bold: '$3000 ', id: 3 },
  { light: 'Photo Realistic Renderings starts from', bold: '$80', id: 4 },
];
const customPlanDetails = [
  'Custom Hosted Models',
  'Custom Monthly Website Views',
  'First Model Optimization Free',
  'Installation Support',
  'Custom Analytics',
  'Animated Models',
  'Dedicated Account Manager',
  'Custom UI/UX',
];
const CrossIcon = () => (
  <div
    style={{
      height: 12,
      width: 12,
      border: '1px solid rgba(0,0,0,0.5)',
      borderRadius: '50%',
      display: 'inline-grid',
      placeItems: 'center',
    }}
  >
    <img src={crossIconImg} height="6" width="6" />
  </div>
);
const PricingSection = () => {
  const [toggleStatus, setToggleStatus] = useState(false);
  const [monthlyPlans, setMonthlyPlans] = useState([]);
  const [yearlyPlans, setYearlyPlans] = useState([]);

  const [showPlans, setShowPlans] = useState(monthlyPlans);
  useEffect(() => {
    (async () => {
      try {
        const yearUri =
          API.getUrls.getPlansPricing +
          '?limit=3&flag=all&status=annual&plan_status=active';
        const monthUri =
          API.getUrls.getPlansPricing +
          '?limit=3&flag=all&status=monthly&plan_status=active';
        const yearlyData = await axios.get(yearUri);
        setYearlyPlans(yearlyData.data.data.docs);
        const monthlyData = await axios.get(monthUri);
        setMonthlyPlans(monthlyData.data.data.docs);
        setShowPlans(monthlyData.data.data.docs);
      } catch (err) {
        // console.log("error in fetching plans ", err);
      }
    })();
  }, []);
  useEffect(() => {
    // console.log({ monthlyPlans, yearlyPlans });
  }, [monthlyPlans, yearlyPlans]);
  return (
    <Container>
      <section>
        <div>
          <div>
            <h1 className="plans">Plans And Pricing</h1>
            <p className="subTitlePlans">30-day free trial, cancel any time.</p>
            <span
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <span className="annualPlan">Monthly Plans</span>
              <span>
                <Toggle
                  checked={toggleStatus ? true : false}
                  onChange={(e) => {
                    setShowPlans(toggleStatus ? monthlyPlans : yearlyPlans);
                    setToggleStatus((p) => !p);
                  }}
                  value={toggleStatus}
                  icons={false}
                />
              </span>
              <span className="monthlyPlan">Annual Plans</span>
            </span>
          </div>
        </div>
      </section>
      <section
        className="sectionInnerSpacingAbout2"
        style={{
          marginLeft: window.innerWidth < 1100 ? '-18px' : '0',
          marginTop: '40px',
        }}
      >
        <Grid container spacing={4}>
          {showPlans.map((plan) => (
            <Grid item sm={3}>
              <div
                className={
                  window.innerWidth < 1100 ? 'pricingCard1100' : 'pricingCard'
                }
              >
                <div
                  className="imgWrapper"
                  style={{
                    cursor: 'auto',
                  }}
                >
                  <h1 className="beginner">{plan.title}</h1>
                  <h1 className="price"> ${parseInt(plan.item_price)}</h1>
                  <div className="month">/month</div>
                  <p className="modelTitle">{plan.sub_title}</p>

                  <a
                    href={`https://portal.actuality.live/pricing?plan=${
                      toggleStatus ? 'yearly' : 'monthly'
                    }`}
                    target="_blank"
                  >
                    <Button
                      size={'large'}
                      variant="contained"
                      className="themeBtn txtShadow roundedPlansPricing buyNow"
                    >
                      {'Buy Now'}
                    </Button>
                  </a>

                  <div
                    className="bottomBorder"
                    style={{
                      top: '11%',
                    }}
                  ></div>

                  <div className="vectorArea">
                    {plan.forUI.points.map((item) => {
                      return (
                        <div
                          className="paddingTop"
                          style={{
                            border: '0px solid red',
                            padding: '10px 0 0 0',
                          }}
                        >
                          <span
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            {item.show ? (
                              <img
                                src={item.show ? checkIconImg : crossIconImg}
                                alt=""
                                width="12px"
                                height="12px"
                              />
                            ) : (
                              <CrossIcon />
                            )}
                            <span className="hostedModels">{item.value}</span>
                          </span>
                        </div>
                      );
                    })}
                  </div>
                  <div className="bottomBorder2"></div>
                  <ExtraPointsDisplayer points={plan.forUI.extraPoints} />
                </div>
              </div>
            </Grid>
          ))}

          <Grid item sm={3}>
            <div
              className={
                window.innerWidth < 1100 ? 'pricingCard1100' : 'pricingCard'
              }
            >
              <div
                className="imgWrapper"
                style={{
                  cursor: 'auto',
                }}
              >
                <h1 className="beginner">Build Your Plan</h1>
                <h1 className="price">Flexible</h1>
                <div className="month">/month</div>
                <p className="modelTitle">
                  All the basics for businesses that are just getting started.
                </p>
                {/* <div className="buyNow"><span className="buyBtn">Contact Seller</span></div> */}
                <Link to="/contact-us" style={{ textDecoration: 'none' }}>
                  <Button
                    size={'large'}
                    variant="contained"
                    className="themeBtn txtShadow roundedPlansPricing buyNow"
                  >
                    Contact Seller
                  </Button>
                </Link>

                <div
                  className="bottomBorder"
                  style={{
                    top: '11%',
                  }}
                ></div>

                <div className="vectorArea">
                  {customPlanDetails.map((item) => (
                    <div
                      className="paddingTop"
                      style={{
                        border: '0px solid red',
                        padding: '10px 0 0 0',
                      }}
                    >
                      <span
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <img
                          src={checkIconImg}
                          alt=""
                          width="12px"
                          height="12px"
                        />
                        <span className="hostedModels">{item}</span>
                      </span>
                    </div>
                  ))}
                </div>
                <div className="bottomBorder2"></div>
                <ExtraPointsDisplayer points={customExtraPoints} />
              </div>
            </div>
          </Grid>
        </Grid>
      </section>
    </Container>
  );
};

export default PricingSection;
const ExtraPointsDisplayer = ({ points }) => {
  return (
    <div
      className="vectorArea2"
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        border: '1px solid white',
        margin: 0,
        // paddingRight: 20,
      }}
    >
      {points.map((point) => (
        <div
          className="paddingTop2"
          key={point.id}
          style={
            {
              // border: "1px solid red",
              // padding: 0,
            }
          }
        >
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              fontSize: 13,
              color: '#000000',
              alignItems: 'center',
              // lineHeight: 1.5,
              boxSizing: 'border-box',
            }}
          >
            <p
              style={{
                // lineHeight: 2,
                margin: 0,
                padding: 0,
                fontSize: 13,
                fontWeight: 400,
                paddingBottom: 4,
                color: '#000000',
                // maxWidth: "70%",
                paddingRight: 10,
                // display:'il'
              }}
            >
              {' '}
              {point.light}
            </p>
            {/* <div
                style={
                  {
                    // width: 40,
                  }
                }
              /> */}
            <b
              style={{
                // fontSize: 12,
                fontWeight: 600,
                color: '#000000',
              }}
            >
              {point.bold}
            </b>{' '}
          </div>

          {/* <div className="hostedModels">
        {point.light}
      </div>
      <span
        className="varient"
        style={{
          border: "0px solid aqua",
        }}
      >
        {point.bold}
        
      </span> */}
        </div>
      ))}
      {/* <div className="paddingTop2">
    <div className="hostedModels">
      AR Model Optimization starts from
    </div>
    <span
      className="varient"
      style={{
        border: "0px solid aqua",
      }}
    >
      {`$${
        plan.title.toLowerCase() == "beginner"
          ? 150
          : plan.title.toLowerCase() == "growth"
          ? 125
          : 100
      } + $20/Variant`}
    </span>
  </div>
  <div className="paddingTop2">
    <span className="hostedModels">
      VR starts from{" "}
    </span>
    <span className="varient2">$300</span>
  </div>
  <div className="paddingTop2">
    <span className="gamefied">
      Gamified Walkthrough starts <br />{" "}
      <span style={{ paddingLeft: "10px" }}>
        from <span className="varient2">$4000</span>{" "}
      </span>
    </span>
  </div>
  <div className="paddingTop2">
    <span className="gamefied">
      Photo Realistic Renderings starts <br />{" "}
      <span style={{ paddingLeft: "10px" }}>
        from <span className="varient2">$150</span>{" "}
      </span>
    </span>
  </div> */}
    </div>
  );
};
